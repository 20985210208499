<div class="tasks-container">
  <div id="controls">
    <mat-slide-toggle
      class="padding"
      [checked]="withoutTags"
      (change)="filterWithoutTags($event.checked)"
      >
    </mat-slide-toggle>
    <span>{{ withoutTags ? "Tasks without tags" : "All tasks" }}</span>
  </div>

  <paginated-list
    [data]="filteredTasks"
    (displayDataChanged)="updateDisplayTasks($event)"
    (dataRequested)="fetchData()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
    >
    <div class="list">
      @for (task of displayTasks; track task) {
        <div
          class="item"
          (click)="openCensor(task.id, $event)"
          >
          <p class="id"><span class="bold">ID: </span>{{ task.id }}</p>
          <p><span class="bold">Title: </span>{{ task.title }}</p>
          <p class="description">
            <span class="bold">Description: </span>{{ task.description }}
          </p>
          <p class="tags">
            <span class="bold">Tags: </span
              >@for (tag of task.tag; track tag) {
              <span>{{ tag.tag }} </span>
            }
          </p>
        </div>
      }
    </div>
  </paginated-list>
</div>
