import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import { MediaPreviewDialogComponent } from './media-preview-dialog/media-preview-dialog.component';

@Component({
    selector: 'app-media-grid',
    templateUrl: './media-grid.component.html',
    styleUrls: ['./media-grid.component.scss'],
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS]
})
export class MediaGridComponent {
  @Input() images: any[] = [];
  @Input() docs: any[] = [];

  constructor(private dialog: MatDialog) {}

  private getAllFiles() {
    const imageFiles = this.images.map((img) => ({
      url: img?.location || img.url,
      name: this.getFileName(img),
      type: 'image' as const,
    }));

    const docFiles = this.docs.map((doc) => ({
      url: doc?.location || doc.url,
      name: this.getFileName(doc),
      type: 'document' as const,
    }));

    return [...imageFiles, ...docFiles];
  }

  openPreviewImage(image: any) {
    const files = this.getAllFiles();
    const currentIndex = files.findIndex(
      (f) => f.url === (image?.location || image.url),
    );

    this.dialog.open(MediaPreviewDialogComponent, {
      width: '90vw',
      height: '90vh',
      panelClass: 'media-preview-dialog',
      data: {
        files,
        currentIndex,
      },
    });
  }

  openDocument(doc: any) {
    const files = this.getAllFiles();
    const currentIndex = files.findIndex(
      (f) => f.url === (doc?.location || doc.url),
    );

    this.dialog.open(MediaPreviewDialogComponent, {
      width: '90vw',
      height: '90vh',
      panelClass: 'media-preview-dialog',
      data: {
        files,
        currentIndex,
      },
    });
  }

  getFileName(file: any): string {
    return file?.originalname || file?.name || 'File';
  }
}
