import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Offer } from 'src/app/models/offer.model';
import { Review } from 'src/app/models/review.model';
import { MatCard } from '@angular/material/card';

@Component({
    selector: 'app-reported-review-card',
    templateUrl: './reported-review-card.component.html',
    styleUrls: ['./reported-review-card.component.scss', '../cards.scss'],
    imports: [MatCard, RouterLink]
})
export class ReportedReviewCardComponent implements OnInit {

  @Input() title = "Review";
  @Input() review: Review;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }
}
