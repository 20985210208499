import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { StateService } from 'src/app/services/state.service';
import { SupportTicketService } from 'src/app/services/support.ticket.service';
import { ConflictService } from 'src/app/services/conflict.service';
import { ConflictCase } from 'src/app/models/conflictCase.model';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [...ALL_IMPORTS],
})
export class NavbarComponent implements OnInit, OnDestroy {
  items: any[];
  miscItems: any[];
  miscItemsOpened: boolean;
  activeTab: string;
  hovered: boolean = false;
  conflictCasesRequireAttention: ConflictCase[] = [];

  activeRequestsCount: number;
  activeSupportTicketsCount: number;
  activeReportsCount: number;

  unresolvedSupportTicketsCount: number;

  constructor(
    private conflictService: ConflictService,
    private supportTicketService: SupportTicketService,
    private router: Router,
    private reportService: ReportService,
    private stateService: StateService,
    private adminActionRequestService: AdminActionRequestService,
  ) {
    this.items = [
      { name: 'Search', route: '/search', icon: 'search', opened: false },
      {
        name: 'Support Tickets',
        route: '/support-tickets-v2',
        icon: 'mail',
        opened: false,
      },
      {
        name: 'Requests',
        route: '/requests',
        icon: 'check',
        opened: false,
      },
      {
        name: 'Users',
        route: '/users',
        icon: 'people',
        opened: false,
        subitems: [
          {
            name: 'Reports',
            route: '/reports',
            icon: 'thumb_down',
            opened: false,
          },
        ],
      },
      {
        name: 'Tasks',
        route: '/tasks',
        icon: 'assignment',
        opened: false,
        subitems: [
          {
            name: 'Cancel Tasks',
            route: '/cancel-task',
            icon: 'cancel',
            opened: false,
          },
          {
            name: 'Cancel Tasks Automation',
            route: '/cancel-task-automation',
            icon: 'cancel',
            opened: false,
          },
        ],
      },
      {
        name: 'Entity Lookup',
        route: '/comments',
        icon: 'comment',
        opened: false,
      },
      {
        name: 'Conflict Cases',
        route: '/conflict-cases',
        icon: 'warning',
        opened: false,
      },
      {
        name: 'Insurance applications',
        route: '/insurance-applications',
        icon: 'currency_exchange',
        opened: false,
      },
    ];
    this.miscItems = [
      {
        name: 'Compromise Calculator',
        route: '/compromise-calculator',
        icon: 'calculate',
        opened: false,
      },
      {
        name: 'Coupons',
        route: '/coupons',
        icon: 'local_offer',
        opened: false,
      },
      {
        name: 'System Events',
        route: '/system-events',
        icon: 'list',
        opened: false,
      },
      {
        name: 'Chatbot Logs',
        route: '/chatbot-logs',
        icon: 'chat',
        opened: false,
      },
      {
        name: 'Pending Payments',
        route: '/payments',
        icon: 'payment',
        opened: false,
      },
      {
        name: 'Notification segments',
        route: '/notification-segments',
        icon: 'notifications',
        opened: false,
        subitems: [
          {
            name: 'Notification logs',
            route: '/notification-logs',
            icon: 'notifications',
            opened: false,
          },
        ],
      },
    ];
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // This event listener takes care of correctly highlighting
        // the currently active tab in the navbar, after navigating
        this.initActiveTab();
      });

    this.reportService.getActiveReportsCount().subscribe((count) => {
      this.activeReportsCount = count;
    });

    this.supportTicketService
      .getUnhandledSupportTicketsCount()
      .subscribe((supportTicketsCount) => {
        this.activeSupportTicketsCount = supportTicketsCount;
      });

    this.supportTicketService
      .getUnresolvedSupportTicketsCount()
      .subscribe((count) => {
        this.unresolvedSupportTicketsCount = count;
      });

    this.conflictService.getAllConflictCases(1).subscribe((res) => {
      this.conflictCasesRequireAttention = res.data.filter(
        (conflictCase) => conflictCase.status === 'pending-decision',
      );
    });
    this.adminActionRequestService
      .getUnhandledRequestsCount()
      .subscribe((res) => {
        this.activeRequestsCount = res;
      });

    this.activeReportListener();
    this.readReportListener();
    this.activeSupportTicketListener();
    this.unresolvedSupportTicketListener();
    this.readSupportTicketListener();
    this.activeRequestsListener();
    this.readRequestListener();

    this.refreshUnresolvedCount();
    this.refreshActiveSupportTicketCount();
  }

  activeRequestsListener() {
    this.stateService.totalActiveAdminActionRequestsComponentSource.subscribe(
      (request) => {
        this.activeRequestsCount++;
      },
    );
  }

  readRequestListener() {
    this.stateService.readActiveAdminActionRequestsComponentSource.subscribe(
      (data) => {
        this.activeRequestsCount--;
      },
    );
  }

  // Functions for listening to socket events for reports
  activeReportListener() {
    this.stateService.totalActiveReportsComponentSource.subscribe((report) => {
      this.activeReportsCount++;
    });
  }
  readReportListener() {
    this.stateService.readActiveReportsComponentSource.subscribe((data) => {
      if (data.ids) {
        this.activeReportsCount -= data.ids.length;
      } else if (data.length) {
        this.activeReportsCount -= data.length;
      } else {
        this.activeReportsCount--;
      }
    });
  }

  refreshUnresolvedCount() {
    this.supportTicketService
      .getUnresolvedSupportTicketsCount()
      .subscribe((count) => {
        this.unresolvedSupportTicketsCount = count;
      });
  }

  unresolvedSupportTicketListener() {
    this.stateService.unresolvedSupportTicketsCountSource.subscribe((delta) => {
      this.unresolvedSupportTicketsCount += delta;
    });
  }

  // Functions for listening to socket events for support tickets
  activeSupportTicketListener() {
    this.stateService.newSupportTicketSource.subscribe((supportTicket) => {
      this.activeSupportTicketsCount++;
      // When a new ticket is created, it's already handled by updateUnresolvedSupportTicketsCount
    });
  }

  readSupportTicketListener() {
    this.stateService.readActiveSupportTicketsComponentSource.subscribe(
      (data) => {
        // Only decrement if we receive an array of IDs or a length
        // Single ticket updates are handled by other events
        if (data.ids) {
          this.activeSupportTicketsCount -= data.ids.length;
        } else if (data.length) {
          this.activeSupportTicketsCount -= data.length;
        }
      },
    );
  }

  // Add a new method to refresh the support ticket count
  refreshActiveSupportTicketCount() {
    this.supportTicketService
      .getUnhandledSupportTicketsCount()
      .subscribe((supportTicketsCount) => {
        this.activeSupportTicketsCount = supportTicketsCount;
      });
  }

  initActiveTab() {
    if (this.router.url.startsWith('/search')) {
      this.activeTab = 'Search';
    } else if (this.router.url.startsWith('/support-tickets-v2')) {
      this.activeTab = 'Support Tickets';
    } else if (this.router.url.startsWith('/requests')) {
      this.activeTab = 'Requests';
    } else if (this.router.url.startsWith('/users')) {
      this.activeTab = 'Users';
    } else if (this.router.url.startsWith('/reports')) {
      this.activeTab = 'Reports';
    } else if (this.router.url.startsWith('/tasks')) {
      this.activeTab = 'Tasks';
    } else if (this.router.url.startsWith('/cancel-task-automation')) {
      this.activeTab = 'Tasks';
    } else if (this.router.url.startsWith('/cancel-task')) {
      this.activeTab = 'Tasks';
    } else if (this.router.url.startsWith('/conversation')) {
      this.activeTab = 'Tasks';
    } else if (this.router.url.startsWith('/comments')) {
      this.activeTab = 'Entity Lookup';
    } else if (this.router.url.startsWith('/payments')) {
      this.activeTab = 'Pending Payments';
    } else if (this.router.url.startsWith('/conflict-cases')) {
      this.activeTab = 'Conflict Cases';
    } else if (this.router.url.startsWith('/insurance-applications')) {
      this.activeTab = 'Insurance applications';
    } else if (this.router.url.startsWith('/compromise-calculator')) {
      this.activeTab = 'Compromise Calculator';
    } else if (this.router.url.startsWith('/coupons')) {
      this.activeTab = 'Coupons';
    } else if (this.router.url.startsWith('/system-events')) {
      this.activeTab = 'System Events';
    } else if (this.router.url.startsWith('/chatbot-logs')) {
      this.activeTab = 'Chatbot Logs';
    } else if (this.router.url.startsWith('/notification-segments')) {
      this.activeTab = 'Notification segments';
    } else if (this.router.url.startsWith('/notification-logs')) {
      this.activeTab = 'Notification logs';
      this.miscItemsOpened = true;
      this.miscItems[4].opened = true;
    } else if (this.router.url.startsWith('/notification-buckets')) {
      this.activeTab = 'Notification buckets';
      this.miscItemsOpened = true;
      this.miscItems[4].opened = true;
    } else {
      this.activeTab = 'Search';
    }
  }

  openItemMenu(item: any, event: Event) {
    event.stopPropagation();
    item.opened = !item.opened;
  }

  openMiscItemMenu() {
    if (this.miscItemsOpened) {
      this.miscItemsOpened = false;
      this.miscItems.forEach((item) => {
        item.opened = false;
      });
      return;
    } else {
      this.miscItemsOpened = true;
    }
  }

  goTo(route: string) {
    const navbar = document.getElementsByClassName('navbar_container')[0];
    const page = document.getElementsByClassName('page_container')[0];
    const mediaWidth = window.matchMedia('(max-width: 768px)');
    if (!navbar.classList.contains('hidden')) {
      if (mediaWidth.matches) {
        navbar.classList.add('hidden');
      }
      page.classList.remove('hidden');
    }
    switch (route) {
      case '/search':
        this.router.navigate(['/search']);
        this.activeTab = 'Search';
        break;
      case '/support-tickets-v2':
        this.router.navigate(['/support-tickets-v2']);
        this.activeTab = 'Support Tickets';
        break;
      case '/requests':
        this.router.navigate(['/requests']);
        this.activeTab = 'Requests';
        break;
      case '/users':
        this.router.navigate(['/users']);
        this.activeTab = 'Users';
        break;
      case '/reports':
        this.router.navigate(['/reports']);
        this.activeTab = 'Reports';
        break;
      case '/tasks':
        this.router.navigate(['/tasks']);
        this.activeTab = 'Tasks';
        break;
      case '/cancel-task-automation':
        this.router.navigate(['/cancel-task-automation']);
        this.activeTab = 'Cancel Tasks Automation';
        break;
      case '/cancel-task':
        this.router.navigate(['/cancel-task']);
        this.activeTab = 'Cancel Tasks';
        break;
      case '/comments':
        this.router.navigate(['/comments']);
        this.activeTab = 'Entity Lookup';
        break;
      case '/payments':
        this.router.navigate(['/payments']);
        this.activeTab = 'Pending Payments';
        break;
      case '/conflict-cases':
        this.router.navigate(['/conflict-cases']);
        this.activeTab = 'Conflict Cases';
        break;
      case '/insurance-applications':
        this.router.navigate(['/insurance-applications']);
        this.activeTab = 'Insurance applications';
        break;
      case '/compromise-calculator':
        this.router.navigate(['/compromise-calculator']);
        this.activeTab = 'Compromise Calculator';
        this.miscItemsOpened = true;
        break;
      case '/coupons':
        this.router.navigate(['/coupons']);
        this.activeTab = 'Coupons';
        break;
      case '/chatbot-logs':
        this.router.navigate(['/chatbot-logs']);
        this.activeTab = 'Chatbot Logs';
        break;
      case '/system-events':
        this.router.navigate(['/system-events']);
        this.activeTab = 'System Events';
        break;
      case '/notification-segments':
        this.router.navigate(['/notification-segments']);
        this.activeTab = 'Notification segments';
        break;
      case '/notification-logs':
        this.router.navigate(['/notification-logs']);
        this.activeTab = 'Notification logs';
        break;
      case '/notification-buckets':
        this.router.navigate(['/notification-buckets']);
        this.activeTab = 'Notification buckets';
        break;
      default:
        this.router.navigate(['/search']);
        this.activeTab = 'Search';
        break;
    }
  }

  hover() {
    this.hovered = true;
    if (this.router.url.startsWith('/reports')) {
      this.items[3].opened = true;
      this.activeTab = 'Reports';
    }
    const url = this.router.url;
    switch (url) {
      case '/reports':
        this.items[3].opened = true;
        this.activeTab = 'Reports';
        break;
      case '/cancel-task-automation':
        this.items[4].opened = true;
        this.activeTab = 'Cancel Tasks Automation';
      case '/cancel-task':
        this.items[4].opened = true;
        this.activeTab = 'Cancel Tasks';
        break;
      case '/notification-logs':
        this.miscItemsOpened = true;
        this.activeTab = 'Notification logs';
        this.miscItems[5].opened = true;
        break;
      case '/notification-buckets':
        this.miscItemsOpened = true;
        this.activeTab = 'Notification buckets';
        this.miscItems[5].opened = true;
        break;
      case '/notification-segments':
        this.miscItemsOpened = true;
        this.activeTab = 'Notification segments';
        break;
      case '/chatbot-logs':
        this.miscItemsOpened = true;
        this.activeTab = 'Chatbot Logs';
        break;
      case '/system-events':
        this.miscItemsOpened = true;
        this.activeTab = 'System Events';
        break;
      case '/coupons':
        this.miscItemsOpened = true;
        this.activeTab = 'Coupons';
        break;
      case '/payments':
        this.miscItemsOpened = true;
        this.activeTab = 'Pending Payments';
        break;
      case '/compromise-calculator':
        this.miscItemsOpened = true;
        this.activeTab = 'Compromise Calculator';
        break;
    }
  }
  unhover() {
    this.hovered = false;
    this.miscItems.forEach((item) => {
      item.opened = false;
    });
    this.items.forEach((item) => {
      item.opened = false;
    });
    const url = this.router.url;
    switch (url) {
      case '/reports':
        this.items[3].opened = false;
        this.activeTab = 'Users';
        break;
      case '/cancel-task-automation':
        this.items[4].opened = false;
        this.activeTab = 'Tasks';
      case '/cancel-task':
        this.items[4].opened = false;
        this.activeTab = 'Tasks';
        break;
      case '/notification-logs':
      case '/notification-buckets':
      case '/notification-segments':
      case '/chatbot-logs':
      case '/system-events':
      case '/compromise-calculator':
        this.miscItemsOpened = false;
        this.activeTab = 'Misc';
        break;
      case '/coupons':
      case '/payments':
        this.miscItemsOpened = false;
        this.activeTab = 'Misc';
        break;
    }
  }

  ngOnDestroy() {
    // Remove the interval clear since we're not using it anymore
    // if (this.countRefreshInterval) {
    //   clearInterval(this.countRefreshInterval);
    // }
  }
}
