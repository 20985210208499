<div class="notification_page_container">
  <div class="header">
    <h1>Notification schedule: {{ notificationId ?? "loading..." }}</h1>
    <button
      class="pinploy_button"
      routerLink="/notification-segments"
      [queryParams]="{ tabs: 1 }"
      >
      Back
    </button>
  </div>
  <div class="details_container">
    @if (notification$ | async; as notification) {
      <div class="top">
        <h3>Notification Schedule details</h3>
        <div [class]="notification.status">{{ notification.status }}</div>
      </div>
      <div class="info">
        <div>Id: {{ notification.id }}</div>
        <div>Title: {{ notification.title }}</div>
        <div>Segment: {{ notification.segmentName }}</div>
        <div>
          Scheduled at:
          {{ notification.scheduledAt | date : "EEEE dd LLLL yyyy HH:mm:ss" }}
        </div>
        <div class="context">
          <h3>Context:</h3>
          <pre>{{ notification.contextData | json }}</pre>
        </div>
      </div>
    } @else {
      <div class="spinner_container">
        <mat-spinner></mat-spinner>
      </div>
    }
    <ng-template #loading>
      <div class="spinner_container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
