<h2 mat-dialog-title>Select Date</h2>

<i style="margin: 10px"
  >This sets the birthday of the user. Note users under 18 is not allowed to
  user the platform.</i
>
<mat-dialog-content class="content">
  <mat-form-field appearance="fill">
    <mat-label>Choose a date</mat-label>
    <mat-datepicker-toggle
      matIconSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <input
      matInput
      [matDatepicker]="picker"
      [max]="maxDate"
      [(ngModel)]="selectedDate"
    />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button
    mat-button
    class="pinploy_button red_button small_button"
    (click)="onCancel()"
  >
    Cancel
  </button>
  <button
    mat-button
    class="pinploy_button small_button"
    (click)="onSubmit()"
  >
    Submit
  </button>
</mat-dialog-actions>
