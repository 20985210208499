<div
  class="navbar_container transition"
  [ngClass]="{
    hidden: hovered === false,
    mat_badge_width:
      activeSupportTicketsCount >= 1 ||
      activeReportsCount >= 1 ||
      activeRequestsCount >= 1,
  }"
  (mouseenter)="hover()"
  (mouseleave)="unhover()"
  >
  @for (item of items; track item; let i = $index) {
    <div>
      <div
        class="nav_item"
        [ngClass]="{ active: activeTab === item.name, hidden: true }"
        (click)="goTo(item.route)"
        >
        <div class="name_and_icon">
          @if (item.name === 'Support Tickets') {
            @if (unresolvedSupportTicketsCount >= 1) {
              <h4>
                <mat-icon
                  [matBadge]="unresolvedSupportTicketsCount"
                  matBadgeColor="warn"
                  >{{ item.icon }}</mat-icon
                  >
                  <p
              [ngClass]="{
                shown: hovered === true,
                not_shown: hovered === false,
              }"
                    >
                    {{ item.name }}
                  </p>
                </h4>
              }
              @if (unresolvedSupportTicketsCount <= 0) {
                <h4>
                  <mat-icon>{{ item.icon }}</mat-icon>
                  <p
              [ngClass]="{
                shown: hovered === true,
                not_shown: hovered === false,
              }"
                    >
                    {{ item.name }}
                  </p>
                </h4>
              }
            }
            @if (
              item.name == 'Users' && activeReportsCount >= 1 && !item.opened
              ) {
              <h4
                >
                <mat-icon
                  matBadge="{{ activeReportsCount }}"
                  matBadgeColor="warn"
                  >{{ item.icon }}</mat-icon
                  >
                  <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                    >
                    {{ item.name }}
                  </p>
                </h4>
              }
              @if (item.name == 'Users' && activeReportsCount >= 1 && item.opened) {
                <h4
                  >
                  <mat-icon>{{ item.icon }}</mat-icon>
                  <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                    >
                    {{ item.name }}
                  </p>
                </h4>
              }
              @if (item.name == 'Users' && activeReportsCount <= 0) {
                <h4>
                  <mat-icon>{{ item.icon }}</mat-icon>
                  <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                    >
                    {{ item.name }}
                  </p>
                </h4>
              }
              @if (
                item.name == 'Conflict Cases' &&
                conflictCasesRequireAttention.length >= 1
                ) {
                <h4
                  >
                  <mat-icon
                    matBadge="{{ conflictCasesRequireAttention.length }}"
                    matBadgeColor="warn"
                    >{{ item.icon }}</mat-icon
                    >
                    <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                      >
                      {{ item.name }}
                    </p>
                  </h4>
                }
                @if (
                  item.name == 'Conflict Cases' &&
                  conflictCasesRequireAttention.length <= 0
                  ) {
                  <h4
                    >
                    <mat-icon>{{ item.icon }}</mat-icon>
                    <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                      >
                      {{ item.name }}
                    </p>
                  </h4>
                }
                @if (item.name == 'Requests' && activeRequestsCount >= 1) {
                  <h4>
                    <mat-icon
                      matBadge="{{ activeRequestsCount }}"
                      matBadgeColor="warn"
                      >{{ item.icon }}</mat-icon
                      >
                      <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                        >
                        {{ item.name }}
                      </p>
                    </h4>
                  }
                  @if (item.name == 'Requests' && !activeRequestsCount) {
                    <h4>
                      <mat-icon>{{ item.icon }}</mat-icon>
                      <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                        >
                        {{ item.name }}
                      </p>
                    </h4>
                  }
                  @if (
                    item.name != 'Support Tickets' &&
                    item.name != 'Conflict Cases' &&
                    item.name != 'Users' &&
                    item.name != 'Requests'
                    ) {
                    <h4
                      >
                      <mat-icon>{{ item.icon }}</mat-icon>
                      <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                        >
                        {{ item.name }}
                      </p>
                    </h4>
                  }
                </div>
                @if (item.subitems) {
                  <div
                    class="drop_down_arrows"
        [ngClass]="{
          shown: hovered === true,
          not_shown: hovered === false,
        }"
                    >
                    @if (!item.opened && item.subitems) {
                      <mat-icon
                        class="pointer"
                        (click)="openItemMenu(items[i], $event)"
                        >arrow_drop_down</mat-icon
                        >
                      }
                      @if (item.opened && item.subitems) {
                        <mat-icon
                          class="pointer"
                          (click)="openItemMenu(items[i], $event)"
                          >arrow_drop_up</mat-icon
                          >
                        }
                      </div>
                    }
                  </div>
                  @if (item.opened && item.subitems) {
                    <div>
                      @for (subitem of item.subitems; track subitem; let j = $index) {
                        <div
                          class="nav_item sub"
                          [ngClass]="{ active: activeTab === subitem.name }"
                          (click)="goTo(subitem.route)"
                          >
                          <div class="{ 'active': activeTab === subitem.name }">
                            @if (activeReportsCount <= 0 && subitem.name == 'Reports') {
                              <h4>
                                <mat-icon>{{ subitem.icon }}</mat-icon
                                  >{{ subitem.name }}
                                </h4>
                              }
                              @if (activeReportsCount >= 1 && subitem.name == 'Reports') {
                                <h4>
                                  <mat-icon
                                    matBadge="{{ activeReportsCount }}"
                                    matBadgeColor="warn"
                                    >{{ subitem.icon }}</mat-icon
                                    >{{ subitem.name }}
                                  </h4>
                                }
                                @if (subitem.name !== 'Reports') {
                                  <h4>
                                    <mat-icon>{{ subitem.icon }}</mat-icon
                                      >{{ subitem.name }}
                                    </h4>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    }

                    <div style="flex-direction: column">
                      <div
                        class="nav_item"
                        [ngClass]="{ hidden: hovered === false, active: activeTab === 'Misc' }"
                        >
                        <div class="name_and_icon">
                          <h4>
                            <mat-icon>more_horiz</mat-icon>
                            <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
                              >
                              Misc
                            </p>
                          </h4>
                        </div>

                        <div
                          class="drop_down_arrows"
                          (click)="openMiscItemMenu()"
        [ngClass]="{
          shown: hovered === true,
          not_shown: hovered === false,
        }"
                          >
                          @if (miscItemsOpened) {
                            <mat-icon
                              class="pointer"
                              >arrow_drop_up</mat-icon
                              >
                            }
                            @if (!miscItemsOpened) {
                              <mat-icon
                                class="pointer"
                                >arrow_drop_down</mat-icon
                                >
                              }
                            </div>
                          </div>

                          @for (item of miscItems; track item; let j = $index) {
                            <div>
                              @if (miscItemsOpened) {
                                <div
                                  class="nav_item sub"
                                  (click)="goTo(item.route)"
                                  [ngClass]="{ active: activeTab === item.name }"
                                  >
                                  <div>
                                    <div>
                                      <h4>
                                        <mat-icon>{{ item.icon }}</mat-icon>
                                        {{ item.name }}
                                      </h4>
                                    </div>
                                  </div>
                                  @if (item.subitems) {
                                    <div
                                      (click)="openItemMenu(item, $event)"
                                      class="drop_down_arrows"
                                      >
                                      @if (!item.opened && item.subitems) {
                                        <mat-icon
                                          class="pointer"
                                          >arrow_drop_down</mat-icon
                                          >
                                        }
                                        @if (item.opened && item.subitems) {
                                          <mat-icon
                                            class="pointer"
                                            >arrow_drop_up</mat-icon
                                            >
                                          }
                                        </div>
                                      }
                                    </div>
                                  }
                                  @if (item.opened && item.subitems) {
                                    <div>
                                      @for (subitem of item.subitems; track subitem) {
                                        <div
                                          class="nav_item subsub"
                                          [ngClass]="{ active: activeTab === subitem.name }"
                                          (click)="goTo(subitem.route)"
                                          >
                                          <div class="{ 'active': activeTab === item.id }">
                                            <h4>
                                              <mat-icon>{{ subitem.icon }}</mat-icon
                                                >{{ subitem.name }}
                                              </h4>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
