<div class="notification_page_container">
  <div class="header">
    <h1>Notification segments: {{ notificationId ?? "loading..." }}</h1>
    <button class="pinploy_button" routerLink="/notification-segments">
      Back
    </button>
  </div>
  <div class="details_container">
    @if (notification$ | async; as notification) {
      <div class="top">
        <h3>Notification segment details</h3>
        <div [class]="notification.status">{{ notification.status }}</div>
      </div>
      <div class="info">
        <div>Id: {{ notification.id }}</div>
        <div>Title: {{ notification.title }}</div>
        <div>Segment: {{ notification.segment }}</div>
        <div>
          Started at:
          {{ notification.started_at | date : "EEEE dd LLLL yyyy HH:mm:ss" }}
        </div>
        <div>
          Ended at:
          {{ notification.ended_at | date : "EEEE dd LLLL yyyy HH:mm:ss" }}
        </div>
        <div>Ellapsed: {{ getEllapsedTime(notification) }}</div>
        <div>Number of success: {{ notification.nb_success }}</div>
        <div>Number of failed: {{ notification.nb_failed }}</div>
        <div class="error_list">
          <div>Errors:</div>
          <ul>
            @for (error of notification.errors; track error) {
              <li>
                {{ error }}
              </li>
            }
          </ul>
        </div>
      </div>
    } @else {
      <div class="spinner_container">
        <mat-spinner></mat-spinner>
      </div>
    }
    <ng-template #loading>
      <div class="spinner_container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
