<div class="dialog_container">
  <div class="sticky_filtering_options">
    <h3
      class="header"
      style="margin: 0px !important"
      >
      Users tasks
    </h3>
    <div class="filteringOptions">
      <div class="search_options">
        <div #status>
          <mat-form-field>
            <mat-label>Filter by status</mat-label>
            <mat-select
              [(value)]="searchStatus"
              (selectionChange)="selectedStatusChange($event)"
              >
              @for (status of statusOptions; track status) {
                <mat-option
                  [value]="status.value"
                  >
                  {{ status.viewValue }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div
          id="date"
          #date
          >
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="Start date"
                (dateChange)="searchStartDateChange($event)"
                />
              <input
                matEndDate
                placeholder="End date"
                (dateChange)="searchEndDateChange($event)"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input
              matInput
              placeholder="Enter a search query"
              [(ngModel)]="searchQuery"
              (ngModelChange)="searchQueryChange($event)"
              (keyup.enter)="searchTasks(true)"
              />
          </mat-form-field>
        </div>

        <div
          style="align-content: center"
          #reset
          >
          <button
            mat-icon-button
            (click)="resetFilter()"
            >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div
          style="display: flex; align-items: center"
          #searchButton
          >
          <button
            mat-fab
            extended
            color="primary"
            (click)="searchTasks(true)"
            >
            <mat-icon>search</mat-icon>
            Search
          </button>
        </div>
      </div>
    </div>
    <mat-paginator
      [length]="userTasks.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="onPageChange($event)"
      >
    </mat-paginator>
  </div>

  <div class="dialog_row_container">
    <div class="content_scroll_container">
      <div class="task_card_container_grid">
        @for (task of paginatedTasks; track task; let i = $index) {
          <div>
            <div class="task_card_container">
              <app-task-card
                [task]="task"
                (closeDialogEvent)="closeDialogEvent($event)"
              ></app-task-card>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
