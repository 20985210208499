import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthGuard,
  PreventLoginGuard,
  IsInsurance,
} from './services/auth.guard';
import { BanService } from './services/ban.service';
import { BucketNotificationFormService } from './services/bucket-notification-form.service';
import { BucketNotificationService } from './services/bucket-notification.service';
import { ChatbotLogService } from './services/chatbot-log.service';
import { CommentService } from './services/comment.service';
import { ConflictService } from './services/conflict.service';
import { CouponService } from './services/coupon.service';
import { CsvDataService } from './services/csv-data.service';
import { DecoratorService } from './services/decorator.service';
import { FaqService } from './services/faq.service';
import { InterceptorService } from './services/interceptor.service';
import { MiscService } from './services/misc.service';
import { NotificationService } from './services/notification.service';
import { OfferService } from './services/offer.service';
import { PaymentService } from './services/payment.service';
import { ReportService } from './services/report.service';
import { StoredCreditsService } from './services/storedCredits.service';
import { SupportTicketService } from './services/support.ticket.service';
import { SystemEventsService } from './services/system-events.service';
import { TagService } from './services/tag.service';
import { TaskService } from './services/task.service';
import { UserService } from './services/user.service';
import { ConversationService } from './services/conversation.service';
import { AdminActionRequestService } from './services/adminActionRequest.service';

export const services = [
  ConversationService,
  UserService,
  StoredCreditsService,
  TaskService,
  OfferService,
  TagService,
  ReportService,
  AuthGuard,
  PreventLoginGuard,
  IsInsurance,
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  CsvDataService,
  BanService,
  PaymentService,
  CouponService,
  MiscService,
  CommentService,
  SupportTicketService,
  FaqService,
  DecoratorService,
  SystemEventsService,
  ChatbotLogService,
  ConflictService,
  NotificationService,
  BucketNotificationService,
  BucketNotificationFormService,
  AdminActionRequestService,
];
