import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { CompressorService } from 'src/app/services/compressor.service';
import { FaqService } from 'src/app/services/faq.service';
import { MiscService } from 'src/app/services/misc.service';
import { StateService } from 'src/app/services/state.service';
import { SupportTicketService } from 'src/app/services/support.ticket.service';
import { MediaGridComponent } from 'src/app/components/media-grid/media-grid.component';
import Quill from 'quill';
import {
  SupportTicket,
  SupportTicketCreationData,
} from 'src/app/models/supportTicket.model';
import { SmartResponsesDialogData } from '../../supporTicketList/smart-responses/smart-responses.component';
import {
  DEFAULT_TEMPLATE,
  TEMPLATE_STORAGE_KEY,
} from '../supportTicketListV2.component';
import { SmartResponsesDialogComponent } from '../../supporTicketList/smart-responses/smart-responses.component';
import { StorageService } from 'src/app/services/storage.service';
import { MessageTemplate } from '../supportTicketListV2.component';
import { Media } from 'src/app/models/media.model';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

// Define RequestCategory interface if it doesn't exist in the models
interface RequestCategory {
  issue: string;
  cat: string;
  subCats?: string[];
  [key: string]: any;
}

@Component({
    selector: 'app-create-support-ticket-dialog',
    templateUrl: './createSupportTicketDialog.component.html',
    styleUrls: ['./createSupportTicketDialog.component.scss'],
    imports: [...ALL_IMPORTS, MediaGridComponent]
})
export class CreateSupportTicketDialogComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('editor', { static: true }) editor: ElementRef;
  @ViewChild('editorToolbar', { static: true }) editorToolbar: ElementRef;
  @ViewChild('imagesPicker', { static: true }) imagesPicker: ElementRef;
  @ViewChild('docsPicker', { static: true }) docsPicker: ElementRef;

  user: User | null = null;
  quill: Quill | null = null;

  mainCats: any[] = [];
  requestCategories: RequestCategory[] = [];
  isUserSpecified: boolean = false;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  supportTicketCreationData: SupportTicketCreationData = {
    taskId: null,
    topic: null,
    subTopic: null,
    mainConcern: '',
    detailConcern: '',
    userId: null,
    email: '',
    faqTopic: null,
    user: null,
    images: [],
    docs: [],
  };
  supportTicket: SupportTicket = new SupportTicket(
    this.supportTicketCreationData,
  );

  constructor(
    private supportTicketService: SupportTicketService,
    private toastr: ToastrService,
    private faqService: FaqService,
    private stateService: StateService,
    private compressor: CompressorService,
    private miscService: MiscService,
    public dialogRef: MatDialogRef<CreateSupportTicketDialogComponent>,
    private dialog: MatDialog,
    private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data && (data.userId || data.user)) {
      this.isUserSpecified = true;
      if (data.user) {
        this.user = data.user;
        this.supportTicketCreationData.userId = data.user.id;
        this.supportTicketCreationData.email = data.user.email;
      } else {
        this.supportTicketCreationData.userId = data.userId;
        this.supportTicketCreationData.email = data.email;
      }
    } else {
      this.supportTicketCreationData.email = data.email;
    }
  }

  ngOnInit(): void {
    this.fetchLocalizedMainCats('dk');
    this.fetchSupportMainCats();
  }

  ngAfterViewInit(): void {
    this.initQuill();
  }

  initQuill() {
    this.quill = new Quill(this.editor.nativeElement, {
      theme: 'snow',
      placeholder: 'Write your reply...',
      modules: {
        toolbar: {
          container: this.editorToolbar.nativeElement,
        },
      },
    });

    // Handle focus event
    this.quill.root.addEventListener('focus', () => {
      this.applyGreetingAndSignature();
    });

    // Handle all text changes (typing, smart responses, mixed)
    this.quill.on('text-change', () => {
      let html = this.quill.root.innerHTML;

      // if getText() is empty, user has deleted all text
      if (this.quill.getText().length <= 0) {
        html = null;
      }

      // Update form with the HTML content
      this.supportTicketCreationData.detailConcern = html;
    });
  }

  getMessageTemplate(content?: string): string {
    const template = this.getStoredTemplate();

    const greeting = this.user
      ? template.greeting.replace('{firstName}', this.user.firstName)
      : template.greeting.replace('{firstName}', '').replace(',', '');

    const signature = template.signature.replace(
      '{supportName}',
      this.storage.user.firstName,
    );

    // Convert \n to <br> for HTML display
    const formattedGreeting = greeting.replace(/\n/g, '<br>');
    const formattedSignature = signature.replace(/\n/g, '<br>');

    return content
      ? `${formattedGreeting}<br><br>${content}${formattedSignature}`
      : `${formattedGreeting}<br><br>${formattedSignature}`;
  }

  getStoredTemplate(): MessageTemplate {
    const stored = this.storage.get(TEMPLATE_STORAGE_KEY);
    return stored || DEFAULT_TEMPLATE;
  }

  applyGreetingAndSignature() {
    if (!this.quill) return;

    if (!this.quill.getText().trim()) {
      const template = this.getMessageTemplate();
      this.quill.clipboard.dangerouslyPasteHTML(template);
    }
  }

  private isHtml(text: string): boolean {
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlTagRegex.test(text.trim());
  }

  openSmartResponses() {
    const dialogRef = this.dialog.open<
      SmartResponsesDialogComponent,
      SmartResponsesDialogData
    >(SmartResponsesDialogComponent, {
      data: {
        supportTicket: {
          ...this.supportTicket,
          ...this.supportTicketCreationData,
        },
      },
      width: '50vw',
      height: '80vh',
    });

    const sub = dialogRef.componentInstance.emailTemplateSelected.subscribe(
      (template) => {
        // Get current cursor position
        const range = this.quill.getSelection(true);

        // Insert a newline before the template if we're not at the start
        if (range.index > 0) {
          this.quill.insertText(range.index, '\n');
          range.index += 1;
        }

        // Insert the template content
        if (template.response) {
          if (this.isHtml(template.response)) {
            // For HTML content, insert at current position
            this.quill.clipboard.dangerouslyPasteHTML(
              range.index,
              template.response,
              'api',
            );
          } else {
            // For plain text, just insert as text
            this.quill.insertText(range.index, template.response);
          }
        }

        // Insert a newline after the template
        const length = this.quill.getLength();
        this.quill.insertText(length, '\n');
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  fetchSupportMainCats(countryCode: string = 'dk') {
    this.supportTicketService.getSupportMainCategories().subscribe((res) => {
      if (res) {
        if (countryCode === 'dk') {
          this.requestCategories = res[0].categories.dk;
        } else {
          this.requestCategories = res[0].categories.eng;
        }
      }
    });
  }

  fetchLocalizedMainCats(countryCode: string) {
    this.faqService.getMainFaqs().subscribe((res) => {
      if (res) {
        if (countryCode === 'dk') {
          this.mainCats = res.DK;
        } else {
          this.mainCats = res.ENG;
        }
      }
    });
  }

  onTopicChange(event) {
    const selectedTopic = event.value;
    this.supportTicketCreationData.topic = selectedTopic.issue;
    this.supportTicketCreationData.subTopic = selectedTopic.cat;
    this.supportTicketCreationData.faqTopic =
      this.supportTicketService.mapSupportCategoryToFaqTopic(selectedTopic.cat);
  }

  async onImagesPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      const filesArray = Array.from(files);
      const uploadedFiles = await this.compressAndUploadImages(filesArray);
      const currentImages = this.supportTicketCreationData.images || [];
      this.supportTicketCreationData.images = [
        ...currentImages,
        ...uploadedFiles.files,
      ];
      input.value = '';
    }
  }

  async onDocumentsPicked(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      const filesArray = Array.from(files);
      const uploadedFiles = await this.uploadDocuments(filesArray);
      const currentDocs = this.supportTicketCreationData.docs || [];
      this.supportTicketCreationData.docs = [
        ...currentDocs,
        ...uploadedFiles.files,
      ];
      input.value = '';
    }
  }

  async compressAndUploadImages(files: File[]): Promise<any> {
    this.stateService.toggleWaitingPage(true);
    return new Promise(async (resolve, reject) => {
      if (files.length > 0) {
        const toCompressedImages = await Promise.all(
          files.map((file) => this.compressor.compress2(file)),
        );
        this.miscService
          .uploadMedia(
            { folderUploadPath: 'supportTicket/message' },
            toCompressedImages,
          )
          .subscribe({
            next: (value) => {
              this.stateService.toggleWaitingPage(false);
              resolve(value);
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              console.error(err);
              reject();
            },
          });
      } else {
        this.stateService.toggleWaitingPage(false);
        reject();
      }
    });
  }

  async uploadDocuments(files: File[]): Promise<any> {
    this.stateService.toggleWaitingPage(true);
    return new Promise((resolve, reject) => {
      if (files.length > 0) {
        this.miscService
          .uploadMedia({ folderUploadPath: 'supportTicket/message' }, files)
          .subscribe({
            next: (value) => {
              this.stateService.toggleWaitingPage(false);
              resolve(value);
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              console.error(err);
              reject();
            },
          });
      } else {
        this.stateService.toggleWaitingPage(false);
        reject();
      }
    });
  }

  removePreviewImage(image: Media) {
    const currentImages = this.supportTicketCreationData.images || [];
    this.supportTicketCreationData.images = currentImages.filter(
      (img: Media) => img !== image,
    );
  }

  removePreviewDoc(doc: Media) {
    const currentDocs = this.supportTicketCreationData.docs || [];
    this.supportTicketCreationData.docs = currentDocs.filter(
      (file: Media) => file !== doc,
    );
  }

  openPreviewImage(image) {
    const imageUrl = image?.location || image.url;
    window.open(imageUrl, '_blank');
  }

  // TODO: Add validation
  createSupportTicket() {
    this.stateService.toggleWaitingPage(true);
    this.supportTicketService
      .createSupportTicketFromAdmin(this.supportTicketCreationData)
      .subscribe({
        next: (supportTicket) => {
          this.toastr.success('Support ticket created successfully');
          this.dialogRef.close(supportTicket);
          this.stateService.toggleWaitingPage(false);
        },
        error: (error) => {
          console.error('Error creating support ticket:', error);
          this.toastr.error('Error creating support ticket');
          this.stateService.toggleWaitingPage(false);
        },
      });
  }
}
