<div class="dialog-container">
  <div class="dialog-header">
    <div class="header-content">
      <h2>{{ currentFile.name }}</h2>
      <span class="file-count"
        >{{ currentIndex + 1 }} / {{ files.length }}</span
      >
    </div>
    <div class="dialog-actions">
      <button
        mat-icon-button
        (click)="downloadFile()"
      >
        <mat-icon>download</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="closeDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="dialog-content">
    <button
      mat-icon-button
      class="nav-button prev"
      [disabled]="!hasPrevious"
      (click)="previousFile()"
      [class.hidden]="!hasPrevious"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>

    @if (currentFile.type === 'image') {
      <img
        [src]="currentFile.url"
        [alt]="currentFile.name"
        [style.transform]="'scale(' + zoomLevel + ')'"
      />

      <div class="zoom-controls">
        <button
          mat-icon-button
          (click)="zoomIn()"
        >
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="resetZoom()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="zoomOut()"
        >
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
    } @else {
      <iframe
        [src]="safeUrl"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    }

    <button
      mat-icon-button
      class="nav-button next"
      [disabled]="!hasNext"
      (click)="nextFile()"
      [class.hidden]="!hasNext"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
