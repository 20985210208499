<div>
  <h3 class="header">Users penalties</h3>
</div>
<div class="dialog_container">
  <mat-tab-group>
    @if (userAppliedPenalties.length !== 0) {
      <mat-tab label="Applied penalties ({{ userAppliedPenalties.length }})">
        <div class="dialog_container_with_fixed_button">
          <!-- Scrollable penalties container -->
          <div class="scrollable_penalties">
            @for (
              penalty of userAppliedPenalties;
              track penalty;
              let i = $index
            ) {
              <div class="dialog_row_container">
                <div class="penalty_index">{{ i + 1 }}</div>
                <div class="penalty_card_container">
                  <app-penalty-card [penalty]="penalty"></app-penalty-card>
                </div>
                <mat-checkbox
                  color="primary"
                  (change)="onAppliedPenaltyCheckboxChange($event, penalty.id)"
                >
                </mat-checkbox>
              </div>
            }
          </div>

          <div class="fixed_button_container">
            <div class="fixed_text_container">
              <mat-form-field appearance="outline">
                <mat-label>Admin note / reason</mat-label>
                <textarea
                  matInput
                  rows="3"
                  placeholder="Add your notes / reason here"
                  [(ngModel)]="appliedAdminReason"
                ></textarea>
              </mat-form-field>
              @if (appliedAdminReason.trim().length < 5) {
                <mat-error>
                  Atleast {{ 5 - appliedAdminReason.trim().length }} more
                  characters
                </mat-error>
              }
              @if (selectedAppliedPenalties.length < 1) {
                <mat-error> Atleast 1 more penalty.</mat-error>
              }
            </div>

            <button
              class="pinploy_button small_button red_button"
              color="primary"
              [disabled]="
                selectedAppliedPenalties.length === 0 ||
                appliedAdminReason.trim().length < 5
              "
              [ngClass]="{
                disabled:
                  selectedAppliedPenalties.length === 0 ||
                  appliedAdminReason.trim().length < 5,
              }"
              (click)="onPayoutFees()"
            >
              Payout fee / fees ({{ selectedAppliedPenalties.length }})
            </button>
          </div>
        </div>
      </mat-tab>
    }

    @if (userPendingPenalties.length !== 0) {
      <mat-tab label="Pending Penalties ({{ userPendingPenalties.length }})">
        <div class="dialog_container_with_fixed_button">
          <!-- Scrollable penalties container -->
          <div class="scrollable_penalties">
            @for (
              penalty of userPendingPenalties;
              track penalty;
              let i = $index
            ) {
              <div class="dialog_row_container">
                <div class="penalty_index">{{ i + 1 }}</div>
                <div class="penalty_card_container">
                  <app-penalty-card [penalty]="penalty"></app-penalty-card>
                </div>
                <mat-checkbox
                  color="primary"
                  (change)="onPendingPenaltyCheckboxChange($event, penalty.id)"
                >
                </mat-checkbox>
              </div>
            }
          </div>

          <!-- Fixed button container -->
          <div class="fixed_button_container">
            <div class="fixed_text_container">
              <mat-form-field appearance="outline">
                <mat-label>Admin note / reason</mat-label>
                <textarea
                  matInput
                  rows="3"
                  placeholder="Add your notes / reason here"
                  [(ngModel)]="pendingAdminReason"
                ></textarea>
              </mat-form-field>
              @if (pendingAdminReason.trim().length < 5) {
                <mat-error>
                  Atleast {{ 5 - pendingAdminReason.trim().length }} more
                  characters
                </mat-error>
              }
              @if (selectedPendingPenalties.length < 1) {
                <mat-error> Atleast 1 more penalty.</mat-error>
              }
            </div>

            <button
              class="pinploy_button small_button red_button"
              color="primary"
              [disabled]="
                selectedPendingPenalties.length === 0 ||
                pendingAdminReason.trim().length < 5
              "
              [ngClass]="{
                disabled:
                  selectedPendingPenalties.length === 0 ||
                  pendingAdminReason.trim().length < 5,
              }"
              (click)="onCancelFees()"
            >
              Cancel fee / fees ({{ selectedPendingPenalties.length }})
            </button>
          </div>
        </div>
      </mat-tab>
    }

    @if (userCanceledPenalties.length !== 0) {
      <mat-tab label="Canceled Penalties ({{ userCanceledPenalties.length }})">
        <div class="dialog_container_with_fixed_button">
          <!-- Scrollable penalties container - no button -->
          <div class="scrollable_penalties full_width">
            @for (
              penalty of userCanceledPenalties;
              track penalty;
              let i = $index
            ) {
              <div class="dialog_row_container">
                <div class="penalty_index">{{ i + 1 }}</div>
                <div class="penalty_card_container">
                  <app-penalty-card [penalty]="penalty"></app-penalty-card>
                </div>
              </div>
            }
          </div>
        </div>
      </mat-tab>
    }

    @if (userCompletedPenalties.length !== 0) {
      <mat-tab
        label="Completed Penalties ({{ userCompletedPenalties.length }})"
      >
        <div class="dialog_container_with_fixed_button">
          <!-- Scrollable penalties container - no button -->
          <div class="scrollable_penalties full_width">
            @for (
              penalty of userCompletedPenalties;
              track penalty;
              let i = $index
            ) {
              <div class="dialog_row_container">
                <div class="penalty_index">{{ i + 1 }}</div>
                <div class="penalty_card_container">
                  <app-penalty-card [penalty]="penalty"></app-penalty-card>
                </div>
              </div>
            }
          </div>
        </div>
      </mat-tab>
    }

    @if (userTransferedPenalties.length !== 0) {
      <mat-tab
        label="Transfered Penalties ({{ userTransferedPenalties.length }})"
      >
        <div class="dialog_container_with_fixed_button">
          <!-- Scrollable penalties container - no button -->
          <div class="scrollable_penalties full_width">
            @for (
              penalty of userTransferedPenalties;
              track penalty;
              let i = $index
            ) {
              <div class="dialog_row_container">
                <div class="penalty_index">{{ i + 1 }}</div>
                <div class="penalty_card_container">
                  <app-penalty-card [penalty]="penalty"></app-penalty-card>
                </div>
              </div>
            }
          </div>
        </div>
      </mat-tab>
    }
  </mat-tab-group>
</div>
