import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { UserNoteComponent } from '../../cards/user-card/user-note-card/user-note-card.component';

@Component({
    selector: 'app-user-notes',
    styleUrls: ['../dialogs.scss', './user-notes.component.scss'],
    templateUrl: './user-notes.component.html',
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatButtonModule,
        UserNoteComponent,
    ]
})
export class UserNotesDialogComponent implements OnInit {
  userNotes: any[] = [];
  userId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
  ) {
    this.userId = data.userId;
  }

  ngOnInit(): void {
    this.userService.getUserNotes(this.userId, 1, true).subscribe({
      next: (res) => {
        this.userNotes = res.data;
      },
    });
  }
}
