import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { format } from 'date-fns';
import { SystemEventsService } from '../../services/system-events.service';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-system-events',
    templateUrl: './system-events.component.html',
    styleUrls: ['./system-events.component.scss'],
    imports: [...ALL_IMPORTS, PaginatedList]
})
export class SystemEvents implements AfterViewInit, OnInit, OnDestroy {
  eventName?: string;
  userId?: number;
  taskId?: number;
  offerId?: number;
  data: any[] = [];
  filteredData: any[] = [];
  displayData: any[] = [];
  hideNotificationEvents = true;

  filterKeys: string[] = ['eventName', 'userId', 'taskId'];
  lastPage = 0;
  hasMore = true;
  isBusy = false;

  constructor(
    private title: Title,
    private systemEventsService: SystemEventsService,
    private authService: AuthService,
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.setMetaTitle();
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }

  setMetaTitle(): void {
    this.title.setTitle(`System Events`);
  }

  fetchData(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      const { eventName, userId, taskId, offerId } = this;

      const query = {
        eventName,
        userId,
        taskId,
        offerId,
        page: this.lastPage + 1,
      };

      this.systemEventsService.getSystemEvents(query).subscribe(
        (res) => {
          this.data = this.data.concat(res.events);
          this.hasMore = res.hasMore;
          this.lastPage += 1;
        },
        (err) =>
          this.authService.handleError(err, 'Failed to get system events!'),
        () => (this.isBusy = false),
      );
    }
  }

  applyFilters(): void {
    this.data = [];
    this.displayData = [];
    this.lastPage = 0;
    this.hasMore = true;
    this.fetchData();
  }

  updateDisplayData(data: any[]) {
    this.displayData = data;
  }

  getTimestamp(timestamp: string) {
    return format(new Date(timestamp), 'dd-MM-yyyy HH:mm');
  }

  onClick(index: number) {
    let element =
      document.getElementsByClassName('event')[index].lastElementChild;
    if (!element) {
      return;
    }

    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden');
    } else {
      element.classList.add('hidden');
    }
  }
}
