import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OfferService } from '../../services/offer.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Offer } from '../../models/offer.model';
import { User } from 'src/app/models/user.model';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-offer-list',
    templateUrl: './offerList.component.html',
    styleUrls: ['./offerList.component.scss'],
    imports: [PaginatedList, ...COMMON_IMPORTS]
})
export class OfferListComponent {
  offers: Offer[] = [];
  displayOffers: Offer[] = [];
  users: User[] = [];
  filterKeys: string[] = ['id', 'message', 'taskId', 'user', 'fullName'];
  hasMore: boolean = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private offerService: OfferService,
    private userService: UserService,
  ) {}

  fetchOffers(): void {
    if (this.hasMore) {
      this.offerService.getOffers().subscribe(
        (res) => {
          this.offers = res.map((val) => ({
            ...val,
            fullName: val.user.firstName + ' ' + val.user.lastName,
          }));
          this.hasMore = false;
        },
        (err) => this.authService.handleError(err, 'Failed to get offers!'),
      );
    }
  }

  updateDisplayOffers(offers: Offer[]): void {
    this.displayOffers = offers;
    const userIds: number[] = Array.from(
      new Set(offers.map((offer) => offer.userId)),
    );
    if (userIds.length > 0) {
      this.userService.getMultipleUsers(userIds).subscribe(
        (res) => (this.users = res),
        (err) => this.authService.handleError(err, 'Failed to get users!'),
      );
    }
  }

  getOfferUserName(userId: number): string {
    if (this.users.length > 0) {
      const user = this.users.find((user) => user.id === userId);
      return user ? `${user.firstName} ${user.lastName}` : 'Loading...';
    }
  }

  getOfferUserEmail(userId: number): string {
    if (this.users.length > 0) {
      const user = this.users.find((user) => user.id === userId);
      return user ? user.email : 'Loading...';
    }
  }
}
