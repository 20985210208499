<div class="dialog_container">
  <i
    class="material-icons exit_icon"
    (click)="close('close')"
    >close</i
  >
  <h3>{{ title }}</h3>

  @if (descriptions.length > 0) {
    <div class="text_info">
      @for (description of descriptions; track description) {
        <p>{{ description }}</p>
      }
    </div>
  }
  @if (object) {
    <div class="object_info">
      @for (key of objectKeys; track key) {
        <p>
          {{ key }}: {{ object[key] }}

          @if (key === 'dob') {
            (<a
              class="link"
              (click)="openDatePicker(object[key])"
              >Change</a
            >)
          }
        </p>
      }
    </div>
  }
  <div class="buttons">
    <button
      class="pinploy_button small_button"
      (click)="close('close')"
    >
      OK
    </button>
  </div>
</div>
