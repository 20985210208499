<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper" [ngClass]="{ blur: isLoading }">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>New Gift Card</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="gift-card-info">
          <div class="link-icon-area">
            <a
              target="_blank"
              matTooltip="Open payment in Stripe"
              [href]="'https://dashboard.stripe.com/payments/' + giftCardData?.payment_intent"
            >
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>
          <div class="info-box">
            <h4>Gift Card Details</h4>
            @if (giftCardData?.amount) {
              <p>Amount: {{ giftCardData.amount / 100 }} kr</p>
            }
            @if (giftCardData?.code) {
              <p>Code: {{ giftCardData.code }}</p>
            }
            @if (giftCardData?.email) {
              <p>Email: {{ giftCardData.email }}</p>
            }
          </div>
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
