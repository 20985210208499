<p>Uses</p>
<paginated-list
  [data]="couponUses"
  (displayDataChanged)="updateDisplayCouponUses($event)"
  (dataRequested)="fetchMoreCouponUses()"
  [filterKeys]="filterKeys"
  [moreDataAvailable]="hasMore"
  >
  @for (couponUse of displayCouponUses; track couponUse) {
    <div class="coupon-use-entry">
      <div class="column">
        <p class="id"><span class="bold">Id: </span>{{ couponUse.id }}</p>
        <p><span class="bold">Coupon Id: </span>{{ couponUse.couponId }}</p>
        <p><span class="bold">Task Id: </span>{{ couponUse.taskId }}</p>
        <p><span class="bold">Offer Id: </span>{{ couponUse.offerId }}</p>
        <p><span class="bold">TaskOwner Id: </span>{{ couponUse.taskOwnerId }}</p>
        <p>
          <span class="bold">Handyhander Id: </span>{{ couponUse.handyhanderId }}
        </p>
      </div>
      <div class="column">
        <p>
          <span class="bold">Amount: </span>{{ formatAmount(couponUse.amount) }}
        </p>
        <p>
          <span class="bold">Transfer Id: </span
            >{{ formatString(couponUse.transferId) }}
          </p>
          <p>
            <span class="bold">Reversed: </span
              >{{ formatAmount(couponUse.reversed) }}
            </p>
            <p>
              <span class="bold">Created At: </span
                >{{ formatTimestamp(couponUse.createdAt) }}
              </p>
              <p>
                <span class="bold">Updated At: </span
                  >{{ formatTimestamp(couponUse.updatedAt) }}
                </p>
                <p>
                  <span class="bold">Deleted At: </span
                    >{{ formatTimestamp(couponUse.deletedAt) }}
                  </p>
                </div>
              </div>
            }
          </paginated-list>
          <button (click)="close.emit()">Close</button>
