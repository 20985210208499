import { Component, Input, OnInit, output } from '@angular/core';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import { ToastrService } from 'ngx-toastr';
import { AdminActionRequest } from 'src/app/models/adminActionRequest.model';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';
import { fadeUpAnimation } from 'src/app/animations/fade-animations';

@Component({
    selector: 'app-request-payment-failed',
    templateUrl: './requestPaymentFailed.component.html',
    styleUrls: [
        './requestPaymentFailed.component.scss',
        '../requestHandlingPage.component.scss',
    ],
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS],
    providers: [],
    animations: [fadeUpAnimation]
})
export class RequestPaymentFailedComponent implements OnInit {
  @Input({ required: true }) request: AdminActionRequest;
  requestHandledEvent = output<AdminActionRequest>();

  isLoading: boolean = false;
  requestHandled: boolean = false;

  charge: any;
  failedCharges: any[];
  userId: string;
  taskId: string;
  links: { text: string; url: string }[];

  constructor(
    private adminActionRequestService: AdminActionRequestService,
    private toastr: ToastrService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.charge = this.request?.data?.charge;
    this.userId = this.request?.data?.userId;
    this.taskId = this.request?.data?.taskId;
    this.links = this.request?.data?.links;
    this.failedCharges = this.request?.data?.failedCharges;
  }

  handleRequest() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'Payment failed request marked as handled',
            'Success',
          );
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );

          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }
}
