import { Component, Input, OnInit, Output } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { MiscService } from 'src/app/services/misc.service';
import { ToastrService } from 'ngx-toastr';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';

import { EventEmitter } from '@angular/core';
import { UserCardComponent } from '../../../components/cards/user-card/user-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';
import { MatIconModule } from '@angular/material/icon';
@Component({
    selector: 'app-request-rejected-censorring',
    templateUrl: './requestRejectedCensorring.component.html',
    styleUrls: [
        './requestRejectedCensorring.component.scss',
        '../requestHandlingPage.component.scss',
    ],
    animations: [
        trigger('fadeUpAnimation', [
            transition(':leave', [
                animate('1000ms ease-out', keyframes([
                    style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }), // Initial state
                    style({ opacity: 1, transform: 'translateY(-2%)', offset: 0.4 }), // Start slow
                    style({ opacity: 0, transform: 'translateY(-5%)', offset: 1 }), // Dissapear
                ])),
            ]),
        ]),
    ],
    imports: [
        ...COMMON_IMPORTS,
        MatProgressSpinnerModule,
        MatIconModule,
        UserCardComponent,
    ]
})
export class RequestRejectedCensorringComponent implements OnInit {
  @Input() request: any;
  @Output() requestHandledEvent = new EventEmitter<any>();
  requestHandled: boolean = false;
  isLoading: boolean = false;

  constructor(
    private toastr: ToastrService,
    private stateService: StateService,
    private miscService: MiscService,
    private adminActionRequestService: AdminActionRequestService,
  ) {}

  ngOnInit() {}

  handleRequest(answer: 'accept' | 'decline') {
    this.isLoading = true;
    const resourceTypeToUserIdMap = {
      offer_message: this.request.offer?.userId,
      offer_comment: this.request.offerComment?.userId,
      task_comment: this.request.taskComment?.userId,
    };

    const userId = resourceTypeToUserIdMap[this.request.resourceType];
    if (userId) {
      this.handleRequestWithUserId(answer, userId);
    }
  }

  handleRequestWithUserId(answer: 'accept' | 'decline', userId: number) {
    this.miscService
      .handleRejectedCensoringRequest(this.request, userId, answer)
      .subscribe((res) => {
        if (res) {
          this.handleSuccess();
        } else {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        }
      });
  }

  handleSuccess() {
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.toastr.success('Request handled', 'Success');
          this.requestHandled = true;
          this.isLoading = false;
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );
        },
        error: () => {
          this.toastr.error('Something went wrong', 'Error');
          this.isLoading = false;
        },
      });

    setTimeout(() => {
      this.requestHandledEvent.emit(this.request);
    }, 999);
  }
}
