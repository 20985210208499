<div class="page_container">
  <h1 class="page-title">Insurance Applications</h1>

  <paginated-list
    [data]="insuranceApplications"
    [filterKeys]="filterKeys"
    (dataRequested)="fetchData()"
    (displayDataChanged)="updatePagination($event)"
    [moreDataAvailable]="hasMore"
    class="paginated-container"
  >
    <div class="table-container">
      <table class="insurance-table">
        <thead>
          <tr>
            <th>Task ID</th>
            <th>Status</th>
            <th>Task owner name</th>
            <th>Task owner email</th>
            <th>Task owner phone</th>
            <th>Created at</th>
            <th>Updated at</th>
          </tr>
        </thead>
        <tbody>
          @for (
            application of displayInsuranceApplications;
            track application.id
          ) {
            <tr
              class="insurance-application-row"
              [ngClass]="'status-' + application.status.toLowerCase()"
              [routerLink]="['/insurance-applications', application.taskId]"
            >
              <td>{{ application.taskId }}</td>
              <td>
                <span
                  class="status-badge"
                  [ngClass]="'status-badge-' + application.status.toLowerCase()"
                  >{{ application.status }}</span
                >
              </td>
              <td>
                {{ application.user.firstName }} {{ application.user.lastName }}
              </td>
              <td>{{ application.user.email }}</td>
              <td>{{ application.user.phone }}</td>
              <td>{{ application.createdAt | date }}</td>
              <td>{{ application.updatedAt | date }}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </paginated-list>
</div>
