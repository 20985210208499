<div class="dialog_container">
  <h3>Manage user bans</h3>
  @if (userData) {
    <div
      class="userData"
      >
      <p>
        <span class="bold">User: </span>{{ userData.firstName }}
        {{ userData.lastName }}
      </p>
      <p><span class="bold">ID: </span>{{ userData.id }}</p>
      <p><span class="bold">Email: </span>{{ userData.email }}</p>
    </div>
  }
  <div
    #banList
    class="banList"
    >
    @if (banHistory.length > 0) {
      <div>
        <p class="bold">History:</p>
      </div>
    }
    @for (ban of banHistory; track ban; let i = $index) {
      <div
        class="ban-history-entry"
        [ngClass]="{ activeBan: ban.isActive, dark: i % 2 === 1 }"
        >
        <span class="flex_half">ID: {{ ban.id }}, </span>
        @if (ban.bannedUntil) {
          <span class="flex_1"
            >Banned: {{ ban.createdAt | date: 'dd-MM-yyyy' }},
          </span>
          <span class="flex_1"
            >Until: {{ ban.bannedUntil | date: 'dd-MM-yyyy' }},
          </span>
        } @else if (ban.until) {
          <span class="flex_1"
            >Graced: {{ ban.createdAt | date: 'dd-MM-yyyy' }},
          </span>
          <span class="flex_1"
            >Until: {{ ban.until | date: 'dd-MM-yyyy' }},
          </span>
        }
        <span class="flex_1"
          >Cancelled:
          {{ ban.cancelled ? getDate(ban.updatedAt) : 'False' }}</span
          >
          <span class="flex_2">Reason: {{ ban.reason }}</span>
          <span class="flex_2">Admin notes: {{ ban.adminNotes }}</span>
        </div>
      }
      @if (banHistory.length === 0) {
        <div>
          <p>The user has never been banned</p>
        </div>
      }
    </div>
    @if (getActiveBan() === undefined) {
      <div class="horizontal">
        <mat-form-field class="input">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Ban until"
            [formControl]="banDate"
            />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          class="input"
          id="banReason"
          >
          <!-- Dropdown for choosing reason -->
          @if (!otherBanReasonSelected) {
            <mat-select
              name="myControl"
              placeholder="Reason"
              [value]="banReason"
              [(ngModel)]="banReason"
              (selectionChange)="onReasonSelectionChange($event)"
              >
              <mat-option value="Deling af kontaktinformationer"
                >Deling af kontaktinformationer</mat-option
                >
                <mat-option value="Brud på retningslinjerne"
                  >Brud på retningslinjerne</mat-option
                  >
                  <mat-option
                    value="true"
                    [(ngModel)]="otherBanReasonSelected"
                    >Andet</mat-option
                    >
                  </mat-select>
                }
                @if (otherBanReasonSelected) {
                  <div
                    id="banReasonCss"
                    >
                    <input
                      id="banReasonInput"
                      matInput
                      placeholder="Custom reason"
                      [(ngModel)]="banReason"
                      />
                    <mat-icon (click)="resetBanReason()"> arrow_drop_down </mat-icon>
                  </div>
                }
              </mat-form-field>
              <mat-form-field class="input">
                <input
                  matInput
                  placeholder="Admin Notes"
                  [(ngModel)]="adminNotes"
                  />
              </mat-form-field>
            </div>
            @if (banError) {
              <div
                class="error"
                >
                {{ banError }}
              </div>
            }
            <button
              class="pinploy_button small_button"
              (click)="banUser()"
              >
              Ban
            </button>
          } @else {
            <p>The user is currently banned</p>
            <button
              class="pinploy_button small_button"
              (click)="cancelBan()"
              >
              Cancel Ban
            </button>
          }
        </div>
