<mat-dialog-content class="number-input-dialog">
  <h2>Update Phone Number</h2>
  <br />
  <h4>
    <i>
      Only do this if you are certain they have access to this phone number.
    </i>
    <br />
    <br />
    <i> This means you have to have the user on the phone while doing it. </i>
  </h4>
  <div class="input-container">
    <div class="input-group">
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input1
          (input)="onInput($event, 0)"
          (keydown)="onKeyDown($event, 0)"
          (focus)="onFocus(0)"
          (click)="onClick(0)"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input2
          (input)="onInput($event, 1)"
          (keydown)="onKeyDown($event, 1)"
          (focus)="onFocus(1)"
          (click)="onClick(1)"
        />
      </mat-form-field>
      <span class="separator">-</span>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input3
          (input)="onInput($event, 2)"
          (keydown)="onKeyDown($event, 2)"
          (focus)="onFocus(2)"
          (click)="onClick(2)"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input4
          (input)="onInput($event, 3)"
          (keydown)="onKeyDown($event, 3)"
          (focus)="onFocus(3)"
          (click)="onClick(3)"
        />
      </mat-form-field>
      <span class="separator">-</span>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input5
          (input)="onInput($event, 4)"
          (keydown)="onKeyDown($event, 4)"
          (focus)="onFocus(4)"
          (click)="onClick(4)"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input6
          (input)="onInput($event, 5)"
          (keydown)="onKeyDown($event, 5)"
          (focus)="onFocus(5)"
          (click)="onClick(5)"
        />
      </mat-form-field>
      <span class="separator">-</span>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input7
          (input)="onInput($event, 6)"
          (keydown)="onKeyDown($event, 6)"
          (focus)="onFocus(6)"
          (click)="onClick(6)"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="digit-input"
      >
        <input
          matInput
          type="text"
          maxlength="1"
          #input8
          (input)="onInput($event, 7)"
          (keydown)="onKeyDown($event, 7)"
          (focus)="onFocus(7)"
          (click)="onClick(7)"
        />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button
    class="pinploy_button red_button small_button"
    (click)="onCancel()"
  >
    Cancel
  </button>
  <button
    class="pinploy_button small_button"
    (click)="onSubmit()"
    [disabled]="!isComplete()"
    [ngClass]="{ disabled: !isComplete() }"
  >
    Confirm
  </button>
</mat-dialog-actions>
