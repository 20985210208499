import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SupportTicket } from 'src/app/models/supportTicket.model';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
import { SupportTicketCardComponent } from 'src/app/components/cards/support-ticket-card/support-ticket-card.component';
export interface SupportTicketsDialogData {
  openSupportTickets: SupportTicket[];
  closedSupportTickets: SupportTicket[];
}
@Component({
  selector: 'support-tickets-dialog',
  templateUrl: 'supportTicketsDialog.component.html',
  styleUrls: ['../dialogs.scss', 'supportTicketsDialog.component.scss'],
  imports: [...ALL_IMPORTS, SupportTicketCardComponent],
})
export class SupportTicketsDialogComponent implements OnInit, OnDestroy {
  openSupportTickets: SupportTicket[] = [];
  closedSupportTickets: SupportTicket[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SupportTicketsDialogData,
    private dialogRef: MatDialogRef<SupportTicketsDialogComponent>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.openSupportTickets = this.data.openSupportTickets;
    this.closedSupportTickets = this.data.closedSupportTickets;
  }

  openTicket($event: MouseEvent, uid: string): Promise<boolean> {
    if ($event.ctrlKey || $event.metaKey) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['support-tickets-v2'], {
          queryParams: { uid },
        }),
      );
      window.open(url, '_blank');
    } else {
      this.closeDialog();
      return this.router.navigate(['support-tickets-v2'], {
        queryParams: { uid },
      });
    }
  }

  closeDialogEvent(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }
}
