<h2 mat-dialog-title>User's support tickets</h2>
<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Open Tickets">
      <div class="tickets-container">
        @for (ticket of openSupportTickets; track $index) {
          <app-support-ticket-card
            [ticket]="ticket"
            [includeButton]="true"
            (closeDialogEvent)="closeDialogEvent()"
          ></app-support-ticket-card>
        }
      </div>
    </mat-tab>
    <mat-tab label="Closed Tickets">
      <div class="tickets-container">
        @for (ticket of closedSupportTickets; track $index) {
          <app-support-ticket-card
            [ticket]="ticket"
            [includeButton]="true"
            (closeDialogEvent)="closeDialogEvent()"
          ></app-support-ticket-card>
        }
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
  >
    Close
  </button>
</mat-dialog-actions>
