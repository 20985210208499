<div class="users-container">
  <div id="controls"></div>
  <paginated-list
    [data]="users"
    (displayDataChanged)="updateDisplayUsers($event)"
    (dataRequested)="fetchUsers()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
    >
    <div class="list">
      @for (user of displayUsers; track user) {
        <a
          class="item"
          routerLink="{{ '/users/' + user.id }}"
          >
          <p class="id"><span class="bold">ID: </span>{{ user.id }}</p>
          <p class="name">
            <span class="bold">Name: </span>{{ user.firstName }}
            {{ user.lastName }}
          </p>
          <p class="title"><span class="bold">Email: </span>{{ user.email }}</p>
          <p class="status"><span class="bold">Phone: </span>{{ user.phone }}</p>
          <p class="budget">
            <span class="bold">Status: </span
              >{{ user.isActive ? "Active" : "Not Active" }}
            </p>
          </a>
        }
      </div>
    </paginated-list>
  </div>
