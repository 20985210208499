@if (ticket) {
  <mat-card>
    <div class="card_header">
      <h5>Ticket</h5>
      @if (includeButton) {
        <button
          class="pinploy_button small_button"
          (click)="openTicket($event, ticket.uid)"
        >
          Open ticket details
        </button>
      }
    </div>
    <div class="card_body">
      <!-- Ticket ID-->
      <div class="card_row">
        <p class="strong">Ticket ID</p>
        <p>{{ ticket.id }}</p>
      </div>
      <!-- Ticket UID-->
      <div class="card_row">
        <p class="strong">Ticket UID</p>
        <p>{{ ticket.uid }}</p>
      </div>
      <!-- USER EMAIL-->
      <div class="card_row">
        <p class="strong">Created</p>
        <p>{{ ticket.createdAt | date }}</p>
      </div>
      <!-- USER PHONE-->
      <div class="card_row">
        <p class="strong">Type</p>
        <p>{{ ticket.topic }}</p>
      </div>
    </div>
  </mat-card>
}
