import { Component, Input, OnInit, output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminActionRequest } from 'src/app/models/adminActionRequest.model';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';
import { fadeUpAnimation } from 'src/app/animations/fade-animations';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-request-payout-failed',
    templateUrl: './requestPayoutFailed.component.html',
    styleUrls: [
        './requestPayoutFailed.component.scss',
        '../requestHandlingPage.component.scss',
    ],
    imports: [...ALL_IMPORTS],
    providers: [],
    animations: [fadeUpAnimation]
})
export class RequestPayoutFailedComponent implements OnInit {
  @Input({ required: true }) request: AdminActionRequest;
  requestHandledEvent = output<AdminActionRequest>();

  isLoading: boolean = false;
  requestHandled: boolean = false;
  payoutData: any;

  constructor(
    private adminActionRequestService: AdminActionRequestService,
    private toastr: ToastrService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.payoutData = this.request?.data?.payoutData;
  }

  handleRequest() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'Cancellation denied request marked as handled',
            'Success',
          );
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );

          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }
}
