import { Component } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';
import { AuthService } from 'src/app/services/auth.service';
import { TagService } from 'src/app/services/tag.service';
import { Task } from '../../models/task.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { TagDialogComponent } from 'src/app/components/dialogs/tag/tag.component';
import { Tag } from 'src/app/models/tag.model';
import { ToastrService } from 'ngx-toastr';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-tasks-tags',
    templateUrl: './tasksTags.component.html',
    styleUrls: ['./tasksTags.component.scss'],
    imports: [MatSlideToggle, PaginatedList, ...COMMON_IMPORTS]
})
export class TasksTagsComponent {
  tasks: Task[] = [];
  filteredTasks: Task[] = [];
  displayTasks: Task[] = [];
  tags: Tag[] = [];
  withoutTags: boolean = true;
  filterKeys: string[] = ['id', 'title', 'tag'];
  lastPage: number = 0;
  hasMore: boolean = true;
  isBusy: boolean = false;

  // Filter properties
  searchQuery = '';
  searchStatus = null;
  searchStartDate: Date = null;
  searchEndDate: Date = null;

  constructor(
    private authService: AuthService,
    private taskService: TaskService,
    private tagService: TagService,
    private deviceDetector: DeviceDetectorService,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {}

  openCensor(taskId: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const task = this.filteredTasks.find((task) => task.id === taskId);
    const width = this.deviceDetector.isDesktop() ? '50%' : '90%';

    this.dialog
      .open(TagDialogComponent, {
        width: width,
        data: {
          taskId: taskId,
          taskTitle: task.title,
          taskDescription: task.description,
          selectedTags: task.tag ?? [],
          tags: this.tags,
        },
      })
      .afterClosed()
      .subscribe(
        (res) => {
          if (res) {
            const index = this.filteredTasks.findIndex(
              (task) => task.id === res.taskId,
            );
            this.updateTask(res.taskId, res.tags);
            if (res.next) {
              if (
                index >= 0 &&
                index <
                  (this.withoutTags
                    ? this.filteredTasks.length
                    : this.filteredTasks.length - 1)
              ) {
                this.openCensor(
                  this.filteredTasks[this.withoutTags ? index : index + 1].id,
                );
              } else {
                this.toastr.error(`No next task!`);
              }
            }
          }
        },
        (err) =>
          this.authService.handleError(err, 'Error when closing dialog!'),
      );
  }

  updateTask(taskId: number, tags: Tag[]): void {
    const task = this.tasks.find((task) => task.id === taskId);
    if (task) {
      task.tag = tags;
    }
    this.filterWithoutTags(this.withoutTags);
  }

  fetchData(click?: boolean): void {
    if (click) {
      this.lastPage = 0;
      this.tasks = [];
      this.hasMore = true;
    }

    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      const nextPage = this.lastPage + 1;

      this.taskService
        .getTasks(nextPage, true, {
          searchQuery: this.searchQuery,
          status: this.searchStatus,
          dateRange: { start: this.searchStartDate, end: this.searchEndDate },
        })
        .subscribe({
          next: (res) => {
            if (this.hasMore && this.tasks.length) {
              this.tasks = this.tasks.concat(res.tasks);
            } else if (this.hasMore && this.tasks.length === 0) {
              this.tasks = res.tasks;
            } else if (!this.hasMore && this.tasks.length === 0) {
              this.tasks = res.tasks;
            }
            this.lastPage = nextPage;
            this.hasMore = res.hasMore;
            this.filterWithoutTags(this.withoutTags);
          },
          error: (err) => {
            this.authService.handleError(err, 'Failed to get tasks!');
          },
          complete: () => {
            this.isBusy = false;
          },
        });

      // Fetch tags
      this.tagService.getAllTags().subscribe({
        next: (res) => (this.tags = res),
        error: (err) =>
          this.authService.handleError(err, 'Failed to get tags!'),
      });
    }
  }

  updateDisplayTasks(tasks: Task[]): void {
    this.displayTasks = tasks;
  }

  filterWithoutTags(x: boolean): void {
    if (this.withoutTags !== x) {
      this.withoutTags = x;
    }
    if (x) {
      this.filteredTasks = this.tasks.filter(
        (task) => !task.tag || task.tag.length === 0,
      );
    } else {
      this.filteredTasks = this.tasks.slice();
    }
  }
}
