import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { TagService } from 'src/app/services/tag.service';
import { Subscription } from 'rxjs';
import { RequestNewGiftCardComponent } from './requestNewGiftCard/requestNewGiftCard.component';
import { RequestPaymentFailedComponent } from './requestPaymentFailed/requestPaymentFailed.component';
import { RequestMultipleChargesComponent } from './requestMultipleCharges/requestMultipleCharges.component';
import { RequestTaskCancellationDeniedComponent } from './requestTaskCancellationDenied/requestTaskCancellationDenied.component';
import { RequestPayoutFailedComponent } from './requestPayoutFailed/requestPayoutFailed.component';
import { RequestConflictCasePendingComponent } from './requestConflictCasePending/requestConflictCasePending.component';
import { RequestCVRcheckComponent } from './requestCVRcheck/requestCVRcheck.component';
import { RequestRejectedCensorringComponent } from './requestRejectedCensorring/requestRejectedCensorring.component';
import { RequestUserMentionedMobilePayComponent } from './requestUserMentionedMobilePay/requestUserMentionedMobilePay.component';
import { RequestTaggingFailedComponent } from './requestTaggingFailed/requestTaggingFailed.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-request-handling-page',
    templateUrl: './requestHandlingPage.component.html',
    styleUrls: ['./requestHandlingPage.component.scss'],
    imports: [
        ...ALL_IMPORTS,
        RequestTaggingFailedComponent,
        RequestUserMentionedMobilePayComponent,
        RequestRejectedCensorringComponent,
        RequestCVRcheckComponent,
        RequestConflictCasePendingComponent,
        RequestPayoutFailedComponent,
        RequestTaskCancellationDeniedComponent,
        RequestMultipleChargesComponent,
        RequestPaymentFailedComponent,
        RequestNewGiftCardComponent,
    ]
})
export class RequestHandlingPageComponent implements OnInit, OnDestroy {
  requests: any[] = [];
  shownRequests: any[] = [];
  allTags: any[] = [];
  allTagsWithAssociatedTagCat;
  removingTag = false;

  // For the filter
  types = [
    { slug: 'all', name: 'All' },
    { slug: 'task-tagging-failed', name: 'Task tagging failed' },
    { slug: 'reject-automatic-censoring', name: 'Censoring rejection' },
    {
      slug: 'user-mentioned-mobile-pay-in-message',
      name: 'Mobile pay in message',
    },
    { slug: 'pending-company-approval', name: 'CVR Approval' },
    { slug: 'conflict-case-pending-decision', name: 'Conflict case pending' },
    { slug: 'payout-failed', name: 'Payout failed' },
    { slug: 'task-cancellation-denied', name: 'Task cancellation denied' },
    { slug: 'multiple-charges', name: 'Multiple charges' },
    { slug: 'payment-failed', name: 'Payment failed' },
    { slug: 'new-gift-card', name: 'New gift card' },
  ];
  selectedType: string = 'all';

  private sub: Subscription;
  constructor(
    private toastr: ToastrService,
    private title: Title,
    private stateService: StateService,
    private adminActionRequestService: AdminActionRequestService,
    private tagService: TagService,
  ) {}

  ngOnInit() {
    this.stateService.toggleWaitingPage(true);
    this.setMetaTitle();
    this.getUnhandledRequests();
    this.populateAllTagsFromDb();
    this.getAllTagsAssociatedToTagCat();
    this.readActiveRequests();
  }

  filterRequests(event: Event) {
    if (this.selectedType === 'all') {
      this.shownRequests = this.requests;
      return;
    }
    this.shownRequests = this.requests.filter(
      (r) => r.entityType === this.selectedType,
    );
  }

  getUnhandledRequests(page = 1) {
    this.adminActionRequestService.getUnhandledRequests(page).subscribe(
      (res) => {
        if (res.length !== 0) {
          this.requests = res;
          this.shownRequests = this.requests;

          this.stateService.toggleWaitingPage(false);
        } else {
          this.stateService.toggleWaitingPage(false);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  // Tag fetching and sorting area
  populateAllTagsFromDb() {
    this.tagService.getAllTags().subscribe((res) => {
      if (res) {
        this.allTags = res;
      }
    });
  }

  readActiveRequests() {
    this.sub =
      this.stateService.readActiveAdminActionRequestsComponentSource.subscribe(
        (res) => {
          if (res) {
            this.requests = this.requests.filter((r) => r.id !== res.id);
            this.toastr.success('Request handled by another admin', 'Success');
          }
        },
        (err) => {
          console.log(err);
        },
      );
  }

  getAllTagsAssociatedToTagCat() {
    this.tagService.getTagsAssociatedByTagCategory().subscribe((res) => {
      if (res) {
        this.allTagsWithAssociatedTagCat = res;
        this.allTagsWithAssociatedTagCat.andet.tags =
          this.allTagsWithAssociatedTagCat.andet.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.digital.tags =
          this.allTagsWithAssociatedTagCat.digital.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.transport.tags =
          this.allTagsWithAssociatedTagCat.transport.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.hus.tags =
          this.allTagsWithAssociatedTagCat.hus.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
        this.allTagsWithAssociatedTagCat.have.tags =
          this.allTagsWithAssociatedTagCat.have.tags.sort((a, b) =>
            a.tag.localeCompare(b.tag),
          );
      }
    });
  }

  setMetaTitle() {
    this.title.setTitle(`Requests`);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.title.setTitle(`Admin Dashboard`);
  }

  requestHandled(request: any) {
    // Find the request in both arrays by ID
    const requestId = request.id;

    // Remove from main requests array
    this.requests = this.requests.filter((r) => r.id !== requestId);

    // Remove from shown requests array
    this.shownRequests = this.shownRequests.filter((r) => r.id !== requestId);

    // If we're filtering by type and no more requests of that type, reset to all
    if (this.selectedType !== 'all' && !this.shownRequests.length) {
      this.selectedType = 'all';
      this.shownRequests = this.requests;
    }

    // If no more requests at all, refresh from server
    if (!this.requests.length) {
      this.stateService.toggleWaitingPage(true);
      this.getUnhandledRequests();
    }
  }
}
