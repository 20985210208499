import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { StoredCreditsService } from 'src/app/services/storedCredits.service';
import { QuestionDialogComponent } from '../questionDialog/questionDialog.component';
import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/app/services/state.service';
import { StoredCreditsCard } from '../../cards/storedCredits-card/storedCredits-card.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'user-stored-credits-dialog',
    templateUrl: 'userStoredCredits.component.html',
    styleUrls: ['../dialogs.scss', 'userStoredCredits.component.scss'],
    imports: [StoredCreditsCard, ...ALL_IMPORTS]
})
export class UserStoredCreditsComponent implements OnInit {
  user: User;
  userId: number;
  storedCredits: any;
  creditsToBeWithdrawn: number[] = [];
  creditsToBeWithdrawnTotalAmount: number = 0;

  constructor(
    private stateService: StateService,
    private storedCreditsService: StoredCreditsService,
    private toastr: ToastrService,
    private userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserStoredCreditsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user stored credits dialog');
    }
  }

  ngOnInit(): void {
    this.userService.getUserProfile(this.data.userId).subscribe((res) => {
      this.user = res;
    });

    this.storedCreditsService
      .getStoredCredits(this.data.userId)
      .subscribe((res) => {
        this.storedCredits = res.credits;
      });
  }

  onCheckboxChange(e, credit) {
    if (e.checked) {
      let amount = credit.remaining;
      this.creditsToBeWithdrawn.push(credit.id);
      this.creditsToBeWithdrawnTotalAmount =
        this.creditsToBeWithdrawnTotalAmount + amount;
    } else {
      let amount = credit.remaining;
      // if unchecked remove from array
      this.creditsToBeWithdrawn = this.creditsToBeWithdrawn.filter(
        (m) => m !== credit.id,
      );
      this.creditsToBeWithdrawnTotalAmount =
        this.creditsToBeWithdrawnTotalAmount - amount;
    }
  }

  confirmWithdrawal() {
    const dialog = this.matDialog.open(QuestionDialogComponent, {
      width: '500px',
      data: {
        title: `Withdraw Credits (${this.creditsToBeWithdrawnTotalAmount / 100} DKK) for user?`,
        description:
          'Are you sure? Once you confirm the withdrawal will be final!',
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res === 'yes') {
        this.withdrawCredits(this.creditsToBeWithdrawn);
      }
    });
  }

  withdrawCredits(credits: number[]) {
    this.stateService.toggleWaitingPage(true);
    this.storedCreditsService.withdrawCredits(this.user.id, credits).subscribe(
      (res) => {
        this.stateService.toggleWaitingPage(false);
        this.closeDialogEvent();
        this.toastr.success('Credits withdrawn successfully');
      },
      (err) => {
        this.stateService.toggleWaitingPage(false);
        this.closeDialogEvent();
        this.toastr.error('Something went wrong');
      },
    );
  }

  isWithdrawBtnDisabled() {
    if (this.creditsToBeWithdrawnTotalAmount == 0) {
      return true;
    } else {
      return false;
    }
  }
  closeDialogEvent() {
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
