<div class="dialog_container">
  <!--if editing is disabled-->
  @if (disableEditing) {
    <div
      class="image-wrapper"
      >
      <img
        [src]="imageUrl"
        alt=""
        class="background-image"
        />
    </div>
  }

  <!--if editing is enabled-->
  @if (!disableEditing) {
    <div
      class="canvas-container"
      >
      <canvas id="image-canvas"></canvas>
    </div>
  }

  <!--button container-->
  @if (!disableEditing) {
    <div
      class="button-container"
      cdkDrag
      cdkDragBoundary=".dialog_container"
      >
      <mat-slider
        min="5"
        max="50"
        step="1"
        discrete
        (mousedown)="$event.stopPropagation()"
        [displayWith]="formatLabel"
        valueChange="changeSliderValue($event)"
        >
        <input
          matSliderThumb
          [(ngModel)]="sliderValue"
          (ngModelChange)="changeSliderValue($event)"
          />
      </mat-slider>
      <input
        type="color"
        id="colorPicker"
        />
      <button
        (click)="undoLastAction()"
        class="pinploy_button small_button red_button"
        style="margin: 10px"
        [disabled]="!undoStack.length"
        [ngClass]="{ disabled: !undoStack.length }"
        >
        Undo last stroke
      </button>
      <button
        class="pinploy_button small_button"
        style="margin: 10px"
        (click)="saveImage()"
        [disabled]="!undoStack.length"
        [ngClass]="{ disabled: !undoStack.length }"
        >
        Save image
      </button>
      <button
        (click)="downloadImage()"
        class="pinploy_button small_button"
        style="margin: 10px"
        >
        Download
      </button>
      <button
        (click)="deleteImage()"
        class="pinploy_button small_button red_button"
        style="margin: 10px"
      [disabled]="
        data?.image?.resource !== 'header' &&
        data?.image?.resource !== 'portfolio'
      "
      [ngClass]="{
        disabled:
          data?.image?.resource !== 'header' &&
          data?.image?.resource !== 'portfolio',
      }"
        >
        Delete image
      </button>
    </div>
  }
</div>
