import { Component, OnDestroy, OnInit } from '@angular/core';
import { MiscService } from 'src/app/services/misc.service';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import * as dateFns from 'date-fns';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-insurance-applications',
    templateUrl: './insurance-applications.component.html',
    styleUrl: './insurance-applications.component.scss',
    imports: [...COMMON_IMPORTS, PaginatedList]
})
export class InsuranceApplicationListComponent implements OnInit, OnDestroy {
  insuranceApplications: any[] = [];
  displayInsuranceApplications: any[];
  filterKeys: string[] = [
    'id',
    'status',
    'createdAt',
    'updatedAt',
    'user.firstName',
    'user.lastName',
    'user.email',
    'phone',
  ];
  lastPage = 0;
  hasMore = true;
  isBusy = false;

  constructor(
    private title: Title,
    private miscService: MiscService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.setMetaTitle();
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }

  setMetaTitle(): void {
    this.title.setTitle(`Insurance Applications`);
  }

  fetchData(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.miscService
        .getInsuranceApplicationsPaginated(this.lastPage + 1)
        .subscribe(
          (res) => {
            this.insuranceApplications = this.insuranceApplications.concat(
              res.data,
            );
            this.lastPage++;
            this.hasMore = res.hasMore;
          },
          (err) =>
            this.authService.handleError(
              err,
              'Failed to fetch insurance applications',
            ),
          () => (this.isBusy = false),
        );
    }
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return 'N/A';
    }
    return dateFns.format(new Date(dateString), 'dd/MM/yyyy');
  }

  updatePagination(applications: any[]): void {
    this.displayInsuranceApplications = applications;
  }
}
