<div class="media-grid">
  @for (image of images; track $index) {
    <div
      class="media-item image"
      [matTooltip]="getFileName(image)"
      (click)="openPreviewImage(image)"
    >
      <img
        [src]="image?.location || image.url"
        [alt]="getFileName(image)"
      />
    </div>
  }
  @for (doc of docs; track $index) {
    <div
      class="media-item doc"
      [matTooltip]="getFileName(doc)"
      (click)="openDocument(doc)"
    >
      <mat-icon>picture_as_pdf</mat-icon>
      <span class="doc-name">{{ getFileName(doc) }}</span>
    </div>
  }
</div>
