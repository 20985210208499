import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { TaskService } from 'src/app/services/task.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDatepickerInputEvent,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { AuthService } from 'src/app/services/auth.service';

interface StatusOption {
  value: string;
  viewValue: string;
}

interface GetTasksResult {
  tasks: Task[];
  hasMore: boolean;
}
import { TaskCardComponent } from '../../cards/task-card/task-card.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'user-tasks-dialog',
    templateUrl: 'userTasks.component.html',
    styleUrls: ['../dialogs.scss', 'userTasks.component.scss'],
    imports: [...ALL_IMPORTS, TaskCardComponent]
})
export class UserTasksDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('picker') datePicker: MatDateRangePicker<Date>;
  dataSource = new MatTableDataSource<Task>();

  constructor(
    public userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserTasksDialogComponent>,
    public taskService: TaskService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user tasks dialog');
    }
  }

  statusOptions: StatusOption[] = [
    { value: null, viewValue: 'All' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'canceled', viewValue: 'Cancelled' },
    { value: 'expired', viewValue: 'Expired' },
    { value: 'erased', viewValue: 'Erased' },
  ];

  userData: User;
  userTasks = [];
  paginatedTasks = [];
  searchHasMore = true;
  lastPage = 0;
  isBusy = false;

  pageSize = 5;
  pageIndex = 0;
  pageEvent: PageEvent;

  searchStartDate: any = null;
  searchEndDate: any = null;
  searchQuery = '';
  searchStatus = null;

  ngOnInit(): void {
    this.searchTasks();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  searchTasks(click?: boolean): void {
    if (click) {
      this.lastPage = 0;
      this.userTasks = [];
      this.searchHasMore = true;
      this.pageIndex = 0;
      this.paginator.firstPage();
    }

    if (this.data.userId && !this.isBusy && this.searchHasMore) {
      this.isBusy = true;
      this.taskService
        .getTasks(this.lastPage + 1, true, {
          userId: this.data.userId,
          status: this.searchStatus,
          dateRange: { start: this.searchStartDate, end: this.searchEndDate },
          searchQuery: this.searchQuery,
        })
        .subscribe({
          next: (res: GetTasksResult) => {
            if (this.searchHasMore && this.userTasks.length) {
              this.userTasks = this.userTasks.concat(res.tasks);
            } else if (this.searchHasMore && this.userTasks.length === 0) {
              this.userTasks = res.tasks;
            } else if (!this.searchHasMore && this.userTasks.length === 0) {
              this.userTasks = res.tasks;
            }
            this.lastPage += 1;
            this.searchHasMore = res.hasMore;
            this.dataSource.data = this.userTasks;
            this.updatePaginatedTasks();
          },
          error: (err) => {
            this.authService.handleError(err, 'Failed to get user tasks!');
          },
          complete: () => {
            this.isBusy = false;
          },
        });
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calculate if we need to fetch more data
    const itemsNeeded = (this.pageIndex + 1) * this.pageSize;
    if (this.searchHasMore && itemsNeeded >= this.userTasks.length) {
      this.searchTasks();
    } else {
      this.updatePaginatedTasks();
    }
  }

  updatePaginatedTasks(): void {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedTasks = this.userTasks.slice(startIndex, endIndex);
  }

  searchQueryChange(event): void {
    this.searchQuery = event;
  }

  searchStartDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.searchStartDate = event.value;
  }

  searchEndDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.searchEndDate = event.value;
  }

  selectedStatusChange(event): void {
    this.searchStatus = event.value;
  }

  resetFilter(): void {
    this.searchQuery = '';
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.searchStatus = null;
    this.datePicker.select(null);
  }

  fetchUserData(): void {
    this.userService
      .getUserProfile(this.data.userId)
      .subscribe((result) => (this.userData = result));
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }

  closeDialogEvent() {
    this.close();
  }
}
