@if (nbUsers$ | async; as nbUsers) {
  @if (displayedUsers$ | async) {
    <div class="confirm_notification_dialog">
      <h1>Confirm</h1>
      <p>
        You are about to send a notification to the segment
        <span> "{{ formValues.segment }}"</span>. This represents
        <span> {{ nbUsers }} </span> users.
      </p>
      <p>
        Wanted notification: <span>{{ getWantedString() }}</span>
      </p>
      <p>
        Schedule time: <span>{{ getScheduleTime() }}</span>
      </p>
      <paginated-list
        [data]="users"
        (displayDataChanged)="updateDisplayedUsers($event)"
        (dataRequested)="fetchUsers()"
        [moreDataAvailable]="hasMoreUsers()"
        [isDefaultSort]="false"
        >
        <div class="userList">
          @for (u of displayedUsers; track u) {
            <div>
              <div>{{ u.firstName }} {{ u.lastName }}</div>
              <div>{{ u.email }}</div>
            </div>
          }
        </div>
      </paginated-list>
      <div class="question">
        Are you sure you want to send this notification?
      </div>
      <div class="actions">
        <button
          class="pinploy_button small_button red_button"
          (click)="onClickCancel()"
          >
          Cancel
        </button>
        <button class="pinploy_button small_button" (click)="onClickSend()">
          Send
        </button>
      </div>
    </div>
  } @else {
    <div class="loading_spinner">
      <mat-spinner></mat-spinner>
    </div>
  }
} @else {
  <div class="loading_spinner">
    <mat-spinner></mat-spinner>
  </div>
}

