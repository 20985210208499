import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferService } from 'src/app/services/offer.service';
import { TaskService } from 'src/app/services/task.service';
import { ToastrService } from 'ngx-toastr';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-change-cancellation-dialog',
    templateUrl: 'changeCancellationDialog.component.html',
    styleUrls: ['../../dialogs.scss', 'changeCancellationDialog.component.scss'],
    imports: [...ALL_IMPORTS, CdkTextareaAutosize]
})
export class ChangeCancellationDialog implements OnInit {
  title: string;
  cancelTask: any;
  offer: any;

  reasons = [
    {
      reason: 'toNoTime',
      feeOwner: 'TO',
      readable: "Taskowner: Didn't have time",
    },
    {
      reason: 'toPersonal',
      feeOwner: 'TO',
      readable: 'Taskowner: Personal reasons',
    },
    {
      reason: 'toDecided',
      feeOwner: 'TO',
      readable: 'Taskowner: Decided not to continue',
    },
    {
      reason: 'toMisleadingScope',
      feeOwner: 'TO',
      readable: 'Taskowner: Misleading description',
    },
    {
      reason: 'ppNoShowOrAnswer',
      feeOwner: 'PP',
      readable: 'Handyhander: No show / answer',
    },
    {
      reason: 'ppDecided',
      feeOwner: 'PP',
      readable: 'Handyhander: Could not fulfill task',
    },
  ];

  cancelReasonComment: string;
  cancelReason: string;

  constructor(
    private toastr: ToastrService,
    private taskService: TaskService,
    private offerService: OfferService,
    public dialogRef: MatDialogRef<ChangeCancellationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.cancelReason = data.cancelTask.cancelTask.reason;
    this.cancelReasonComment = data.cancelTask.cancelTask.reasonComment;
    this.cancelTask = data.cancelTask;
  }

  ngOnInit(): void {
    this.offerService
      .getOfferById(this.cancelTask.cancelTask.offerId)
      .subscribe((offer) => {
        this.offer = offer;
      });
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  updateCancellation() {
    this.cancelTask.cancelTask.reason = this.cancelReason;
    this.cancelTask.cancelTask.reasonComment = this.cancelReasonComment;

    switch (this.cancelTask.cancelTask.reason) {
      case 'toNoTime':
      case 'toPersonal':
      case 'toDecided':
      case 'toMisleadingScope':
        this.cancelTask.cancelTask.feeOwner = 'TO';
        break;
      case 'ppNoShowOrAnswer':
      case 'ppDecided':
        this.cancelTask.cancelTask.feeOwner = 'PP';
        break;
    }

    this.taskService
      .editCancelTask(this.cancelTask.cancelTask.id, this.cancelTask.cancelTask)
      .subscribe({
        next: (res) => {
          const data = {
            ...this.offer,
            status: null,
            offerId: this.offer.id,
          };
          switch (this.cancelTask.cancelTask.feeOwner) {
            case 'TO':
              data.status = 'canceled';
              this.offerService.realEditOffer(this.offer.id, data).subscribe({
                next: (res) => {
                  this.toastr.success('Cancellation +/ offer updated');
                  this.close(res);
                },
                error: (error) => {
                  console.error(error);
                },
              });
              break;
            case 'PP':
              data.status = 'disputed';
              this.offerService.realEditOffer(this.offer.id, data).subscribe({
                next: (res) => {
                  this.toastr.success('Cancellation +/ offer updated');
                  this.close(res);
                },
                error: (error) => {
                  console.error(error);
                },
              });
              break;
          }
        },
        error: (error) => {
          this.toastr.error('Error updating cancellation');
          console.error(error);
        },
      });
  }
}
