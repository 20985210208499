import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { LocalStorage, SessionStorage, LocalStorageService } from 'ngx-webstorage';

const keys = {
  token: 'token',
  user: 'user',
  socketId: 'socketId'
};

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  @LocalStorage(keys.token) private _token: string;
  @LocalStorage(keys.user) private _user: User;
  @LocalStorage(keys.socketId) private _socketId: string;

  constructor(private localStorage: LocalStorageService) {}

  clear() {
    this.localStorage.clear();
  }

  // General purpose methods for backward compatibility
  save(key: string, object: any): void {
    this.localStorage.store(key, object);
  }

  get(key: string): any {
    return this.localStorage.retrieve(key);
  }

  remove(key: string): void {
    this.localStorage.clear(key);
  }

  get token(): string {
    return this._token || '';
  }
  
  set token(token: string) {
    this._token = token;
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
  }

  get socketId(): string {
    return this._socketId;
  }

  set socketId(socketId: string) {
    this._socketId = socketId;
  }
}
