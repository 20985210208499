<div
  class="dialog_container"
  style="max-height: 80vh"
>
  <!-- If no stored credits has been creditted or debitted-->
  @if (
    storedCredits == null ||
    storedCredits == undefined ||
    storedCredits.length == 0
  ) {
    <div class="no-credit">
      <h2>No stored credit history...</h2>
      <h4>As of yet!</h4>
    </div>
  }
  @if (
    storedCredits !== null ||
    storedCredits !== undefined ||
    storedCredits.length !== 0
  ) {
    <div class="no-credit">
      <h2>Stored credits history</h2>
    </div>
  }

  @if (storedCredits) {
    <div class="table_container">
      <table
        mat-table
        [dataSource]="storedCredits"
      >
        <!-- Credit id Column -->
        <ng-container matColumnDef="Credit id">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Credit Id
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            @if (credit.id != null) {
              <div>
                {{ credit.id }}
              </div>
            }
            @if (credit.id == null) {
              <div>Current total:</div>
            }
          </td>
        </ng-container>
        <!-- Type Column -->
        <ng-container matColumnDef="Type">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Type
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            @if (credit.type == 'usedDebit') {
              <div>Spent credit</div>
            }
            @if (credit.type == 'retainedDebit') {
              <div>
                Connection fee "withheld" from cancelling.
                @if (credit.type == 'retainedDebit' && !credit.deletedAt) {
                  <a
                    (click)="openReleaseDebitDialog(credit)"
                    class="retained-debit-release"
                  >
                    (Release)
                  </a>
                }
              </div>
            }
            @if (credit.type == 'cancellationCredit') {
              <div>Credit from cancelled task</div>
            }
            @if (credit.type == 'priceDecreaseCredit') {
              <div>Credit from decreased task</div>
            }
            @if (credit.type == 'referralCredit') {
              <div>Credit from refer a friend</div>
            }
            @if (credit.type == 'expiredTaskCredit') {
              <div>Credit from expired task</div>
            }
            @if (credit.type == 'expiredDebit') {
              <div>
                Credit that expired. (Credit id: {{ credit.sourceCreditId }})
              </div>
            }
            @if (credit.type == 'withdrawnDebit') {
              <div>Credit withdrawn from the platform</div>
            }
            @if (credit.type == 'reversed') {
              <div>Credits reversed on debit entry</div>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="Transaction Type">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Transaction Type
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            @if (credit.transactionType == 'credit') {
              <div>Credit</div>
            }
            @if (credit.transactionType == 'debit') {
              <div>Debit</div>
            }
          </td>
        </ng-container>
        <!-- Offer Column -->
        <ng-container matColumnDef="Offer">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Offer
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            @if (!credit.deletedAt) {
              <a href="/offers/{{ credit.offerId }}">
                {{ credit.offerId }}
              </a>
            }
            @if (credit.markedAsExpired) {
              <span class="expired">
                <b style="color: red; font-size: large">
                  EXPIRED AT: {{ credit.expiresAt | date: 'dd/MM/yyyy' }}
                </b>
              </span>
            }
            @if (credit.deletedAt) {
              <span class="deleted">
                <b style="color: red; font-size: large">
                  DELETED AT: {{ credit.deletedAt | date: 'dd/MM/yyyy' }}
                </b>
              </span>
            }
          </td>
        </ng-container>
        <!-- Task Column -->
        <ng-container matColumnDef="Task">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Task
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            @if (credit.task?.id) {
              <a href="/tasks/{{ credit.task?.id }}">
                {{ credit.task.id }}
              </a>
            }
          </td>
        </ng-container>
        <!-- Created at Column -->
        <ng-container matColumnDef="Created at">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Created at
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            {{ credit.createdAt | date: 'dd/MM/yyyy HH:mm' }}
          </td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="Amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Amount
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            @if (credit.transactionType == 'credit') {
              <div>+ {{ credit.amount / 100 }} kr.</div>
            }
            @if (credit.transactionType == 'debit') {
              <div>- {{ credit.amount / 100 }} kr.</div>
            }
            <!-- <div *ngIf="credit.transactionType == 'credit' && credit.reversed">
            + {{ credit.amount / 100 }} ({{ credit.reversed / 100 }} reversed)
            kr.
          </div>
          <div *ngIf="credit.transactionType == 'debit' && credit.reversed">
            - {{ credit.amount / 100 }} ({{ credit.reversed / 100 }} reversed)
            kr.
          </div> -->
          </td>
        </ng-container>
        <!-- Running total Column -->
        <ng-container matColumnDef="Remaining">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            Running total
          </th>
          <td
            mat-cell
            *matCellDef="let credit"
          >
            {{ credit.remaining }} kr.
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.strikethrough]="
            (checkExpiringDate(row.expiresAt) && !row.amountUsed) ||
            row.deletedAt
          "
          [ngClass]="{
            'credit-row': row.transactionType === 'credit',
            'debit-row': row.transactionType === 'debit',
          }"
        ></tr>
      </table>
      <mat-divider></mat-divider>
      <p>Current balance: {{ runningTotal / 100 }} kr.</p>
    </div>
  }
</div>
