<div class="dialog_container">
  <h3>Message user</h3>
  @if (userData) {
    <p><span class="bold">Name: </span>{{userData.firstName}} {{userData.lastName}}</p>
    <p><input type="checkbox" [(ngModel)]="emailSelected" checked><span class="bold">Email: </span>{{userData.email}}</p>
    @if (userData.phone) {
      <p><input type="checkbox" [(ngModel)]="phoneSelected"><span class="bold">Phone: </span>{{userData.phone}}</p>
    }
  }
  @if (emailSelected) {
    <mat-form-field>
      <input matInput [(ngModel)]="subject" placeholder="Subject" (input)="setContentEdited()">
    </mat-form-field>
  }
  <mat-form-field>
    <textarea
      cdkTextareaAutosize
      matInput
      #messageInput
      [(ngModel)]="text"
      placeholder="Message"
      (input)="setContentEdited()"
    ></textarea>
  </mat-form-field>
  <p id="charCount">Character count: {{text.length}}</p>
  <div class="buttons">
    <button
      class="pinploy_button gray"
      [matMenuTriggerFor]="menu"
      >
      Apply template
    </button>
    <button
      class="pinploy_button"
      (click)="handleSend()"
      >
      Send
    </button>
  </div>
  <mat-menu #menu="matMenu">
    @for (template of templates; track template; let i = $index) {
      <button
        mat-menu-item
        (click)="applyTemplate(i)"
        >
        {{template.name}}
      </button>
    }
  </mat-menu>
</div>
