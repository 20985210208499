<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper" [ngClass]="{ blur: isLoading }">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>Task Cancellation Denied</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="task-cancellation-info">
          <div class="link-icon-area">
            <a [routerLink]="['/cancel-task', cancelTask.id]" target="_blank" matTooltip="Open entity in dedicated area">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>
          <div class="info-box">
            <h4>Task Cancellation Details</h4>
            <p>Cancellation ID: {{ cancelTask?.id }}</p>
            <p>Status: {{ cancelTask?.status }}</p>
          </div>
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
