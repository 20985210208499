<div class="page_container">
  <div class="filter">
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Filter by User ID"
        />
    </mat-form-field>
  </div>
  @if (isLoading) {
    <mat-progress-spinner
      mode="indeterminate"
    ></mat-progress-spinner>
  }
  @if (!isLoading) {
    <mat-table
      [dataSource]="dataSource"
      matSort
      matSortDirection="desc"
      >
      <!-- User ID column -->
      <ng-container matColumnDef="userId">
        <mat-header-cell
          class="narrow-mat-cell"
          *matHeaderCellDef
          >User ID</mat-header-cell
          >
          <mat-cell
            class="narrow-mat-cell"
            *matCellDef="let row"
            >{{ row.userId }}</mat-cell
            >
          </ng-container>
          <!-- Question column -->
          <ng-container matColumnDef="question">
            <mat-header-cell *matHeaderCellDef>Question</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.questionText }}</mat-cell>
          </ng-container>
          <!-- Answer column -->
          <ng-container matColumnDef="answer">
            <mat-header-cell *matHeaderCellDef>Answer</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.answerText }}</mat-cell>
          </ng-container>
          <!-- Answered Question column -->
          <ng-container matColumnDef="answeredQuestion">
            <mat-header-cell
              class="narrow-mat-cell"
              *matHeaderCellDef
              >Satisfying answer</mat-header-cell
              >
              <mat-cell
                class="narrow-mat-cell"
                *matCellDef="let row"
                >{{ row.answeredQuestion }}</mat-cell
                >
              </ng-container>
              <!-- CreatedAt column -->
              <ng-container matColumnDef="createdAt">
                <mat-header-cell
                  class="narrow-mat-cell"
                  *matHeaderCellDef
                  >Created At</mat-header-cell
                  >
                  <mat-cell
                    class="narrow-mat-cell"
                    *matCellDef="let row"
                    >{{ convertTimestamp(row.createdAt) }}</mat-cell
                    >
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
                </mat-table>
              }

              <mat-paginator
                [length]="totalItems"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 100]"
                >
              </mat-paginator>
            </div>
