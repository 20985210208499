<div class="dialog_container">
  <h3>Edit {{ data.type }}</h3>
  @if (entryBeingEdited) {
    <div
      id="edit"
      >
      <p><span class="bold">Id: </span>{{ entryBeingEdited.id }}</p>
      <p class="bold">Content:</p>
      <mat-form-field>
        <textarea
          cdkTextareaAutosize
          matInput
          #messageInput
          [(ngModel)]="text"
        ></textarea>
      </mat-form-field>
      <div class="horizontal">
        <label
          for="censored"
          class="bold"
          >Censor:</label
          >
          <mat-checkbox
            [checked]="censorChecked"
            [color]="'primary'"
            [(ngModel)]="censorChecked"
            (change)="updateSendCensorEmail()"
          ></mat-checkbox>
        </div>
        <div class="horizontal">
          <label
            for="sendCensorEmail"
            class="bold"
            >Send censor email:
          </label>
          <mat-checkbox
            id="sendCensorEmail"
            [checked]="sendCensorEmailChecked"
            [color]="'primary'"
            [(ngModel)]="sendCensorEmailChecked"
            [disabled]="!censorChecked"
          ></mat-checkbox>
        </div>
        <div class="horizontal buttons">
          <button
            class="pinploy_button"
            (click)="reset()"
            >
            Reset
          </button>
          <button
            class="pinploy_button"
            (click)="submit()"
            >
            Submit
          </button>
        </div>
      </div>
    }
    @if (submitError || fetchError) {
      <div>
        @if (fetchError) {
          <p
            class="error"
            >
            {{ fetchError }}
          </p>
        }
      </div>
    }
  </div>
