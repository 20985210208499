import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { MatFormField, MatLabel } from "@angular/material/form-field";



@Component({
    selector: 'create-user-note-dialog',
    templateUrl: 'createUserNoteDialog.component.html',
    styleUrls: ['../dialogs.scss', 'createUserNoteDialog.scss'],
    imports: [MatFormField, MatLabel, CdkTextareaAutosize, MatInput, FormsModule]
})

export class CreateUserNoteDialog implements OnInit {
    userNote: string;

    constructor(
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<CreateUserNoteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.userId === undefined) {
            throw new Error('No user id passed to user header image dialog')
        }
    }

    ngOnInit(): void {
    }

    close(res: any = null): void {
        this.dialogRef.close(res);
    }

    submit(): void {
        this.close({ userNote: this.userNote});
    }

}