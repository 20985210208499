import { Component, Input, OnInit, output } from '@angular/core';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import { ToastrService } from 'ngx-toastr';
import { AdminActionRequest } from 'src/app/models/adminActionRequest.model';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';
import { fadeUpAnimation } from 'src/app/animations/fade-animations';

@Component({
    selector: 'app-request-new-gift-card',
    templateUrl: './requestNewGiftCard.component.html',
    styleUrls: [
        './requestNewGiftCard.component.scss',
        '../requestHandlingPage.component.scss',
    ],
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS],
    providers: [],
    animations: [fadeUpAnimation]
})
export class RequestNewGiftCardComponent implements OnInit {
  @Input({ required: true }) request: AdminActionRequest;
  requestHandledEvent = output<AdminActionRequest>();

  isLoading: boolean = false;
  requestHandled: boolean = false;
  giftCardData: any;

  constructor(
    private adminActionRequestService: AdminActionRequestService,
    private toastr: ToastrService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.giftCardData = this.request?.data?.giftCardData;
  }

  handleRequest() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'New gift card request marked as handled',
            'Success',
          );
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );

          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }
}
