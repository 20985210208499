import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StateService } from 'src/app/services/state.service';
import { MiscService } from 'src/app/services/misc.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { ImagePreviewComponent } from '../../../components/image-preview/image-preview.component';

import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-insurance-application-page',
    templateUrl: './insurance-application-page.component.html',
    styleUrl: './insurance-application-page.component.scss',
    providers: [],
    imports: [...ALL_IMPORTS, CdkTextareaAutosize, ImagePreviewComponent]
})
export class InsuranceApplicationPageComponent implements OnInit, OnDestroy {
  insuranceApplicationTaskId: string;
  insuranceApplication: any;
  decisionAmount: number | null = null;
  decisionReason: string = '';

  handyhander: any;

  estimationFiles: any[] = [];
  incidentFiles: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private miscService: MiscService,
    private stateService: StateService,
    private title: Title,
    private toastrService: ToastrService,
  ) {
    this.stateService.toggleWaitingPage(true);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.insuranceApplicationTaskId = params.taskId;
      this.loadInsuranceApplication();
    });
  }

  ngOnDestroy(): void {
    this.title.setTitle('Admin Dashboard');
  }

  loadInsuranceApplication(): void {
    this.miscService
      .getInsuranceApplication(this.insuranceApplicationTaskId)
      .subscribe({
        next: (res) => {
          this.insuranceApplication = res.data;
          this.handyhander = res.data.handyhander;

          this.decisionAmount = this.insuranceApplication.decisionAmount;
          this.decisionReason = this.insuranceApplication.decisionReason || '';

          this.estimationFiles = res.estimateImageLinks;
          this.incidentFiles = res.documentationImageLinks;

          this.title.setTitle(
            `Insurance Application ${this.insuranceApplication.id}`,
          );
          this.stateService.toggleWaitingPage(false);
        },
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
        },
      });
  }

  downloadDocumentationFiles() {
    const docs = this.incidentFiles.filter((file) => file.fileType === 'doc');
    const images = this.incidentFiles.filter(
      (file) => file.fileType === 'image',
    );
    const media = [...docs, ...images];
    let fileUrls = [];
    media.forEach((file) => {
      fileUrls.push(file.url);
    });

    this.miscService.downloadFiles(fileUrls).subscribe((blob: Blob) => {
      saveAs(
        blob,
        `Application-documentation-${this.insuranceApplicationTaskId}.zip`,
      );
    });
  }

  downloadEstimationFiles() {
    const docs = this.estimationFiles.filter((file) => file.fileType === 'doc');
    const images = this.estimationFiles.filter(
      (file) => file.fileType === 'image',
    );
    const media = [...docs, ...images];
    let fileUrls = [];
    media.forEach((file) => {
      fileUrls.push(file.url);
    });

    this.miscService.downloadFiles(fileUrls).subscribe((blob: Blob) => {
      saveAs(
        blob,
        `Application-estimation-documentation-${this.insuranceApplicationTaskId}.zip`,
      );
    });
  }

  onSubmitDecision(): void {
    if (!this.insuranceApplication) return;

    if (this.decisionAmount === null) {
      this.toastrService.error('Decision amount is required');
      return;
    } else if (this.decisionAmount < 0) {
      this.toastrService.error('Decision amount cannot be negative');
      return;
    } else if (this.decisionAmount > 10000) {
      this.toastrService.error('Decision amount cannot be greater than 10,000');
      return;
    }

    if (this.decisionReason === null) {
      this.toastrService.error('Decision reason is required');
      return;
    }

    this.stateService.toggleWaitingPage(true);

    const updateData = {
      decisionAmount: this.decisionAmount,
      decisionReason: this.decisionReason,
    };

    this.miscService
      .updateInsuranceApplication(this.insuranceApplication.id, updateData)
      .subscribe({
        next: (res) => {
          this.insuranceApplication = res;
          this.stateService.toggleWaitingPage(false);
          switch (res.status) {
            case 'approved':
              this.toastrService.success('Insurance approved successfully');
              break;
            case 'denied':
              this.toastrService.success('Insurance denied successfully');
              break;
            default:
              this.toastrService.warning('Insurance pending');
              break;
          }
        },
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastrService.error('Failed to update insurance decision');
        },
      });
  }
}
