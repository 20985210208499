<!-- IF THE REVIEW WAS GIVEN TO A HANDYHANDER, AND HASNT BEEN CHANGED -->
@if (areObjectsEqual(review, tempReview)) {
  @if (review.toUserRole == 'pp') {
    <mat-card
      matTooltip="Click any star or the tekst to edit"
      [matTooltipPosition]="'above'"
    >
      <div class="card_header">
        <h4>ReviewID: {{ review.id }}</h4>
        <h4>
          Task:
          @if (review.offer?.task) {
            @if (review.offer?.task) {
              <a href="/tasks/{{ review.offer?.task?.id }}">{{
                review.offer.task.title
              }}</a>
            }
          } @else {
            <span>(This task)</span>
          }
        </h4>
        <h4>
          From user:
          <a href="/users/{{ review.fromUser.id }}">
            {{ review.fromUser.firstName }} {{ review.fromUser.lastName }}</a
          >
        </h4>
        <h4>
          Review given:
          <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
        </h4>
      </div>
      <div class="card_body">
        <div class="card_row">
          <h2>Rating</h2>
          <div class="star_container">
            @for (
              star of [0.5, 1.5, 2.5, 3.5, 4.5];
              track star;
              let i = $index
            ) {
              <mat-icon
                class="notranslate star"
                (click)="changeStarRating('rating', i + 1)"
                [ngClass]="{
                  yellow: star <= review.rating,
                  gray: star > review.rating,
                  star: true,
                  large: true,
                }"
                >star_rate</mat-icon
              >
            }
          </div>
        </div>
        <div class="rating_grid">
          <!-- Communication (Grid 1) -->
          <div class="card_row">
            <h3>Communication</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('communication', i + 1)"
                  [ngClass]="{
                    yellow: star <= review.communication,
                    gray: star > review.communication,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
          <!-- Punctuality (Grid 2)-->
          <div class="card_row">
            <h3>Punctuality</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('punctuality', i + 1)"
                  [ngClass]="{
                    yellow: star <= review.punctuality,
                    gray: star > review.punctuality,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
          <!-- Eye for detail (Grid 3)-->
          <div class="card_row">
            <h3>Eye for detail</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('eyeForDetail', i + 1)"
                  [ngClass]="{
                    yellow: star <= review.eyeForDetail,
                    gray: star > review.eyeForDetail,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
          <!-- Effeciency (Grid 4)-->
          <div class="card_row">
            <h3>Effeciency</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('efficiency', i + 1)"
                  [ngClass]="{
                    yellow: star <= review.efficiency,
                    gray: star > review.efficiency,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
        </div>
      </div>
      <h3>Message:</h3>
      <div class="message_area">
        <textarea
          id="reviewMessage"
          [(ngModel)]="tempReview.message"
        ></textarea>
      </div>
      <div class="buttons">
        @if (!review.image) {
          <button
            class="pinploy_button small_button"
            (click)="openUploadDocumentDialog()"
          >
            Upload image
          </button>
        }
        <button
          class="pinploy_button small_button red_button"
          (click)="openDeleteReviewDialog()"
        >
          Delete review
        </button>
      </div>
      @if (review.image) {
        <div class="image_area">
          <div class="tiny_image_container">
            <app-image-preview
              [imageUrl]="review.image.url"
              [fullImageObject]="review.image"
              [tiny]="true"
            ></app-image-preview>
          </div>
        </div>
      }
    </mat-card>
  }
} @else {
  <!-- IF THE REVIEW WAS GIVEN TO A HANDYHANDER, AND HAS BEEN CHANGED -->
  @if (tempReview.toUserRole == 'pp') {
    <mat-card class="changed-card">
      <div class="card_header">
        <h4>
          Task:
          @if (tempReview.offer?.task) {
            <a href="/tasks/{{ tempReview.offer?.task?.id }}">{{
              review.offer.task.title
            }}</a>
          }
        </h4>
        <h4>
          From user:
          <a href="/users/{{ tempReview.fromUser.id }}">
            {{ tempReview.fromUser.firstName }}
            {{ tempReview.fromUser.lastName }}</a
          >
        </h4>
        <h4>
          Review given:
          <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
        </h4>
      </div>
      <div class="card_body">
        <div class="card_row">
          <h2>Rating</h2>
          <div class="star_container">
            @for (
              star of [0.5, 1.5, 2.5, 3.5, 4.5];
              track star;
              let i = $index
            ) {
              <mat-icon
                class="notranslate star"
                (click)="changeStarRating('rating', i + 1)"
                [ngClass]="{
                  yellow: star <= tempReview.rating,
                  gray: star > tempReview.rating,
                  star: true,
                  large: true,
                }"
                >star_rate</mat-icon
              >
            }
          </div>
        </div>
        <div class="rating_grid">
          <!-- Communication (Grid 1) -->
          <div class="card_row">
            <h3>Communication</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('communication', i + 1)"
                  [ngClass]="{
                    yellow: star <= tempReview.communication,
                    gray: star > tempReview.communication,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
          <!-- Punctuality (Grid 2)-->
          <div class="card_row">
            <h3>Punctuality</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('punctuality', i + 1)"
                  [ngClass]="{
                    yellow: star <= tempReview.punctuality,
                    gray: star > tempReview.punctuality,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
          <!-- Eye for detail (Grid 3)-->
          <div class="card_row">
            <h3>Eye for detail</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('eyeForDetail', i + 1)"
                  [ngClass]="{
                    yellow: star <= tempReview.eyeForDetail,
                    gray: star > tempReview.eyeForDetail,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
          <!-- Effeciency (Grid 4)-->
          <div class="card_row">
            <h3>Effeciency</h3>
            <div class="star_container">
              @for (
                star of [0.5, 1.5, 2.5, 3.5, 4.5];
                track star;
                let i = $index
              ) {
                <mat-icon
                  class="notranslate"
                  (click)="changeStarRating('efficiency', i + 1)"
                  [ngClass]="{
                    yellow: star <= tempReview.efficiency,
                    gray: star > tempReview.efficiency,
                    star: true,
                  }"
                  >star_rate</mat-icon
                >
              }
            </div>
          </div>
        </div>
      </div>
      <h3>Message:</h3>
      <div class="message_area">
        <textarea
          id="reviewMessage"
          [(ngModel)]="tempReview.message"
          #reviewMessage
        ></textarea>
      </div>
      @if (tempReview.images) {
        <div class="image_area">
          @for (image of tempReview.images; track image) {
            <div class="tiny_image_container">
              <app-image-preview
                [imageUrl]="image.url"
                [tiny]="true"
              ></app-image-preview>
            </div>
          }
        </div>
      }
      <div class="buttons">
        @if (!review.image) {
          <button
            class="pinploy_button small_button"
            (click)="openUploadDocumentDialog()"
          >
            Upload image
          </button>
        }
        <button
          class="pinploy_button small_button"
          (click)="resetReview()"
        >
          Reset review details
        </button>
        <button
          class="pinploy_button small_button green_button"
          (click)="updateReview()"
        >
          Update review details
        </button>
        <button
          class="pinploy_button small_button red_button"
          (click)="openDeleteReviewDialog()"
        >
          Delete review
        </button>
      </div>
    </mat-card>
  }
}

<!-- IF THE REVIEW WAS GIVEN TO A TASK OWNER -->
@if (areObjectsEqual(review, tempReview)) {
  @if (review.toUserRole == 'to') {
    <mat-card
      matTooltip="Click the stars or the tekst to edit"
      [matTooltipPosition]="'above'"
    >
      <div class="card_header">
        <h4>
          Task:
          @if (review.offer?.task) {
            @if (review.offer?.task) {
              <a href="/tasks/{{ review.offer?.task?.id }}">{{
                review.offer.task.title
              }}</a>
            }
          } @else {
            <span>(This task)</span>
          }
        </h4>
        <h4>
          From user:
          <a href="/users/{{ review.fromUser.id }}">
            {{ review.fromUser.firstName }} {{ review.fromUser.lastName }}</a
          >
        </h4>
        <h4>
          Review given:
          <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
        </h4>
      </div>
      <div class="card_body">
        <div class="card_row">
          <h2>Rating</h2>
          <div class="star_container">
            @for (
              star of [0.5, 1.5, 2.5, 3.5, 4.5];
              track star;
              let i = $index
            ) {
              <mat-icon
                class="notranslate"
                (click)="changeStarRating('rating', i + 1)"
                [ngClass]="{
                  yellow: star <= review.rating,
                  gray: star > review.rating,
                  star: true,
                  large: true,
                }"
                >star_rate</mat-icon
              >
            }
          </div>
        </div>
      </div>
      <h3>Message:</h3>
      <div class="message_area">
        <textarea
          id="reviewMessage"
          [(ngModel)]="review.message"
        ></textarea>
      </div>
      @if (review.images) {
        <div class="image_area">
          @for (image of review.images; track image) {
            <div class="tiny_image_container">
              <app-image-preview
                [fullImageObject]="image"
                [tiny]="true"
              ></app-image-preview>
            </div>
          }
        </div>
      }
    </mat-card>
  }
} @else {
  @if (tempReview.toUserRole == 'to') {
    <mat-card class="changed-card">
      <div class="card_header">
        <h4>
          Task:
          @if (tempReview.offer?.task) {
            @if (tempReview.offer?.task) {
              <a href="/tasks/{{ review.offer?.task?.id }}">{{
                review.offer.task.title
              }}</a>
            }
          } @else {
            <span>(This task)</span>
          }
        </h4>
        <h4>
          From user:
          <a href="/users/{{ tempReview.fromUser.id }}">
            {{ tempReview.fromUser.firstName }}
            {{ tempReview.fromUser.lastName }}</a
          >
        </h4>
        <h4>
          Review given:
          <span>{{ review.createdAt | date: 'dd/MM/yyyy, HH:mm' }}</span>
        </h4>
      </div>
      <div class="card_body">
        <div class="card_row">
          <h2>Rating</h2>
          <div class="star_container">
            @for (
              star of [0.5, 1.5, 2.5, 3.5, 4.5];
              track star;
              let i = $index
            ) {
              <mat-icon
                class="notranslate"
                (click)="changeStarRating('rating', i + 1)"
                [ngClass]="{
                  yellow: star <= tempReview.rating,
                  gray: star > tempReview.rating,
                  star: true,
                  large: true,
                }"
                >star_rate</mat-icon
              >
            }
          </div>
        </div>
      </div>
      <h3>Message:</h3>
      <div class="message_area">
        <textarea
          id="reviewMessage"
          [(ngModel)]="tempReview.message"
          #reviewMessage
        ></textarea>
      </div>
      @if (tempReview.images) {
        <div class="image_area">
          @for (image of tempReview.images; track image) {
            <div class="tiny_image_container">
              <app-image-preview
                [fullImageObject]="image"
                [tiny]="true"
              ></app-image-preview>
            </div>
          }
        </div>
      }
      <div class="buttons">
        @if (!review.image) {
          <button
            class="pinploy_button small_button"
            (click)="openUploadDocumentDialog()"
          >
            Upload image
          </button>
        }
        <button
          class="pinploy_button small_button"
          (click)="resetReview()"
        >
          Reset review details
        </button>
        <button
          class="pinploy_button small_button green_button"
          (click)="updateReview()"
        >
          Update review details
        </button>
        <button
          class="pinploy_button small_button red_button"
          (click)="openDeleteReviewDialog()"
        >
          Delete review
        </button>
      </div>
    </mat-card>
  }
}
