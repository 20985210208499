<div class="cancel-task-container">
  <h3 class="section_title first_title">Cancel task: {{ cancelTask.id }}</h3>
  <h4>View and handle cancellation here.</h4>
  <div class="refund-information"></div>

  @if (cancelTaskAuto?.status === 'manual') {
    <div class="manual-resolve">
      <h2>Cancellation conflict detected</h2>
      <h4>Choose who is liable for the cancellation</h4>
      <h5>This "completes" the cancellation and refunds the taskowner.</h5>
      <h5>If Handyhander is chosen, the Handyhander gets a penalty</h5>
      <div>
        <!-- Choose who is actually liable -->
        <mat-form-field>
          <mat-select
            placeholder="Liable"
            [value]="cancelTaskAuto.feeOwner"
            [(ngModel)]="cancelTaskAuto.feeOwner"
            >
            <mat-option value="PP">Handyhander</mat-option>
            <mat-option value="TO">Taskowner</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="pinploy_button small_button save-btn"
          [ngClass]="{ 'resolve-disabled': clickedResolve }"
          [disabled]="clickedResolve"
          (click)="resolveConflict()"
          >
          Resolve
        </button>
      </div>
    </div>
    <div class="cancel-reason">
      <h3>Taskowner Cancellation Reason</h3>
      <br />
      <p>"{{ cancelTask?.reasonComment }}"</p>
    </div>
    @if (cancelTaskAuto?.hhReport) {
      <div
        class="hh-report"
        >
        <h3>Handyhander Explanation</h3>
        <br />
        <p>"{{ cancelTaskAuto?.hhReport }}"</p>
        @if (hhReportImages) {
          <div
            class="image-grid"
            >
            @for (image of hhReportImages; track image) {
              <div>
                <app-image-preview
                  [imageUrl]="image.url"
                  class="report-image"
                ></app-image-preview>
              </div>
            }
          </div>
        }
      </div>
    }
    <br />
    <mat-divider></mat-divider>
    <br />
  }

  @if (offer?.userId) {
    <div
      class="cards"
      >
      @if (task?.user) {
        <app-user-card
          [user]="task.user"
          [title]="'Taskowner'"
        ></app-user-card>
      }
      @if (task) {
        <app-task-card
          [task]="task"
        ></app-task-card>
      }
      @if (!task) {
        <mat-spinner
          diameter="120"
        ></mat-spinner>
      }
      @if (offer?.userId) {
        <app-user-card
          [userId]="offer.userId"
          [title]="'Handyhander'"
        ></app-user-card>
      }
    </div>
  }
  <br />
  <mat-divider></mat-divider>
  <br />

  <div class="section_body">
    <h3>Additional information</h3>

    <div>
      <!-- CreatedAt -->
      <mat-form-field>
        <input
          matInput
          placeholder="Cancellation created at"
          [(ngModel)]="cancelTask.createdAt"
          [disabled]="true"
          />
      </mat-form-field>

      <!-- UpdatedAt -->
      <mat-form-field>
        <input
          matInput
          placeholder="Cancellation updated at"
          [(ngModel)]="cancelTask.updatedAt"
          [disabled]="true"
          />
      </mat-form-field>
      <!-- ChargeId -->
      <mat-form-field>
        <input
          matInput
          placeholder="Charge Id"
          [(ngModel)]="cancelTask.chargeId"
          [disabled]="true"
          />
      </mat-form-field>

      <!-- Status -->
      <mat-form-field>
        <mat-select
          placeholder="Status"
          [value]="cancelTask.status"
          [(ngModel)]="cancelTask.status"
          [disabled]="true"
          >
          <mat-option value="pending">Pending</mat-option>
          <mat-option value="pendingAutoFlow">PendingAutoFlow</mat-option>
          <mat-option value="resurrected">Resurrected</mat-option>
          <mat-option value="canceled">Canceled</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Reason Comment-->
      <mat-form-field>
        <textarea
          matInput
          class="textarea-field"
          placeholder="Reason comment"
          [(ngModel)]="cancelTask.reasonComment"
        ></textarea>
      </mat-form-field>
      <!-- Reason -->
      <mat-form-field>
        <textarea
          matInput
          class="textarea-field"
          placeholder="Reason"
          [(ngModel)]="cancelTask.reason"
        ></textarea>
      </mat-form-field>
    </div>
    <!-- Status type container -->
    <div class="status-type-container">
      <h3>StatusType</h3>
      <p>Info about different refund types</p>
      <ul>
        <li><h5>Pending -> Awaiting Admin Attention</h5></li>
        <li><h5>Canceled -> Have been set as canceled by Admin</h5></li>
        <li>
          <h5>Resurrected -> TO & PP have reconnected and continued task</h5>
        </li>
      </ul>
    </div>
  </div>
</div>
