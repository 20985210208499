@if (task) {
  <mat-card>
    <div class="card_header">
      <h5>{{ title }}</h5>
      <button
        class="pinploy_button small_button"
        (click)="goToTaskDetails(task.id, $event)"
        >
        Open task details
      </button>
    </div>
    <div class="card_body">
      <div class="card_row">
        <p class="strong">Id</p>
        <p>{{ task.id }}</p>
      </div>
      <div class="card_row">
        <p class="strong">Title</p>
        <p>{{ task.title }}</p>
      </div>
      <div class="card_row">
        <p class="strong">Budget</p>
        <p>{{ task.budget }}</p>
      </div>
      @if (task.acceptedOffer) {
        <div
          class="card_row"
          >
          <p class="strong">Accepted offer</p>
          <p>{{ task.acceptedOffer.priceBid }}</p>
        </div>
      }
      @if (task.completedOffer) {
        <div
          class="card_row"
          >
          <p class="strong">Completed offer</p>
          <p>{{ task.completedOffer.priceBid }}</p>
        </div>
      }
      @if (task.status != undefined) {
        <div
          class="card_row"
          >
          <p class="strong">Status</p>
          <p>{{ getFormattedStatus() }}</p>
        </div>
      }
      @if (task.status === 'completed') {
        <div
          class="card_row"
          >
          <p class="strong">COMPLETED AT</p>
          <p>{{ task?.acceptedOffer?.completedAt | date: 'dd/MM/yyyy, HH:mm' }}</p>
        </div>
      }
      @if (task.status === 'pending' && task.acceptedOffer) {
        <div
          class="card_row"
          >
          <p class="strong">ACCEPTED AT</p>
          <p>{{ task?.acceptedOffer?.acceptedAt | date: 'dd/MM/yyyy, HH:mm' }}</p>
        </div>
      }
      @if (task.status === 'canceled' && task.acceptedOffer?.cancelledAt) {
        <div
          class="card_row"
          >
          <p class="strong">CANCELLED AT</p>
          <p>{{ task?.acceptedOffer?.cancelledAt | date: 'dd/MM/yyyy, HH:mm' }}</p>
        </div>
      }
      @if (task.user) {
        <div
          class="card_row"
          >
          <p class="strong">Taskowner</p>
          <p>{{ task.user?.firstName }} {{ task.user?.lastName }}</p>
        </div>
      }
      @if (task.city) {
        @if (task.acceptedOffer?.user) {
          <div
            class="card_row"
            >
            <p class="strong">Handyhander</p>
            <p>{{ getFullName() }}</p>
          </div>
        }
        <div
          class="card_row"
          >
          <p class="strong">City</p>
          <p>{{ task?.city }}</p>
        </div>
      }
    </div>
  </mat-card>
}

@if (!task) {
  <mat-card>
    <div class="card_header">
      <h5>{{ title }}</h5>
    </div>

    <div class="spinner_body">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
}
