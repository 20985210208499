import { Component, Inject, OnInit, output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupportTicket } from 'src/app/models/supportTicket.model';
import { StripHtmlPipe } from 'src/app/pipes/stripHtml.pipe';
import { SupportTicketService } from 'src/app/services/support.ticket.service';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

export interface SmartResponsesDialogData {
  supportTicket: SupportTicket;
}

export type EmailTemplateItem = {
  countryCode: 'dk' | 'eng';
  emailTemplate: string;
  question: string;
  response: string;
  _id: string;
};

@Component({
    selector: 'app-smart-responses',
    templateUrl: './smart-responses.component.html',
    styleUrls: [
        '../../../components/dialogs/dialogs.scss',
        './smart-responses.component.scss',
    ],
    imports: [...ALL_IMPORTS, StripHtmlPipe]
})
export class SmartResponsesDialogComponent implements OnInit {
  language: 'danish' | 'english' = 'danish';
  allPrioritizedEmailTemplates: any[] = [];
  engPrioritizedEmailTemplates: any[] = [];
  dkPrioritizedEmailTemplates: any[] = [];

  emailTemplates: any[] = [];
  filteredEmailTemplates: any = {
    dk: [],
    eng: [],
  };

  emailTemplateCategories: any = [
    { name: 'account', enName: 'Account', dkName: 'Konto' },
    { name: 'payment', enName: 'Payment', dkName: 'Betaling' },
    { name: 'cancellation', enName: 'Cancellation', dkName: 'Annullering' },
    { name: 'question', enName: 'Question', dkName: 'Spørgsmål' },
    { name: 'task', enName: 'Task', dkName: 'Opgave' },
    { name: 'conflict', enName: 'Conflict', dkName: 'Konflikt' },
    { name: 'guidelines', enName: 'Guidelines', dkName: 'Retningslinjer' },
    { name: 'document', enName: 'Document', dkName: 'Dokument' },
    { name: 'payout', enName: 'Payout', dkName: 'Udbetaling' },
    { name: 'tech', enName: 'Tech', dkName: 'Tech' },
  ];

  emailTemplateSelected = output<EmailTemplateItem>();

  selectedTabIndex: number = 0;
  searchTerm: string = '';

  get showSearchTab(): boolean {
    return !!this.searchTerm;
  }

  get searchResults() {
    if (!this.searchTerm) return [];

    const searchLower = this.searchTerm.toLowerCase();
    return this.emailTemplates.filter(
      (template) =>
        template.countryCode.toLowerCase() ===
          (this.language === 'danish' ? 'dk' : 'eng') &&
        (template.question?.toLowerCase().includes(searchLower) ||
          template.response?.toLowerCase().includes(searchLower)),
    );
  }

  get filteredPrioritizedTemplates() {
    const templates =
      this.language === 'danish'
        ? this.dkPrioritizedEmailTemplates
        : this.engPrioritizedEmailTemplates;

    if (!this.searchTerm) return templates;

    const searchLower = this.searchTerm.toLowerCase();
    return templates.filter(
      (template) =>
        template.question?.toLowerCase().includes(searchLower) ||
        template.response?.toLowerCase().includes(searchLower),
    );
  }

  onSearchChange() {
    if (this.searchTerm && this.selectedTabIndex < 3) {
      this.selectedTabIndex = 3; // Switch to search results tab
    }
  }

  constructor(
    public dialogRef: MatDialogRef<SmartResponsesDialogComponent>,
    private supportTicketService: SupportTicketService,
    @Inject(MAT_DIALOG_DATA) public data: SmartResponsesDialogData,
  ) {}

  ngOnInit(): void {
    this.loadPrioritizedEmailTemplates();
    this.fetchEmailTemplates();
  }

  selectEmailTempCategory(emailTempCat: any) {
    if (this.language === 'danish') {
      this.filteredEmailTemplates.dk = this.filterTemplatesByCategory(
        this.emailTemplates.filter(
          (template) =>
            template.cat === emailTempCat.name &&
            template.countryCode.toLowerCase() === 'dk',
        ),
      );
    } else {
      this.filteredEmailTemplates.eng = this.filterTemplatesByCategory(
        this.emailTemplates.filter(
          (template) =>
            template.cat === emailTempCat.name &&
            template.countryCode.toLowerCase() === 'eng',
        ),
      );
    }
  }

  fetchEmailTemplates() {
    this.supportTicketService.getSupportEmailTemplates().subscribe((res) => {
      if (res) {
        this.emailTemplates = res;
      }
    });
  }

  selectAndInsertEmailTemp(emailTemplate) {
    this.emailTemplateSelected.emit(emailTemplate);
    this.close();
  }

  loadPrioritizedEmailTemplates() {
    const data = {
      cat: this.data.supportTicket.subTopic,
      subCat: this.data.supportTicket.subTopic,
    };
    this.supportTicketService
      .getSupportTicketTemplateByCatOrSubCat(data)
      .subscribe((res) => {
        if (res && res.length > 0) {
          this.allPrioritizedEmailTemplates = res;
          this.dkPrioritizedEmailTemplates =
            this.allPrioritizedEmailTemplates.filter(
              (x) => x.countryCode.toLowerCase() === 'dk',
            );
          this.engPrioritizedEmailTemplates =
            this.allPrioritizedEmailTemplates.filter(
              (x) => x.countryCode.toLowerCase() === 'eng',
            );
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  filterTemplatesByCategory(templates: any[]) {
    if (!this.searchTerm) return templates;

    const searchLower = this.searchTerm.toLowerCase();
    return templates.filter(
      (template) =>
        template.question?.toLowerCase().includes(searchLower) ||
        template.response?.toLowerCase().includes(searchLower),
    );
  }
}
