import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Report, ReportReasons } from 'src/app/models/report.model';
import { ReportService } from 'src/app/services/report.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { TaskConversationComponent } from '../../../components/task-conversation/task-conversation.component';
import { ReportedReviewCardComponent } from '../../../components/cards/reported-review-card/reported-review-card.component';
import { CommentCardComponent } from '../../../components/cards/comment-card/comment-card.component';
import { OfferCardComponent } from '../../../components/cards/offer-card/offer-card.component';
import { TaskCardComponent } from '../../../components/cards/task-card/task-card.component';
import { UserCardComponent } from '../../../components/cards/user-card/user-card.component';
import { UsersCurrentlyViewingComponent } from '../../../components/users-currently-viewing/users-currently-viewing.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  imports: [
    ...ALL_IMPORTS,
    UsersCurrentlyViewingComponent,
    UserCardComponent,
    TaskCardComponent,
    OfferCardComponent,
    CommentCardComponent,
    ReportedReviewCardComponent,
    TaskConversationComponent,
  ],
})
export class ReportComponent implements OnInit, OnDestroy {
  report: Report;
  saving = false;
  conversationType: string;
  conversationTypeId: number;
  usersCurrentlyViewing: User[] = [];
  usersCurrentlyViewingSubscription: Subscription;

  get REASONS() {
    return Object.values(ReportReasons);
  }

  constructor(
    private title: Title,
    private router: Router,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private toastr: ToastrService,
    private userService: UserService,
    private socketService: SocketService,
  ) {}

  ngOnInit() {
    this.report = new Report();
    this.stateService.toggleWaitingPage(true);
    this.getReport();
    this.socketService.emitEvent({
      eventName: 'viewing_entity',
      data: {
        entityId: this.route.snapshot.params.id,
        entityType: 'report',
      },
    });
  }

  subscribeToCurrentlyViewedReport() {
    this.usersCurrentlyViewingSubscription =
      this.stateService.currentlyViewedReportsSource.subscribe({
        next: (reports) => {
          const id = String(this.report.id);
          if (id in reports) {
            // remove duplicate users from the list
            const viewedBySet = new Set();
            this.usersCurrentlyViewing = reports[id].filter((x) => {
              const duplicate = viewedBySet.has(x.id);
              viewedBySet.add(x.id);
              return !duplicate;
            });
          }
        },
      });
  }

  getReport() {
    this.route.params.subscribe((params) => {
      this.reportService.getOneReport(params.id).subscribe(
        (res) => {
          this.report = res;
          this.setConversationType(this.report);
          this.setMetaTitle();
          this.stateService.toggleWaitingPage(false);
          this.subscribeToCurrentlyViewedReport();
        },
        (err) => {
          console.log(err);
          this.toastr.error('Failed to load report data.');
        },
      );
    });
  }

  setConversationType(report: Report) {
    switch (report.type) {
      case 'offer':
        this.conversationType = 'offer';
        this.conversationTypeId = report.offer.id;
        break;

      case 'offer_comment':
        this.conversationType = 'offer-comment';
        this.conversationTypeId = report.offerComment.id;
        break;

      case 'task_comment':
        this.conversationType = 'task-comment';
        this.conversationTypeId = report.taskComment.id;
        break;

      case 'task':
        this.conversationType = 'task';
        this.conversationTypeId = report.task.id;
        break;

      default:
        this.conversationType = null;
        this.conversationTypeId = null;
        break;
    }
  }

  onSetStatusActive() {
    this.saving = true;
    this.reportService.editReportStatus(this.report.id, 'active').subscribe(
      (result) => {
        this.report = Object.assign(this.report, result);
        this.stateService.updateTotalActiveReports(this.report);
        this.toastr.success('Status updated');
        this.saving = false;
      },
      (err) => {
        this.toastr.error('Failed to update status');
        this.saving = false;
      },
    );
  }

  onCloseAllReports(targetId?: number, type?: string) {
    this.reportService
      .closeAllCorrespondingReports(
        type || this.report.type,
        targetId || this.report.itemId,
      )
      .subscribe(
        (res) => {
          this.stateService.readActiveReportsComponentSource.next({
            ids: res.successful,
            ownEvent: true,
          });
          this.toastr.success(
            'All reports on this task comment have been closed',
          );
          this.saving = false;
          this.router.navigate(['/reports']);
        },
        (err) => {
          this.toastr.error('Failed to close all reports on this task comment');
          this.saving = false;
        },
      );
  }

  onSetStatusRemoved() {
    this.saving = true;

    switch (this.report.type) {
      case 'offer':
        this.onCloseAllReports(this.report.itemId, 'offer');
        break;
      case 'task_comment':
      case 'offer_comment':
      case 'task':
        this.onCloseAllReports();
        break;
      default:
        this.reportService
          .editReportStatus(this.report.id, 'removed')
          .subscribe(
            (result) => {
              this.report = Object.assign(this.report, result);
              this.stateService.readActiveReportsComponentSource.next({
                id: this.report.id,
                ownEvent: true,
              });
              this.toastr.success('Status updated');
              this.saving = false;
              this.router.navigate(['/reports']);
            },
            (err) => {
              this.toastr.error('Failed to update status');
              this.saving = false;
            },
          );
        break;
    }
  }

  onSendEmail(template: string): void {
    if (this.report.task == null) {
      return;
    }

    const dialogDescription = `Send a report e-mail to user ${this.report.targetId} regarding task ${this.report.task.id}?`;
    if (confirm(dialogDescription)) {
      this.saving = true;
      this.userService
        .sendEmail(this.report.targetId, template, this.report.task.id)
        .subscribe(
          () => {
            this.toastr.success('E-mail sent');
            this.saving = false;
          },
          (err) => {
            this.toastr.error(err.message);
            this.saving = false;
          },
        );
    }
  }

  setMetaTitle() {
    this.title.setTitle(
      `${this.report.target.firstName} reported by ${this.report.reporter.firstName}`,
    );
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);

    this.socketService.emitEvent({
      eventName: 'stop_viewing_entity',
      data: {
        entityId: this.route.snapshot.params.id,
        entityType: 'report',
      },
    });
  }
}
