import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SocketService } from '../services/socket.service';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';
import { WaitingComponent } from '../components/waiting/waiting.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { HeaderComponent } from '../components/header/header.component';
import { NavbarInsuranceComponent } from '../components/navbar-insurance/navbar-insurance.component';
import { COMMON_IMPORTS } from '../shared/standalone-imports';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        ...COMMON_IMPORTS,
        NavbarComponent,
        WaitingComponent,
        NavbarInsuranceComponent,
        HeaderComponent,
    ]
})
export class AppComponent implements OnInit {
  constructor(
    private socketService: SocketService,
    private storage: StorageService,
    private router: Router,
    private authService: AuthService,
  ) {}

  isInsurance = false;

  showComponent() {
    return this.router.url !== '/login';
  }

  ngOnInit() {
    const socketId = Math.random().toString(36).substring(2, 15);
    this.storage.socketId = socketId;
    this.socketService.connectSocket();
    this.isInsurance = this.authService.hasRole('insurance');
    this.authService.tokenUpdated.subscribe(() => {
      this.isInsurance = this.authService.hasRole('insurance');
    });
  }
}
