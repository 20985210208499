<div class="cancel-task-list-container">
  <div id="controls">
    <h1>Cancel Task list</h1>
    <p>Admin can adminsitrative take control over cancelled tasks.</p>
  </div>
  <paginated-list
    [data]="cancelledTasks"
    (displayDataChanged)="updateDisplayCancelledTasks($event)"
    (dataRequested)="fetchCancelledTasks()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
    >
    <div class="list">
      @for (task of displayCancelledTasks; track task) {
        <a
          class="item"
          routerLink="{{ '/cancel-task/' + task.id }}"
          >
          <p class="id"><span class="bold">ID: </span>{{ task.id }}</p>
          <p><span class="bold">Status: </span>{{ task.status }}</p>
          <p><span class="bold">TaskOwnerId: </span>{{ task.taskOwnerId }}</p>
          <p class="id"><span class="bold">TaskId: </span>{{ task.taskId }}</p>
          <p class="id"><span class="bold">OfferId: </span>{{ task.offerId }}</p>
          <p class="text"><span class="bold">Reason: </span>{{ task.reason }}</p>
          <p class="id">
            <span class="bold">PPUserId: </span>{{ task.offer.userId }}
          </p>
        </a>
      }
    </div>
  </paginated-list>
</div>
