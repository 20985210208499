import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './datePickerDialog.component.html',
  styleUrls: ['../dialogs.scss', './datePickerDialog.component.scss'],
  imports: [ALL_IMPORTS],
  standalone: true,
})
export class DatePickerDialogComponent {
  selectedDate: Date;
  maxDate: Date = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18),
  ); // Max date is 18 years ago from today

  constructor(
    public dialogRef: MatDialogRef<DatePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { date?: string },
  ) {
    this.selectedDate = data.date ? new Date(data.date) : new Date();
    // Ensure selected date isn't in the future if provided through data
    if (this.selectedDate > this.maxDate) {
      this.selectedDate = this.maxDate;
    }
  }

  onSubmit(): void {
    this.dialogRef.close(this.selectedDate);
  }

  onCancel(): void {
    this.dialogRef.close('cancel');
  }
}
