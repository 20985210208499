import { Media } from "./media.model";
import { Task } from "./task.model";
import { User } from "./user.model";
import { Video } from "./video.model";

const legacyStatuses = ['Open', 'Close'] as const;
const supportTicketStatuses = ['unresolved', 'resolved', 'closed'] as const;
export type SupportTicketStatuses =
  | (typeof supportTicketStatuses)[number]
  | (typeof legacyStatuses)[number];

export type SupportTicketMessage = {
  id: number;
  text: string;
  supportTicketId: number,
  createdAt: string;
  updatedAt: string;
  createdBy: User;
  images: Media[];
  docs: Media[];
  videos: Video[];
};
export type SupportTicketReminder = {
  id: number;
  createdAt: string;
  note: string;
  expiresAt: string;
  completedAt: string;
  user: User;
};

export class SupportTicket {
  id: number;
  uid?: string;
  email: string;
  status: SupportTicketStatuses;
  taskId: number;
  topic: string;
  subTopic: string;
  mainConcern: string;
  detailConcern: string;
  stripeChargeId: string;
  stripePayoutId: string;
  userId: number;
  assigneeId: number | null;
  media: [];
  createdAt: string;
  updatedAt: string;
  viewedBy: any;
  messages: SupportTicketMessage[];
  reminders: SupportTicketReminder[];
  overdueReminders: SupportTicketReminder[];
  user: User;
  assignee: User;
  task: Task;
  faqTopic: string;
  constructor(data: any = null) {
    Object.assign(this, data);
  }
}

export type PaginatedSupportTickets = {
  tickets: SupportTicket[];
  total: number;
  totalPages: number;
  currentPage: number;
  hasMore: boolean;
}

export type SupportTicketCreationData = {
  taskId: number | null;
  topic: string;
  subTopic: string;
  mainConcern: string;
  detailConcern: string;
  userId: number | null;
  email: string;
  docs?: Media[];
  images?: Media[];
  faqTopic: string | null;
  user?: User;
}
