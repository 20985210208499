import { Component, Inject, OnInit } from '@angular/core';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SupportTicketReminder } from 'src/app/models/supportTicket.model';

@Component({
    selector: 'app-support-ticket-reminder-info-dialog',
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS],
    templateUrl: './support-ticket-reminder-info-dialog.component.html',
    styleUrls: [
        '../dialogs.scss',
        './support-ticket-reminder-info-dialog.component.scss',
    ]
})
export class SupportTicketReminderInfoDialogComponent implements OnInit {
  public reminder: SupportTicketReminder;
  constructor(
    public dialogRef: MatDialogRef<SupportTicketReminderInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.reminder = this.data.reminder;
  }

  close(): void {
    this.dialogRef.close();
  }
}
