import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'app-requestCancellation-dialog',
    templateUrl: 'requestCancellationDialog.component.html',
    styleUrls: ['../../dialogs.scss', 'requestCancellationDialog.component.scss'],
    imports: [MatRadioGroup, FormsModule, MatRadioButton, MatFormField, MatLabel, MatInput]
})
export class RequestCancellationDialog {

  title: any;
  description: any;
  cancellationReason;
  
  reasons = [
    { reason: 'toNoTime', feeOwner: 'TO' },
    { reason: 'toPersonal', feeOwner: 'TO' },
    { reason: 'toDecided', feeOwner: 'TO' },
    { reason: 'toMisleadingScope', feeOwner: 'TO' },
    { reason: 'ppNoShowOrAnswer', feeOwner: 'PP' },
    { reason: 'ppDecided', feeOwner: 'PP'},
  ];
  options = [
    { option: 'As user'},
    { option: 'Force'},
  ];
  
  cancellationOptions = this.options[0];
  constructor(
    public dialogRef: MatDialogRef<RequestCancellationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.title = data.title;
  }


  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  apply(): void {
    this.close({ res: 'yes', cancellationReason: this.cancellationReason, reasonComment: this.description, cancellationOptions: this.cancellationOptions.option });
  }

  setReason(reason){
    this.cancellationReason = reason;
  }

  setOptions(option){
    switch(option.option){
      case 'Force':
        this.description = 'Force cancellation';
        this.cancellationOptions = option;
        break;
      case 'As user':
        this.description = '';
        this.cancellationOptions = option;
        break;
    }
  }
}
