import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StateService } from 'src/app/services/state.service';
import { MatIconModule } from '@angular/material/icon';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-navbar-insurance',
    templateUrl: './navbar-insurance.component.html',
    styleUrls: ['./navbar-insurance.component.scss'],
    imports: [...COMMON_IMPORTS, MatIconModule]
})
export class NavbarInsuranceComponent implements OnInit, OnDestroy {
  items: any[];

  activeTab: 'Insurance applications';
  hovered: boolean = false;

  constructor(
    private router: Router,
    private stateService: StateService,
  ) {
    this.items = [
      {
        name: 'Insurance applications',
        route: '/insurance-applications',
        icon: 'currency_exchange',
        opened: false,
      },
    ];
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.initActiveTab();
      });
    this.initActiveTab();
  }

  initActiveTab() {
    if (this.router.url.startsWith('/insurance-applications')) {
      this.activeTab = 'Insurance applications';
    } else {
      this.activeTab = 'Insurance applications';
    }
  }

  openItemMenu(item: any, event: Event) {
    event.stopPropagation();
    item.opened = !item.opened;
  }

  goTo(route: string) {
    const navbar = document.getElementsByClassName('navbar_container')[0];
    const page = document.getElementsByClassName('page_container')[0];
    const mediaWidth = window.matchMedia('(max-width: 768px)');
    if (!navbar.classList.contains('hidden')) {
      if (mediaWidth.matches) {
        navbar.classList.add('hidden');
      }
      page.classList.remove('hidden');
    }

    switch (route) {
      case '/insurance-applications':
        this.router.navigate(['/insurance-applications']);
        this.activeTab = 'Insurance applications';
        break;
      default:
        this.router.navigate(['/insurance-applications']);
        this.activeTab = 'Insurance applications';
        break;
    }
  }

  hover() {
    this.hovered = true;
    const url = this.router.url;
    switch (url) {
      case '/insurance-applications':
        this.items[0].opened = true;
        this.activeTab = 'Insurance applications';
        break;
    }
  }

  unhover() {
    this.hovered = false;
    this.items.forEach((item) => {
      item.opened = false;
    });
    const url = this.router.url;
    switch (url) {
      case '/insurance-applications':
        this.items[0].opened = false;
        this.activeTab = 'Insurance applications';
        break;
    }
  }

  ngOnDestroy() {}
}
