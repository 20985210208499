<div class="user_container">
  <div class="user_image_area">
    <!-- If the user has a header image-->
    @if (userHeaderImage) {
      <div class="user_header_image">
        <img
          src="{{ this.userHeaderImage.url }}"
          alt="image"
          class="user_header_image"
        />
        <button
          mat-icon-button
          aria-label="Icon for editing the image"
          class="edit_header_button"
          (click)="openUserHeaderImageDialog()"
          [ngClass]="{ disabled: isUserHeaderImageBtnDisabled() }"
          [disabled]="isUserHeaderImageBtnDisabled()"
        >
          <mat-icon>edit</mat-icon>
        </button>

        @if (user.profileImg) {
          <img
            src="{{ this.user.profileImg }}"
            alt="Profile picture"
            class="profile_image"
          />
          @if (user.mitIdVerified) {
            <mat-icon class="verified_icon">verified_user</mat-icon>
          }
        } @else {
          <mat-icon
            role="img"
            class="profile_image_no_image"
            >account_circle</mat-icon
          >
          @if (user.mitIdVerified) {
            <mat-icon class="verified_icon">verified_user</mat-icon>
          }
        }

        <button
          mat-icon-button
          aria-label="Icon for editing the profile picture"
          class="edit_profile_picture_button"
          (click)="openRemoveProfilePicture()"
          [ngClass]="{ disabled: isRemoveProfilePictureBtnDisabled() }"
          [disabled]="isRemoveProfilePictureBtnDisabled()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    }

    <!-- If the user doesn't have a header image-->
    @if (!userHeaderImage) {
      <div class="user_header_image">
        <img
          src="https://d10a6kin4dgxel.cloudfront.net/private/lp/webp/hh_logo_blue.webp"
          alt="Handyhand Logo"
          class="user_header_image_no_image"
        />
        <button
          mat-icon-button
          aria-label="Icon for editing the image"
          class="edit_header_button"
          (click)="openUserHeaderImageDialog()"
          [ngClass]="{ disabled: isUserHeaderImageBtnDisabled() }"
          [disabled]="isUserHeaderImageBtnDisabled()"
        >
          <mat-icon>edit</mat-icon>
        </button>

        @if (user.profileImg) {
          <img
            src="{{ this.user.profileImg }}"
            alt="Profile picture"
            class="profile_image"
          />
          @if (user.mitIdVerified) {
            <mat-icon class="verified_icon">verified_user</mat-icon>
          }
        } @else {
          <mat-icon
            role="img"
            class="profile_image_no_image"
            >account_circle</mat-icon
          >
          @if (user.mitIdVerified) {
            <mat-icon class="verified_icon">verified_user</mat-icon>
          }
        }

        <button
          mat-icon-button
          aria-label="Icon for editing the profile picture"
          class="edit_profile_picture_button"
          (click)="openRemoveProfilePicture()"
          [ngClass]="{ disabled: isRemoveProfilePictureBtnDisabled() }"
          [disabled]="isRemoveProfilePictureBtnDisabled()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    }
  </div>

  <div class="section_body">
    <!-- User info Left box-->
    <div
      class="left"
      id="userInfoLeft"
    >
      <!-- ID -->

      <mat-form-field>
        <mat-label>User ID</mat-label>
        <mat-icon
          matPrefix
          class="icon"
          >perm_identity</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.id"
          disabled
        />
        <mat-icon matSuffix>
          <a
            href="https://handyhand.dk/hh/detaljer/{{ user.id }}"
            target="_blank"
          >
            <mat-icon> open_in_new </mat-icon>
          </a>
        </mat-icon>
      </mat-form-field>

      <!-- First name -->
      <mat-form-field>
        <mat-label>First name</mat-label>

        <mat-icon
          matPrefix
          class="icon"
          >text_fields</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.firstName"
        />
      </mat-form-field>
      <!-- Last name -->
      <mat-form-field>
        <mat-label>Last name</mat-label>

        <mat-icon
          matPrefix
          class="icon"
          >text_fields</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.lastName"
        />
      </mat-form-field>

      <!-- Email -->
      <mat-form-field>
        <mat-label>Email</mat-label>

        <mat-icon
          matPrefix
          class="icon"
          >email</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.email"
        />
      </mat-form-field>

      <!-- Phone -->
      <mat-form-field>
        <mat-label>Phone number</mat-label>

        <mat-icon
          matPrefix
          class="icon"
          >phone</mat-icon
        >
        <input
          matInput
          [(ngModel)]="phoneReadable"
          [disabled]="true"
        />

        <button
          mat-icon-button
          matSuffix
          aria-label="Icon for editing the phone number"
          class="edit_phone_number_button"
          (click)="openPhoneNumberDialog()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!-- User info Right box-->
    <div
      class="right"
      id="userInfoRight"
    >
      <!-- Stored Credit -->

      @if (storedCreditsBalance) {
        <div class="storedCreditArea">
          <mat-form-field>
            <mat-label>Stored Credits</mat-label>

            <mat-icon
              matPrefix
              class="icon"
              >attach_money</mat-icon
            >
            <input
              matInput
              [(ngModel)]="storedCreditsBalance"
              [disabled]="true"
            />
          </mat-form-field>
          <div class="stored_credit_buttons">
            @if (balance !== 0) {
              <button
                class="pinploy_button small"
                (click)="openUserStoredCreditsDialog()"
              >
                Withdrawal
              </button>
            }
            <button
              class="pinploy_button small"
              (click)="openUserStoredCreditsHistoryDialog()"
            >
              History
            </button>
          </div>
        </div>
      }

      <!-- Stripe Customer ID -->
      @if (user.stripeCustomerId) {
        <mat-form-field>
          <mat-label>Stripe customer ID</mat-label>

          <svg
            matPrefix
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.87 28.87"
            id="stripe"
          >
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <rect
                  width="28.87"
                  height="28.87"
                  fill="#6772e5"
                  rx="6.48"
                  ry="6.48"
                ></rect>
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M13.3 11.2c0-.69.57-1 1.49-1a9.84 9.84 0 0 1 4.37 1.13V7.24a11.6 11.6 0 0 0-4.36-.8c-3.56 0-5.94 1.86-5.94 5 0 4.86 6.68 4.07 6.68 6.17 0 .81-.71 1.07-1.68 1.07A11.06 11.06 0 0 1 9 17.25v4.19a12.19 12.19 0 0 0 4.8 1c3.65 0 6.17-1.8 6.17-5 .03-5.21-6.67-4.27-6.67-6.24z"
                ></path>
              </g>
            </g>
          </svg>
          <input
            matInput
            [(ngModel)]="user.stripeCustomerId"
            disabled
          />
          <mat-icon matSuffix>
            <a
              href="{{ stripeUrl }}/customers/{{ user.stripeCustomerId }}"
              target="_blank"
            >
              <mat-icon> open_in_new </mat-icon>
            </a>
          </mat-icon>
        </mat-form-field>
      }

      <!-- Stripe Account ID -->
      @if (user.stripeAccountId) {
        <mat-form-field>
          <mat-label>Stripe connect ID</mat-label>

          <svg
            matPrefix
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28.87 28.87"
            id="stripe"
          >
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <rect
                  width="28.87"
                  height="28.87"
                  fill="#6772e5"
                  rx="6.48"
                  ry="6.48"
                ></rect>
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M13.3 11.2c0-.69.57-1 1.49-1a9.84 9.84 0 0 1 4.37 1.13V7.24a11.6 11.6 0 0 0-4.36-.8c-3.56 0-5.94 1.86-5.94 5 0 4.86 6.68 4.07 6.68 6.17 0 .81-.71 1.07-1.68 1.07A11.06 11.06 0 0 1 9 17.25v4.19a12.19 12.19 0 0 0 4.8 1c3.65 0 6.17-1.8 6.17-5 .03-5.21-6.67-4.27-6.67-6.24z"
                ></path>
              </g>
            </g>
          </svg>
          <input
            matInput
            type="text"
            [(ngModel)]="user.stripeAccountId"
            disabled
          />
          <mat-icon matSuffix>
            <a
              href="{{ stripeUrl }}/connect/accounts/{{ user.stripeAccountId }}"
              target="_blank"
            >
              <mat-icon>open_in_new</mat-icon>
            </a>
          </mat-icon>
        </mat-form-field>
      }

      <!-- Last online -->
      @if (user.lastOnline) {
        <mat-form-field
          matTooltip="Signed up: {{
            user.createdAt | date: 'dd/MM/yyyy HH:mm'
          }}"
        >
          <mat-label>Last online</mat-label>

          <mat-icon
            matPrefix
            class="icon"
            >computer</mat-icon
          >
          @if (!lastActivity?.client) {
            <input
              matInput
              value="{{ user.lastOnline | date: 'dd/MM/yyyy HH:mm' }}"
              [disabled]="true"
            />
          } @else {
            <input
              matInput
              value="{{ user.lastOnline | date: 'dd/MM/yyyy HH:mm' }} ({{
                lastActivity.client
              }})"
              [disabled]="true"
            />
          }
        </mat-form-field>
      }
    </div>
  </div>

  <div class="section_body">
    <!-- Description -->
    <div class="left small">
      <mat-form-field>
        <mat-label>Description</mat-label>

        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="10"
          [(ngModel)]="user.description"
          class="user_input_field"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="right small">
      <!-- Education -->
      <mat-form-field>
        <mat-label>Education</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="10"
          [(ngModel)]="user.education"
          class="user_input_field"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <mat-divider></mat-divider>
  <br />
  <div
    class="center"
    style="justify-content: space-evenly; margin-top: 1rem"
  >
    <button
      class="pinploy_button small_button"
      [ngClass]="{ disabled: isBtnDisabled() }"
      [disabled]="isBtnDisabled()"
      (click)="saveChanges()"
    >
      Save user information
    </button>

    <button
      class="pinploy_button small_button save_btn save-btns"
      [matMenuTriggerFor]="menu"
    >
      <p>More options</p>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="openUserMediaPortfolio()"
        [ngClass]="{ disabled: isUserMediaPortfolioBtnDisabled() }"
        [disabled]="isUserMediaPortfolioBtnDisabled()"
      >
        <mat-icon>photo_album</mat-icon>
        View Portfolio
      </button>

      <button
        mat-menu-item
        (click)="updateAccountCapabilities()"
      >
        <mat-icon>refresh</mat-icon>
        Update Account Capabilities (Enable transfer)
      </button>

      <button
        mat-menu-item
        (click)="openMergeUsersDialog()"
      >
        <mat-icon>call_merge</mat-icon>
        Merge users
      </button>

      <button
        mat-menu-item
        (click)="sendGeneratedPassword()"
      >
        <mat-icon>lock_open</mat-icon>
        Send user temporary password
      </button>

      <button
        mat-menu-item
        (click)="openUserInformationDetailsDialog()"
      >
        <mat-icon>search</mat-icon>
        View full user data (JSON)
      </button>

      <button
        mat-menu-item
        (click)="deleteAccount()"
      >
        <mat-icon class="red">delete_forever</mat-icon>
        Delete account
      </button>
    </mat-menu>
  </div>
  <mat-divider></mat-divider>

  <mat-tab-group
    animationDuration="0ms"
    class="mat-tab-area"
    mat-stretch-tabs="true"
    mat-align-tabs="center"
  >
    <!-- Banning area for user -->
    <mat-tab label="Ban / unban user">
      <div class="tab">
        <div class="ban_section">
          <h3 class="center">Ban / Grace history</h3>
          <mat-divider></mat-divider>
          <div class="clear">
            <div class="section_body ban-history-container">
              <div class="content">
                @for (banOrGrace of banAndGraceHistory; track banOrGrace.id) {
                  <!-- BANS -->
                  @if (banOrGrace.reason) {
                    <div
                      class="ban-history-entry"
                      [ngClass]="{
                        activeBan: isActiveBanOrGracePeriod(banOrGrace),
                        inactiveBan: !isActiveBanOrGracePeriod(banOrGrace),
                      }"
                    >
                      <span>Type: Ban</span>
                      <span><b>ID:</b> {{ banOrGrace.id }}, </span>
                      <span
                        ><b>Banned:</b> {{ getDate(banOrGrace.createdAt) }},
                      </span>
                      <span
                        ><b>Until:</b> {{ getDate(banOrGrace.bannedUntil) }},
                      </span>
                      <span
                        ><b>Cancelled:</b>
                        {{
                          banOrGrace.cancelled
                            ? getDate(banOrGrace.updatedAt)
                            : 'False'
                        }},
                      </span>
                      <span><b>Reason:</b> {{ banOrGrace.reason }} </span>
                      @if (!banOrGrace.linkType) {
                        <span
                          ><b>Admin Notes:</b> {{ banOrGrace.adminNotes }}</span
                        >
                      } @else {
                        <span
                          ><b>Admin Notes: </b>
                          <a
                            (click)="navToBanItem(banOrGrace, $event)"
                            class="link"
                          >
                            {{ banOrGrace.adminNotes }}
                          </a>
                        </span>
                      }
                    </div>
                  }

                  @if (banOrGrace.reason) {
                    <mat-divider style="margin: unset"></mat-divider>
                  }
                  <!-- GRACE PERIODS-->
                  <div>
                    @if (banOrGrace.type) {
                      <div
                        class="ban-history-entry"
                        [ngClass]="{
                          activeGrace:
                            isActiveBanOrGracePeriod(banOrGrace) &&
                            banOrGrace.deletedAt === null,
                          inactiveGrace:
                            banOrGrace.deletedAt !== null ||
                            !isActiveBanOrGracePeriod(banOrGrace),
                        }"
                      >
                        <span>Type: Grace</span>
                        <span><b>ID:</b> {{ banOrGrace.id }}, </span>
                        <span
                          ><b>Graced:</b> {{ getDate(banOrGrace.createdAt) }},
                        </span>
                        <span
                          ><b>Until:</b> {{ getDate(banOrGrace.until) }},
                        </span>
                        <span
                          ><b>Cancelled:</b>
                          {{
                            banOrGrace.deletedAt
                              ? getDate(banOrGrace.updatedAt)
                              : 'False'
                          }},
                        </span>
                      </div>
                    }
                    @if (banOrGrace.type) {
                      <mat-divider style="margin: unset"></mat-divider>
                    }
                  </div>
                }
                @if (banAndGraceHistory.length === 0) {
                  <div>
                    <p>The user has never been banned</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <h3 class="section_title center">Ban / unban user</h3>
        <div class="center">
          <div class="section_body">
            @if (getActiveBan() === undefined) {
              <mat-form-field class="input">
                <mat-label>Ban until</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="banDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field
                class="input"
                id="banReason"
              >
                @if (!otherBanReasonSelected) {
                  <mat-label>Reason</mat-label>
                } @else {
                  <mat-label>Custom reason</mat-label>
                }
                <!-- Dropdown for choosing reason -->
                @if (!otherBanReasonSelected) {
                  <mat-select
                    [value]="banReason"
                    [(ngModel)]="banReason"
                    (selectionChange)="onReasonSelectionChange($event)"
                  >
                    <mat-option value="Deling af kontaktinformationer"
                      >Deling af kontaktinformationer</mat-option
                    >
                    <mat-option value="Brud på retningslinjerne"
                      >Brud på retningslinjerne</mat-option
                    >
                    <mat-option value="true">Custom</mat-option>
                  </mat-select>
                }
                @if (otherBanReasonSelected) {
                  <div id="banReasonCss">
                    <input
                      id="banReasonInput"
                      matInput
                      [(ngModel)]="banReason"
                    />
                    <mat-icon (click)="resetBanReason()">
                      arrow_drop_down
                    </mat-icon>
                  </div>
                }
              </mat-form-field>

              <mat-form-field class="input">
                <mat-label>Admin notes</mat-label>
                <input
                  matInput
                  [(ngModel)]="adminNotes"
                />
              </mat-form-field>

              <div class="buttons">
                @if (!getActiveGracePeriod()) {
                  <button
                    class="pinploy_button small_button margin green"
                    (click)="openGiveGracePeriodDialog('ban')"
                  >
                    Give grace period
                  </button>
                }
                @if (getActiveGracePeriod()) {
                  <button
                    class="pinploy_button small_button margin delete_btn"
                    (click)="confirmRemoveGracePeriod('ban')"
                  >
                    Remove grace period
                  </button>
                }
                <button
                  class="pinploy_button small_button margin"
                  (click)="banUser()"
                  [ngClass]="{ disabled: isBanBtnDisabled() }"
                  [disabled]="isBanBtnDisabled()"
                >
                  Ban
                </button>
              </div>
            } @else {
              <div class="section_body center flexColumn">
                <p>The user is currently already banned</p>
                &nbsp;
                @if (!getActiveGracePeriod()) {
                  <button
                    class="pinploy_button small_button green"
                    (click)="openGiveGracePeriodDialog('ban')"
                  >
                    Give grace period
                  </button>
                }
                @if (getActiveGracePeriod()) {
                  <button
                    class="pinploy_button small_button delete_btn"
                    (click)="confirmRemoveGracePeriod('ban')"
                  >
                    > Remove grace period
                  </button>
                }
                <button
                  class="pinploy_button small_button"
                  (click)="cancelBan()"
                >
                  Cancel Ban
                </button>
              </div>
            }
          </div>
          @if (banError) {
            <p class="ban-error">
              >
              {{ banError }}
            </p>
          }
        </div>
      </div>
    </mat-tab>

    <!-- User notes -->
    <mat-tab label="Notes ({{ userNotes.length }})">
      <div class="tab">
        <div class="center flexColumn">
          <button
            (click)="openNewUserNoteDialog()"
            class="pinploy_button small_button"
          >
            New note
          </button>
          <div class="notes-page-container">
            <paginated-list
              [data]="userNotes"
              [moreDataAvailable]="userNotesHasMore"
              [cards]="true"
              (dataRequested)="fetchUserNotes()"
              [filterKeys]="userNotesFilterKeys"
              (displayDataChanged)="updateDisplayUserNotes($event)"
            >
              @for (note of displayUserNotes; track note.id) {
                <div>
                  <app-user-note-card [noteInput]="note"></app-user-note-card>
                </div>
              }
            </paginated-list>
          </div>
        </div>
      </div>
    </mat-tab>

    <!-- User reviews -->
    <mat-tab label="{{ initialUserReviewsTotalTitle }}">
      <div class="tab">
        <div class="center flexColumn">
          @if (userHHReviews.length == 0 && userTOReviews.length == 0) {
            <b style="font-size: 25px; color: grey"
              >User has not received any reviews yet.</b
            >
          }
          @if (userHHReviews.length !== 0 || userTOReviews.length !== 0) {
            <mat-tab-group
              animationDuration="0ms"
              class="mat-tab-area"
              mat-stretch-tabs="true"
              mat-align-tabs="center"
            >
              <!-- Tab for all reviews received as a Handyhander -->
              @if (userHHReviews.length != 0) {
                <mat-tab label="As a Handyhander">
                  <div class="review_page_container">
                    <paginated-list
                      [data]="userHHReviews"
                      [moreDataAvailable]="userHHReviewsHasMore"
                      [cards]="true"
                      (dataRequested)="fetchUserHHReviews()"
                      [filterKeys]="userHHReviewsFilterKeys"
                      (displayDataChanged)="updateDisplayUserHHReviews($event)"
                    >
                      @for (
                        hhReview of displayUserHHReviews;
                        track hhReview.id
                      ) {
                        <div>
                          <app-user-review-card
                            [review]="hhReview"
                            (reviewDeletedEvent)="deleteReview($event)"
                          />
                        </div>
                      }
                    </paginated-list>
                  </div>
                </mat-tab>
              }

              <!-- Tab for all reviews received as a Taskowner -->
              @if (userTOReviews.length != 0) {
                <mat-tab label="As a Taskowner">
                  <div class="review_page_container">
                    <paginated-list
                      [cards]="true"
                      [data]="userTOReviews"
                      [moreDataAvailable]="userTOReviewsHasMore"
                      (dataRequested)="fetchUserTOReviews()"
                      [filterKeys]="userTOReviewsFilterKeys"
                      (displayDataChanged)="updateDisplayUserTOReviews($event)"
                    >
                      @for (
                        toReview of displayUserTOReviews;
                        track toReview.id
                      ) {
                        <div>
                          <app-user-review-card [review]="toReview" />
                        </div>
                      }
                    </paginated-list>
                  </div>
                </mat-tab>
              }
            </mat-tab-group>
          }
        </div>
      </div>
    </mat-tab>

    <!-- User limitations -->
    <mat-tab label="Limitations">
      <div class="tab">
        <h4 class="section_title center">User Limitations</h4>
        @if (!user.userLimitations) {
          <div class="center">
            <button
              class="pinploy_button"
              (click)="getUserLimitations(user.id)"
            >
              Load user limitations
            </button>
          </div>
        } @else {
          <div class="limits_row">
            <div class="user_view">
              <h3><b>Message the user gets:</b></h3>
              <div class="sub_row">
                @if (user.userLimitations?.cancellationRate === null) {
                  <p>Som ny bruger har du ingen begrænsninger</p>
                } @else {
                  <p>Baseret på din aktivitet, er du:</p>
                  @if (
                    user.userLimitations?.taskBudgetLimit !== null &&
                    user.userLimitations?.offerCountLimit !== null
                  ) {
                    <ul>
                      <li>
                        {{
                          'Kun i stand til at give op til $$limit$$ bud om dagen'.replace(
                            '$$limit$$',
                            user.userLimitations?.offerCountLimit
                          )
                        }}
                      </li>
                      <li>
                        {{
                          'Kun i stand til at interagere med opgaver som har en budget på mindre end $$limit$$ kr.'.replace(
                            '$$limit$$',
                            user.userLimitations?.taskBudgetLimit
                          )
                        }}
                      </li>
                    </ul>
                  } @else {
                    <ul>
                      <li>
                        Ikke begrænset på nogen måde. Fortsæt det gode arbejde!
                      </li>
                    </ul>
                  }
                }
              </div>
              @if (user.userLimitations?.nextIn > 0) {
                <div class="sub_row">
                  >
                  <br />
                  <mat-divider></mat-divider>
                  <p>
                    {{
                      'Udfør $$numTasks$$ opgaver uden annulleringer, så kan du:'.replace(
                        '$$numTasks$$',
                        user.userLimitations?.nextIn
                      )
                    }}
                  </p>
                  @if (
                    user.userLimitations?.nextTaskBudgetLimit !== null &&
                    user.userLimitations?.nextOfferCountLimit !== null
                  ) {
                    <ul>
                      <li>
                        {{
                          'Give op til $$limit$$ bud om dagen'.replace(
                            '$$limit$$',
                            user.userLimitations?.nextOfferCountLimit
                          )
                        }}
                      </li>
                      <li>
                        {{
                          'Interagere med opgave som har en budget på mindre end $$limit$$ kr.'.replace(
                            '$$limit$$',
                            user.userLimitations?.nextTaskBudgetLimit
                              | number: '.0-2'
                          )
                        }}
                      </li>
                    </ul>
                  } @else {
                    <ul>
                      <li>Give et ubegrænset antal bud om dagen</li>
                      <li>Interagere med alle opgaver</li>
                    </ul>
                  }
                </div>
              }
            </div>
            <div>
              <h3><b>User limitations raw data:</b></h3>
              <p>
                Task budget limit: {{ user.userLimitations.taskBudgetLimit }}
              </p>
              <p>
                Daily offer limit: {{ user.userLimitations.offerCountLimit }}
              </p>
              <p>
                Offers made today: {{ user.userLimitations.numOffersMadeToday }}
              </p>
              <p>
                Cancellation rate: {{ user.userLimitations.cancellationRate }}
              </p>
              <mat-divider></mat-divider>
              <p>Next in: {{ user.userLimitations.nextIn }}</p>
              <p>
                Next task budget limit:
                {{ user.userLimitations.nextTaskBudgetLimit }}
              </p>
              <p>
                Next offer count limit:
                {{ user.userLimitations.nextOfferCountLimit }}
              </p>
            </div>
          </div>
          @if (user?.userLimitations?.gracePeriod) {
            <div class="limitation_grace_period">
              >
              <div
                class="five_repeat"
                [ngClass]="{
                  activeGrace:
                    isActiveBanOrGracePeriod(
                      user.userLimitations.gracePeriod
                    ) && user.userLimitations.gracePeriod.deletedAt === null,
                  inactiveGrace:
                    user.userLimitations.gracePeriod.deletedAt !== null ||
                    !isActiveBanOrGracePeriod(user.userLimitations.gracePeriod),
                  five_repeat: true,
                }"
              >
                <span>Type: Grace</span>
                <span
                  ><b>ID:</b> {{ user.userLimitations.gracePeriod.id }},
                </span>
                <span
                  ><b>Graced:</b>
                  {{ getDate(user.userLimitations.gracePeriod.createdAt) }},
                </span>
                <span
                  ><b>Until:</b>
                  {{ getDate(user.userLimitations.gracePeriod.until) }},
                </span>
                <span
                  ><b>Cancelled:</b>
                  {{
                    user.userLimitations.gracePeriod.deletedAt
                      ? getDate(user.userLimitations.gracePeriod.updatedAt)
                      : 'False'
                  }},
                </span>
              </div>
            </div>
          }
          <div class="grace_buttons">
            @if (!user?.userLimitations?.gracePeriod) {
              <button
                class="pinploy_button small_button grace_button green"
                (click)="openGiveGracePeriodDialog('handyhander-limitations')"
              >
                Give grace period
              </button>
            }
            @if (user?.userLimitations?.gracePeriod) {
              <button
                class="pinploy_button small_button grace_button delete_btn"
                (click)="confirmRemoveGracePeriod('handyhander-limitations')"
              >
                Remove grace period
              </button>
            }
            <button
              class="pinploy_button center"
              (click)="updateUserLimitations()"
            >
              Manual limitation update <mat-icon>refresh</mat-icon>
            </button>
          </div>
        }
      </div>
    </mat-tab>

    <!-- User Notification setting-->
    @if (userNotificationSettings) {
      <mat-tab label="Notification Settings">
        <div class="tab center-items">
          <h2 class="section_title center">Notification settings</h2>

          <div class="toggle_list">
            <!-- New task Notifications-->
            <div class="toggle_list_areas">
              <h4 class="toggle_title">New Tasks</h4>
              <div class="toggle_list_body">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.newRemoteTasks"
                  >Remote Task Notifications</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.newTaskPush"
                  >Push notifications</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.newTaskWeb"
                  >Web Notifications</mat-slide-toggle
                >
                <mat-form-field>
                  <mat-label>New Task Email Frequency</mat-label>
                  <mat-select
                    [(ngModel)]="userNotificationSettings.newTaskEmail"
                  >
                    @for (
                      frequency of notificationFrequencies;
                      track frequency
                    ) {
                      <mat-option [value]="mapNotificationToValue(frequency)">{{
                        frequency
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Own offer Notifications-->
            <div class="toggle_list_areas">
              <h4 class="toggle_title">Own Offer</h4>
              <div class="toggle_list_body">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.ownOfferEmail"
                  >Email Notifications</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.ownOfferPush"
                  >Push Notifications</mat-slide-toggle
                >

                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.ownOfferWeb"
                  >Web Notifications</mat-slide-toggle
                >
              </div>
            </div>

            <!-- Own task Notifications-->
            <div class="toggle_list_areas">
              <h4 class="toggle_title">Own Task</h4>
              <div class="toggle_list_body">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.ownTaskEmail"
                  >Email Notification</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.ownTaskPush"
                  >Push Notifications</mat-slide-toggle
                >

                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.ownTaskWeb"
                  >Web Notifications</mat-slide-toggle
                >
              </div>
            </div>

            <!-- Promotional notifications-->
            <div class="toggle_list_areas">
              <h4 class="toggle_title">Promotion Notification</h4>
              <div class="toggle_list_body">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.promotionEmail"
                  >Email Notifications</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.promotionPush"
                  >Push Notifications</mat-slide-toggle
                >

                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.promotionWeb"
                  >Web Notifications</mat-slide-toggle
                >
              </div>
            </div>

            <!-- Review notifications -->
            <div class="toggle_list_areas">
              <h4 class="toggle_title">Review Notification</h4>
              <div class="toggle_list_body">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.reviewEmail"
                  >Email Notifications</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.reviewPush"
                  >Push Notifications</mat-slide-toggle
                >
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="userNotificationSettings.reviewWeb"
                  >Web Notifications</mat-slide-toggle
                >
              </div>
            </div>
          </div>
          <div class="buttons">
            <button
              class="pinploy_button small_button"
              (click)="updateNotificationSettings()"
            >
              Update
            </button>

            <button
              class="pinploy_button small_button"
              style="margin: 25px"
              (click)="openUserNotificationListDialog()"
            >
              See all notifications
            </button>
          </div>
        </div>
      </mat-tab>
    }

    <!-- User tags -->
    <mat-tab label="{{ userTagsAmountTitle }}">
      <div class="tab">
        <h4 class="section_title center">User Tags</h4>
        <div class="tag-prediction">
          <!-- User tags -->
          <div class="tag_field">
            <h3>Update User Tags</h3>
            <mat-form-field class="tag-input">
              <mat-chip-grid #taglist>
                @for (Tag of Tags; track $index) {
                  <mat-chip
                    color="primary"
                    [removable]="removable"
                    (removed)="remove(Tag)"
                  >
                    {{ Tag | titlecase }}
                    @if (removable) {
                      <mat-icon matChipRemove>cancel</mat-icon>
                    }
                  </mat-chip>
                }
              </mat-chip-grid>

              <input
                placeholder="Add tag or multiple"
                [matChipInputFor]="taglist"
                #tagInput
                [matAutocomplete]="auto"
                [(ngModel)]="tagChanged"
                (ngModelChange)="onTagChange($event)"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
                class="input_field"
              />

              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="selected($event)"
              >
                @for (Tag of filteredTags | async; track Tag.tag) {
                  <mat-option [value]="Tag.tag">
                    {{ Tag.tag | titlecase }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
            <div class="save-btns">
              <button
                class="pinploy_button small_button save_btn"
                [ngClass]="{ disabled: isTagBtnDisabled() }"
                [disabled]="isTagBtnDisabled()"
                (click)="saveTagChanges()"
              >
                Save Tags
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-divider></mat-divider>

  <h4 class="section_title center">Statistics</h4>
  <div class="completion-rate-area">
    <!-- Completion rate -->
    @if (user.completionRate <= 0) {
      <mat-form-field>
        <mat-label>Completion rate</mat-label>
        <input
          matInput
          type="text"
          value="No / not enough tasks completed yet"
          disabled
        />
        <mat-icon
          class="info-icon"
          matSuffix
          matTooltip="Completion rate of the last 20 tasks, shown on profile. Only counts cancellations that are the Handyhanders fault"
          class="info-icon"
          >info</mat-icon
        >
      </mat-form-field>
    } @else {
      <mat-form-field>
        <mat-label>Completion rate</mat-label>
        <mat-icon
          class="info-icon"
          matSuffix
          matTooltip="Completion rate of the last 20 tasks, shown on profile. Only counts cancellations that are the Handyhanders fault"
          >info</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.completionRate"
          disabled
        />
      </mat-form-field>
    }

    <!-- Total Completion rate -->
    @if (user.totalCompletionRate <= 0) {
      <mat-form-field>
        <mat-label>Total completion rate</mat-label>
        <input
          matInput
          type="text"
          value="No / not enough tasks completed yet"
          disabled
        />
        <mat-icon
          class="info-icon"
          matSuffix
          matTooltip="Life time completion rate."
          >info</mat-icon
        >
      </mat-form-field>
    }

    @if (user.totalCompletionRate > 0) {
      <mat-form-field>
        <mat-label>Total completion rate</mat-label>
        <mat-icon
          class="info-icon"
          matSuffix
          matTooltip="Life time completion rate."
          >info</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.totalCompletionRate"
          disabled
        />
      </mat-form-field>
    }

    <!-- Cancellation rate -->
    @if (user.cancellationRate <= 0) {
      <mat-form-field>
        <mat-label>Cancellation rate</mat-label>
        <mat-icon
          class="info-icon"
          matSuffix
          matTooltip="Cancellation rate of the last 20 tasks, used to impose limitations. Takes all cancellations in to account"
          >info</mat-icon
        >
        <input
          matInput
          type="text"
          value="No / not enough tasks cancelled yet"
          [disabled]="true"
        />
      </mat-form-field>
    }

    @if (user.cancellationRate > 0) {
      <mat-form-field>
        <mat-label>Cancellation rate</mat-label>
        <mat-icon
          class="info-icon"
          matSuffix
          matTooltip="Cancellation rate of the last 20 tasks, used to impose limitations. Takes all cancellations in to account"
          >info</mat-icon
        >
        <input
          matInput
          [(ngModel)]="user.cancellationRate"
          disabled
        />
      </mat-form-field>
    }
  </div>
  <div
    class="section_body"
    style="justify-content: space-between"
  >
    <!--  Tasks Created  TODO: add real data -->
    <mat-card>
      <h5 class="card_header">Tasks created</h5>
      <div class="card_row">
        <p>Active</p>
        <p>{{ user.activeTasks }}</p>
      </div>
      <div class="card_row">
        <p>In progress</p>
        <p>{{ user.inProgressTasks }}</p>
      </div>
      <div class="card_row">
        <p>Completed</p>
        <p>{{ user.completedTasks }}</p>
      </div>
      <div class="card_row">
        <p>Cancelled</p>
        <p>{{ user.canceledTasks }}</p>
      </div>
      <div class="card_row">
        <p>Expired</p>
        <p>{{ user.expiredTasks }}</p>
      </div>
      <div class="card_row">
        <p>Erased</p>
        <p>{{ user.erasedTasks }}</p>
      </div>

      <button
        class="pinploy_button small_button"
        (click)="openUserTasksDialog()"
        [ngClass]="{ disabled: isUserTasksDialogBtnDisabled() }"
        [disabled]="isUserTasksDialogBtnDisabled()"
      >
        See all
      </button>
    </mat-card>
    <mat-divider [vertical]="true"></mat-divider>

    <!--  Tasks Applied To -->
    @if (offersGiven) {
      <mat-card>
        <h5 class="card_header">Tasks applied to</h5>
        <div class="card_row">
          <p>In progress</p>
          <p>{{ offersGiven?.accepted }}</p>
        </div>
        <div class="card_row">
          <p>Completed</p>
          <p>{{ offersGiven?.completed }}</p>
        </div>
        <div class="card_row">
          <p>Cancelled</p>
          <p>{{ offersGiven?.canceled }}</p>
        </div>
        <div class="card_row">
          <p>Pending</p>
          <p>{{ offersGiven?.pending }}</p>
        </div>
        <div class="card_row">
          <p>Erased</p>
          <p>{{ offersGiven?.erased }}</p>
        </div>
        <button
          class="pinploy_button small_button"
          (click)="openUserActiveOffersDialog()"
          [ngClass]="{ disabled: isUserActiveOffersDialogBtnDisabled() }"
          [disabled]="isUserActiveOffersDialogBtnDisabled()"
        >
          See all
        </button>
      </mat-card>
    }
    <mat-divider [vertical]="true"></mat-divider>

    <!--  Task Comments -->
    <mat-card>
      <h5 class="card_header">Task comments</h5>
      <div class="card_row">
        <p>Active</p>
        <p>{{ user.activeTaskComments }}</p>
      </div>
      <div class="card_row">
        <p>Removed</p>
        <p>{{ user.removedTaskComments }}</p>
      </div>
    </mat-card>
    <mat-divider [vertical]="true"></mat-divider>

    <!--  User reports -->
    <mat-card>
      <h5 class="card_header">User reports received</h5>
      <div class="card_row">
        <p>Active</p>
        <p>{{ user.activeUserReports }}</p>
      </div>
      <div class="card_row">
        <p>Removed</p>
        <p>{{ user.removedUserReports }}</p>
      </div>
    </mat-card>
    <mat-divider [vertical]="true"></mat-divider>

    <mat-card>
      <!-- Penalties -->
      <h5 class="card_header">Penalities</h5>
      <div class="section_body">
        <div class="penalty-container">
          <div class="card_row">
            <p>Applied:</p>
            <p>{{ userPenalties?.applied?.length }}</p>
          </div>
          <div class="card_row">
            <p>Pending:</p>
            <p>{{ userPenalties?.pending?.length }}</p>
          </div>
          <div class="card_row">
            <p>Canceled:</p>
            <p>{{ userPenalties?.canceled?.length }}</p>
          </div>
          <div class="card_row">
            <p>Completed:</p>
            <p>{{ userPenalties?.completed?.length }}</p>
          </div>
          <div class="card_row">
            <p>Transfered:</p>
            <p>{{ userPenalties?.transfered?.length }}</p>
          </div>
        </div>
      </div>
      <br />
      <button
        class="pinploy_button small_button"
        (click)="goToUserPenalties(user.id)"
        [disabled]="isUserPenaltiesBtnDisabled()"
        [ngClass]="{ disabled: isUserPenaltiesBtnDisabled() }"
      >
        See user penalties
      </button>
    </mat-card>

    <mat-divider [vertical]="true"></mat-divider>

    <!-- support tickets -->
    <mat-card>
      <h5 class="card_header">Support tickets</h5>
      <div class="section_body">
        <div class="support_tickets_container">
          <div class="card_row">
            <p>Open</p>
            <p>{{ openSupportTickets?.length }}</p>
          </div>
          <div class="card_row">
            <p>Closed</p>
            <p>{{ closedSupportTickets?.length }}</p>
          </div>
        </div>
      </div>
      <br />
      <div class="buttons">
        @if (
          openSupportTickets?.length > 0 || closedSupportTickets?.length > 0
        ) {
          <button
            class="pinploy_button small_button margin"
            (click)="openSupportTicketsDialog(user.id)"
            [disabled]="isSupportTicketsDialogBtnDisabled()"
            [ngClass]="{ disabled: isSupportTicketsDialogBtnDisabled() }"
          >
            See support tickets
          </button>
        }
        <button
          class="pinploy_button small_button margin"
          (click)="openCreateSupportTicketDialog(user)"
        >
          Create ticket
        </button>
      </div>
    </mat-card>
  </div>

  <mat-divider></mat-divider>
</div>
