import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { SupportTicket } from 'src/app/models/supportTicket.model';
import { Router } from '@angular/router';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
  selector: 'app-support-ticket-card',
  imports: [...ALL_IMPORTS],
  templateUrl: './support-ticket-card.component.html',
  styleUrls: ['./support-ticket-card.component.scss', '../cards.scss'],
})
export class SupportTicketCardComponent implements OnChanges {
  @Input() ticket: SupportTicket;
  @Input() includeButton: boolean = false;

  @Output() closeDialogEvent = new EventEmitter();
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['ticket'] &&
      changes['ticket'].previousValue !== changes['ticket'].currentValue
    ) {
      // Force change detection to run
      this.cdr.detectChanges();
    }
  }

  openTicket($event: MouseEvent, uid: string): Promise<boolean> {
    if ($event.ctrlKey || $event.metaKey) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['support-tickets-v2'], {
          queryParams: { uid },
        }),
      );
      window.open(url, '_blank');
    } else {
      this.closeDialog();
      return this.router.navigate(['support-tickets-v2'], {
        queryParams: { uid },
      });
    }
  }

  closeDialog() {
    this.closeDialogEvent.emit();
  }
}
