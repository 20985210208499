<div class="task_container">
  <div class="outer-task-information">
    <div class="task-information">
      <!-- Task id and link-->
      <div class="left">
        <p class="description">
          Task-ID: {{ task.id }}
          <a
            href="https://Handyhand.dk/opgaver/link/{{ task.id }}"
            target="_blank"
          >
            <mat-icon style="color: black">open_in_new</mat-icon>
          </a>
        </p>
        <br />

        <!-- Title -->
        <mat-form-field appearance="outline">
          <mat-label>Title</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMaxRows="1"
            [(ngModel)]="task.title"
            class="task_input_field_title"
          ></textarea>
        </mat-form-field>

        <!-- Description -->
        <mat-form-field
          class="task_input_field_desc"
          appearance="outline"
        >
          <mat-label>Description</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMaxRows="1"
            placeholder="Give an updated description here..."
            [(ngModel)]="task.description"
          ></textarea>
        </mat-form-field>
        <div class="views_area">
          <mat-icon
            role="img"
            class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
            aria-hidden="true"
            data-mat-icon-type="font"
            >remove_red_eye</mat-icon
          >
          <p>{{ taskViews }} visninger</p>
          @if (task?.boostedAt) {
            <p>(Boosted at: {{ task.boostedAt | date: 'dd/MM/yy, HH:mm' }})</p>
          }
          @if (!task.boostedAt) {
            <p>(Not boosted yet)</p>
          }
        </div>
      </div>

      <!-- Is the title AI generated?-->
      <div class="right">
        @if (task?.aiGeneratedTitle) {
          <div class="right_form_field">
            <mat-icon class="no_color">cloud_queue</mat-icon>
            <mat-form-field>
              <mat-label>AI generated title</mat-label>
              <input
                matInput
                [value]="isTitleGenerated"
                disabled
              />
            </mat-form-field>
          </div>
        }

        <!-- Budget -->
        <div class="right_form_field">
          <mat-icon class="no_color">attach_money</mat-icon>
          <mat-form-field>
            <mat-label>Budget</mat-label>
            <input
              matInput
              [(ngModel)]="task.budget"
              [disabled]="
                task.acceptedOffer ||
                task.status === 'completed' ||
                task.status === 'canceled' ||
                task.status === 'erased' ||
                task.status === 'expired'
              "
            />
          </mat-form-field>
        </div>

        <!-- Address -->
        @if (!task.extended) {
          <div class="right_form_field">
            <mat-icon class="no_color">location_on</mat-icon>
            @if (task.address) {
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input
                  class="address"
                  matInput
                  [disabled]="true"
                  [value]="task.address"
                />
              </mat-form-field>
            }
            @if (!task.address) {
              <mat-form-field>
                <mat-label>Address</mat-label>
                <input
                  class="address"
                  matInput
                  [disabled]="true"
                  [value]="'Online'"
                />
              </mat-form-field>
            }
          </div>
        }

        <!-- Created at -->
        <div class="right_form_field">
          <mat-icon class="no_color">subject</mat-icon>
          <mat-form-field>
            <mat-label>Created at</mat-label>
            <input
              matInput
              [disabled]="true"
              [value]="formatDate(task.createdAt)"
            />
          </mat-form-field>
        </div>

        <!-- Due date -->
        <div class="right_form_field">
          <mat-icon
            class="no_color"
            matTooltip="{{ task?.dateType }}"
            >calendar_today</mat-icon
          >
          <mat-form-field>
            <mat-label>Due date</mat-label>
            <input
              id="picker"
              matInput
              (click)="picker.open()"
              [(ngModel)]="task.dueDate"
              [matDatepicker]="picker"
            />
            <span matTextSuffix>({{ task?.dateType }})</span>

            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Completed at -->
        @if (task?.acceptedOffer?.completedAt) {
          <div class="right_form_field">
            <mat-icon class="no_color">calendar_today</mat-icon>
            <mat-form-field>
              <mat-label>Completed at</mat-label>
              <input
                matInput
                [disabled]="true"
                [value]="formatDate(task?.acceptedOffer?.completedAt)"
              />
            </mat-form-field>
          </div>
        }

        <!-- Tools needed-->
        <div class="right_form_field">
          <mat-icon class="no_color">build</mat-icon>
          <mat-form-field>
            <mat-label>Tools needed</mat-label>
            <input
              matInput
              [disabled]="true"
              [value]="toolsNeeded()"
            />
          </mat-form-field>
        </div>

        @if (task?.extended?.data) {
          <div class="extended_information">
            <!-- Extended task info: type -->
            <div class="right_form_field">
              <mat-icon class="no_color">extension</mat-icon>
              <mat-form-field>
                <mat-label>Extended task type</mat-label>
                <input
                  matInput
                  [(ngModel)]="task.extended?.data.type"
                  disabled
                />
              </mat-form-field>
            </div>
            <!-- Extended task info: if moving -->
            @if (task?.extended?.data.type == 'moving') {
              <div>
                @if (task?.extended?.data.fromAddress?.address) {
                  <div class="right_form_field">
                    <mat-icon class="no_color">location_on</mat-icon>
                    <mat-form-field>
                      <mat-label>From address</mat-label>
                      <input
                        matInput
                        [(ngModel)]="task.extended?.data.fromAddress.address"
                        disabled
                      />
                    </mat-form-field>
                  </div>
                }
                <!-- Extended task info: To adress-->
                @if (task?.extended?.data.toAddress?.address) {
                  <div class="right_form_field">
                    <mat-icon class="no_color">location_on</mat-icon>
                    <mat-form-field>
                      <mat-label>To address</mat-label>
                      <input
                        matInput
                        [(ngModel)]="task.extended?.data.toAddress.address"
                        disabled
                      />
                    </mat-form-field>
                  </div>
                }
                <!-- Extended task info: To adress-->
                @if (task?.extended?.data.movingStairType) {
                  <div class="right_form_field">
                    <mat-icon class="no_color">layers</mat-icon>
                    <mat-form-field>
                      <mat-label>Stair locations</mat-label>
                      <input
                        matInput
                        [(ngModel)]="task.extended?.data.movingStairType"
                        disabled
                      />
                    </mat-form-field>
                  </div>
                }
              </div>
            }
            <!-- Extended task info: if gardening -->
            @if (task?.extended.data.type == 'garden') {
              <div>
                @if (
                  checkServiceType(
                    task.extended.data.serviceType,
                    'gardenTaskType'
                  )
                ) {
                  <div class="right_form_field">
                    <mat-icon class="no_color">local_florist</mat-icon>
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Garden task types"
                        [(ngModel)]="task.extended?.data.gardenTaskType"
                        disabled
                      />
                      @for (
                        services of task?.extended?.data.serviceType;
                        track services
                      ) {
                        <p disabled>{{ services.name }},</p>
                      }
                    </mat-form-field>
                  </div>
                }
                @if (task?.extended?.data.area) {
                  <div class="right_form_field">
                    <img
                      src="https://d10a6kin4dgxel.cloudfront.net/private/garden-icons/2-3-garden-medium-large-fat.svg"
                      alt=""
                      width="24"
                      height="24"
                      class="gray"
                    />
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Garden task size"
                        disabled
                      />
                      <p>
                        {{ task?.extended.data.area.name }} ({{
                          task?.extended?.data.area.size
                        }})
                      </p>
                    </mat-form-field>
                  </div>
                }
                @if (task?.extended?.data.type == 'garden') {
                  <div class="right_form_field">
                    <mat-icon class="no_color">restore_from_trash</mat-icon>
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Should the garden waste be removed?"
                        disabled
                      />
                      <p>{{ task?.extended?.data.hasGreenWasteBin }}</p>
                    </mat-form-field>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
  <h3>Task status:</h3>
  <div class="task-status-container">
    <div class="good-statuses">
      <div
        class="task-status-field"
        [ngClass]="
          (task.status === 'completed' ? 'active' : '') ||
          (task.status === 'pending' && task.acceptedOffer ? 'active' : '') ||
          (task.status === 'pending' ? 'active' : 'inactive')
        "
      >
        <p>Pending</p>
      </div>

      <div
        class="task-status-field"
        [ngClass]="
          (task.status === 'pending' && task.acceptedOffer ? 'active' : '') ||
          (task.status === 'completed' ? 'active' : 'inactive')
        "
      >
        <p>Accepted</p>
      </div>

      <div
        class="task-status-field"
        [ngClass]="task.status === 'completed' ? 'active' : 'inactive'"
      >
        <p>Completed</p>
      </div>
    </div>
    <div class="bad-statuses">
      <div
        class="task-status-field"
        [ngClass]="task.status === 'canceled' ? 'bad' : 'inactive'"
      >
        <p>Canceled</p>
      </div>

      <div
        class="task-status-field"
        [ngClass]="task.status === 'erased' ? 'bad' : 'inactive'"
      >
        <p>Erased</p>
      </div>

      <div
        class="task-status-field"
        [ngClass]="task.status === 'expired' ? 'bad' : 'inactive'"
      >
        <p>Expired</p>
      </div>
    </div>
  </div>

  <!-- Task under cancellation banner -->
  @if (cancelTask?.cancelTask && task.status === 'pending') {
    <div class="cancel-task-banner">
      <div class="left">
        <h3>Task cancellation information</h3>
        <br />
        <p>
          <b>Cancellation created at:</b>
          {{ cancelTask.cancelTask.createdAt | date: 'dd/MM/yyyy kl: HH:mm' }}
        </p>
        @if (
          cancelTask?.cancelTaskAuto &&
          cancelTask?.cancelTaskAuto.status === 'pending'
        ) {
          <p>
            Time to automatic resolution:
            {{ calculateTimeDifference(cancelTask.cancelTaskAuto.createdAt) }}
            hours
          </p>
        }
        <p>
          <b>Reason: </b>{{ cancelTask.cancelTask.reason }} (<a
            class="change-link"
            [class.disabled]="cancellationChanged"
            [ngClass]="{ disabled: cancellationChanged }"
            (click)="openChangeCancellationDialog()"
            >Change</a
          >)
        </p>
        <p><b>Reason comment: </b>{{ cancelTask.cancelTask.reasonComment }}</p>
        @if (
          cancelTask?.cancelTaskAuto &&
          cancelTask?.cancelTaskAuto.status === 'manual'
        ) {
          <div class="manual-area">
            <p>Cancellation requires attention!</p>
            <button
              class="pinploy_button small_button"
              (click)="goToManualCancellation()"
            >
              Handle cancellation
            </button>
          </div>
        }
      </div>
      <div class="right">
        <button
          class="pinploy_button small_button save_btn red_button"
          (click)="openStopCancellationQuestion()"
        >
          Stop cancellation.
        </button>
      </div>
    </div>
  }

  <!-- Task already cancelled banner / information-->
  @if (cancelTask?.cancelTask && task.status === 'canceled') {
    <div class="cancel-task-banner">
      <div class="left">
        <h3>Task cancellation information</h3>
        <br />
        <p>
          <b>Cancellation created at:</b>
          {{ cancelTask.cancelTask.createdAt | date: 'dd/MM/yyyy kl: HH:mm' }}
        </p>
        <p>
          <b>Cancellation completed at:</b>
          {{
            cancelTask.cancelTask.cancellationCompletionDate
              | date: 'dd/MM/yyyy kl: HH:mm'
          }}
        </p>
        <span>
          <b>Reason: </b>{{ cancelTask.cancelTask.reason }} (
          @if (!cancellationChanged) {
            <a
              class="change-link"
              [class.disabled]="cancellationChanged"
              [ngClass]="{ disabled: cancellationChanged }"
              (click)="openChangeCancellationDialog()"
              >Change</a
            >
          } @else {
            <a
              class="change-link"
              [class.disabled]="cancellationChanged"
              [ngClass]="{ disabled: cancellationChanged }"
              >Change</a
            >
          }
          )
        </span>
        <p><b>Reason comment: </b>{{ cancelTask.cancelTask.reasonComment }}</p>
      </div>
    </div>
  }

  <br />

  <div class="horizontal">
    <button
      class="pinploy_button small_button save_btn"
      [ngClass]="{ disabled: isBtnDisabled() }"
      [disabled]="isBtnDisabled()"
      (click)="saveChanges()"
    >
      Update Task Info
    </button>
    <button
      style="background-color: orange"
      class="pinploy_button small_button save_btn"
      (click)="toggleTaskTagging()"
    >
      Toggle Task Tagging
    </button>

    <button
      class="pinploy_button small_button save_btn"
      [matMenuTriggerFor]="menu"
    >
      <p>More options</p>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      @if (task.status === 'pending' && !task.acceptedOffer) {
        <button
          mat-menu-item
          (click)="deleteTask()"
        >
          <mat-icon class="red">clear</mat-icon>
          Delete task
        </button>
      }

      @if (
        (task.status === 'erased' || task.status === 'expired') &&
        !task.acceptedOffer
      ) {
        <button
          mat-menu-item
          (click)="restoreTask()"
        >
          <mat-icon class="green">restore</mat-icon>
          Restore task
        </button>
      }

      <button
        [disabled]="!task.acceptedOffer || task.status !== 'pending'"
        mat-menu-item
        (click)="completeTask()"
      >
        <mat-icon class="green">check</mat-icon>
        Complete Task
      </button>

      <button
        [disabled]="task.status === 'completed' || !task.acceptedOffer"
        (click)="requestCancellationAsUser()"
        mat-menu-item
      >
        <mat-icon class="orange">sentiment_very_dissatisfied</mat-icon>
        Cancel task (Force / as user)
      </button>

      <button
        mat-menu-item
        (click)="updatePayoutCalculations()"
        [disabled]="!(task?.acceptedOffer || task?.status === 'completed')"
      >
        <mat-icon class="green">refresh</mat-icon>
        Update payout calculations
      </button>
      <button
        mat-menu-item
        (click)="openTaskInformationDetailsDialog()"
      >
        <mat-icon>search</mat-icon>
        View full user data (JSON)
      </button>
      <button
        [disabled]="
          !(
            task?.acceptedOffer ||
            task?.status === 'completed' ||
            task?.status === 'canceled'
          )
        "
        mat-menu-item
        [routerLink]="['/conversation', task.id]"
      >
        <mat-icon> message </mat-icon>
        Open task conversation
      </button>

      <button
        [disabled]="isConflictCaseBtnDisabled()"
        mat-menu-item
        [ngClass]="{ disabled: isConflictCaseBtnDisabled() }"
        (click)="startConflictCase()"
      >
        <mat-icon class="red">warning</mat-icon>
        {{ getConflictCastBtnText() }}
      </button>
    </mat-menu>
  </div>

  <!-- Tag prediction -->
  @if (isTaskTaggingEnabled == true) {
    <div class="tag-prediction">
      <h3>Select Tags for Task</h3>
      <br />
      @if (task.tagUpdated) {
        <h2 style="color: red">
          This task updated Tags last time on: {{ task.tagUpdated | date }}
        </h2>
      }
      <br />
      <mat-chip-listbox #taglist>
        @for (tag of Tags; track tag) {
          <mat-chip-option
            selected
            color="primary"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(tag)"
            >{{ tag | titlecase }}
            @if (removable) {
              <mat-icon matChipRemove>cancel</mat-icon>
            }
          </mat-chip-option>
        }
        <input
          placeholder="Search to add a tag"
          [matChipInputFor]="taglist"
          #tagInput
          [matAutocomplete]="auto"
          [formControl]="tagChanged"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
          class="input_field"
        />
      </mat-chip-listbox>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        @for (Tag of filteredTags | async; track Tag) {
          <mat-option [value]="Tag.tag">
            {{ Tag.tag | titlecase }}
          </mat-option>
        }
      </mat-autocomplete>
      <br />
      <button
        class="pinploy_button small_button save_btn"
        [ngClass]="{ disabled: isTagBtnDisabled('save') }"
        [disabled]="isTagBtnDisabled('save')"
        (click)="saveTagChanges()"
      >
        Save Tags
      </button>
      @if (tagNotificationsSent === false) {
        <button
          class="pinploy_button red_button save_btn"
          [ngClass]="{ disabled: isTagBtnDisabled('instant') }"
          [disabled]="isTagBtnDisabled('instant')"
          (click)="instantNotificationsNewTags()"
        >
          Tag Instant Notifications
        </button>
      }
      <br />
      @if (isHighlightedTagCatActive) {
        <button
          class="pinploy_button small_button save_btn"
          (click)="clearHighlightedCategory()"
        >
          Go back to main cats
        </button>
      }
      <div
        class="tag-cats"
        [ngStyle]="{ display: isHighlightedTagCatActive ? '' : 'flex' }"
      >
        <div
          class="andet-tagcat"
          #andetTagCat
        >
          <!-- Andet Main Tag Cat -->
          <h2
            class="highlighted-cat"
            (click)="selectedHighlightedCategory('andet')"
          >
            Andet
          </h2>
          <mat-selection-list>
            @for (tag of allTagsWithAssociatedTagCat.andet.tags; track tag) {
              <mat-list-item
                class="category-hover"
                (click)="addOrRemoveTag(tag)"
                >{{ tag.tag | titlecase }}
              </mat-list-item>
            }
          </mat-selection-list>
        </div>
        <div
          class="digital-tagcat"
          #digitalTagCat
        >
          <!-- Digital Main Tag Cat -->
          <h2
            class="highlighted-cat"
            (click)="selectedHighlightedCategory('digital')"
          >
            Digital
          </h2>
          <mat-selection-list>
            @for (tag of allTagsWithAssociatedTagCat.digital.tags; track tag) {
              <mat-list-item
                class="category-hover"
                (click)="addOrRemoveTag(tag)"
              >
                {{ tag.tag | titlecase }}
              </mat-list-item>
            }
          </mat-selection-list>
        </div>
        <div
          class="have"
          #haveTagCat
        >
          <!-- Hus Main Tag Cat -->
          <h2
            class="highlighted-cat"
            (click)="selectedHighlightedCategory('have')"
          >
            Have
          </h2>
          <mat-selection-list>
            @for (tag of allTagsWithAssociatedTagCat.have.tags; track tag) {
              <mat-list-item
                class="category-hover"
                (click)="addOrRemoveTag(tag)"
                >{{ tag.tag | titlecase }}
              </mat-list-item>
            }
          </mat-selection-list>
        </div>
        <div
          class="hus"
          #husTagCat
        >
          <!-- Hus Main Tag Cat -->
          <h2
            class="highlighted-cat"
            (click)="selectedHighlightedCategory('hus')"
          >
            Hus
          </h2>
          <mat-selection-list>
            @if (!isHighlightedTagCatActive) {
              @for (tag of allTagsWithAssociatedTagCat.hus.tags; track tag) {
                <mat-list-item
                  class="category-hover"
                  (click)="addOrRemoveTag(tag)"
                  >{{ tag.tag | titlecase }}
                </mat-list-item>
              }
            }
          </mat-selection-list>
          @if (isHighlightedTagCatActive && chunkedCategoryArray.length > 0) {
            <div class="highlighted-column">
              <div>
                <mat-selection-list>
                  @for (tag of chunkedCategoryArray[0]; track tag) {
                    <mat-list-item
                      class="category-hover"
                      (click)="addOrRemoveTag(tag)"
                      >{{ tag.tag | titlecase }}
                    </mat-list-item>
                  }
                </mat-selection-list>
              </div>
              <div>
                <mat-selection-list>
                  @for (tag of chunkedCategoryArray[1]; track tag) {
                    <mat-list-item
                      class="category-hover"
                      (click)="addOrRemoveTag(tag)"
                      >{{ tag.tag | titlecase }}
                    </mat-list-item>
                  }
                </mat-selection-list>
              </div>
              <div>
                <mat-selection-list>
                  @for (tag of chunkedCategoryArray[2]; track tag) {
                    <mat-list-item
                      class="category-hover"
                      (click)="addOrRemoveTag(tag)"
                      >{{ tag.tag | titlecase }}
                    </mat-list-item>
                  }
                </mat-selection-list>
              </div>
              <div>
                <mat-selection-list>
                  @for (tag of chunkedCategoryArray[3]; track tag) {
                    <mat-list-item
                      class="category-hover"
                      (click)="addOrRemoveTag(tag)"
                      >{{ tag.tag | titlecase }}
                    </mat-list-item>
                  }
                </mat-selection-list>
              </div>
            </div>
          }
        </div>
        <div
          class="transport"
          #transportTagCat
        >
          <!-- Transport Main Tag Cat -->
          <h2
            class="highlighted-cat"
            (click)="selectedHighlightedCategory('transport')"
          >
            Transport
          </h2>
          <mat-selection-list>
            @for (
              tag of allTagsWithAssociatedTagCat.transport.tags;
              track tag
            ) {
              <mat-list-item
                class="category-hover"
                selected
                color="primary"
                (click)="addOrRemoveTag(tag)"
              >
                {{ tag.tag | titlecase }}
              </mat-list-item>
            }
          </mat-selection-list>
        </div>
      </div>
    </div>
  }

  <mat-divider></mat-divider>
  <div
    class="section_body"
    [ngClass]="{
      center: !task.acceptedOffer || !cancelTask,
      'space-evenly': task.acceptedOffer || cancelTask,
    }"
  >
    <!-- Task creator -->
    <div class="section_body_row">
      <app-user-card
        title="Task creator"
        [user]="task.user"
      ></app-user-card>
    </div>

    @if (task?.acceptedOffer !== null && task?.acceptedOffer?.userId !== null) {
      <div>
        <app-user-card
          title="Assigned Handyhander"
          [userId]="task.acceptedOffer?.userId"
        ></app-user-card>
      </div>
    } @else if (cancelTask?.cancelTask?.offer?.userId) {
      <div>
        <app-user-card
          title="Assigned Handyhander"
          [userId]="cancelTask?.cancelTask?.offer?.userId"
        ></app-user-card>
      </div>
    }
    @if (reviewOfHandyhander || reviewOfTaskOwner) {
      <div
        class="section_body_row space-evenly"
        style="width: 100%"
      >
        @if (reviewOfTaskOwner) {
          <app-user-review-card
            [review]="reviewOfTaskOwner"
          ></app-user-review-card>
        }
        @if (reviewOfHandyhander) {
          <app-user-review-card
            [review]="reviewOfHandyhander"
          ></app-user-review-card>
        }
      </div>
    }
  </div>

  <!-- Task images -->
  <div class="section_body_row">
    <mat-divider></mat-divider>
    <h4 class="section_title center">Task images</h4>
    <div class="section_body_row">
      <div class="task_images_grid">
        @for (img of task.images; track img) {
          <div>
            @if (img.fileType == 'image') {
              <app-image-preview
                [imageUrl]="img.url"
                [fullImageObject]="img"
                class="support-img"
                (deleteImageEvent)="deleteImage(img.id)"
              ></app-image-preview>
            }
            @if (img.fileType == 'doc') {
              <app-image-preview
                [pdfUrl]="img.url"
                class="support-img"
              ></app-image-preview>
            }
          </div>
        }
        <div class="center">
          <div
            class="add__image__container"
            (click)="openUploadDocumentDialog()"
          >
            <mat-icon>add</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>
  <app-task-conversation
    [inputType]="conversationType"
    [inputTypeId]="conversationTypeId"
  ></app-task-conversation>
</div>
