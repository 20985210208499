import { enableProdMode, importProvidersFrom, providePlatformInitializer } from '@angular/core';
import 'hammerjs';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/root/app.component';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { services } from './app/app.services';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app/app.routing';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { provideNgxWebstorage, withLocalStorage } from 'ngx-webstorage';
import { ALL_IMPORTS } from './app/shared/standalone-imports';

// Token getter function using ngx-webstorage
export function tokenGetter() {
  // This function will be used by JwtModule
  // We need to use localStorage directly here since this function is called
  // before the Angular DI system is initialized
  return localStorage.getItem('ngx-webstorage|token');
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideNgxWebstorage(withLocalStorage()),
    provideNativeDateAdapter(),
    provideAnimations(),
    provideToastr(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideRouter(appRoutes),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ['*'],
        },
      }),
      ...ALL_IMPORTS,
    ),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ...services,
  ],
}).catch((err) => console.log(err));
