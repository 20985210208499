import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';
import { DatePickerDialogComponent } from '../datePickerDialog/datePickerDialog.component';

@Component({
  selector: 'app-information-dialog',
  templateUrl: 'informationDialog.component.html',
  styleUrls: ['../dialogs.scss', 'informationDialog.component.scss'],
  imports: [...COMMON_IMPORTS],
})
export class InformationDialogComponent {
  title: any;
  descriptions: string[];
  object?: any;
  objectKeys?: string[];

  ngOnInit(): void {
    if (this.data.object === undefined) return;
    this.objectKeys = Object.keys(this.data.object);
  }

  constructor(
    public dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    this.title = data.title;

    if (data.description instanceof Array) {
      this.descriptions = data.description;
    } else {
      this.descriptions = [data.description];
    }

    if (data.object) {
      this.object = data.object;
    }
  }

  openDatePicker(date: string): void {
    const dialogRef = this.dialog.open(DatePickerDialogComponent, {
      width: '500px',
      data: { date },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') return;
      this.close({ dob: result });
    });
  }

  close(result: any): void {
    this.dialogRef.close(result);
  }
}
