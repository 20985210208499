import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Comment } from 'src/app/models/comment.model';
import { Offer } from 'src/app/models/offer.model';
import { OfferComment } from 'src/app/models/offerComment.model';
import { MatCard } from '@angular/material/card';

@Component({
    selector: 'app-comment-card',
    templateUrl: './comment-card.component.html',
    styleUrls: ['./comment-card.component.scss', '../cards.scss'],
    imports: [MatCard]
})
export class CommentCardComponent implements OnInit {

  @Input() title = "Comment";
  @Input() comment: Comment | OfferComment;
  @Input() type: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }

  goToCommentDetails(): void {
    if (this.type === 'offer_comment') {
      this.router.navigate(['/comments', 'offer-comm', this.comment.id])
    } else if (this.type === 'task_comment') {
      this.router.navigate(['/comments', 'task-comm', this.comment.id])
    }
  }
}
