import { Component } from '@angular/core';
import { OfferService } from '../../services/offer.service';
import { Offer } from '../../models/offer.model';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { format } from 'date-fns';
import { OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-payments-list',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss'],
    imports: [PaginatedList, ...COMMON_IMPORTS]
})
export class PaymentsComponent implements OnInit {
  pendingPayments: Offer[] = [];
  displayPendingPayments: Offer[] = [];
  filterKeys = ['userId', 'stripeChargeId'];
  hasMore: boolean = true;

  constructor(
    private offerService: OfferService,
    private router: Router,
    private authService: AuthService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.setMetaData();
  }

  fetchPendingPayments(): void {
    if (this.hasMore) {
      this.offerService.getUnpaidCompletedOffer().subscribe(
        (res) => {
          this.pendingPayments = res;
          this.hasMore = false;
        },
        (err) =>
          this.authService.handleError(
            err,
            'Failed to get unpaid completed offers!',
          ),
      );
    }
  }

  setMetaData(): void {
    this.title.setTitle('Pending payments');
  }

  updateDisplayPayments(offers: Offer[]): void {
    this.displayPendingPayments = offers;
  }

  goToPaymentDetails(offerId: number) {
    this.router.navigate(['/offers', offerId]);
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), 'dd-MM-yyyy');
  }
}
