import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Coupon } from 'src/app/models/coupon.model';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-coupon-create-edit',
    templateUrl: './couponCreateEdit.component.html',
    styleUrls: ['../common.scss', './couponCreateEdit.component.scss'],
    imports: [...ALL_IMPORTS]
})
export class CouponCreateEditComponent implements OnChanges {
  @Input() coupon: Coupon | null = null;
  @Output() close = new EventEmitter<void>();

  inputs: Partial<
    Omit<Coupon, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'>
  > = { type: 'fixed' };

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coupon.currentValue !== changes.coupon.previousValue) {
      this.inputs =
        changes.coupon.currentValue === null
          ? { type: 'fixed' }
          : { ...changes.coupon.currentValue };
    }
  }

  handleClose() {
    // Todo: test for unsaved changes, prompt user if there are any
    this.close.emit();
  }

  handleValueModelChange() {
    return this.inputs.type === 'fixed'
      ? this.inputs.value / 100
      : this.inputs.value;
  }

  handleValueInputChange(value: number) {
    this.inputs.value = this.inputs.type === 'fixed' ? value * 100 : value;
  }

  handleTypeModelChange() {
    return this.inputs.type;
  }

  handleTypeInputSelect(value: 'fixed' | 'percentage') {
    this.inputs.type = value;
    if (value === 'percentage') {
      this.inputs.value = Math.min((this.inputs.value || 100_00) / 100, 100);
    } else {
      this.inputs.value = (this.inputs.value || 100) * 100;
    }
  }

  displayValue(): string {
    if (!this.inputs.value) {
      return 'N/A';
    }
    return this.inputs.type === 'fixed'
      ? `${this.inputs.value / 100} kr.`
      : `${this.inputs.value}%`;
  }

  test() {
    this.inputs.type = this.inputs.type === 'fixed' ? 'percentage' : 'fixed';
  }
}
