<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
  >
  @if (isLoading) {
    <mat-spinner
      class="cvr-local-spinner"
    ></mat-spinner>
  }
  <div
    class="box-wrapper"
    [ngClass]="{
      'box-wrapper': true,
      'box-wrapper--is-handled': request?.disabled,
      blur: isLoading
    }"
    >
    <div class="box">
      <div class="CVR-check-wrapper">
        <div class="request-information">
          <h2>User uploaded CVR-document</h2>
          <i>at: {{ request?.createdAt | date : 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="user-card-wrapper">
          <app-user-card
            [title]="'Requesting user'"
            [user]="requestUser"
          ></app-user-card>
        </div>
        @if(companyInformation && document){
          <div class="company-card-wrapper">
            <app-company-card
              [title]="'Company'"
              [company]="companyInformation"
            ></app-company-card>
          </div>
          <div class="pdf-view-wrapper">
            <app-image-preview [pdfUrl]="document.url"></app-image-preview>
          </div>
        }
      </div>

      <div class="button-container">
        <button
          class="pinploy_button small_button red_button"
          (click)="handleRequest('rejected')"
          [disabled]="isLoading"
          >
          Decline
        </button>
        <button
          class="pinploy_button small_button"
          (click)="handleRequest('approved')"
          [disabled]="isLoading"
          >
          Accept
        </button>
      </div>
    </div>
  </div>
</div>
