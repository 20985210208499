@if (contextForm.get('template'); as choosedTemplate) {
  <div
    class="tab_content_container"
    >
    <mat-form-field appearance="fill">
      <mat-label>Template</mat-label>
      <mat-select [formControl]="choosedTemplate" required>
        @for (template of templates; track template) {
          <mat-option [value]="template">
            {{ template.label }}
          </mat-option>
        }
      </mat-select>
      @if (choosedTemplate.invalid) {
        <mat-error>Template is required</mat-error>
      }
    </mat-form-field>
    <div
      class="lang_container"
      [ngClass]="{ vertical: choosedLanguage === 'userSettings' }"
      >
      <!-- Display both language -->
      @if (choosedLanguage === 'userSettings') {
        <ng-container
          *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
        ></ng-container>
        <mat-divider [vertical]="true"></mat-divider>
        <ng-container
          *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
        ></ng-container>
      }
      <!-- Force dk language -->
      @if (choosedLanguage === 'dk') {
        <ng-container
          *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
        ></ng-container>
      }
      <!-- Force en language -->
      @if (choosedLanguage === 'en') {
        <ng-container
          *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
        ></ng-container>
      }
    </div>
    <!-- Always display dynamics field -->
    @if (choosedTemplate.valid) {
      @if (getDynamicsFields(choosedTemplate); as fields) {
        @if (fields && fields.length > 0) {
          <div class="dynamic_datas">
            <p>
              The list of variables contained in the HTML template and which will
              be automatically filled. These variables must be returned by the
              Bucket Function.
            </p>
            <ul>
              @for (field of fields; track field) {
                <li>
                  {{ field.label }}
                </li>
              }
            </ul>
          </div>
        }
      }
    }
    <ng-template #completeForm let-lang="lang">
      @if (choosedTemplate.value) {
        <div class="lang_container_item">
          <h2>{{ lang === "dk" ? "Danish" : "English" }}</h2>
          @if (contextForm.get(lang); as fg) {
            <div class="form_container">
              <mat-form-field appearance="fill">
                <mat-label> Main text </mat-label>
                <textarea
                  matInput
                  [formControl]="fg.get('plainText')"
                  rows="5"
                  required
                ></textarea>
                @if (
                  fg.get('plainText').invalid &&
                  fg.get('plainText').hasError('required')
                  ) {
                  <mat-error
                    >
                    Main text is required
                  </mat-error>
                }
              </mat-form-field>
              @for (field of choosedTemplate.value?.fields; track field) {
                <div
                  class="email_form_item"
                  >
                  @if (fg.get(field.label); as inputForm) {
                    <mat-form-field appearance="fill">
                      <mat-label> {{ field.label }} </mat-label>
                      @if (['text', 'url'].includes(field.type)) {
                        <input
                          matInput
                          [formControl]="inputForm"
                          [type]="field.type"
                          required
                          />
                      }
                      @if (field.type === 'textarea') {
                        <textarea
                          matInput
                          [formControl]="inputForm"
                          rows="5"
                          required
                        ></textarea>
                      }
                      @if (inputForm.invalid && inputForm.hasError('required')) {
                        <mat-error
                          >
                          {{ field.label }} is required
                        </mat-error>
                      }
                    </mat-form-field>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </ng-template>
  </div>
}
