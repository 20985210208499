import { Component, Input, OnInit, Output } from '@angular/core';
import { MiscService } from 'src/app/services/misc.service';
import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/app/services/state.service';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { UserService } from 'src/app/services/user.service';

import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';

import { EventEmitter } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ImagePreviewComponent } from '../../../components/image-preview/image-preview.component';
import { CompanyCardComponent } from '../../../components/cards/company-card/company-card.component';
import { UserCardComponent } from '../../../components/cards/user-card/user-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-request-CVR-check',
    templateUrl: './requestCVRcheck.component.html',
    styleUrls: [
        './requestCVRcheck.component.scss',
        '../requestHandlingPage.component.scss',
    ],
    animations: [
        trigger('fadeUpAnimation', [
            transition(':leave', [
                animate('1000ms ease-out', keyframes([
                    style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }), // Initial state
                    style({ opacity: 1, transform: 'translateY(-2%)', offset: 0.4 }), // Start slow
                    style({ opacity: 0, transform: 'translateY(-5%)', offset: 1 }), // Dissapear
                ])),
            ]),
        ]),
    ],
    imports: [
        ...COMMON_IMPORTS,
        MatProgressSpinnerModule,
        UserCardComponent,
        CompanyCardComponent,
        ImagePreviewComponent,
    ]
})
export class RequestCVRcheckComponent implements OnInit {
  @Input() request: any;
  @Output() requestHandledEvent = new EventEmitter<any>();
  requestHandled: boolean = false;
  isLoading: boolean = false;
  document: any;
  companyInformation: any;
  requestUserId: number;
  requestUser: User;

  constructor(
    private miscService: MiscService,
    private toastr: ToastrService,
    private userService: UserService,
    private stateService: StateService,
    private adminActionRequestService: AdminActionRequestService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.miscService
      .getMediaByResourceAndId('company_document', this.request.resourceId)
      .subscribe({
        next: (data) => {
          this.document = data[0];
          this.isLoading = false;
        },
        error: (error) => {
          this.toastr.error('Failed to fetch document');
          this.isLoading = false;
        },
      });

    this.companyInformation = this.request.data.dataValues.companyInfo;

    this.requestUserId = this.request.data.hhUserId;
    this.userService.getUserProfile(this.requestUserId).subscribe({
      next: (data) => {
        this.requestUser = data;
      },
      error: (error) => {
        this.toastr.error('Failed to fetch user');
      },
    });
  }

  handleRequest(status: string) {
    this.isLoading = true;
    this.userService
      .updateCVRCheckStatus(
        this.companyInformation.vat.toString(),
        this.requestUserId,
        status,
      )
      .subscribe({
        next: (data) => {
          this.handleSuccess();
        },
        error: (error) => {
          this.toastr.error('Failed to handle request');
          this.isLoading = false;
        },
      });
  }

  handleSuccess() {
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'CVR check on ' +
              this.companyInformation.vat +
              ' marked as handled',
            'Success',
          );

          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );
          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: () => {
          this.toastr.error(
            'Something went wrong with marking request as handled',
            'Error',
          );
          this.isLoading = false;
        },
      });
  }
}
