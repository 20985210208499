<div
  class="tab_content_container"
  [ngClass]="{ vertical: choosedLanguage === 'userSettings' }"
  >
  <!-- Display both language -->
  @if (choosedLanguage === 'userSettings') {
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
    ></ng-container>
    <mat-divider [vertical]="true"></mat-divider>
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
    ></ng-container>
  }

  <!-- Force dk language -->
  @if (choosedLanguage === 'dk') {
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
    ></ng-container>
  }

  <!-- Force en language -->
  @if (choosedLanguage === 'en') {
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
    ></ng-container>
  }
  <ng-template #completeForm let-lang="lang">
    <div class="lang_container">
      <h2>{{ lang === "dk" ? "Danish" : "English" }}</h2>

      @if (contextForm.get(lang); as fg) {
        <div class="form_container">
          <!-- Message -->
          @if (fg.get('message'); as inputForm) {
            <mat-form-field appearance="fill">
              <mat-label> Message </mat-label>
              <input matInput [formControl]="inputForm" type="text" required />
              @if (inputForm.invalid && inputForm.hasError('required')) {
                <mat-error
                  >
                  Message is required
                </mat-error>
              }
            </mat-form-field>
          }
        </div>
      }
    </div>
  </ng-template>
</div>
