import { NgModule } from "@angular/core";
import { LinkifyPipe } from "../pipes/linkify.pipe";
import { SafeHtmlPipe } from "../pipes/safeHtml.pipe";
import { StripHtmlPipe } from "../pipes/stripHtml.pipe";

@NgModule({
  declarations: [],
  imports: [StripHtmlPipe, SafeHtmlPipe, LinkifyPipe],
  exports: [StripHtmlPipe, SafeHtmlPipe, LinkifyPipe],
})
export class PipesModule {}
