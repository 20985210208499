<div
  class="search-bar"
  [class.focused]="isInputFocused"
  >
  <div class="search-and-icon">
    <mat-icon>search</mat-icon>
    <input
      #searchInput
      (focus)="focusSearch($event); isInputFocused = true"
      (blur)="checkBlur($event)"
      (keyup)="onSearchInput($event)"
      (keyup.enter)="onSearchInput($event)"
      type="text"
      class="search-input"
      [(ngModel)]="searchQuery"
      placeholder="Search..."
      />
  </div>

  <div
    class="dropdown"
    (mousedown)="$event.preventDefault()"
    >
    <mat-select
      (closed)="onDropdownClosed()"
      (selectionChange)="onSelectionChange($event)"
      [(ngModel)]="searchTypeSelected"
      class="my-select"
      >
      <mat-option
        matTooltip="Shortcut: Esc"
        value="all"
        [matTooltipPosition]="'right'"
        >All</mat-option
        >
        <mat-option
          matTooltip="Shortcut: !"
          value="users"
          [matTooltipPosition]="'right'"
          >Users</mat-option
          >
          <mat-option
            matTooltip="Shortcut: #"
            value="tasks"
            [matTooltipPosition]="'right'"
            >Tasks</mat-option
            >
            <mat-option
              matTooltip="Shortcut: $"
              value="offers"
              [matTooltipPosition]="'right'"
              >Offers</mat-option
              >
              <mat-option
                [matTooltipPosition]="'right'"
                matTooltip="Shortcut: /"
                value="offercomments"
                >Offer comments</mat-option
                >
                <mat-option
                  [matTooltipPosition]="'right'"
                  matTooltip="Shortcut: %"
                  value="taskcomments"
                  >Task comments</mat-option
                  >
                  <mat-option
                    matTooltipPosition="right"
                    matTooltip="Shortcut: @"
                    value="supporttickets"
                    >Support tickets
                  </mat-option>
                </mat-select>
              </div>
            </div>

            @if (searchFocussed) {
              <div
                (keydown.escape)="onEscape()"
                #searchDialog
                class="search_dialog"
                >
                @if (loading) {
                  <mat-spinner></mat-spinner>
                }
                @if (!loading && results && hasResults) {
                  <div
                    class="result_container"
                    >
                    <mat-accordion multi="true">
                      <!-- User expandable-->
                      @if (results?.users.length > 0) {
                        <mat-expansion-panel
                          expanded="true"
                          >
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>Users ({{ results?.users.length }})</p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (user of results?.users; track user) {
                              <li
                                style="list-style-type: none"
                                (click)="navToUser(user.id); unFocusSearch($event)"
                                >
                                <div class="result">
                                  <mat-icon class="mat-icon">person</mat-icon>
                                  <p class="item-id">{{ user.id }}</p>
                                  <h4 class="item-titel">
                                    {{ user.firstName }} {{ user.lastName }},
                                  </h4>
                                  @if (user.phone) {
                                    <i>{{ user.phone }},&nbsp;</i>
                                  }
                                  <i class="item-extra-info">{{ user.email }}</i>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                      <!-- Task expandable-->
                      @if (results?.tasks.length > 0) {
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>Tasks ({{ results?.tasks.length }})</p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (task of results?.tasks; track task) {
                              <li
                                style="list-style-type: none"
                                (click)="navToTask(task.id); unFocusSearch($event)"
                                >
                                <div class="result extended">
                                  <div class="info">
                                    <mat-icon class="mat-icon">calendar_today</mat-icon>
                                    <p class="item-id">{{ task.id }}</p>
                                    <h4 class="item-titel">
                                      {{ task.title }}
                                    </h4>
                                  </div>
                                  <div class="extra_info">
                                    <p
                  [ngClass]="{
                    orange: task.status === 'pending',
                    red: task.status === 'canceled',
                    grey: task.status === 'erased',
                    green: task.status === 'completed',
                  }"
                                      >
                                      {{ task.status }}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                      <!-- Offers expandable-->
                      @if (results?.offers.length > 0) {
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>Offers ({{ results?.offers.length }})</p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (offer of results?.offers; track offer) {
                              <li
                                style="list-style-type: none"
                                (click)="navToOffer(offer.taskId, offer.id); unFocusSearch($event)"
                                >
                                <div class="result extended">
                                  <div class="info">
                                    <mat-icon class="mat-icon">add_shopping_cart</mat-icon>
                                    <p class="item-id">{{ offer.id }}</p>
                                    <h4 class="item-titel">{{ offer.message }}</h4>
                                  </div>
                                  <div class="extra_info">
                                    <p
                  [ngClass]="{
                    orange: offer.status === 'pending',
                    red:
                      offer.status === 'canceled' ||
                      offer.status === 'disputed' ||
                      offer.status === 'rejected',
                    grey: offer.status === 'erased',
                    green:
                      offer.status === 'completed' ||
                      offer.status === 'accepted',
                  }"
                                      >
                                      {{ offer.status }}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                      <!-- Offer comment expandable-->
                      @if (results?.offerComments.length > 0) {
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>Offer Comments ({{ results?.offerComments.length }})</p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (comment of results?.offerComments; track comment) {
                              <li
                                style="list-style-type: none"
            (click)="
              navToOfferComment(comment.taskId, comment.id);
              unFocusSearch($event)
            "
                                >
                                <div class="result extended">
                                  <div class="info">
                                    <mat-icon class="mat-icon">comment</mat-icon>
                                    <p class="item-id">{{ comment.id }}</p>
                                    <h4 class="item-titel">{{ comment.text }}</h4>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                      <!-- Task Comment expandable-->
                      @if (results?.taskComments.length > 0) {
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>Task Comments ({{ results?.taskComments.length }})</p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (comment of results?.taskComments; track comment) {
                              <li
                                style="list-style-type: none"
            (click)="
              navToTaskComment(comment.taskId, comment.id);
              unFocusSearch($event)
            "
                                >
                                <div class="result extended">
                                  <div class="info">
                                    <mat-icon class="mat-icon">comment</mat-icon>
                                    <p class="item-id">{{ comment.id }}</p>
                                    <h4 class="item-titel">{{ comment.text }}</h4>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                      <!-- Support ticket expandable -->
                      @if (results?.supportTickets?.length > 0) {
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>Support tickets ({{ results?.supportTickets.length }})</p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (ticket of results?.supportTickets; track $index) {
                              <li
                                style="list-style-type: none"
                                (click)="navToSupportTicket(ticket.uid); unFocusSearch($event)"
                                >
                                <div class="result extended">
                                  <div class="info">
                                    <mat-icon class="mat-icon">comment</mat-icon>
                                    <p class="item-id">{{ ticket.uid }}</p>
                                    <h4 class="item-titel">{{ ticket.email }}</h4>
                                  </div>
                                  <div class="extra_info">
                                    <p
                      [ngClass]="{
                        red: ticket.status === 'unresolved',
                        grey: ticket.status === 'closed',
                        green: ticket.status === 'resolved',
                      }"
                                      >
                                      {{ ticket.status }}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                      <!-- Support ticket messages expandable-->
                      @if (results?.supportTicketMessages?.length > 0) {
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p>
                                Support ticket messages ({{
                                results?.supportTicketMessages.length
                                }})
                              </p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul>
                            @for (message of results?.supportTicketMessages; track $index) {
                              <li
                                [matTooltip]="htmlToText(message.text)"
                                matTooltipPosition="after"
                                matTooltipClass="tooltip-messages"
                                style="list-style-type: none"
                (click)="
                  navToSupportTicket(message.supportTicketUid);
                  unFocusSearch($event)
                "
                                >
                                <div class="result extended">
                                  <div class="info">
                                    <mat-icon class="mat-icon">comment</mat-icon>
                                    <p class="item-id">{{ message.supportTicketUid }}</p>
                                    <h4 class="item-titel">
                                      @if (htmlToText(message.text).length > 50) {
                                        {{
                                        htmlToText(message.text)
                                        | slice
                                        : htmlToText(message.text.toLowerCase()).indexOf(
                                        searchQuery.toLowerCase()
                                        ) - 20
                                        : htmlToText(message.text.toLowerCase()).indexOf(
                                        searchQuery.toLowerCase()
                                        ) + 30
                                        }}...
                                      } @else {
                                        {{ htmlToText(message.text) }}
                                      }
                                    </h4>
                                  </div>
                                  <div class="extra_info">
                                    <p
                      [ngClass]="{
                        red: message.supportTicketStatus === 'unresolved',
                        grey: message.supportTicketStatus === 'closed',
                        green: message.supportticketStatus === 'resolved',
                      }"
                                      >
                                      {{ message.supportTicketStatus }}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            }
                          </ul>
                        </mat-expansion-panel>
                      }
                    </mat-accordion>
                  </div>
                }
              </div>
            }
