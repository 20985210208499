import * as dateFns from 'date-fns';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConflictCase } from 'src/app/models/conflictCase.model';
import { ConflictService } from 'src/app/services/conflict.service';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-conflict-case-list',
    templateUrl: './conflictCaseList.component.html',
    styleUrls: ['./conflictCaseList.component.scss'],
    imports: [PaginatedList, ...ALL_IMPORTS]
})
export class ConflictCaseListComponent implements OnInit, OnDestroy {
  conflictCases: ConflictCase[] = [];
  displayConflictCases: ConflictCase[];
  filterKeys: string[] = ['id', 'taskId', 'createAt', 'deadline', 'status'];
  lastPage = 0;
  hasMore = true;
  isBusy = false;

  constructor(
    private title: Title,
    private conflictService: ConflictService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.setMetaTitle();
  }

  ngOnDestroy(): void {
    this.title.setTitle(`Admin Dashboard`);
  }

  setMetaTitle(): void {
    this.title.setTitle(`Conflict Cases`);
  }

  fetchData(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.conflictService.getAllConflictCases(this.lastPage + 1).subscribe(
        (res) => {
          this.conflictCases = this.conflictCases.concat(res.data);
          this.lastPage++;
          this.hasMore = res.hasMore;
        },
        (err) =>
          this.authService.handleError(err, 'Failed to fetch conflict cases'),
        () => (this.isBusy = false),
      );
    }
  }

  formatDate(dateString: string | Date): string {
    if (!dateString) {
      return 'N/A';
    }
    return dateFns.format(new Date(dateString), 'dd/MM/yyyy');
  }

  updatePagination(conflicts: any[]): void {
    this.displayConflictCases = conflicts;
  }
}
