<div class="search-container">
  <!-- Search -->
  <mat-form-field>
    <input matInput #searchInput placeholder="Search ..." [(ngModel)]="searchString" (keyup)="onSearchInput($event)">
  </mat-form-field>

  <!-- Results -->
  <div class="results-container">

    <!-- Users -->
    @if (results?.users) {
      <div class="card-container">
        <h4>Users</h4>
        @for (user of results.users; track user) {
          <a routerLink="{{'/users/' + user.id}}">
            <mat-card>
              <mat-card-content>
                <p>Id: {{user.id}}</p>
                <p>Name: {{user.firstName}} {{user.lastName}}</p>
                <p>Email: {{user.email}}</p>
                <p>Phone: {{user.phone}}</p>
              </mat-card-content>
            </mat-card>
          </a>
        }
      </div>
    }
    <!-- Tasks -->
    @if (results?.tasks) {
      <div class="card-container">
        <h4>Tasks</h4>
        @for (task of results.tasks; track task) {
          <a routerLink="{{'/tasks/' + task.id}}">
            <mat-card>
              <mat-card-content>
                <p>ID: {{task.id}} Status: {{task.status.toUpperCase()}} UserID: {{task.userId}}</p>
                <p class="title">{{task.title}}</p>
              </mat-card-content>
            </mat-card>
          </a>
        }
      </div>
    }
    <!-- Offers -->
    @if (results?.offers) {
      <div class="card-container">
        <h4>Offers</h4>
        @for (offer of results.offers; track offer) {
          <a routerLink="{{'/offers/' + offer.id}}">
            <mat-card>
              <mat-card-content>
                <p>ID: {{offer.id}} Status: {{offer.status.toUpperCase()}}</p>
                <p>UserID: {{offer.userId}} TaskID: {{offer.taskId}} PriceBid: {{offer.priceBid}}</p>
                <p class="comment">{{offer.message}}</p>
              </mat-card-content>
            </mat-card>
          </a>
        }
      </div>
    }
    <!-- Offer comments -->
    @if (results?.offerComments) {
      <div class="card-container">
        <h4>Offer comments</h4>
        @for (comment of results.offerComments; track comment) {
          <a routerLink="{{'/comments/offer-comm/' + comment.id}}">
            <mat-card>
              <mat-card-content>
                <p>ID: {{comment.id}} Status: {{comment.status.toUpperCase()}}</p>
                <p>UserID: {{comment.userId}} TaskID: {{comment.taskId}} OfferID: {{comment.offerId}}</p>
                <p class="comment">{{comment.text}}</p>
              </mat-card-content>
            </mat-card>
          </a>
        }
      </div>
    }
    <!-- Task comments -->
    @if (results?.taskComments) {
      <div class="card-container">
        <h4>Task comments</h4>
        @for (comment of results.taskComments; track comment) {
          <a routerLink="{{'/comments/task-comm/' + comment.id}}">
            <mat-card>
              <mat-card-content>
                <p>ID: {{comment.id}} Status: {{comment.status.toUpperCase()}}</p>
                <p>UserID: {{comment.userId}} TaskID: {{comment.taskId}}</p>
                <p class="comment">{{comment.text}}</p>
              </mat-card-content>
            </mat-card>
          </a>
        }
      </div>
    }
  </div>
</div>
