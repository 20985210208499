<div class="tab_content_container">
  <!-- Display both language -->
  @if (choosedLanguage === 'userSettings') {
    <div class="vertical">
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
      ></ng-container>
      <mat-divider [vertical]="true"></mat-divider>
      <ng-container
        *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
      ></ng-container>
    </div>
    <ng-container>
      <ng-container *ngTemplateOutlet="optParams"></ng-container>
    </ng-container>
  }

  <!-- Force dk language -->
  @if (choosedLanguage === 'dk') {
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'dk' }"
    ></ng-container>
  }

  <!-- Force en language -->
  @if (choosedLanguage === 'en') {
    <ng-container
      *ngTemplateOutlet="completeForm; context: { lang: 'en' }"
    ></ng-container>
  }
  <ng-template #completeForm let-lang="lang">
    <div class="lang_container">
      <h2>{{ lang === "dk" ? "Danish" : "English" }}</h2>

      @if (contextForm.get(lang); as fg) {
        <div class="form_container">
          <div>
            <!-- TITLE -->
            @if (fg.get('title'); as inputForm) {
              <mat-form-field appearance="fill">
                <mat-label> Title </mat-label>
                <input matInput [formControl]="inputForm" type="text" required />
                @if (inputForm.invalid && inputForm.hasError('required')) {
                  <mat-error
                    >
                    Title is required
                  </mat-error>
                }
              </mat-form-field>
            }
            @if (choosedLanguage !== 'userSettings') {
              <ng-container *ngTemplateOutlet="optParams"></ng-container>
            }
          </div>
          <!-- BODY -->
          @if (fg.get('body'); as inputForm) {
            <mat-form-field appearance="fill">
              <mat-label> Body </mat-label>
              <textarea
                matInput
                [formControl]="inputForm"
                rows="5"
                required
              ></textarea>
              @if (inputForm.invalid && inputForm.hasError('required')) {
                <mat-error
                  >
                  Body is required
                </mat-error>
              }
            </mat-form-field>
          }
        </div>
      }
    </div>
  </ng-template>
</div>

<!-- PUSH DATA -->
<ng-template #optParams>
  @if (contextForm.get('pushData'); as inputForm) {
    <mat-form-field appearance="fill">
      <mat-label> Push data </mat-label>
      <mat-hint align="end">
        Must be a valid JSON object or empty (eg: {{ "{" }} "taskId": 1
        {{ "}" }})
      </mat-hint>
      <input matInput [formControl]="inputForm" type="text" />
      @if (inputForm.invalid && inputForm.hasError('invalidJson')) {
        <mat-error>
          pushData must be a valid JSON object or empty
        </mat-error>
      }
    </mat-form-field>
  }
</ng-template>
