<div class="dialog_container">
  <div class="title">
    @if (data?.type == 'task-comment') {
      <h3>
        Send comment in thread on task-comment {{ data.id }}
      </h3>
    }
    @if (data?.type == 'offer') {
      <h3>
        Send comment in thread on offer {{ data.id }}
      </h3>
    }
  </div>

  <div class="text-area">
    <mat-form-field class="input">
      <textarea
        matInput
        placeholder="Comment"
        [(ngModel)]="comment"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="20"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="buttons">
    <button class="pinploy_button small_button red_button" (click)="close()">
      Close
    </button>
    <button class="pinploy_button small_button" (click)="send()">Send</button>
  </div>
</div>
