import { Component, Input } from '@angular/core';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import { User } from 'src/app/models/user.model';

@Component({
    selector: 'app-users-currently-viewing',
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS],
    templateUrl: './users-currently-viewing.component.html',
    styleUrl: './users-currently-viewing.component.scss'
})
export class UsersCurrentlyViewingComponent {
  constructor() {}

  @Input({ required: true }) usersCurrentlyViewing: User[];
}
