import { Component, Input, output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminActionRequest } from 'src/app/models/adminActionRequest.model';
import { AdminActionRequestService } from 'src/app/services/adminActionRequest.service';
import { StateService } from 'src/app/services/state.service';
import { fadeUpAnimation } from 'src/app/animations/fade-animations';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-request-conflict-case-pending',
    templateUrl: './requestConflictCasePending.component.html',
    styleUrls: [
        './requestConflictCasePending.component.scss',
        '../requestHandlingPage.component.scss',
    ],
    imports: [...ALL_IMPORTS],
    providers: [],
    animations: [fadeUpAnimation]
})
export class RequestConflictCasePendingComponent {
  @Input({ required: true }) request: any;
  requestHandledEvent = output<AdminActionRequest>();
  requestHandled: boolean = false;

  isLoading: boolean = false;

  constructor(
    private adminActionRequestService: AdminActionRequestService,
    private toastr: ToastrService,
    private stateService: StateService,
  ) {}

  handleRequest() {
    this.isLoading = true;
    this.adminActionRequestService
      .markRequestAsHandled(this.request)
      .subscribe({
        next: (res) => {
          this.requestHandled = true;
          this.toastr.success(
            'Conflict case pending request marked as handled',
            'Success',
          );
          this.stateService.readActiveAdminActionRequestsComponentSource.next(
            null,
          );

          setTimeout(() => {
            this.requestHandledEvent.emit(this.request);
          }, 999);
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.toastr.error('Something went wrong', 'Error');
        },
      });
  }
}
