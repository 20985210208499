<div>
  <h3 class="header">Users media portfolio</h3>
</div>

<div class="dialog_container">
  @for (img of userPortfolio; track img; let i = $index) {
    <div
      class="dialog_row_container"
      >
      <div class="dialog_column_container">
        <div class="image_card_container">
          @if (img.fileType == 'image') {
            <app-image-preview
              [imageUrl]="img.url"
              [fullImageObject]="img"
              [medium]="true"
              class="support-img"
              (deleteImageEvent)="deleteImage(img.id)"
            ></app-image-preview>
          }
        </div>
      </div>
    </div>
  }
</div>
