import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DEFAULT_TEMPLATE, MessageTemplate } from "src/app/pages/supporTicketListV2/supportTicketListV2.component";
import { StorageService } from "src/app/services/storage.service";

@Component({
    imports: [
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
    ],
    selector: 'app-message-template-settings',
    template: `
    <h2 mat-dialog-title>Message Template Settings</h2>
    <mat-dialog-content>
      <form [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>Greeting Template</mat-label>
          <textarea 
            matInput 
            formControlName="greeting" 
            rows="3"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
          ></textarea>
          <mat-hint>Use {{'{firstName}'}} for customer's name</mat-hint>
        </mat-form-field>
        
        <mat-form-field appearance="fill">
          <mat-label>Signature Template</mat-label>
          <textarea 
            matInput 
            formControlName="signature" 
            rows="3"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
          ></textarea>
          <mat-hint>Use {{'{supportName}'}} for your name</mat-hint>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="resetToDefault()">Reset to Default</button>
      <button mat-button (click)="close()">Cancel</button>
      <button mat-button color="primary" (click)="save()">Save</button>
    </mat-dialog-actions>`,
    styles: [`
    mat-form-field {
      width: 100%;
      margin-bottom: 1rem;
    }
    textarea {
      white-space: pre-wrap;
    }
  `]
})
export class MessageTemplateSettingsComponent {
  form: FormGroup;
  
  constructor(
    private dialogRef: MatDialogRef<MessageTemplateSettingsComponent>,
    private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: MessageTemplate
  ) {
    this.form = new FormGroup({
      greeting: new FormControl(data.greeting),
      signature: new FormControl(data.signature)
    });
  }
  
  resetToDefault() {
    this.form.patchValue(DEFAULT_TEMPLATE);
  }
  
  save() {
    this.dialogRef.close(this.form.value);
  }
  
  close() {
    this.dialogRef.close();
  }
}