<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper" [ngClass]="{ blur: isLoading }">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>Payment Failed</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        <div class="payment-failed-info">
          <div class="info-box">
            <h4>Payment Details</h4>
            <p>User {{ userId }} has trouble with payment</p>
            @if (failedCharges && failedCharges.length > 0) {
              <p>
                Two payments have failed for:
                {{ failedCharges[0].amount / 100 }} kr.
              </p>
            }
          </div>
          
          @if (links && links.length > 0) {
            <div class="info-box">
              <h4>Links</h4>
              <div class="links-container">
                @for (link of links; track $index) {
                  <div class="link-item">
                    <a href="{{ link.url }}" target="_blank" matTooltip="Open in Stripe">
                      <mat-icon>open_in_new</mat-icon>
                      {{ link.text }}
                    </a>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        <div class="action-buttons">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Dismiss request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
