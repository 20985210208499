<div class="payments-container">
  <div id="controls">
    <h1>Payments ready for payout</h1>
    <p>
      Pending payments from Completed offers, waiting to be paid out to
      Pinployees. In queue for payout to bank account.
    </p>
  </div>
  <paginated-list
    [data]="pendingPayments"
    (displayDataChanged)="updateDisplayPayments($event)"
    (dataRequested)="fetchPendingPayments()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
    >
    <div class="list">
      @for (payment of displayPendingPayments; track payment) {
        <a
          class="item"
          routerLink="{{ '/offers/' + payment.id }}"
          >
          <p class="id"><span class="bold">ID: </span>{{ payment.id }}</p>
          <p id="status">
            <span class="bold">Status: </span>{{ payment.status }}
          </p>
          <p><span class="bold">Paid: </span>{{ payment.isPaid }}</p>
          <p class="id">
            <span class="bold">User ID: </span>{{ payment.userId }}
          </p>
          <p>
            <span class="bold">Stripe Charge ID: </span
              >{{ payment.stripeChargeId }}
            </p>
            <p>
              <span class="bold">Created at: </span
                >{{ convertTimestamp(payment.createdAt) }}
              </p>
              <p>
                <span class="bold">Updated at: </span
                  >{{ convertTimestamp(payment.updatedAt) }}
                </p>
              </a>
            }
          </div>
        </paginated-list>
      </div>
