import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MediaUpload } from '../models/media.model';
import { saveAs } from 'file-saver';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class MiscService {
  baseUrl = `${environment.apiUrl}/api/misc`;
  mediaBaseHref = `${environment.apiUrl}/api/media`;
  conflictUrl = `${environment.apiUrl}/api/admin/conflicts`;
  adminMiscUrl = `${environment.apiUrl}/api/admin/misc`;

  constructor(private http: HttpClient) {}

  getContentFeedData(start?: string | Date): Observable<any[]> {
    let url = `${this.baseUrl}/get-content-feed`;
    if (start) {
      url += `?start=${
        typeof start === 'string' ? start : start.toISOString()
      }`;
    }
    return this.http.get<any[]>(url);
  }

  getSearchResults(query: string, queryType?: string): Observable<any> {
    let url = `${this.baseUrl}/admin-search?q=${query.toLowerCase()}`;
    if (queryType) {
      url += `&type=${queryType.toLowerCase()}`;
    }
    return this.http.get(url);
  }

  getTaskAndOffersAndComments(type: string, id: string): Observable<any> {
    const url = `${this.baseUrl}/comments/${type}/${id}`;
    return this.http.get(url);
  }

  getMediaPortfolio(userId: number): Observable<any> {
    const url = `${environment.apiUrl}/api/admin/misc/media/${userId}/portfolio`;
    return this.http.get<any>(url);
  }

  getMediaByResourceAndId(
    resource: string,
    resourceId: number,
  ): Observable<any> {
    const body = {
      resourceId,
      resource,
    };
    const url = `${environment.apiUrl}/api/media/fetch-media`;
    return this.http.post<any>(url, body);
  }

  public allowedImageTypes = [
    'image/jpeg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'application/pdf',
    'image/apng',
    'image/avif',
    'image/svg+xml',
    'image/tiff',
    'image/bmp',
    'image/*',
  ];

  uploadMedia(mediaData: MediaUpload, files: any[]): Observable<any> {
    const url = `${this.mediaBaseHref}/`;
    const formData = new FormData();
    formData.append('data', JSON.stringify(mediaData));

    // Iterate through the files and append them to the FormData
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      if (file && this.allowedImageTypes.includes(file.type)) {
        formData.append('files', file, file.name);
      }
    }
    return this.http.post<any>(url, formData);
  }

  createMedia(data: any): Observable<any> {
    const url = `${this.mediaBaseHref}/create`;
    return this.http.post<any>(url, data);
  }

  createMediaCollection(data: {
    resource: string;
    name: string;
  }): Observable<any> {
    const url = `${this.mediaBaseHref}/media-collection`;
    return this.http.post<any>(url, data);
  }

  deleteMedia(data: {
    resource: string;
    resourceId: number;
    id?: number;
  }): Observable<any> {
    const url = `${this.mediaBaseHref}/delete-media`;
    return this.http.post<any>(url, data);
  }

  deleteMediaById(data: { id: number; resource: string }): Observable<any> {
    const url = `${this.mediaBaseHref}/delete-media-by-id/${data.resource}/${data.id}`;
    return this.http.get<any>(url);
  }

  deleteMediaCollection(mediaCollectionId): Observable<any> {
    const url = `${this.mediaBaseHref}/media-collection/${mediaCollectionId}`;
    return this.http.delete<any>(url);
  }

  deleteMediaByUrl(data: { url: string; resource: string }): Observable<any> {
    const url = `${this.mediaBaseHref}/delete-media-by-url`;
    return this.http.post<any>(url, data);
  }

  fetchCollection(mediaCollectionId): Observable<any> {
    const url = `${this.mediaBaseHref}/media-collection/${mediaCollectionId}`;
    return this.http.get<any>(url);
  }

  fetchMedia(mediaData: {
    resource: string;
    resourceId: number;
  }): Observable<any> {
    const url = `${this.mediaBaseHref}/fetch-media`;
    return this.http.post<any>(url, mediaData);
  }

  updateMediaCollection(data: any): Observable<any> {
    const url = `${this.mediaBaseHref}/media-collection/${data.mediaCollectionId}`;
    return this.http.put<any>(url, data);
  }

  updateMediaCollectionName(data: {
    id: number;
    name: string;
  }): Observable<any> {
    const url = `${this.mediaBaseHref}/media-collection/name/${data.id}`;
    return this.http.put<any>(url, data);
  }

  uploadConflictCaseEvidence(accessId, userId, description, media) {
    const url = `${this.conflictUrl}/create-conflict-case-evidence`;
    const data = {
      accessId,
      userId,
      description,
      media,
    };

    return this.http.post<any>(url, data);
  }

  handleRejectedCensoringRequest(
    request: any,
    userId: number,
    answer: 'accept' | 'decline',
  ): Observable<any> {
    const url = `${environment.apiUrl}/api/user-actions/handle-rej-auto-censored/${request.resourceType}/reject-automatic-censoring/${request.resourceId}/${userId}/${answer}`;
    return this.http.get<any>(url);
  }

  downloadFiles(body: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.adminMiscUrl}/media/download/zip`;
    return this.http.post(url, body, {
      headers,
      responseType: 'blob' as 'json',
    });
  }

  replaceMedia(newImage: any[], oldImage: any): Observable<any> {
    const url = `${this.adminMiscUrl}/media/replace`;

    const body = {
      folderUploadPath: 'tasks',
      resource: oldImage.resource,
      resourceId: oldImage.resourceId,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(body));

    // Iterate through the files and append them to the FormData
    for (let i = 0; i < newImage.length; i += 1) {
      const file = newImage[i];
      if (file && this.allowedImageTypes.includes(file.type)) {
        formData.append('files', file, file.name);
      }
    }
    return this.http.post<any>(url, formData);
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  getInsuranceApplicationsPaginated(page: number): Observable<any> {
    const url = `${this.adminMiscUrl}/insurance?page=${page}`;
    return this.http.get<any>(url);
  }

  getInsuranceApplication(taskId: string): Observable<any> {
    const url = `${this.adminMiscUrl}/insurance/${taskId}`;
    return this.http.get<any>(url);
  }

  updateInsuranceApplication(id: string, data: any): Observable<any> {
    const url = `${this.adminMiscUrl}/insurance/${id}`;
    return this.http.put<any>(url, data);
  }
}
