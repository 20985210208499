<div class="cancel-task-container">
  @if (cancelTaskAuto.status === 'manual') {
    <h2 style="color: darkred">*Requires manual action</h2>
    <br/>
  }
  <h4 class="section_title first_title">Automated Cancel Task Detail </h4>

  <div class="refund-information">
    <!-- Info Container -->
    <div class="refund-type-container">
      <p>Check the status of ongoing </p>
    </div>
  </div>
  <br/>
  <mat-divider></mat-divider>
  <br/>

  <div class="section_body">

    <!-- ID -->
    <mat-form-field>
      <input matInput placeholder="ID" [(ngModel)]="cancelTaskAuto.id" [disabled]="true">
    </mat-form-field>

    <!-- CancelTaskId -->
    <mat-form-field>
      <input matInput placeholder="cancelTaskId" [(ngModel)]="cancelTaskAuto.cancelTaskId" [disabled]="true">
    </mat-form-field>

    <!-- Fee Owner -->
    <mat-form-field>
      <input matInput placeholder="FeeOwner" [(ngModel)]="cancelTaskAuto.feeOwner" [disabled]="true">
    </mat-form-field>

    <!-- reasonComment-->
    <mat-form-field>
      <input matInput placeholder="reasonComment" [(ngModel)]="cancelTaskAuto.reasonComment" [disabled]="true">
    </mat-form-field>

    <!-- adminComment -->
    <mat-form-field>
      <input matInput placeholder="adminComment" [(ngModel)]="cancelTaskAuto.adminComment" [disabled]="true">
    </mat-form-field>

    <!-- requestInitiator -->
    <mat-form-field>
      <input matInput placeholder="Request initiator" [(ngModel)]="cancelTaskAuto.requestInitiator" [disabled]="true">
    </mat-form-field>

    <!-- receiverReason -->
    <mat-form-field>
      <input matInput placeholder="Receiver reason" [(ngModel)]="cancelTaskAuto.receiverReason" [disabled]="true">
    </mat-form-field>

    <!-- receiverFeeOwner-->
    <mat-form-field>
      <input matInput placeholder="Receiver Fee Owner" [(ngModel)]="cancelTaskAuto.receiverFeeOwner" [disabled]="true">
    </mat-form-field>

    <!-- receiverReasonComment-->
    <mat-form-field>
      <input matInput placeholder="ReceiverReasonComment" [(ngModel)]="cancelTaskAuto.receiverReasonCommen" [disabled]="true">
    </mat-form-field>

    <!-- Status -->
    <mat-form-field>
      <mat-select placeholder="Status" [value]="cancelTaskAuto.status" [(ngModel)]="cancelTaskAuto.status">
        <mat-option value="accepted">accepted</mat-option>
        <mat-option value="pending">pending</mat-option>
        <mat-option value="denied">denied</mat-option>
        <mat-option value="closed">closed</mat-option>
        <mat-option value="manual">manual</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Admin Comment -->
    <mat-form-field>
      <textarea matInput class="textarea-field" placeholder="Admin Comment" [(ngModel)]="cancelTaskAuto.adminComment"></textarea>
    </mat-form-field>

    <!-- updatedAt-->
    <mat-form-field>
      <input matInput placeholder="updatedAt" [(ngModel)]="cancelTaskAuto.updatedAt" [disabled]="true">
    </mat-form-field>

    <!-- createdAt-->
    <mat-form-field>
      <input matInput placeholder="updatedAt" [(ngModel)]="cancelTaskAuto.createdAt" [disabled]="true">
    </mat-form-field>

  </div>

  <div style="display: flex; justify-content: space-evenly; width: 600px;">
    <button class="pinploy_button small_button save-btn" [ngClass]="{ 'disabled': isBtnDisabled() }"
    [disabled]="isBtnDisabled()" (click)="saveChanges()">Save Changes</button>
    <button class="pinploy_button small_button save-btn" [ngClass]="{ 'disabled': isBtnDisabled() }"
    (click)="goToPinployee()">Visit Pinployee</button>
    <button class="pinploy_button small_button save-btn" [ngClass]="{ 'disabled': isBtnDisabledStatus() }"
    (click)="gotToHandleCanelTask(cancelTaskAuto.cancelTaskId)">Handle Cancel Task </button>
  </div>

</div>
