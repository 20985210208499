import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullScreenImageDialogComponent } from './fullscreen-image-dialog/fullscreen-image-dialog.component';
import { environment } from '../../../environments/environment';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  imports: [...COMMON_IMPORTS],
})
export class ImagePreviewComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() fullImageObject: any;
  @Input() pdfUrl: string;
  @Input() disableEditing: boolean = false;
  @Input() tiny: boolean = false;
  @Input() medium: boolean = false;
  @Output() deleteImageEvent: EventEmitter<any> = new EventEmitter();

  pdfImage = `${environment.cloudFrontImgUrl}/private/compressed/pdf-icon.svg`;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openImageDialog() {
    const dialog = this.dialog.open(FullScreenImageDialogComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'fullscreen_image_dialog',
      backdropClass: 'dialog__backdrop',
      data: {
        imageUrl: this.imageUrl,
        image: this.fullImageObject,
        disableEditing: this.disableEditing,
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res !== undefined && res.url) {
        this.imageUrl = res.url;
      }
      if (res === 'deleted') {
        this.deleteImageEvent.emit();
      }
    });
  }

  openDoc() {
    window.open(this.pdfUrl, '_blank');
  }
}
