import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Tag } from 'src/app/models/tag.model';
import { AuthService } from 'src/app/services/auth.service';
import { TagService } from 'src/app/services/tag.service';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'tag-dialog',
    templateUrl: 'tag.component.html',
    styleUrls: ['../dialogs.scss', 'tag.component.scss'],
    imports: [...ALL_IMPORTS]
})
export class TagDialogComponent implements OnInit {
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  tags: Tag[];
  selectedTags: Tag[];
  filteredTags: Observable<Tag[]>;
  taskId: number;
  taskTitle: string;
  taskDescription: string;
  tagInputValue = new UntypedFormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public dialogRef: MatDialogRef<TagDialogComponent>,
    public tagService: TagService,
    private toastr: ToastrService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.taskId = this.data.taskId;
    this.taskTitle = this.data.taskTitle;
    this.taskDescription = this.data.taskDescription;
    this.tags = this.data.tags.slice();
    this.selectedTags = this.data.selectedTags.slice();
    this.filteredTags = this.tagInputValue.valueChanges.pipe(
      startWith(''),
      map((tag: string) => (tag ? this._filter(tag) : this.tags)),
    );
  }

  selectTag(event: MatAutocompleteSelectedEvent): void {
    const tag = this.tags.find(
      (tag) => tag.tag.toLowerCase() === event.option.viewValue.toLowerCase(),
    );
    if (tag) {
      if (this.selectedTags.find((item) => item.id === tag.id)) return;
      this.selectedTags.push(tag);
      this.tagInput.nativeElement.value = '';
      this.tagInputValue.setValue(null);
    }
  }

  removeTag(tagId: number): void {
    const index = this.selectedTags.findIndex((tag) => tag.id === tagId);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  saveAndClose(next: boolean): void {
    this.saveTagsInDB(this.taskId, this.selectedTags);
    this.dialogRef.close({
      next,
      taskId: this.taskId,
      tags: this.selectedTags,
    });
  }

  saveTagsInDB(taskId: number, tags: Tag[]): void {
    this.tagService.updateTagFromTask({ tags, taskId }).subscribe(
      (res) => {
        if (res) {
          this.toastr.success(`Tag updated for task id ${taskId}`);
        }
      },
      (err) =>
        this.authService.handleError(
          err,
          `Failed to update tags for task id ${taskId}`,
        ),
    );
  }

  private _filter(value: string): Tag[] {
    const filterValue = value.toLowerCase();
    const filteredTags = this.tags.filter((tag) =>
      tag.tag.toLowerCase().startsWith(filterValue),
    );
    return filteredTags;
  }
}
