<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
  >
  @if (isLoading) {
    <mat-spinner
      class="reject-local-spinner"
    ></mat-spinner>
  }
  <div
    class="box-wrapper"
    [ngClass]="{
      blur: isLoading
    }"
    >
    <div class="box">
      <div class="rejection-wrapper">
        <div class="request-information">
          <h2>User Rejected Censorring</h2>
          <i>at: {{ request?.createdAt | date : 'hh:MM, dd/MM/yy' }}</i>
        </div>

        <!-- IF THE REJECTED MESSAGE IS A TASK COMMENT (CHILD OR PARENT)-->
        @if (request?.taskComment) {
          <div class="rejection-information">
            <div class="link-icon-area">
              <a
                href="/tasks/{{ request?.taskComment?.taskId }}/task-comm/{{
                  request?.resourceId
                }}"
                target="_blank"
                matTooltip="Open entity in dedicated are"
                >
                <mat-icon>open_in_new</mat-icon>
              </a>
            </div>
            <div class="info-box">
              <h4>Censorred text</h4>
              <p>{{ request?.taskComment?.text }}</p>
            </div>
            <div class="small-information">
              <div class="info-box">
                <h4>Entity type</h4>
                <p>TASK COMMENT</p>
              </div>
              <div class="info-box">
                <h4>Task comment ID</h4>
                <p>{{ request?.taskComment?.id }}</p>
              </div>
            </div>
            <div class="user-card-area">
              <app-user-card
                [userId]="request?.taskComment?.userId"
                [title]="'Task comment creator'"
              ></app-user-card>
            </div>
          </div>
        }

        <!-- IF THE REJECTED MESSAGE IS A TASK OFFER ("PARENT" ONLY)-->
        @if (request?.offer) {
          <div class="rejection-information">
            <div class="link-icon-area">
              <a
                href="/tasks/{{ request?.offer?.taskId }}/offer/{{
                  request?.resourceId
                }}"
                target="_blank"
                matTooltip="Open entity in dedicated are"
                >
                <mat-icon>open_in_new</mat-icon>
              </a>
            </div>
            <div class="info-box">
              <h4>Censorred text</h4>
              <p>{{ request?.offer?.message }}</p>
            </div>
            <div class="small-information">
              <div class="info-box">
                <h4>Entity type</h4>
                <p>OFFER</p>
              </div>
              <div class="info-box">
                <h4>Offer ID</h4>
                <p>{{ request?.offer?.id }}</p>
              </div>
            </div>
            <div class="user-card-area">
              <app-user-card
                [userId]="request?.offer?.userId"
                [title]="'Offer creator'"
              ></app-user-card>
            </div>
          </div>
        }

        <!-- IF THE REJECTED MESSAGE IS A TASK OFFER COMMENT ("CHILD" ONLY)-->
        @if (request?.offerComment) {
          <div class="rejection-information">
            <div class="link-icon-area">
              <a
                href="/tasks/{{ request?.offerComment?.taskId }}/offer-comm/{{
                  request?.resourceId
                }}
                "
                target="_blank"
                matTooltip="Open entity in dedicated are"
                >
                <mat-icon>open_in_new</mat-icon>
              </a>
            </div>
            <div class="info-box">
              <h4>Censorred text</h4>
              <p>{{ request?.offerComment?.text }}</p>
            </div>
            <div class="small-information">
              <div class="info-box">
                <h4>Entity type</h4>
                <p>OFFER COMMENT</p>
              </div>
              <div class="info-box">
                <h4>Offer comment ID</h4>
                <p>{{ request?.offerComment?.id }}</p>
              </div>
            </div>
            <div class="user-card-area">
              <app-user-card
                [userId]="request?.offerComment?.userId"
                [title]="'Offer comment creator'"
              ></app-user-card>
            </div>
          </div>
        }
      </div>

      <div class="buttons-area-wrapper">
        <div class="button-container">
          <button
            class="pinploy_button red_button"
            (click)="handleRequest('decline')"
            >
            Decline the request
          </button>
          <button
            class="pinploy_button"
            (click)="handleRequest('accept')"
            >
            Accept the request
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
