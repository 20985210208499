import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Coupon } from 'src/app/models/coupon.model';
import { CommonFunctions } from '../common';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-coupon-details',
    templateUrl: './couponDetails.component.html',
    styleUrls: ['../common.scss', './couponDetails.component.scss'],
    imports: [...COMMON_IMPORTS]
})
export class CouponDetailsComponent extends CommonFunctions {
  @Input() coupon: Coupon | null = null;
  @Output() close = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() showUses = new EventEmitter<void>();

  constructor() {
    super();
  }

  getCouponValue(): string {
    return this.coupon.type === 'percentage'
      ? `${this.coupon.value}%`
      : `${this.coupon.value / 100} kr.`;
  }
}
