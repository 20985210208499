import { Component, OnDestroy, OnInit } from '@angular/core';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state.service';

@Component({
    selector: 'app-online-users',
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS],
    templateUrl: './online-users.component.html',
    styleUrl: './online-users.component.scss'
})
export class OnlineUsersComponent implements OnInit, OnDestroy {
  onlineAdmins$: Subscription;
  onlineAdmins: any[];

  constructor(
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.onlineAdmins$ = this.stateService.onlineAdminsSource.subscribe({
      next: (admins) => {
        this.onlineAdmins = admins;
      }
    });
  }

  ngOnDestroy() {
    this.onlineAdmins$.unsubscribe();
  }
}
