<div
  class="type-container"
  [@fadeUpAnimation]="requestHandled ? 'void' : '*'"
>
  @if (isLoading) {
    <mat-spinner class="tagging-local-spinner"></mat-spinner>
  }

  <div class="box-wrapper" [ngClass]="{ blur: isLoading }">
    <div class="box">
      <div class="wrapper">
        <div class="header-info">
          <h2>User Mentioned MobilePay</h2>
          <i>at: {{ request.createdAt | date: 'hh:MM, dd/MM/yy' }}</i>
        </div>
        
        <div class="mobilepay-info">
          <div class="link-icon-area">
            <a [routerLink]="['/conversation', request.task?.id, 'search', 'mobilepay']" target="_blank" matTooltip="View conversation with MobilePay mention">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </div>
          
          <div class="info-box">
            <h4>Task Information</h4>
            <p>Task ID: {{ request.task?.id }}</p>
            <p>Title: {{ request.task?.title }}</p>
            <p>Status: {{ request.task?.status }}</p>
          </div>
          
          @if (request.task?.description) {
            <div class="info-box">
              <h4>Task Description</h4>
              <p>{{ request.task?.description }}</p>
            </div>
          }
        </div>
        
        <div class="action-buttons">
          <button
            class="pinploy_button"
            (click)="handleRequest()"
            [disabled]="isLoading"
          >
            Mark as handled
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
