import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { CompressorService } from 'src/app/services/compressor.service';
import { FullScreenImageDialogComponent } from '../../image-preview/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { MiscService } from 'src/app/services/misc.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-upload-conflict-case-media-dialog',
    templateUrl: 'uploadConflictCaseMediaDialog.component.html',
    styleUrls: [
        '../dialogs.scss',
        'uploadConflictCaseMediaDialog.component.scss',
    ],
    imports: [...ALL_IMPORTS, CdkTextareaAutosize]
})
export class UploadConflictCaseMediaDialogComponent {
  taskId = this.data.taskId;
  conflictCase = this.data.conflictCase;
  userId = this.data.userId;
  conflictStory: string = '';

  createConflictCaseMedia$: any;

  imageData: any[] = [];
  imageMedia: any[] = [];
  imageMediaUploaded: any[] = [];

  pdfData: any[] = [];

  //filter pdf from imageData
  // dont compress pdf
  // append pdf to upload, uncompressed

  constructor(
    private fullScreenImageDialog: MatDialog,
    private miscService: MiscService,
    public dialog: MatDialog,
    private stateService: StateService,
    private toastr: ToastrService,
    private compressor: CompressorService,
    public dialogRef: MatDialogRef<UploadConflictCaseMediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async uploadConflictImages(event) {
    this.imageData = event.target.files;
    if (this.imageData.length === 0) {
      return;
    }
    if (
      this.imageData.length > 10 ||
      this.imageData.length + this.imageMediaUploaded.length > 10
    ) {
      this.toastr.error(
        'Du kan kun uploade 10 billeder i alt',
        'Billeder kunne ikke uploades',
      );
      return;
    } else {
      this.pdfData = Array.from(this.imageData).filter(
        (file) => file.type === 'application/pdf',
      );
      this.imageData = Array.from(this.imageData).filter(
        (file) => file.type !== 'application/pdf',
      );
      await this.compressImages();
    }
  }

  async compressImages() {
    this.stateService.toggleWaitingPage(true);
    if (this.imageData.length > 0) {
      const toCompressedImages = await Promise.all(
        Array.from(this.imageData).map((file) =>
          this.compressor.compress2(file),
        ),
      );
      this.imageMedia = toCompressedImages;
      this.imageData = [];
    } else {
      this.stateService.toggleWaitingPage(false);
    }

    this.createConflictCaseMedia$ = this.miscService.uploadMedia(
      { folderUploadPath: 'conflict' },
      [...this.imageMedia, ...this.pdfData],
    );

    this.createConflictCaseMedia$.subscribe((mediaResult: any) => {
      this.imageMediaUploaded = this.imageMediaUploaded.concat(
        mediaResult?.files,
      );
      this.imageMedia = [];
      this.pdfData = [];
      this.stateService.toggleWaitingPage(false);
    });
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }

  openImageDialog(media) {
    this.fullScreenImageDialog.open(FullScreenImageDialogComponent, {
      data: {
        imageUrl: media?.location,
      },
    });
  }

  //Remove images from upload
  deleteImage(media) {
    this.imageMediaUploaded = this.imageMediaUploaded.filter(
      (image) => image !== media,
    );
  }

  upload() {
    this.stateService.toggleWaitingPage(true);
    this.miscService
      .uploadConflictCaseEvidence(
        this.conflictCase.accessId,
        this.userId,
        this.conflictStory,
        this.imageMediaUploaded,
      )
      .subscribe((res) => {
        this.toastr.success(
          'The evidence was successfully uploaded',
          'Evidence Uploaded',
        );
        this.stateService.toggleWaitingPage(false);
        this.close('upload');
      }),
      (err) => {
        this.toastr.error(
          'Something went wrong, please try again later',
          'Error',
        );
        this.stateService.toggleWaitingPage(false);
        this.close();
      };
  }

  isImageFile(media: any): boolean {
    const allowedExtensions = [
      'image/jpeg',
      'image/png',
      'image/dng',
      'image/heic',
      'image/hevc',
      'image/webp',
      'image/jpg',
    ];
    return allowedExtensions.includes(media);
  }

  openDoc(doc: any) {
    window.open(doc.location, '_blank');
  }
}
