@if (conversation && task) {
  <div class="conversation-container">
    <h2>Private conversation on task:</h2>
    <app-task-card [taskId]="task?.id"></app-task-card>
    <h3>Conversation Status: {{ conversation.status }}</h3>
    <h3>
      Conversation Blocked?:
      @if (conversation?.blockedByUserId) {
        <span>True</span>
      }
      @if (
        conversation?.blockedByUserId &&
        conversation?.blockedByUserId === handyhander.id
        ) {
        <span>(by Handyhander)</span>
      }
      @if (
        conversation?.blockedByUserId &&
        conversation?.blockedByUserId === taskOwner.id
        ) {
        <span>(by Task Owner)</span>
      }
      @if (!conversation?.blockedByUserId) {
        <span>False</span>
      }
    </h3>

    @if (taskOwner && handyhander && conversation?.blockedByUserId === null) {
      <div
        class="block-area"
        >
        <div class="drop-down">
          <h4>Who blocks the conversation</h4>
          <mat-form-field>
            <mat-label>Blocker</mat-label>
            <mat-select [(value)]="chosenBlocker">
              <mat-option value="{{ taskOwner.id }}">Task Owner</mat-option>
              <mat-option value="{{ handyhander.id }}">Handyhander</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          class="pinploy_button small_button"
          (click)="blockConversationAsUser()"
          [disabled]="!chosenBlocker"
          (disabledChange)="chosenBlocker = $event"
          [ngClass]="{ disabled: !chosenBlocker }"
          >
          Block conversation
        </button>
      </div>
    }

    @if (taskOwner && handyhander && conversation?.blockedByUserId !== null) {
      <div
        class="block-area"
        style="margin: 40px"
        >
        <button
          class="pinploy_button small_button red_button"
          (click)="blockConversationAsUser()"
          >
          Unblock conversation
        </button>
      </div>
    }

    <mat-divider class="divider"></mat-divider>

    <div class="search-area">
      <mat-form-field class="search-field">
        <input
          matInput
          placeholder="Search"
          [(ngModel)]="searchTerm"
          (keyup.enter)="performSearch()"
          />
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        (click)="performSearch()"
        >
        Search
      </button>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div class="user-information">
      <div class="task-owner-information">
        <app-user-card
          [title]="'Task Owner'"
          [userId]="taskOwner.id"
        ></app-user-card>
      </div>

      <div class="handyhander-information">
        <app-user-card
          [title]="'Handyhander'"
          [userId]="handyhander.id"
        ></app-user-card>
      </div>
    </div>

    <div class="message-container">
      <!--Message from original offer-->
      <div class="message green">
        <div class="message-information">
          <div class="meta">
            <span
              >{{ handyhander?.firstName }} {{ handyhander?.lastName }}</span
              >
              <span>{{ formatDate(offer?.createdAt) }}</span>
              <mat-icon
                class="read-icon"
                matTooltip="Message is read by receiving user"
                [matTooltipPosition]="'right'"
                >done_all</mat-icon
                >
              </div>
              <mat-divider></mat-divider>
              <p class="text">{{ offer?.message }}</p>
            </div>
          </div>

          @for (message of conversation?.messages; track message) {
            <div
              class="message"
              #messageContainer
        [ngClass]="
          message.type === 'system'
            ? 'system-message'
            : message.fromUserId === task.user.id
              ? 'task-owner-message'
              : 'handyhander-message'
        "
              >
              <!--Messages from the system-->
              @if (message?.type === 'system') {
                <div class="message-information system">
                  <div class="meta">
                    <span>System</span>
                    <span>{{ formatDate(message.createdAt) }}</span>
                    <mat-icon
                      class="read-icon"
                      matTooltip="This message doesn't need to marked as read"
                      [matTooltipPosition]="'right'"
                      >done_all</mat-icon
                      >
                    </div>
                    <mat-divider></mat-divider>
                    <p class="text">{{ message.text }}</p>
                  </div>
                }
                <!--Messages from the task owner-->
                @if (
                  taskOwner &&
                  message?.fromUserId === task?.user?.id &&
                  message?.type !== 'system'
                  ) {
                  <div class="message-information">
                    <div class="meta">
                      <span>{{ taskOwner?.firstName }} {{ taskOwner?.lastName }}</span>
                      <span>{{ formatDate(message?.createdAt) }}</span>
                      @if (message.isRead) {
                        <mat-icon
                          class="read-icon"
                          matTooltip="Message is read by receiving user"
                          [matTooltipPosition]="'right'"
                          >done_all</mat-icon
                          >
                        }
                      </div>
                      <mat-divider></mat-divider>
                      @if (message.type === 'bookAgain') {
                        <span style="font-size: 13px">Rebooking request</span>
                        <mat-divider></mat-divider>
                      }
                      <p class="text">{{ message.text }}</p>
                      @for (image of message.media; track image) {
                        <div>
                          <app-image-preview
                            [imageUrl]="image.url"
                            [medium]="true"
                          ></app-image-preview>
                        </div>
                      }
                      @if (checkForMbpay(message.text)) {
                        <div class="button_container">
                          <button
                            class="pinploy_button small_button"
                            (click)="sendSystemWarning()"
                            >
                            Send mobilepay reminder
                          </button>
                        </div>
                      }
                    </div>
                  }
                  <!--Messages from the handyhander-->
                  @if (
                    handyhander &&
                    message?.fromUserId !== task?.user?.id &&
                    message?.type !== 'system'
                    ) {
                    <div class="message-information">
                      <div class="meta">
                        <span
                          >{{ handyhander?.firstName }} {{ handyhander?.lastName }}</span
                          >
                          <span>{{ formatDate(message?.createdAt) }}</span>
                          @if (message?.isRead) {
                            <mat-icon
                              class="read-icon"
                              matTooltip="Message is read by receiving user"
                              [matTooltipPosition]="'right'"
                              >done_all</mat-icon
                              >
                            }
                          </div>
                          <mat-divider></mat-divider>
                          @if (message?.type === 'offerRequest') {
                            <span style="font-size: 13px">Rebooking response</span>
                            <mat-divider></mat-divider>
                          }
                          <p class="text">{{ message?.text }}</p>
                          @for (image of message.media; track image) {
                            <div>
                              <app-image-preview
                                [imageUrl]="image.url"
                                [medium]="true"
                              ></app-image-preview>
                            </div>
                          }
                          @if (checkForMbpay(message.text)) {
                            <div class="button_container">
                              <button
                                class="pinploy_button small_button"
                                (click)="sendSystemWarning()"
                                >
                                Send mobilepay reminder
                              </button>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            }
