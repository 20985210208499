@if (bucketNotification$ | async; as bucketNotification) {
  @if (!isNew || (bucketDefinition$ | async)) {
    <div class="page_container">
      <div class="header">
        @if (isNew) {
          <h2>New bucket notification</h2>
        }
        @if (!isNew) {
          <h2>Bucket notification: {{ bucketNotification.id }}</h2>
        }
        <button
          class="pinploy_button small_button"
          [routerLink]="['/notification-buckets', definitionId]"
          >
          Back
        </button>
      </div>
      <mat-divider></mat-divider>
      <div class="top_actions">
        <div class="row row_def_name">
          <div class="def_name">Definition name: {{ bucketDefinition.name }}</div>
          <div class="right">
            @if (!isNew) {
              <button
                class="pinploy_button small_button red_button"
                (click)="onClickDelete()"
                >
                Delete notification
              </button>
            }
            <button class="pinploy_button small_button" (click)="onClickSave()">
              {{ isNew ? "Create" : "Save" }}
            </button>
          </div>
        </div>
        <div class="row">
          <!-- Description -->
          <mat-form-field appearance="fill" class="w100">
            <mat-label>Description</mat-label>
            <input matInput [formControl]="form.get('description')" required />
            @if (form.get('description').hasError('required')) {
              <mat-error>
                Description is required
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="row time">
          <mat-form-field appearance="fill">
            <mat-label>Days</mat-label>
            <input
              matInput
              [(ngModel)]="numberDays"
              type="number"
              min="0"
              required
              (change)="onChangePeriodicity()"
              />
            <mat-hint>
              Number of days before sending notification after event/campaign has
              been triggered
            </mat-hint>
            @if (form.get('periodicity').hasError('required')) {
              <mat-error>
                Days is required
              </mat-error>
            }
            @if (form.get('periodicity').hasError('min')) {
              <mat-error>
                Days must be positive
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Hour</mat-label>
            <input
              matInput
              [(ngModel)]="hour"
              type="time"
              required
              (change)="onChangeHour()"
              />
            <mat-hint>
              Hour of the day when notification should be sent
            </mat-hint>
            @if (form.get('hour').hasError('required')) {
              <mat-error>
                Hour is required
              </mat-error>
            }
            @if (form.get('hour').hasError('min')) {
              <mat-error>
                Hour must be positive
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="fill" class="language">
            <mat-label>Language</mat-label>
            <mat-select [formControl]="form.get('language')" required>
              <mat-option selected value="userSettings">User settings</mat-option>
              <mat-option value="dk">Danish</mat-option>
              <mat-option value="en">English</mat-option>
            </mat-select>
            @if (form.get('language').invalid) {
              <mat-error>
                Language is required
              </mat-error>
            }
          </mat-form-field>
          <div class="wanted">
            <mat-slide-toggle [formControl]="form.get('emailWanted')">
              Email
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="form.get('webWanted')">
              Web
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="form.get('pushWanted')">
              Push
            </mat-slide-toggle>
            <mat-slide-toggle [formControl]="form.get('smsWanted')">
              SMS
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row">
          <div class="row flex1">
            <mat-slide-toggle [formControl]="form.get('repeatEnabled')">
              Repeatable
            </mat-slide-toggle>
            <mat-form-field appearance="fill" class="flex1">
              <mat-label>Days</mat-label>
              <input
                matInput
                [(ngModel)]="repeatDays"
                type="number"
                min="1"
                [required]="form.get('repeatEnabled').value"
                [disabled]="!form.get('repeatEnabled').value"
                (change)="onChangeRepeatDays()"
                />
              <mat-hint>
                After the first notification, how many days should pass before
                repeating the notification
              </mat-hint>
              @if (form.get('repeat').hasError('required')) {
                <mat-error>
                  Days is required
                </mat-error>
              }
              @if (form.get('repeat').hasError('min')) {
                <mat-error>
                  Days must be positive
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div class="flex1"></div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="forms">
        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
          <mat-tab
            label="Email"
            [disabled]="!form.get('emailWanted').value"
          [labelClass]="
            tabsIsInvalid(
              form.get('emailContext.template'),
              form.get('emailContext.dk'),
              form.get('emailContext.en')
            )
              ? 'tabs_error'
              : ''
          "
            >
            @if (templates$ | async; as templates) {
              <app-notification-email-section
                [templates]="templates"
                [disabled]="!form.get('emailWanted').value"
                [contextForm]="form.get('emailContext')"
                [choosedLanguage]="form.get('language').value"
              ></app-notification-email-section>
            } @else {
              <div class="loading_form_container">
                <mat-spinner></mat-spinner>
              </div>
            }
          </mat-tab>
          <mat-tab
            label="Web"
            [disabled]="!form.get('webWanted').value"
          [labelClass]="
            tabsIsInvalid(form.get('webContext')) ? 'tabs_error' : ''
          "
            >
            <app-notification-web-section
              [contextForm]="form.get('webContext')"
              [choosedLanguage]="form.get('language').value"
              [disabled]="!form.get('webWanted').value"
            ></app-notification-web-section>
          </mat-tab>
          <mat-tab
            label="Push"
            [disabled]="!form.get('pushWanted').value"
          [labelClass]="
            tabsIsInvalid(form.get('pushContext')) ? 'tabs_error' : ''
          "
            >
            <app-notification-push-section
              [contextForm]="form.get('pushContext')"
              [choosedLanguage]="form.get('language').value"
              [disabled]="!form.get('pushWanted').value"
            ></app-notification-push-section>
          </mat-tab>
          <mat-tab
            label="SMS"
            [disabled]="!form.get('smsWanted').value"
          [labelClass]="
            tabsIsInvalid(form.get('smsContext')) ? 'tabs_error' : ''
          "
            >
            <app-notification-sms-section
              [contextForm]="form.get('smsContext')"
              [choosedLanguage]="form.get('language').value"
              [disabled]="!form.get('smsWanted').value"
            ></app-notification-sms-section>
          </mat-tab>
        </mat-tab-group>
      </div>
      <mat-divider></mat-divider>
      @if (definitionId != null && notificationId != null) {
        <div
          class="schedules"
          >
          <app-bucket-notification-schedule-list
            [definitionId]="definitionId"
            [notificationId]="notificationId"
            [requireChange]="notifyChangeSchedule"
          ></app-bucket-notification-schedule-list>
        </div>
      }
    </div>
  }
}
