import { Component, Inject, OnInit, Output } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { PenaltyCardComponent } from '../../cards/penalty-card/penalty-card.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
import { QuestionDialogComponent } from '../questionDialog/questionDialog.component';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'user-penalties--dialog',
  templateUrl: 'userPenaltiesDialog.component.html',
  styleUrls: ['../dialogs.scss', 'userPenaltiesDialog.component.scss'],
  imports: [...ALL_IMPORTS, PenaltyCardComponent],
})
export class UserPenaltiesDialogComponent implements OnInit {
  constructor(
    public userService: UserService,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<UserPenaltiesDialogComponent>,
    public stateService: StateService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user penalties dialog');
    }
  }

  userData: User;
  userAppliedPenalties = [];
  userPendingPenalties = [];
  userCanceledPenalties = [];
  userCompletedPenalties = [];
  userTransferedPenalties = [];

  selectedAppliedPenalties = [];
  selectedPendingPenalties = [];

  appliedAdminReason = '';
  pendingAdminReason = '';

  ngOnInit(): void {
    this.userService.getPenalties(this.data.userId).subscribe((res: any) => {
      this.userAppliedPenalties = res.filter((p) => p.status === 'applied');
      this.userPendingPenalties = res.filter((p) => p.status === 'pending');
      this.userCanceledPenalties = res.filter((p) => p.status === 'canceled');
      this.userCompletedPenalties = res.filter((p) => p.status === 'completed');
      this.userTransferedPenalties = res.filter(
        (p) => p.status === 'transfered',
      );
    });
  }

  onAppliedPenaltyCheckboxChange(event: any, penaltyId: number) {
    if (event.checked) {
      this.selectedAppliedPenalties.push(penaltyId);
    } else {
      this.selectedAppliedPenalties = this.selectedAppliedPenalties.filter(
        (id) => id !== penaltyId,
      );
    }
  }

  onPayoutFees() {
    this.matDialog
      .open(QuestionDialogComponent, {
        data: {
          title: 'Payout Fees',
          description: `Are you sure you want to payout the following fees? \n\n[${this.selectedAppliedPenalties.map((id) => this.userAppliedPenalties.find((p) => p.id === id).id).join(', ')}]`,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res === 'yes') {
          this.stateService.toggleWaitingPage(true);

          this.userService
            .payoutFees(this.selectedAppliedPenalties, this.appliedAdminReason)
            .subscribe((res: any) => {
              if (res.success === true) {
                // Find the penalties that are being paid out
                const penaltiesToComplete = this.userAppliedPenalties.filter(
                  (penalty) =>
                    this.selectedAppliedPenalties.includes(penalty.id),
                );

                // Update each penalty's status to 'completed'
                const completedPenalties = penaltiesToComplete.map(
                  (penalty) => ({
                    ...penalty,
                    status: 'canceled',
                  }),
                );

                // Add these to the completed penalties array
                this.userCompletedPenalties = [
                  ...this.userCompletedPenalties,
                  ...completedPenalties,
                ];

                // Remove them from the applied penalties array
                this.userAppliedPenalties = this.userAppliedPenalties.filter(
                  (penalty) =>
                    !this.selectedAppliedPenalties.includes(penalty.id),
                );

                this.selectedAppliedPenalties = [];
                this.stateService.toggleWaitingPage(false);
                this.toastr.success('Fees paid out successfully');
              }
            });
        }
      });
  }

  onCancelFees() {
    this.matDialog
      .open(QuestionDialogComponent, {
        data: {
          title: 'Cancel Fees',
          description: `Are you sure you want to cancel the following fees? \n\n[${this.selectedPendingPenalties.map((id) => this.userPendingPenalties.find((p) => p.id === id).id).join(', ')}]`,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res === 'yes') {
          this.stateService.toggleWaitingPage(true);
          this.userService
            .cancelPenalties(
              this.selectedPendingPenalties,
              this.pendingAdminReason,
            )
            .subscribe((res: any) => {
              if (res[0] === this.selectedPendingPenalties.length) {
                const penaltiesToCancel = this.userPendingPenalties.filter(
                  (penalty) =>
                    this.selectedPendingPenalties.includes(penalty.id),
                );
                const canceledPenalties = penaltiesToCancel.map((penalty) => ({
                  ...penalty,
                  status: 'canceled',
                }));
                this.userCanceledPenalties = [
                  ...this.userCanceledPenalties,
                  ...canceledPenalties,
                ];
                this.userPendingPenalties = this.userPendingPenalties.filter(
                  (penalty) =>
                    !this.selectedPendingPenalties.includes(penalty.id),
                );
                this.selectedPendingPenalties = [];
                this.stateService.toggleWaitingPage(false);
                this.toastr.success('Fees cancelled successfully');
              }
            });
        }
      });
  }

  onPendingPenaltyCheckboxChange(event: any, penaltyId: number) {
    if (event.checked) {
      this.selectedPendingPenalties.push(penaltyId);
    } else {
      this.selectedPendingPenalties = this.selectedPendingPenalties.filter(
        (id) => id !== penaltyId,
      );
    }
  }
  closeDialogEvent() {
    this.close();
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }
}
