import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BucketDefinition } from 'src/app/models/BucketDefinition.model';
import { BucketNotification } from 'src/app/models/BucketNotification.model';
import { BucketNotificationService } from 'src/app/services/bucket-notification.service';
import { StateService } from 'src/app/services/state.service';
import { QuestionDialogComponent } from '../../../components/dialogs/questionDialog/questionDialog.component';

import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-bucket-definition-details',
    templateUrl: 'bucket-definition-details.component.html',
    styleUrls: ['bucket-definition-details.component.scss'],
    imports: [...ALL_IMPORTS]
})
export class BucketDefinitionDetailsComponent implements OnInit {
  bucketId: number;
  definition$: Observable<BucketDefinition>;

  isNew: boolean = false;
  canBeLaunched: boolean = false;

  bucketFunctions: Map<string, string[]> = new Map<string, string[]>();
  bucketFunctions$: Observable<string[]>;

  types = ['event', 'campaign'];
  repeats = ['daily', 'weekly', 'monthly', 'yearly'];

  permissionGroups = ['ownOffer', 'ownTask', 'review', 'newTask', 'promotion'];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private bucketNotificationService: BucketNotificationService,
    private stateService: StateService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.initComponent();
  }

  private initComponent() {
    this.stateService.toggleWaitingPage(true);
    this.definition$ = this.activatedRoute.paramMap.pipe(
      map((params) => {
        const strId = params.get('id');
        if (!strId) {
          this.toastr.error('Bucket definition id is required', 'Not found');
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(['/notification-buckets']);
          return;
        } else if (strId === 'new') {
          this.isNew = true;
          return 0;
        } else if (isNaN(+strId)) {
          this.toastr.error('Bucket definition id is invalid', 'Not a number');
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(['/notification-buckets']);
          return;
        }
        this.isNew = false;
        return +strId;
      }),
      tap((id) => (this.bucketId = id)),
      switchMap((id) => {
        if (this.isNew) {
          const def = new BucketDefinition();
          def.isActive = false;
          return of(def);
        }

        return this.bucketNotificationService.getBucketDefinition(id);
      }),
      catchError((err) => {
        this.toastr.error(err.error, 'Unknown error');
        this.stateService.toggleWaitingPage(false);
        this.router.navigate(['/notification-buckets']);
        return of(new BucketDefinition());
      }),
      tap((bucketDefinition) => {
        if (!bucketDefinition) {
          this.toastr.error('Unable to find bucket definition', 'Not found');
          this.stateService.toggleWaitingPage(false);
          this.router.navigate(['/notification-buckets']);
        }
        this.evalCanLaunch(bucketDefinition);
        if (this.isNew) {
          return;
        }
        this.bucketFunctions$ = this.bucketNotificationService
          .listBucketFunctions(bucketDefinition.type)
          .pipe(
            tap((bucketFunctions) => {
              this.bucketFunctions.set(bucketDefinition.type, bucketFunctions);
            }),
          );
      }),

      tap(() => this.stateService.toggleWaitingPage(false)),
    );
  }

  getTime(periodicity: number) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const years = Math.floor(periodicity / msPerYear);
    const months = Math.floor((periodicity % msPerYear) / msPerMonth);
    const days = Math.floor(
      ((periodicity % msPerYear) % msPerMonth) / msPerDay,
    );
    const hours = Math.floor(
      (((periodicity % msPerYear) % msPerMonth) % msPerDay) / msPerHour,
    );
    const minutes = Math.floor(
      ((((periodicity % msPerYear) % msPerMonth) % msPerDay) % msPerHour) /
        msPerMinute,
    );

    const timeArr = [];
    if (years > 0) {
      timeArr.push(`${years} year${years > 1 ? 's' : ''}`);
    }
    if (months > 0) {
      timeArr.push(`${months} month${months > 1 ? 's' : ''}`);
    }
    if (days > 0) {
      timeArr.push(`${days} day${days > 1 ? 's' : ''}`);
    }
    if (hours > 0) {
      timeArr.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
      timeArr.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }

    if (timeArr.length === 0) {
      timeArr.push('instantly');
    }

    return timeArr.join(', ');
  }

  onClickDeleteBucket(definition: BucketDefinition) {
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      width: '583px',
      data: {
        title: 'Are you sure?',
        description: `Are you sure you want to delete bucket definition <b>${definition.name}</b>?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'yes') return;
      this.deleteBucket(definition);
    });
  }

  private deleteBucket(definition: BucketDefinition) {
    this.stateService.toggleWaitingPage(true);
    this.bucketNotificationService
      .deleteBucketDefinition(definition.id)
      .subscribe({
        next: () => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success('Bucket definition deleted', 'Success');
          this.router.navigate(['/notification-buckets']);
        },
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error('Unable to delete bucket definition', 'Error');
        },
      });
  }

  editBucket(definition: BucketDefinition) {
    if (!definition) {
      this.toastr.error('Unable to find bucket definition', 'Not found');
      return;
    }
    if (!definition.name?.trim()) {
      this.toastr.error('Bucket definition name is required', 'Invalid name');
      return;
    }
    const action = this.isNew ? 'create' : 'update';
    this.stateService.toggleWaitingPage(true);
    this.bucketNotificationService
      .createOrUpdateBucketDefinition(definition, this.isNew)
      .subscribe({
        next: (res) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success(`Bucket definition ${action}d`, 'Success');
          this.router.navigate(['/notification-buckets', res.id]);
          this.evalCanLaunch(res);
        },
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(`Unable to ${action} bucket definition`, 'Error');
        },
      });
  }

  navigateToNotificationBucket(notificationBucket: BucketNotification) {
    this.router.navigate([
      '/notification-buckets',
      notificationBucket.bucketDefinitionId,
      notificationBucket.id,
    ]);
  }

  navigateToNewNotificationBucket(definition: BucketDefinition) {
    this.router.navigate(['/notification-buckets', definition.id, 'new']);
  }

  onSelectType(definition) {
    if (this.bucketFunctions.has(definition.type)) return;
    this.bucketFunctions$ = this.bucketNotificationService
      .listBucketFunctions(definition.type)
      .pipe(
        tap((bucketFunctions) => {
          this.bucketFunctions.set(definition.type, bucketFunctions);
        }),
      );
  }

  evalCanLaunch(definition: BucketDefinition) {
    this.canBeLaunched =
      definition &&
      !definition.launchDate &&
      definition.isActive &&
      !definition.isLaunched &&
      definition.type === 'campaign';
  }

  onClickLaunchCampaign(definition: BucketDefinition) {
    this.evalCanLaunch(definition);
    if (!this.canBeLaunched) {
      this.toastr.error('Unable to launch campaign', 'Error');
      return;
    }
    this.stateService.toggleWaitingPage(true);
    this.bucketNotificationService
      .countUsersForCampaign(definition.bucketFunction)
      .subscribe({
        next: (res) => this.openDialogCampaignLaunch(res, definition),
        error: (err) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error('Unable to launch campaign', 'Error');
        },
      });
  }

  private openDialogCampaignLaunch(
    count: number,
    definition: BucketDefinition,
  ) {
    this.stateService.toggleWaitingPage(false);
    const dialogRef = this.dialog.open(QuestionDialogComponent, {
      width: '583px',
      data: {
        title: 'Are you sure?',
        description: `Are you sure you want to launch campaign <b>${definition.name}</b>?<br>This campaign will be sent to <b>${count}</b> users (At the moment)`,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result !== 'yes') return;
        this.launchCampaign(definition);
      },
      error: (err) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error('Unable to launch campaign', 'Error');
      },
    });
  }

  private launchCampaign(definition: BucketDefinition) {
    this.stateService.toggleWaitingPage(true);
    this.bucketNotificationService.launchCampaign(definition.id).subscribe({
      next: (res) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.success('Campaign launched', 'Success');
        this.router.navigate(['/notification-buckets', definition.id]);
      },
      error: (err) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error(err.error, 'Error');
        console.log(err);
      },
    });
  }
}
