<div [ngClass]="cards ? 'card-list' : ''">
  <div class="list">
    @if (filterKeys) {
      <div>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="filterString"
            placeholder="Filter"
            (ngModelChange)="applyFilter()"
            />
        </mat-form-field>
      </div>
    }

    @if (data?.length > 0) {
      <div
        class="pagination"
        >
        <button
          (click)="prevPage()"
          [disabled]="pageNumber <= 1"
          >
          Prev
        </button>
        <p>{{ getPaginationString() }}</p>
        <button
          (click)="nextPage()"
          [disabled]="pageNumber >= getMaxNumPages()"
          >
          Next
        </button>
      </div>
    }

    <div [ngClass]="cards ? 'cards' : ''">
      <ng-content></ng-content>
    </div>

    @if (data?.length > 0) {
      <div
        class="back-to-top"
        >
        <p (click)="scrollToTop()">Back to top</p>
      </div>
    }
  </div>
</div>
