import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  private baseHref = `${environment.apiUrl}/api`;

  constructor(private http: HttpClient) {}

  getConversationByTaskId(taskId: number): Observable<any> {
    const url = `${this.baseHref}/admin/conversations/${taskId}`;
    return this.http.get(url);
  }

  blockConversationAsUser(
    conversationId: number,
    userId: number,
  ): Observable<any> {
    const url = `${this.baseHref}/admin/conversations/${conversationId}/${userId}/block/`;
    return this.http.get(url);
  }

  unblockConversationAsUser(
    conversationId: number,
    userId: number,
  ): Observable<any> {
    const url = `${this.baseHref}/admin/conversations/${conversationId}/${userId}/unblock/`;
    return this.http.get(url);
  }

  sendSystemWarning(offerId: number): Observable<any> {
    const url = `${this.baseHref}/admin/conversations/${offerId}/warning/`;
    return this.http.get(url);
  }
}
