import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BucketNotificationSchedule } from 'src/app/models/BucketNotificationSchedule.model';
import { BucketNotificationService } from 'src/app/services/bucket-notification.service';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
import { PaginatedList } from '../../../components/paginatedList/paginatedList.component';

@Component({
    selector: 'app-bucket-notification-schedule-list',
    templateUrl: 'bucket-notification-schedule-list.component.html',
    styleUrls: ['bucket-notification-schedule-list.component.scss'],
    imports: [...ALL_IMPORTS, PaginatedList]
})
export class BucketNotificationScheduleListComponent implements OnInit {
  @Input() definitionId: number;
  @Input() notificationId: number;
  @Input() requireChange: Subject<boolean>;

  notifications: BucketNotificationSchedule[] = [];
  displayNotifications: BucketNotificationSchedule[] = [];

  hasMore: boolean = true;
  isBusy: boolean = false;
  lastPage: number = 0;

  constructor(
    private bucketNotificationService: BucketNotificationService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.requireChange?.subscribe((res) => {
      if (res) {
        this.lastPage = 0;
        this.hasMore = true;
        this.notifications = [];
        this.displayNotifications = [];
        this.fetchMoreNotifications();
      }
    });
  }

  updateDisplayNotification(notifications: BucketNotificationSchedule[]): void {
    this.displayNotifications = notifications;
  }

  fetchMoreNotifications(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.bucketNotificationService
        .listNotificationSchedule(
          this.definitionId,
          this.notificationId,
          this.lastPage + 1,
        )
        .subscribe(
          (res) => {
            this.notifications = this.notifications.concat(
              res.bucketNotificationSchedules,
            );
            this.hasMore = res.hasMore;
            this.lastPage += 1;
          },
          (err) =>
            this.toastr.error(err.message, 'Failed to get notifications!'),
          () => (this.isBusy = false),
        );
    }
  }
}
