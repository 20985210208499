import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TaskService } from '../../services/task.service';
import { PenaltyModel } from '../../models/penalty.model';
import { AuthService } from 'src/app/services/auth.service';
import { format } from 'date-fns';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-cancel-task-list',
    templateUrl: './penaltyList.component.html',
    styleUrls: ['./penaltyList.component.scss'],
    imports: [PaginatedList, ...COMMON_IMPORTS]
})
export class PenaltyListComponent {
  penalties: PenaltyModel[] = [];
  displayPenalties: PenaltyModel[] = [];
  filterKeys: string[] = [];
  hasMore: boolean = true;

  constructor(
    private router: Router,
    private taskService: TaskService,
    private authService: AuthService,
  ) {}

  fetchPenalties(): void {
    if (this.hasMore) {
      this.taskService.getAllPenalties().subscribe(
        (res) => {
          this.penalties = res;
          this.hasMore = false;
        },
        (err) =>
          this.authService.handleError(err, 'Failed to get all penalties!'),
      );
    }
  }

  updateDisplayPenalties(penalties: PenaltyModel[]): void {
    this.displayPenalties = penalties;
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), 'dd-MM-yyyy');
  }
}
