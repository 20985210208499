@if (conflictCase$ | async; as conflictCase) {
  <div
    class="conflictCaseContainer"
    >
    <div class="conflictInformation">
      <!-- Area where the conflict case information is-->
      <h1>Conflict Case Overview</h1>
      <mat-divider></mat-divider>
      <h2>Conflict Case ID: {{ conflictCase?.id }}</h2>
      <h3>Task ID: {{ conflictCase?.taskId }}</h3>
      <h4>Handyhand.dk/platform/konflikt/{{ conflictCase?.accessId }}</h4>
      <div style="display: flex; flex-direction: row; margin-bottom: 2%">
        <h4 style="margin-right: 10px">Case status:</h4>
        <h4
          class="conflictCaseStatus"
          [ngClass]="conflictCase.status"
          >
          {{ conflictCase.status }}
        </h4>
      </div>
      <mat-form-field>
        <mat-label>Deadline</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [(value)]="conflictCase.deadline"
          (dateInput)="setDeadline($event, conflictCase)"
        [disabled]="
          conflictCase.status === 'closed' ||
          conflictCase.status === 'cancelled'
        "
          />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-divider></mat-divider>
      <!-- Area where the task that the case is about is-->
      <div class="taskCard">
        @if (conflictCase.offer?.task) {
          <app-task-card
            [task]="conflictCase.offer?.task"
          ></app-task-card>
        }
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="conflictParties">
      <!-- Area where the TO evidence and responses are-->
      <div class="taskOwnerArea">
        <h2>Task Owner</h2>
        <div class="userCard">
          @if (taskOwner$ | async; as taskOwner) {
            <app-user-card
              [title]="'Task Owner'"
              [user]="taskOwner"
            ></app-user-card>
          }
        </div>
        <!-- TO images-->
        <div
          class="userDescriptionArea"
          #taskOwnerDescriptionArea
          >
          @if (conflictCase.evidenceTO) {
            <p
              class="evidence-upload-date-text"
              >
              Evidence uploaded at:
              {{ conflictCase.evidenceTO?.createdAt | date : 'dd/MM/yyyy HH:mm' }}
            </p>
          }
          @if (!conflictCase.evidenceTO) {
            <p
              class="evidence-upload-date-text"
              >
              No evidence uploaded yet.
              <button
                class="pinploy_button small_button"
                (click)="uploadConflictCaseMedia('to')"
                >
                Upload for Task Owner.
              </button>
            </p>
          }
          <br />
          <mat-form-field
            class="width90"
            appearance="outline"
            >
            <mat-label>Task Owner's side of the case</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              value="{{ conflictCase.evidenceTO?.description }}"
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="10"
              cdkAutosizeMaxRows="50"
              [disabled]="true"
              >
            </textarea>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <!-- TO images-->
        <div
          class="userImagesArea"
          #taskOwnerImagesArea
          >
          <h3>Task Owner Images</h3>
          <div class="user_images_grid">
            @if (
              !conflictCase.evidenceTO ||
              (conflictCase.evidenceTO.images.length === 0 &&
              conflictCase.evidenceTO.docs.length === 0)
              ) {
              No evidence images or docs
            }
            @if (conflictCase.evidenceTO) {
              @for (image of conflictCase.evidenceTO.images; track image) {
                <app-image-preview
                  [imageUrl]="image.url"
                ></app-image-preview>
              }
              @for (doc of conflictCase.evidenceTO.docs; track doc) {
                <app-image-preview
                  [pdfUrl]="doc.url"
                ></app-image-preview>
              }
            }
          </div>
          @if (conflictCase.evidenceTO) {
            <button
              (click)="handleDownloadEvidence('to')"
              class="pinploy_button small"
              >
              Download as Zip
            </button>
          }
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <mat-divider class="phone-divider"></mat-divider>
      <!-- Area where the HH evidence and responses are-->
      <div class="handyhanderArea">
        <h2>Handyhander</h2>
        <div class="userCard">
          @if (handyhander$ | async; as handyhander) {
            <app-user-card
              [title]="'Handyhander'"
              [user]="handyhander"
            ></app-user-card>
          }
        </div>
        <!-- HH side of the story-->
        <div
          class="userDescriptionArea"
          #handyhanderDescriptionArea
          >
          @if (conflictCase.evidenceHH) {
            <p
              class="evidence-upload-date-text"
              >
              Evidence uploaded at:
              {{ conflictCase.evidenceHH?.createdAt | date : 'dd/MM/yyyy HH:mm' }}
            </p>
          }
          @if (!conflictCase.evidenceHH) {
            <p
              class="evidence-upload-date-text"
              >
              No evidence uploaded yet.
              <button
                class="pinploy_button small_button"
                (click)="uploadConflictCaseMedia('hh')"
                >
                Upload for Handyhander.
              </button>
            </p>
          }
          <br />
          <mat-form-field
            class="width90"
            appearance="outline"
            >
            <mat-label>Handyhander's side of the case</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              value="{{ conflictCase.evidenceHH?.description }}"
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="10"
              cdkAutosizeMaxRows="10"
              [disabled]="true"
              >
            </textarea>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <!-- HH images-->
        <div
          class="userImagesArea"
          #handyhanderImagesArea
          >
          <h3>Handyhander Images</h3>
          <div class="user_images_grid">
            @if (
              !conflictCase.evidenceHH ||
              (conflictCase.evidenceHH.images.length === 0 &&
              conflictCase.evidenceHH.docs.length === 0)
              ) {
              No evidence images or docs
            }
            @if (conflictCase.evidenceHH) {
              @for (image of conflictCase.evidenceHH.images; track image) {
                <app-image-preview
                  [imageUrl]="image.url"
                ></app-image-preview>
              }
              @for (doc of conflictCase.evidenceHH.docs; track doc) {
                <app-image-preview
                  [pdfUrl]="doc.url"
                ></app-image-preview>
              }
            }
          </div>
          @if (conflictCase.evidenceHH) {
            <button
              (click)="handleDownloadEvidence('hh')"
              class="pinploy_button small"
              >
              Download as Zip
            </button>
          }
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Area where the admin can enter the third party comment-->
    <div class="adminInputFields">
      <div class="thirdPartyCommentArea">
        <h3>Third Party Comment</h3>
        <mat-form-field
          class="width90"
          appearance="outline"
          >
          <mat-label>Third Party Comment</mat-label>
          <textarea
            matInput
            class="thirdPartyCommentInput"
            [(ngModel)]="conflictCase.thirdPartyComment"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="10"
            cdkAutosizeMaxRows="10"
          [disabled]="
            conflictCase.status === 'closed' ||
            conflictCase.status === 'cancelled'
          "
            (change)="saveThirdPartyComment(conflictCase)"
            >
          </textarea>
        </mat-form-field>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="adminCommentArea">
        <h3>
          <mat-checkbox
            labelPosition="before"
            [(ngModel)]="includeAdminComment"
            color="primary"
            >
            Admin Comment
          </mat-checkbox>
        </h3>
        <mat-form-field
          class="width90"
          appearance="outline"
          >
          <mat-label>Admin Comment</mat-label>
          <textarea
            matInput
            class="adminCommentInput"
            [(ngModel)]="conflictCase.adminComment"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="10"
            cdkAutosizeMaxRows="10"
          [disabled]="
            !includeAdminComment ||
            conflictCase.status === 'closed' ||
            conflictCase.status === 'cancelled'
          "
            (change)="saveAdminComment(conflictCase)"
            >
          </textarea>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="submitArea">
      <div class="textBoxRow">
        <p>Total amount : {{ conflictCase.totalAmount }} DKK</p>
        <div class="percentageArea">
          <mat-form-field appearance="outline">
            <mat-label>Task Owner's Percentage</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="conflictCase.toHHpercentage"
              [disabled]="true"
              min="0"
              max="100"
              />
          </mat-form-field>
          <p class="unit">%</p>
          <mat-form-field appearance="outline">
            <mat-label>Handyhander's Percentage</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="conflictCase.toHHpercentage"
            [disabled]="
              conflictCase.status === 'closed' ||
              conflictCase.status === 'cancelled'
            "
              (change)="setHHpercentage(conflictCase)"
              min="0"
              max="100"
              />
          </mat-form-field>
        </div>
        <div class="amountArea">
          <mat-form-field appearance="outline">
            <mat-label>Task Owner's refund amount</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="conflictCase.refundAmountTO"
            [disabled]="
              conflictCase.status === 'closed' ||
              conflictCase.status === 'cancelled'
            "
              (change)="setTORefundAmount(conflictCase)"
              />
          </mat-form-field>
          <p class="unit">DKK</p>
          <mat-form-field appearance="outline">
            <mat-label>Handyhander's amount</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="conflictCase.refundAmountHH"
            [disabled]="
              conflictCase.status === 'closed' ||
              conflictCase.status === 'cancelled'
            "
              (change)="setHHRefundAmount(conflictCase)"
              />
          </mat-form-field>
        </div>
      </div>
      <div class="buttonRow">
        @if (
          conflictCase.status !== 'closed' &&
          conflictCase.status !== 'cancelled'
          ) {
          <button
            class="pinploy_button red_button"
            (click)="cancelConflictCase(conflictCase.id)"
            >
            Cancel conflict case
          </button>
        }
        @if (
          conflictCase.status !== 'closed' &&
          conflictCase.status !== 'cancelled'
          ) {
          <button
            class="pinploy_button"
            (click)="closeConflictCase(conflictCase.id)"
            [disabled]="conflictCase.status !== 'pending-decision'"
            >
            Close conflict case
          </button>
        }
        @if (conflictCase.status == 'cancelled') {
          <button
            class="pinploy_button"
            (click)="reopenConflictCase(conflictCase.id)"
            >
            Reopen conflict case
          </button>
        }
      </div>
    </div>
  </div>
}
