<div class="dialog">
  <div class="dialog_header">
    <h2>
      Chose a period to give user: {{ userId }} a grace period from
      @if (type == 'ban') {
        <span>ban</span>
      }
      @if (type == 'handyhander-limitations') {
        <span>limitations</span>
      }
    </h2>
  </div>

  <div class="dialog_body">
    <mat-form-field style="max-width: 30%">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Choose a date"
        [formControl]="untilDate"
        [min]="minDate"
        disabled
        />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        disabled="false"
      ></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="dialog_buttons">
    <button
      class="pinploy_button red_button"
      (click)="close('no')"
      >
      Cancel
    </button>
    <button
      class="pinploy_button"
      [disabled]="isGraceBtnDisabled()"
      (click)="giveGrace()"
      [ngClass]="{ disabled: isGraceBtnDisabled() }"
      >
      Give grace period
    </button>
  </div>
</div>
