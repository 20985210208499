<div class="support-admin-panel">
  <mat-sidenav-container
    autosize
    style="height: 100%"
  >
    <mat-sidenav-content style="display: flex; overflow: hidden">
      <!-- Ticket List -->
      <div class="ticket-list">
        <div class="tab_bar_container">
          <!-- Tab Bar -->
          <div>
            <mat-tab-group
              dynamicHeight
              (selectedTabChange)="onTabChange($event)"
              [(selectedIndex)]="selectedTabIndex"
            >
              <mat-tab
                #openStatusTab
                label="Open"
              >
                <ng-template mat-tab-label>
                  <span>Open</span>
                </ng-template></mat-tab
              >
              <mat-tab label="Closed"></mat-tab>
            </mat-tab-group>
          </div>
          <!-- Add filter toggle and banner -->
          <div
            class="filter-section"
            [ngStyle]="{
              'background-color': hideExpiredReminders
                ? '#e57373'
                : 'transparent',
            }"
          >
            <mat-slide-toggle
              [checked]="hideExpiredReminders"
              (change)="toggleHideExpiredReminders()"
              color="primary"
            >
              Hide reminders
            </mat-slide-toggle>
          </div>
        </div>

        <!-- TODO: ticket / ticket list component -->
        <div
          #supportTicketListContainer
          (scroll)="onSupportTicketListScroll($event)"
          class="list-container"
        >
          <ul>
            @for (ticket of filteredTickets; track $index) {
              <!-- TODO: <app-support-ticket-v2 /> -->
              <li
                style="position: relative"
                class="ticket"
                [class.selected]="ticket === selectedTicket"
                [class.unresolved]="ticket.status === 'unresolved'"
                (click)="onTicketListClick($event, ticket)"
              >
                <div class="ticket-header">
                  <div class="indicators-wrapper">
                    <p
                      [class.status-unresolved]="ticket.status === 'unresolved'"
                      [class.status-resolved]="ticket.status === 'resolved'"
                      [class.status-closed]="ticket.status === 'closed'"
                      class="status_ticket"
                    >
                      {{ ticket.status | uppercase }}
                    </p>
                    @if (ticket?.overdueReminders?.length > 0) {
                      <div
                        class="overdue-indicator"
                        [matTooltip]="
                          ticket.overdueReminders.length +
                          ' overdue reminder(s)'
                        "
                      >
                        <mat-icon class="warning-icon">warning</mat-icon>
                        <span class="overdue-count">{{
                          ticket.overdueReminders.length
                        }}</span>
                      </div>
                    }
                    <app-users-currently-viewing
                      [usersCurrentlyViewing]="ticket.viewedBy"
                      class="compact-view"
                    />
                  </div>
                </div>
                <div class="ticket-content">
                  <p>
                    @if (ticket?.user) {
                      <b>
                        {{ ticket.user.email }}
                      </b>
                    } @else {
                      <b>
                        {{ ticket.email || 'Unknown User' }}
                      </b>
                    }
                  </p>
                  <p>
                    <b>{{ ticket.mainConcern }}</b>
                  </p>
                  <p>{{ ticket.createdAt | date }}</p>
                </div>
              </li>
            } @empty {}
          </ul>
        </div>

        @if (loading) {
          <div class="loading-spinner"></div>
        }
      </div>
      <!-- TODO: ticket messages component? -->
      <!-- Conversation Thread -->
      <div class="conversation-thread">
        <div
          class="messages_container"
          #selectedTicketMessagesContainer
        >
          @if (selectedTicket) {
            @if (selectedTicket?.overdueReminders?.length > 0) {
              <div class="overdue-reminders-banner">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <h3 style="color: #d32f2f">Overdue Reminders</h3>
                    </mat-panel-title>
                    <mat-panel-description>
                      <mat-icon>warning</mat-icon>
                      <span
                        >{{ selectedTicket.overdueReminders.length }} overdue
                        reminder(s)</span
                      >
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <div class="reminders-list">
                      @for (
                        reminder of selectedTicket.overdueReminders;
                        track $index
                      ) {
                        <div class="reminder-item">
                          <div
                            class="reminder-content"
                            (click)="openReminderDialog(reminder)"
                          >
                            <p class="reminder-note">{{ reminder?.note }}</p>
                            <div class="reminder-meta">
                              <span>By: {{ reminder?.user?.firstName }}</span>
                              <span>Due: {{ reminder?.expiresAt | date }}</span>
                            </div>
                          </div>
                          <mat-checkbox
                            [checked]="reminder.completedAt"
                            [color]="'warn'"
                            (change)="
                              completeSupportTicketReminder($event, reminder.id)
                            "
                            #reminderCheckbox
                          ></mat-checkbox>
                        </div>
                      }
                    </div>
                  </ng-template>
                </mat-expansion-panel>
              </div>
            }
            <!-- Add main concern header before messages -->
            <div class="main-concern-header">
              <h2>{{ selectedTicket.mainConcern }}</h2>
              <span class="created-at">{{
                selectedTicket.createdAt | date: 'medium'
              }}</span>
            </div>

            @for (message of selectedTicket?.messages; track $index) {
              <!-- <app-support-ticket-message /> -->
              <div
                class="message_container"
                [class.user-message]="
                  !message?.createdBy ||
                  message?.createdBy === selectedTicket.userId
                "
              >
                <div class="user-bubble">
                  @if (!message?.createdBy) {
                    <mat-icon [matTooltip]="selectedTicket?.email"
                      >person</mat-icon
                    >
                  } @else if (message?.createdBy === selectedTicket.userId) {
                    <mat-icon [matTooltip]="selectedTicket?.user?.firstName"
                      >person</mat-icon
                    >
                  } @else {
                    <mat-icon matTooltip="Handyhand Support"
                      >support_agent</mat-icon
                    >
                  }
                </div>
                <div class="message">
                  <div class="header">
                    @if (!message.createdBy) {
                      <i>{{ selectedTicket?.email }}</i>
                    } @else if (message?.createdBy === selectedTicket.userId) {
                      <i>{{ selectedTicket?.user?.firstName }}</i>
                    } @else {
                      <i>{{ message?.user?.firstName }}</i>
                    }
                    <p class="time_stamp">
                      {{ message.createdAt | date: 'd/M/yy, H:mm' }}
                    </p>
                  </div>
                  <mat-divider />
                  <div
                    class="message-text"
                    [innerHTML]="message.text | safeHtml"
                  >
                    <!-- {{ message.text }} -->
                  </div>
                  @if (
                    message?.images?.length > 0 || message?.docs?.length > 0
                  ) {
                    <mat-divider></mat-divider>
                    <app-media-grid
                      [images]="message.images"
                      [docs]="message.docs"
                    ></app-media-grid>
                  }
                  @if (message?.videos?.length > 0) {
                    <mat-divider></mat-divider>
                    <div class="video-section">
                      <button
                        mat-button
                        class="video-toggle"
                        (click)="message.showVideos = !message.showVideos"
                      >
                        <mat-icon>{{
                          message.showVideos ? 'expand_less' : 'expand_more'
                        }}</mat-icon>
                        Videos ({{ message.videos.length }})
                      </button>
                      @if (message.showVideos) {
                        <div class="video-container">
                          @for (video of message?.videos; track $index) {
                            @if (video.failedAt) {
                              <p>Video upload or processing failed</p>
                            } @else if (
                              video.completedAt && video.cloudfrontUrl
                            ) {
                              <div class="video-wrapper">
                                <video controls>
                                  <source [src]="video.cloudfrontUrl" />
                                </video>
                              </div>
                            } @else {
                              <p>
                                Video '{{ getFileName(video) }}' is processing
                              </p>
                            }
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            } @empty {
              <div class="message_container">wow such empty</div>
            }
          }
        </div>
        @if (messageForm.get('images').value || messageForm.get('docs').value) {
          <app-media-grid
            [images]="messageForm.get('images').value"
            [docs]="messageForm.get('docs').value"
          ></app-media-grid>
        }
        <div class="reply-box">
          <div
            #editorToolbar
            id="quill-toolbar"
            class="quill-toolbar"
          >
            <div class="custom-buttons-wrapper">
              <div class="wide">
                <span class="ql-formats">
                  <select class="ql-header"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                </span>
                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                  ></button>
                  <button
                    class="ql-list"
                    value="bullet"
                  ></button>
                  <button
                    class="ql-indent"
                    value="-1"
                  ></button>
                  <button
                    class="ql-indent"
                    value="+1"
                  ></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-link"></button>
                </span>
                <span class="ql-formats">
                  <button
                    (click)="imagesPicker.click()"
                    id="imageButton"
                  >
                    <svg viewBox="0 0 18 18">
                      <rect
                        class="ql-stroke"
                        height="10"
                        width="12"
                        x="3"
                        y="4"
                      ></rect>
                      <circle
                        class="ql-fill"
                        cx="6"
                        cy="7"
                        r="1"
                      ></circle>
                      <polyline
                        class="ql-even ql-fill"
                        points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
                      ></polyline>
                    </svg>
                  </button>
                  <button
                    (click)="docsPicker.click()"
                    id="docButton"
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#000000"
                    >
                      <g
                        id="SVGRepo_bgCarrier"
                        stroke-width="0"
                      ></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fill="#000000"
                          d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm320 512V448h64v128h128v64H544v128h-64V640H352v-64h128z"
                        ></path>
                      </g>
                    </svg>
                  </button>
                </span>
                <span class="ql-formats">
                  <button (click)="openTemplateSettings()">
                    <svg
                      viewBox="0 0 50 50"
                      width="18"
                      height="18"
                    >
                      <path
                        class="ql-fill"
                        d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z"
                      ></path>
                    </svg>
                  </button>
                </span>
              </div>

              <div class="wide">
                <div class="buttons-narrow">
                  <span class="ql-formats">
                    <div
                      (click)="openSmartResponses()"
                      class="custom-buttons"
                    >
                      <mat-icon>star</mat-icon>
                      <button
                        id="smart-response"
                        class="button"
                      >
                        Smart responses
                      </button>
                    </div>
                  </span>
                </div>

                <div class="narrow">
                  <span class="ql-formats">
                    <div
                      (click)="sendMessage(selectedTicket.id)"
                      class="custom-buttons"
                    >
                      <mat-icon>send</mat-icon>
                      <button class="button">
                        <p>Send</p>
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            #editor
            class="html-editor"
          ></div>
          <input
            accept="image/*"
            multiple
            style="display: none"
            type="file"
            #imagesPicker
            (change)="onImagesPicked($event)"
          />
          <input
            accept=".doc,.docx,.pdf"
            multiple
            style="display: none"
            type="file"
            #docsPicker
            (change)="onDocumentsPicked($event)"
          />
        </div>
      </div>
      <!-- Toggle Button Container -->
      <div class="toggle-wrapper">
        <button
          matTooltip="Ctrl + B to toggle"
          class="toggle-button"
          (click)="sidenav.toggle()"
        >
          <mat-icon>{{
            detailsSidebarOpen ? 'chevron_right' : 'chevron_left'
          }}</mat-icon>
        </button>
      </div>
    </mat-sidenav-content>
    <mat-sidenav
      #sidenav
      mode="side"
      [(opened)]="detailsSidebarOpen"
      position="end"
    >
      @if (selectedTicket) {
        <!-- Ticket Details -->
        <div class="ticket-details">
          <mat-tab-group>
            <!-- Details Tab -->
            <mat-tab label="Details">
              <div class="details-tab">
                <div class="ticket-information-cards">
                  <app-support-ticket-card
                    [ticket]="selectedTicket"
                  ></app-support-ticket-card>

                  @if (selectedTicket.user) {
                    <app-user-card
                      [title]="'User'"
                      [user]="selectedTicket.user"
                      [inclStripeCustomer]="true"
                      [inclStripeConnect]="true"
                    ></app-user-card>
                  } @else {
                    <div class="detail_sub">
                      <div>
                        <h4>User</h4>
                      </div>
                      <p>No user associated with this ticket.</p>
                      <mat-form-field>
                        <input
                          #userIdInput
                          matInput
                          placeholder="Enter user ID"
                          type="text"
                        />
                      </mat-form-field>
                      <button
                        [class.disabled]="!userIdInput.value"
                        [disabled]="!userIdInput.value"
                        class="pinploy_button"
                        (click)="onConnectUserToTicket(userIdInput.value)"
                      >
                        Connect ticket to user
                      </button>
                    </div>
                  }
                  @if (selectedTicket.task) {
                    <app-task-card
                      [task]="selectedTicket.task"
                      [task]
                    ></app-task-card>
                  }
                </div>
              </div>
            </mat-tab>

            <!-- Actions Tab -->
            <mat-tab label="Actions">
              <div class="details-tab">
                <div class="actions-container">
                  <mat-list>
                    <mat-list-item>
                      <button
                        mat-button
                        [disabled]="!selectedTicket?.userId"
                        (click)="openStoredCreditsDialog()"
                      >
                        <mat-icon>savings</mat-icon>
                        <span>Stored Credits</span>
                      </button>
                    </mat-list-item>

                    <mat-list-item>
                      <button
                        mat-button
                        [disabled]="
                          selectedTicket?.user?.storedCreditsBalance <= 0
                        "
                        (click)="openWithdrawStoredCreditsDialog()"
                      >
                        <mat-icon>money_off</mat-icon>
                        <span>Withdraw Stored Credits</span>
                      </button>
                    </mat-list-item>

                    <mat-list-item>
                      <button
                        mat-button
                        [disabled]="!selectedTicket?.userId"
                        (click)="openBansOverviewDialog()"
                      >
                        <mat-icon>gavel</mat-icon>
                        <span>Bans</span>
                      </button>
                    </mat-list-item>

                    <mat-list-item>
                      <button
                        mat-button
                        [disabled]="!selectedTicket?.userId"
                        (click)="openNotesOverviewDialog()"
                      >
                        <mat-icon>sticky_note_2</mat-icon>
                        <span>Notes</span>
                      </button>
                    </mat-list-item>

                    <mat-list-item>
                      <button
                        mat-button
                        [disabled]="!userHasPenalties"
                        (click)="openPenaltiesDialog()"
                      >
                        <mat-icon>error</mat-icon>
                        <span>Penalties</span>
                      </button>
                    </mat-list-item>

                    @if (selectedTicket?.status === 'unresolved') {
                      <mat-list-item>
                        <button
                          mat-button
                          (click)="markAsResolved(selectedTicket.id)"
                        >
                          <mat-icon class="success-icon">check_circle</mat-icon>
                          <span>Mark as Resolved</span>
                        </button>
                      </mat-list-item>
                    }

                    <mat-list-item>
                      <button
                        mat-button
                        [disabled]="selectedTicket.status === 'closed'"
                        (click)="closeTicket(selectedTicket.id)"
                      >
                        <mat-icon class="danger-icon">cancel</mat-icon>
                        <span class="danger-text">Close ticket</span>
                      </button>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
            </mat-tab>

            <!-- Tickets Tab (User's other tickets) -->
            <mat-tab label="Tickets">
              <div class="details-tab">
                @for (
                  ticket of selectedTicket?.user?.supportTickets;
                  track $index
                ) {
                  <div class="ticket-item">
                    <div class="ticket-content">
                      <div class="status">
                        <p
                          class="status_ticket"
                          [class.status-unresolved]="
                            ticket.status === 'unresolved'
                          "
                          [class.status-resolved]="ticket.status === 'resolved'"
                          [class.status-closed]="ticket.status === 'closed'"
                        >
                          {{ ticket.status | uppercase }}
                        </p>
                      </div>
                      <p class="main-concern">
                        <b>{{ ticket.mainConcern }}</b>
                      </p>
                      <p>
                        {{ ticket?.messages?.at(-1)?.text || '' | striphtml }}
                      </p>
                      <p>{{ ticket.createdAt | date }}</p>
                    </div>
                    @if (ticket.status !== 'Close') {
                      <div>
                        <a
                          [routerLink]="['.']"
                          target="_blank"
                          [queryParams]="{ uid: ticket.uid }"
                        >
                          <mat-icon>open_in_new</mat-icon>
                        </a>
                      </div>
                    } @else {
                      <div>
                        <p>Ticket from old system.</p>
                        <p>No longer accessible.</p>
                      </div>
                    }
                  </div>
                } @empty {
                  <p>No tickets found.</p>
                }
              </div>
            </mat-tab>

            <!-- TODO: reminders comp -->
            <!-- Reminders Tab -->
            <mat-tab label="Reminders">
              <ng-template mat-tab-label>
                <span
                  [matBadge]="selectedTicket?.overdueReminders?.length || null"
                  matBadgeOverlap="false"
                  matBadgeColor="warn"
                  >Reminders</span
                >
              </ng-template>
              <div class="details-tab">
                <div class="reminder-container">
                  <h3>New reminder</h3>
                  <form
                    [formGroup]="reminderForm"
                    (ngSubmit)="createReminder()"
                  >
                    <textarea
                      formControlName="note"
                      placeholder="What you write will be visible to all your colleagues."
                    ></textarea>

                    <div class="input-group">
                      <input
                        formControlName="expiresAt"
                        type="date"
                        placeholder="dd-mm-yyyy"
                      />
                    </div>
                    <button
                      type="submit"
                      [disabled]="!reminderForm.valid"
                      class="pinploy_button"
                      [ngClass]="{ disabled: !reminderForm.valid }"
                    >
                      Set reminder
                    </button>
                  </form>
                </div>
                @if (selectedTicket?.reminders?.length > 0) {
                  <div>
                    <h4>All reminders</h4>
                    <ul style="list-style: none">
                      @for (
                        reminder of selectedTicket.reminders;
                        track $index
                      ) {
                        <li>
                          <div
                            class="reminder"
                            [class.overdue]="isReminderOverdue(reminder)"
                          >
                            <div class="name_checkbox">
                              <div class="content">
                                <div>
                                  <mat-checkbox
                                    [color]="'primary'"
                                    [checked]="reminder?.completedAt"
                                    (change)="
                                      completeSupportTicketReminder(
                                        $event,
                                        reminder.id
                                      )
                                    "
                                  >
                                  </mat-checkbox>
                                </div>
                                <div>
                                  <p>
                                    Created by: {{ reminder?.user?.firstName }}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    Expires:
                                    {{
                                      reminder?.expiresAt | date: 'dd/MM/YYYY'
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style="cursor: pointer"
                              (click)="openReminderDialog(reminder)"
                            >
                              <p
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ reminder?.note }}
                              </p>
                              <a style="color: #009ac6">Read more</a>
                            </div>
                          </div>
                        </li>
                      }
                    </ul>
                  </div>
                } @else {
                  <p>No reminders.</p>
                }
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      }
    </mat-sidenav>
  </mat-sidenav-container>
</div>
