<div
  class="navbar_container transition"
  [ngClass]="{
    hidden: hovered === false,
  }"
  (mouseenter)="hover()"
  (mouseleave)="unhover()"
  >
  @for (item of items; track item; let i = $index) {
    <div>
      <div
        class="nav_item"
        [ngClass]="{ active: activeTab === item.name, hidden: true }"
        (click)="goTo(item.route)"
        >
        <div class="name_and_icon">
          <h4>
            <mat-icon>{{ item.icon }}</mat-icon>
            <p
            [ngClass]="{
              shown: hovered === true,
              not_shown: hovered === false,
            }"
              >
              {{ item.name }}
            </p>
          </h4>
        </div>
      </div>
    </div>
  }
</div>
