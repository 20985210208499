import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';

@logClass()
@Injectable()
export class StoredCreditsService {
  baseHref = `${environment.apiUrl}/api/stored-credits`;

  constructor(private http: HttpClient) {}

  getBalance(userId: number): Observable<any> {
    const url = `${this.baseHref}/balance/${userId}`;
    return this.http.get<any>(url);
  }

  getStoredCredits(userId: number): Observable<any> {
    const url = `${this.baseHref}/get-stored-credits/${userId}`;
    return this.http.get<any>(url);
  }

  getAllStoredCreditEntriesForUser(userId: number): Observable<any> {
    const url = `${this.baseHref}/get-all-stored-credit-entries/${userId}`;
    return this.http.get<any>(url);
  }

  withdrawCredits(userId, credits: number[]): Observable<any> {
    const url = `${this.baseHref}/withdraw`;
    const data = {
      userId: userId,
      creditIds: credits,
    };
    return this.http.post<any>(url, data);
  }

  getPriceReduction(offerId: number): Observable<any> {
    const url = `${this.baseHref}/price-reduction-credit/${offerId}`;
    return this.http.get<any>(url);
  }

  releaseRetainedDebit(creditId: number): Observable<any> {
    const url = `${this.baseHref}/release-retained-debit/${creditId}`;
    return this.http.get<any>(url);
  }
}
