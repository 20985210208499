<div class="dialog_container">
  <i
    class="material-icons exit_icon"
    (click)="close()"
    >close</i
    >
    <div
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-content: center;
      align-items: center;
    "
      >
      <h2>Upload document</h2>

      <div class="evidence-image-upload-area">
        @if (imageMediaUploaded.length < 1) {
          <div
            class="add__image__container"
            (click)="uploader1.click()"
            >
            <input
              #uploader1
              type="file"
              (change)="uploadFile($event)"
              accept="{{ acceptedFileTypes }}"
              style="display: none"
              />
            <mat-icon>add</mat-icon>
          </div>
        }
        @for (media of imageMediaUploaded; track media; let i = $index) {
          <div>
            <div class="evidence-images">
              @if (isImageFile(media.contentType)) {
                <img
                  loading="lazy"
                  [src]="media.location"
                  (click)="openImageDialog(media)"
                  class="animated fadeIn"
                  />
              }
              @if (!isImageFile(media.contentType)) {
                <div
                  class="pdf-image"
                  (click)="openDoc(media)"
                  >
                  <mat-icon>picture_as_pdf</mat-icon>
                </div>
              }
              <ng-container>
                <div class="image_overlay"></div>
                <mat-icon
                  (click)="deleteImage(media)"
                  class="notranslate"
                  >close</mat-icon
                  >
                </ng-container>
              </div>
            </div>
          }
        </div>

        <div class="buttons">
          <button
            class="pinploy_button small_button"
            [ngClass]="{ disabled: imageMediaUploaded.length < 1 }"
            [disabled]="imageMediaUploaded.length < 1"
            (click)="upload()"
            >
            Upload
          </button>
        </div>
      </div>
    </div>
