import { User } from './user.model';
import { Task } from './task.model';

export class Comment {
  id: number;
  text: string;
  status: string;

  userId: number;
  user: User;

  taskId: number;
  task: Task;

  createdAt: string;
  updatedAt: string;
  parentId: number;
  censored: Date;
  images: any[];

  constructor(data: any = null) {
    Object.assign(this, data);

    if (!this.user) { this.user = new User(); }
    if (!this.task) { this.task = new Task(); }
  }
}
