<paginated-list
  [data]="notifications"
  [filterKeys]="['id', 'title', 'segment', 'success']"
  (displayDataChanged)="updateDisplayNotification($event)"
  (dataRequested)="fetchMoreNotifications()"
  [moreDataAvailable]="hasMore"
  >
  <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>User id</th>
        <th>Scheduled at</th>
        <th>Created</th>
        <th>Updated</th>
      </tr>
    </thead>
    <tbody>
      @if (
        displayNotifications && displayNotifications.length > 0) {
        @for (notification of displayNotifications; track notification) {
          <tr>
            <td>{{ notification.id }}</td>
            <td>{{ notification.userId }}</td>
            <td>{{ notification.scheduledAt | date : "dd/MM/yyyy HH:mm" }}</td>
            <td>{{ notification.createdAt | date : "dd/MM/yyyy HH:mm" }}</td>
            <td>{{ notification.updatedAt | date : "dd/MM/yyyy HH:mm" }}</td>
          </tr>
        }
      } @else {
        <tr>
          <td colspan="5">
            <div class="no_data">
              <mat-icon>info</mat-icon>
              <p>No data available</p>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
</paginated-list>

