import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserPushNotificationService {
  constructor() {}

  async askPermission() {
    await Notification.requestPermission((result) => {
      return result;
    });
  }

  createNotification(title: string, message: string, action: () => void) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, {
        body: message,
        icon: '../favicon.ico',
      });
      notification.onclick = action;
    } else {
      this.askPermission();
    }
  }
}
