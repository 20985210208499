<div class="wrapper">
  <h2 style="text-align: center">User side of the conflict.</h2>

  <div class="evidence-story-upload-area">
    <mat-form-field class="example-full-width">
      <mat-label>User side of the conflict</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="10"
        cdkAutosizeMaxRows="15"
        placeholder="The conflict is about..."
        [(ngModel)]="conflictStory"
      ></textarea>
    </mat-form-field>
    @if (conflictStory.length < 25) {
      <p
        style="color: red"
        >
        Minimum {{ conflictStory.length }} / 25
      </p>
    }
    @if (conflictStory.length >= 25) {
      <p
        style="color: green"
        >
        Minimum reached!
      </p>
    }
  </div>

  <h3 style="text-align: center">Upload images</h3>
  <i style="text-align: center">Maximum 10 images / PDFs</i>
  <div class="evidence-image-upload-area">
    <div
      class="add__image__container"
      (click)="uploader1.click()"
      >
      <input
        #uploader1
        type="file"
        (change)="uploadConflictImages($event)"
        accept=".jpg, .jpeg, .png, .dng, .heic, .hevc, .webp, .pdf"
        multiple
        style="display: none"
        />
      <mat-icon>add</mat-icon>
    </div>
    @for (media of imageMediaUploaded; track media; let i = $index) {
      <div>
        <div class="evidence-images">
          @if (isImageFile(media.contentType)) {
            <img
              loading="lazy"
              [src]="media.location"
              (click)="openImageDialog(media)"
              class="animated fadeIn"
              />
          }
          @if (!isImageFile(media.contentType)) {
            <div
              class="pdf-image"
              (click)="openDoc(media)"
              >
              <mat-icon>picture_as_pdf</mat-icon>
            </div>
          }
          <ng-container>
            <div class="image_overlay"></div>
            <mat-icon
              (click)="deleteImage(media)"
              class="notranslate"
              >close</mat-icon
              >
            </ng-container>
          </div>
        </div>
      }
    </div>
    <div class="buttons">
      <button
        class="pinploy_button small_button red_button"
        (click)="close()"
        >
        Cancel
      </button>
      <button
        class="pinploy_button small_button"
        (click)="upload()"
        [disabled]="conflictStory.length < 25"
        [ngClass]="{ disabled: conflictStory.length < 25 }"
        >
        Upload
      </button>
    </div>
  </div>
