<!--Start of wrapper div-->
<div>
  @if (offers) {
    <div class="task-conversation-container">
      @if (offers.length != 0 && taskCommentsExist) {
        <div id="title">
          <br />
          <h3>Task Conversations</h3>
        </div>
      }
    </div>
  }
  <!--Offer and offer comments area-->
  @if (offers) {
    <div class="task-conversation-container">
      @if (offers.length != 0) {
        <div class="offers-container">
          <h3 class="padding">Offers</h3>
          @for (offer of offers; track offer) {
            <div
              #offerElements
              id="{{ 'offer' + offer.id }}"
              [ngClass]="{ 'winning-plus-OPI': offer.offerPriceIncreases }"
            >
              @if (offer?.offerPriceIncreases) {
                <mat-expansion-panel
                  [ngClass]="{
                    offerPriceIncrease: true,
                  }"
                  hideToggle
                >
                  @for (opi of offer.offerPriceIncreases; track opi) {
                    <mat-expansion-panel-header
                      collapsedHeight="fit-content"
                      expandedHeight="fit-content"
                      [ngClass]="{
                        'individual-win':
                          opi.status == 'accepted' || opi.status == 'completed',
                        'individual-lost':
                          opi.status == 'rejected' || opi.status == 'canceled',
                      }"
                    >
                      <mat-panel-title>
                        <div class="panel-title">
                          <span class="bold OPI-Info"
                            >Price Increase ID:
                            <p class="OPI-Info">{{ opi.id }}</p>
                          </span>
                          <span class="bold OPI-Info splitter">|</span>
                          <span class="bold OPI-Info"
                            >Status:
                            <p class="OPI-Info">{{ opi.status }}</p>
                          </span>
                          <span class="bold OPI-Info splitter">|</span>
                          <span class="bold OPI-Info"
                            >Created at:
                            <p class="OPI-Info">
                              {{ opi.createdAt | date: 'dd/MM/yyyy HH:MM' }}
                            </p></span
                          >
                          <span class="bold OPI-Info splitter">|</span>
                          <span class="bold OPI-Info"
                            >PriceBid:
                            <p class="OPI-Info">{{ opi.priceBid }}</p>
                          </span>
                        </div>
                        <div class="panel-description">
                          {{ opi.message }}
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                  }
                </mat-expansion-panel>
              }
              <mat-expansion-panel
                [ngClass]="{
                  cancelledOffer:
                    offer.status == 'canceled' || offer.status == 'disputed',
                  winningOffer:
                    offer.status == 'accepted' || offer.status == 'completed',
                  censoredAcceptedOffer:
                    offer.censored !== null &&
                    (offer.status == 'accepted' || offer.status == 'completed'),
                  censoredOffer:
                    offer.censored !== null &&
                    !(
                      offer.status == 'accepted' || offer.status == 'completed'
                    ),
                  deletedOffer: offer.status == 'erased',
                  searchedFor: offer.id == inputTypeId && inputType == 'offer',
                }"
                [expanded]="
                  (inputType == 'offer' && offer.id == inputTypeId) ||
                  (inputType == 'offer-comment' &&
                    getOfferIdFromOfferCommentId(inputTypeId) == offer.id)
                "
              >
                @if (users) {
                  <mat-expansion-panel-header
                    collapsedHeight="fit-content"
                    expandedHeight="fit-content"
                  >
                    <mat-panel-title>
                      <div class="panel-title">
                        <span
                          ><b>OfferID: </b
                          ><a
                            class="link"
                            routerLink="{{ '/offers/' + offer.id }}"
                            >{{ offer.id }}</a
                          ></span
                        >
                        <span class="bold OPI-Info splitter">|</span>
                        @if (users && users[offer.userId]?.firstName) {
                          <span>
                            <b>Sent by: </b
                            ><a
                              class="link"
                              routerLink="{{ '/users/' + offer.userId }}"
                              >{{ users[offer.userId]?.firstName }}</a
                            ></span
                          >
                        }
                        @if (users && !users[offer.userId]?.firstName) {
                          <span>
                            <b>Sent by: </b
                            ><a
                              class="link deleted-user"
                              routerLink="{{ '/users/' + offer.userId }}"
                              >DELETED USER</a
                            ></span
                          >
                        }
                        <span class="bold OPI-Info splitter">|</span>
                        <span>
                          <b>Created at: </b
                          >{{
                            offer?.createdAt | date: 'dd/MM/yyyy HH:mm'
                          }}</span
                        >
                        <span class="bold OPI-Info splitter">|</span>
                        <span>
                          <b>Expires at: </b>
                          @if (offer?.expiresAt) {
                            {{ offer?.expiresAt | date: 'dd/MM/yyyy HH:mm' }}
                          } @else {
                            <span>No expiration</span>
                          }
                        </span>
                        <span class="bold OPI-Info splitter">|</span>
                        @if (offer?.id && offer?.userId) {
                          <span
                            class="link"
                            (click)="
                              openNegotiaton(offer?.id, offer?.userId, $event)
                            "
                            >Date negotiation</span
                          >
                          <span class="bold OPI-Info splitter">|</span>
                        }
                        <span class="bold"> PriceBid: </span>
                        <p>
                          {{ offer?.priceBid }} + (Fee:
                          {{ offer?.taskOwnerFee / 100 }}) kr.
                        </p>
                        <span class="bold OPI-Info splitter">|</span>
                        <span
                          [ngClass]="{
                            bold: '',
                            'erased-offer': offer?.status == 'erased',
                          }"
                        >
                          Status: {{ offer?.status }}</span
                        >
                        <span class="bold OPI-Info splitter">|</span>
                        @if (offer.status == 'accepted') {
                          <span>
                            Completion request:
                            {{
                              task?.lastCompletionRequest
                                ? (task?.lastCompletionRequest
                                  | date: 'dd/MM/yyyy')
                                : 'null'
                            }}
                          </span>
                        }
                        @if (offer.status == 'accepted') {
                          <span class="bold OPI-Info splitter">|</span>
                        }
                        <span>Offer level: {{ offer.tierData.tier }}</span>
                        <span class="bold OPI-Info splitter">|</span>
                        <span class="bold">
                          Censored:
                          {{
                            offer?.censored !== null ? 'true' : 'false'
                          }}</span
                        >
                        <span class="bold OPI-Info splitter">|</span>
                        <span
                          class="link"
                          matTooltip="Shortcut: CMD / CTRL + click"
                          (click)="openCensor('offer', offer?.id, $event)"
                          >EDIT</span
                        >
                        <span class="bold OPI-Info splitter">|</span>
                        <span
                          class="banLink"
                          (click)="
                            openBanUserDialog(offer?.userId, 'offer', offer?.id)
                          "
                          >BAN</span
                        >
                      </div>
                      <div class="panel-description">
                        <p style="white-space: break-spaces">
                          {{ offer?.message }}
                        </p>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                }
                @for (comment of offerComments[offer?.id]; track comment) {
                  <div
                    class="comment"
                    id="{{ 'offerComment' + comment?.id }}"
                    [ngClass]="{
                      searchedFor:
                        comment?.id == inputTypeId &&
                        inputType == 'offer-comment',
                    }"
                  >
                    <p
                      class="comment-header"
                      [ngClass]="addHtmlClass(comment, offer)"
                    >
                      <span class="bold">Id: {{ comment?.id }} </span> |
                      <span class="bold">Sent by: </span>
                      @if (
                        users &&
                        comment?.userId &&
                        users[comment?.userId] &&
                        users[comment?.userId]?.firstName
                      ) {
                        <a
                          class="link"
                          routerLink="{{ '/users/' + comment?.userId }}"
                        >
                          {{ users[comment?.userId]?.firstName }}
                          {{ users[comment?.userId]?.lastName }}
                        </a>
                      }
                      |
                      @if (
                        users &&
                        comment?.userId &&
                        users[comment?.userId] &&
                        !users[comment?.userId]?.firstName
                      ) {
                        <a
                          class="link deleted-user"
                          routerLink="{{ '/users/' + comment?.userId }}"
                        ></a>
                      }
                      |
                      <span class="bold"> at </span>
                      {{ convertTimestamp(comment?.createdAt) }} |
                      <span class="bold"> Censored: </span
                      >{{ comment?.censored ? 'true' : 'false' }} |
                      <span
                        class="link"
                        (click)="
                          openCensor('offer-comment', comment?.id, $event)
                        "
                        >Edit
                      </span>
                      <span
                        class="banLink"
                        (click)="
                          openBanUserDialog(
                            comment?.userId,
                            'offer_comment',
                            comment?.id
                          )
                        "
                        >BAN</span
                      >
                    </p>
                    <div
                      class="comment-content"
                      [ngClass]="addHtmlClass(comment)"
                    >
                      <p>{{ comment?.text }}</p>
                      @if (comment?.images && comment?.images.length > 0) {
                        <div>
                          @for (img of comment?.images; track img) {
                            <app-image-preview
                              [imageUrl]="img.url"
                              [fullImageObject]="img"
                              [medium]="true"
                              class="support-img"
                              (deleteImageEvent)="deleteImage(img.id)"
                            ></app-image-preview>
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
                @if (offer?.status === 'pending') {
                  <div class="admin-comment">
                    <button
                      class="pinploy_button small_button"
                      (click)="openAdminOfferComment('offer', offer)"
                    >
                      Send message
                    </button>
                  </div>
                }
              </mat-expansion-panel>
            </div>
          }
        </div>
      }
    </div>
  }

  <!--Taskcomments and subcomments area-->
  @if (taskCommentsExist) {
    <div class="task-comments-container">
      <h3 class="padding">Task comments</h3>
      @for (parentComment of taskComments[0]; track parentComment) {
        <div
          #taskCommentElements
          id="{{ 'thread' + parentComment?.id }}"
        >
          <mat-expansion-panel
            [ngClass]="{
              censoredComment: parentComment?.censored !== null,
              searchedFor:
                parentComment?.id == inputTypeId && inputType == 'task-comment',
            }"
            [expanded]="
              (inputType == 'task-comment' &&
                parentComment?.id == inputTypeId) ||
              (inputType == 'task-comment' &&
                getParentIdFromTaskCommentId(inputTypeId) == parentComment?.id)
            "
          >
            @if (users) {
              <mat-expansion-panel-header
                collapsedHeight="fit-content"
                expandedHeight="fit-content"
              >
                <mat-panel-title>
                  <div class="panel-title">
                    <span class="bold"
                      >Taskcomment: {{ parentComment?.id }}
                    </span>
                    <b class="splitter">|</b>
                    @if (users && users[parentComment?.userId]?.firstName) {
                      <span
                        >Sent by:
                        <a
                          class="link"
                          routerLink="{{ '/users/' + parentComment?.userId }}"
                          >{{ users[parentComment?.userId]?.firstName }}</a
                        >
                      </span>
                    }
                    @if (users && !users[parentComment?.userId]?.firstName) {
                      <span
                        >Sent by:
                        <a
                          class="link deleted-user"
                          routerLink="{{ '/users/' + parentComment?.userId }}"
                          >DELETED USER</a
                        >
                      </span>
                    }
                    <b class="splitter">|</b>
                    <span class="bold"
                      >Created at:
                      {{ convertTimestamp(parentComment?.createdAt) }}</span
                    >
                    <b class="splitter">|</b>
                    <span class="bold"
                      >Censored:
                      {{ parentComment?.censored ? 'true' : 'false' }}
                    </span>
                    <b class="splitter">|</b>
                    <span
                      class="link"
                      (click)="
                        openCensor('task-comment', parentComment?.id, $event)
                      "
                      >EDIT</span
                    >
                    <b class="splitter">|</b>
                    <span
                      class="banLink"
                      (click)="
                        openBanUserDialog(
                          parentComment?.userId,
                          'task_comment',
                          parentComment?.id
                        )
                      "
                      >BAN</span
                    >
                  </div>
                  <div class="panel-description">
                    <p style="white-space: break-spaces">
                      {{ parentComment?.text }}
                    </p>
                  </div>
                  @if (
                    parentComment?.images && parentComment?.images.length > 0
                  ) {
                    <div>
                      @for (img of parentComment?.images; track img) {
                        <app-image-preview
                          [imageUrl]="img.url"
                          [fullImageObject]="img"
                          [medium]="true"
                          class="support-img"
                          (deleteImageEvent)="deleteImage(img.id)"
                        ></app-image-preview>
                      }
                    </div>
                  }
                </mat-panel-title>
              </mat-expansion-panel-header>
            }
            @for (comment of taskComments[parentComment?.id]; track comment) {
              <div
                class="comment"
                id="{{ 'taskComment' + comment?.id }}"
                [ngClass]="{
                  searchedFor:
                    inputType == 'task-comment' && inputTypeId == comment?.id,
                }"
              >
                <p
                  class="comment-header"
                  [ngClass]="addHtmlClass(comment)"
                >
                  <span class="bold">Id: {{ comment?.id }} </span>
                  |
                  <span class="bold">Sent by </span>
                  @if (
                    users &&
                    comment?.userId &&
                    users[comment?.userId] &&
                    users[comment?.userId]?.firstName
                  ) {
                    <a
                      class="link"
                      routerLink="{{ '/users/' + comment?.userId }}"
                      >{{ users[comment?.userId]?.firstName }}
                      {{ users[comment?.userId]?.lastName }}</a
                    >
                  }
                  | at {{ convertTimestamp(comment?.createdAt) }}
                  |
                  @if (
                    users &&
                    comment?.userId &&
                    users[comment?.userId] &&
                    !users[comment?.userId]?.firstName
                  ) {
                    <a
                      class="link deleted-user"
                      routerLink="{{ '/users/' + comment?.userId }}"
                      >DELETED USER</a
                    >
                  }
                  |
                  @if (comment?.censored) {
                    <span class="bold"> Censored | </span>
                  }
                  <span
                    class="link"
                    (click)="openCensor('task-comment', comment?.id, $event)"
                    >Edit
                  </span>
                  <span
                    class="banLink"
                    (click)="
                      openBanUserDialog(
                        comment?.userId,
                        'task_comment',
                        comment?.id
                      )
                    "
                    >BAN</span
                  >
                </p>
                <div
                  class="comment-content"
                  [ngClass]="addHtmlClass(comment)"
                >
                  <p>{{ comment?.text }}</p>
                  @if (comment?.images && comment?.images.length > 0) {
                    <div>
                      @for (img of comment?.images; track img) {
                        <app-image-preview
                          [imageUrl]="img.url"
                          [fullImageObject]="img"
                          [medium]="true"
                          class="support-img"
                          (deleteImageEvent)="deleteImage(img.id)"
                        ></app-image-preview>
                      }
                    </div>
                  }
                </div>
              </div>
            }
            <div class="admin-comment">
              <button
                class="pinploy_button small_button"
                (click)="openAdminOfferComment('task-comment', parentComment)"
              >
                Send message
              </button>
            </div>
          </mat-expansion-panel>
        </div>
      }
    </div>
  }

  <!--End of wrapper div-->
</div>
