<div class="offers-container">
  <div id="controls"></div>

  <paginated-list
    [data]="offers"
    (displayDataChanged)="updateDisplayOffers($event)"
    (dataRequested)="fetchOffers()"
    [filterKeys]="filterKeys"
    [moreDataAvailable]="hasMore"
    >
    <div class="list">
      @for (offer of displayOffers; track offer) {
        <a
          class="item"
          routerLink="{{ '/offers/' + offer.id }}"
          >
          <p class="id"><span class="bold">ID: </span>{{ offer.id }}</p>
          <p>
            <span class="bold">Status: </span>{{ offer.status.toUpperCase() }}
          </p>
          <p><span class="bold">Price bid: </span>{{ offer.priceBid }}</p>
          <p class="id"><span class="bold">Task ID: </span>{{ offer.taskId }}</p>
          <p>
            <span class="bold">Name: </span>{{ getOfferUserName(offer.userId) }}
          </p>
          <p>
            <span class="bold">Email: </span>{{ getOfferUserEmail(offer.userId) }}
          </p>
        </a>
      }
    </div>
  </paginated-list>
</div>
