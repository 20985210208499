@if (insuranceApplication) {
  <div class="page_container">
    <div class="page_header">
      <div class="header_main">
        <h1>Insurance Application</h1>
        <div
          class="status_badge"
          [class]="insuranceApplication.status"
          >
          <p>{{ insuranceApplication.status }}</p>
        </div>
      </div>
      <div class="application_details">
        <p><strong>ID:</strong> {{ insuranceApplication.id }}</p>
        <p><strong>Task ID:</strong> {{ insuranceApplication.taskId }}</p>
        <p>
          <strong>Created:</strong>
          {{ insuranceApplication.createdAt | date: 'medium' }}
        </p>
        <p>
          <strong>Last updated:</strong>
          {{ insuranceApplication.updatedAt | date: 'medium' }}
        </p>
      </div>
    </div>

    <div class="page_content">
      <!-- All Information Section -->
      <div class="information_section">
        <!-- Primary Information -->
        <div class="primary_info">
          <h2 class="section_title">Primary Information</h2>
          <div class="info_grid">
            <!-- Incident Description Card -->
            <div class="info_card full_width">
              <h2>Incident Description</h2>

              <i class="description">
                "{{ insuranceApplication.incidentDescription }}"
              </i>
            </div>

            <!-- Incident documentation div under expansion panel-->
            <div class="full_width">
              <mat-expansion-panel
                class="full_width"
                [disabled]="incidentFiles.length === 0"
                >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h2>Incident Documentation ({{ incidentFiles.length }})</h2>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="images_and_button">
                  <div class="nested_grid">
                    @for (file of incidentFiles; track file) {
                      @if (file.fileType === 'image') {
                        <app-image-preview
                          [imageUrl]="file.url"
                          [fullImageObject]="file"
                          [disableEditing]="true"
                          [medium]="true"
                          >
                        </app-image-preview>
                      } @else {
                        <app-image-preview
                          [pdfUrl]="file.url"
                          [fullImageObject]="file"
                          [disableEditing]="true"
                          [medium]="true"
                          >
                        </app-image-preview>
                      }
                    }
                  </div>
                  @if (incidentFiles.length > 0) {
                    <button
                      class="pinploy_button"
                      (click)="downloadDocumentationFiles()"
                      >
                      Download
                    </button>
                  }
                </div>
              </mat-expansion-panel>
            </div>

            <!-- Estimated Repair Cost Card-->
            <mat-card>
              <mat-card-header>
                <mat-card-title>Estimated Repair Cost</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="nested_details">
                  <p>
                    {{
                    insuranceApplication.estimatedRepairCost | number: '1.2-2'
                    }}
                    DKK
                  </p>
                </div>
              </mat-card-content>
              <mat-card-footer> </mat-card-footer>
            </mat-card>

            <!-- Task Owner Details Card -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>Task Owner details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="nested_details">
                  <p>
                    <strong>Name:</strong> {{ insuranceApplication.toFullName }}
                  </p>
                  <p>
                    <strong>Email:</strong> {{ insuranceApplication.toEmail }}
                  </p>
                  <p>
                    <strong>Phone:</strong> {{ insuranceApplication.toPhone }}
                  </p>
                  <p>
                    <strong>Address:</strong>
                    {{ insuranceApplication.toAddress }}
                  </p>
                </div>
              </mat-card-content>
            </mat-card>

            <!-- Estimated Repair cost documentation div under expansion panel-->
            <div class="full_width">
              <mat-expansion-panel
                class="full_width"
                [disabled]="estimationFiles.length === 0"
                >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h2>
                      Estimated Repair Cost Documentation ({{
                      estimationFiles.length
                      }})
                    </h2>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="images_and_button">
                  <div class="nested_grid">
                    @for (file of estimationFiles; track file) {
                      @if (file.fileType === 'image') {
                        <app-image-preview
                          [imageUrl]="file.url"
                          [fullImageObject]="file"
                          [disableEditing]="true"
                          [medium]="true"
                          >
                        </app-image-preview>
                      } @else {
                        <app-image-preview
                          [pdfUrl]="file.url"
                          [fullImageObject]="file"
                          [disableEditing]="true"
                          [medium]="true"
                          >
                        </app-image-preview>
                      }
                    }
                  </div>
                  @if (estimationFiles.length > 0) {
                    <button
                      class="pinploy_button"
                      (click)="downloadEstimationFiles()"
                      >
                      Download
                    </button>
                  }
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </div>

        <!-- Secondary Information -->
        <div class="secondary_info">
          <h2 class="section_title">Additional details</h2>
          <div class="info_grid">
            <!-- Incident Details Card -->

            <mat-card>
              <mat-card-header>
                <mat-card-title>Incident details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="nested_details">
                  <p>
                    <strong>Time:</strong>
                    {{ insuranceApplication.incidentTime }}
                  </p>
                  <p>
                    <strong>Date:</strong>
                    {{ insuranceApplication.incidentDate | date: 'mediumDate' }}
                  </p>
                  <p>
                    <strong>Location:</strong>
                    {{ insuranceApplication.incidentLocation }}
                  </p>
                </div>
              </mat-card-content>
            </mat-card>

            <!-- Insurance Details Card -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>Insurance details</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="nested_details">
                  <p>
                    <strong>Reported to Own Insurance:</strong>
                    {{
                    insuranceApplication.reportedToOwnInsurance ? 'Yes' : 'No'
                    }}
                  </p>
                  <p>
                    <strong>Reference:</strong>
                    {{
                    insuranceApplication.insuranceReference || 'Not provided'
                    }}
                  </p>
                </div>
              </mat-card-content>
            </mat-card>

            <!-- Handyhander Card -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>Handyhander details </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="nested_details">
                  <p>
                    <strong>Name:</strong>
                    {{ handyhander.firstName }}
                    {{ handyhander.lastName }}
                  </p>
                  <p>
                    <strong>Email:</strong>
                    {{ handyhander.email }}
                  </p>
                  <p>
                    <strong>Phone:</strong>
                    {{ handyhander.phone }}
                  </p>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>

      <!-- Decision Form Section -->
      <div class="decision_section">
        <h2 class="section_title">Make Insurance Decision</h2>
        <div class="form_container">
          <form (ngSubmit)="onSubmitDecision()">
            <div class="form_group">
              <mat-form-field appearance="outline">
                <mat-label>Decision Amount</mat-label>
                <input
                  matInput
                  type="number"
                  [(ngModel)]="decisionAmount"
                  name="decisionAmount"
                  required
                  max="10000"
                  min="0"
                  onwheel="return false"
                  />
                @if (decisionAmount === null) {
                  <mat-error
                    >Decision amount is required</mat-error
                    >
                  }
                  @if (decisionAmount > 10000 && decisionAmount !== null) {
                    <mat-error
                      >Amount cannot exceed 10.000 DKK</mat-error
                      >
                    }
                    <div
                      matTextSuffix
                      class="currency-suffix"
                      >
                      DKK
                    </div>
                  </mat-form-field>
                </div>

                <!-- Decision Reason -->
                <div class="form_group">
                  <mat-form-field appearance="outline">
                    <mat-label>Decision Reason</mat-label>
                    <textarea
                      matInput
                      [(ngModel)]="decisionReason"
                      cdkTextareaAutosize
                      name="decisionReason"
                      class="decision_reason_textarea"
                      required
                    ></textarea>
                    @if (!decisionReason) {
                      <mat-error
                        >Decision reason is required</mat-error
                        >
                      }
                    </mat-form-field>
                  </div>

                  <!-- Submit Decision Button -->
                  @if (decisionAmount === 0 && decisionReason !== null) {
                    @if (decisionAmount === 0 && decisionReason !== null) {
                      <button
                        type="submit"
                        class="pinploy_button red red_button"
                        (click)="onSubmitDecision()"
                [disabled]="
                  decisionAmount === null ||
                  decisionReason === null ||
                  decisionAmount < 0 ||
                  decisionAmount > 10000
                "
                [class.disabled]="
                  decisionAmount === null ||
                  !decisionReason ||
                  decisionAmount < 0 ||
                  decisionAmount > 10000
                "
                        >
                        Submit decision
                      </button>
                    }
                  } @else {
                    @if (decisionAmount !== null && decisionReason !== null) {
                      <button
                        type="submit"
                        class="pinploy_button"
                [disabled]="
                  decisionAmount === null ||
                  decisionReason === null ||
                  decisionAmount < 0 ||
                  decisionAmount > 10000
                "
                [class.disabled]="
                  decisionAmount === null ||
                  !decisionReason ||
                  decisionAmount < 0 ||
                  decisionAmount > 10000
                "
                        (click)="onSubmitDecision()"
                        >
                        Submit decision
                      </button>
                    }
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      }
