@if (segments$ | async; as segments) {
  <div class="header">
    <h1>Send notification to a segment</h1>
    <button class="pinploy_button" routerLink="/notification-segments">
      Back
    </button>
  </div>
  <div class="top_container">
    <div>
      <mat-form-field appearance="fill" class="title-input">
        <mat-label>Title</mat-label>
        @if (form.get('title').invalid) {
          <mat-error>
            Title is required
          </mat-error>
        }
        <input matInput [formControl]="form.get('title')" required />
      </mat-form-field>
      <button class="pinploy_button" (click)="onClickSend()">
        Send notification
      </button>
    </div>
    <div>
      <div class="dropdown_container">
        <mat-form-field appearance="fill">
          <mat-label>Segment</mat-label>
          <mat-select [formControl]="form.get('segment')" required>
            @for (segment of segments; track segment) {
              <mat-option [value]="segment">{{
                segment
              }}</mat-option>
            }
          </mat-select>
          @if (form.get('segment').invalid) {
            <mat-error>
              Segment is required
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Language</mat-label>
          <mat-select [formControl]="form.get('language')" required>
            <mat-option selected value="userSettings">User settings</mat-option>
            <mat-option value="dk">Danish</mat-option>
            <mat-option value="en">English</mat-option>
          </mat-select>
          @if (form.get('language').invalid) {
            <mat-error>
              Language is required
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Schedule date</mat-label>
          <input
            matInput
            [formControl]="form.get('scheduledAt')"
            type="datetime-local"
            />
          <mat-hint>Empty for immediate send</mat-hint>
          @if (form.get('scheduledAt').hasError('notADate')) {
            <mat-error>
              Schedule date must be a date
            </mat-error>
          }
          @if (form.get('scheduledAt').hasError('dateInPast')) {
            <mat-error>
              Schedule date must be in the future
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="toggle_container">
        <mat-slide-toggle
          [(ngModel)]="isEmailWanted"
          (change)="onChangeWanted('email')"
          >Email</mat-slide-toggle
          >
          <mat-slide-toggle
            [(ngModel)]="isWebWanted"
            (change)="onChangeWanted('web')"
            >Web</mat-slide-toggle
            >
            <mat-slide-toggle
              [(ngModel)]="isPushWanted"
              (change)="onChangeWanted('push')"
              >Push</mat-slide-toggle
              >
              <mat-slide-toggle
                [(ngModel)]="isSmsWanted"
                (change)="onChangeWanted('sms')"
                >SMS</mat-slide-toggle
                >
              </div>
            </div>
          </div>
          <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
            <mat-tab
              label="Email"
      [labelClass]="
        tabsIsInvalid(
          form.get('emailContext.template'),
          form.get('emailContext.dk'),
          form.get('emailContext.en')
        )
          ? 'tabs_error'
          : ''
      "
              >
              @if (templates$ | async; as templates) {
                <app-notification-email-section [templates]="templates"
                  [disabled]="!isEmailWanted"
                  [contextForm]="form.get('emailContext')"
                  [choosedLanguage]="form.get('language').value"
                 />
              } @else {
                <mat-spinner />
              }
            </mat-tab>
            <mat-tab
              label="Web"
              [labelClass]="tabsIsInvalid(form.get('webContext')) ? 'tabs_error' : ''"
              >
              <app-notification-web-section [contextForm]="form.get('webContext')"
                [choosedLanguage]="form.get('language').value"
                [disabled]="!isWebWanted"
               />
            </mat-tab>
            <mat-tab
              label="Push"
              [labelClass]="tabsIsInvalid(form.get('pushContext')) ? 'tabs_error' : ''"
              >
              <app-notification-push-section [contextForm]="form.get('pushContext')"
                [choosedLanguage]="form.get('language').value"
                [disabled]="!isPushWanted"
               />
            </mat-tab>
            <mat-tab
              label="SMS"
              [labelClass]="tabsIsInvalid(form.get('smsContext')) ? 'tabs_error' : ''"
              >
              <app-notification-sms-section [contextForm]="form.get('smsContext')"
                [choosedLanguage]="form.get('language').value"
                [disabled]="!isSmsWanted"
               />
            </mat-tab>
          </mat-tab-group>
        }
