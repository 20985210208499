<div class="image_preview_container">
  <!-- IMAGE -->
  @if (imageUrl && tiny == false && medium == false) {
    <div class="doc_container">
      <img
        [src]="imageUrl"
        (click)="openImageDialog()"
        [alt]="imageUrl"
        />
    </div>
  }

  <!-- IMAGE IF SMALL -->
  @if (imageUrl && tiny == true) {
    <img
      [src]="imageUrl"
      (click)="openImageDialog()"
      [alt]="imageUrl"
      class="doc_container_tiny"
      />
  }

  <!-- IMAGE IF MEDIUM -->
  @if (imageUrl && medium == true) {
    <div class="doc_container_medium">
      <img
        [src]="imageUrl"
        (click)="openImageDialog()"
        [alt]="imageUrl"
        class="doc_container_medium"
        />
    </div>
  }

  <!-- PDF -->
  @if (!imageUrl && tiny == false && medium == false) {
    <div
      class="doc_container"
      (click)="openDoc()"
      >
      <img
        [src]="pdfImage"
        [alt]="pdfImage"
        />
    </div>
  }

  <!-- PDF IF SMALL -->
  @if (!imageUrl && tiny == true) {
    <div
      class="doc_container_tiny"
      (click)="openDoc()"
      >
      <img
        [src]="pdfImage"
        [alt]="pdfImage"
        />
    </div>
  }

  <!-- PDF IF MEDIUM -->
  @if (!imageUrl && medium == true) {
    <div
      class="doc_container_medium"
      (click)="openDoc()"
      >
      <img
        [src]="pdfImage"
        [alt]="pdfImage"
        />
    </div>
  }
</div>
