import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COMMON_IMPORTS, MATERIAL_IMPORTS } from 'src/app/shared/standalone-imports';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface MediaFile {
  url: string;
  name: string;
  type: 'image' | 'document';
}

@Component({
    selector: 'app-media-preview-dialog',
    templateUrl: './media-preview-dialog.component.html',
    styleUrls: ['./media-preview-dialog.component.scss'],
    imports: [...COMMON_IMPORTS, ...MATERIAL_IMPORTS]
})
export class MediaPreviewDialogComponent {
  safeUrl: SafeResourceUrl;
  currentIndex: number = 0;
  files: MediaFile[] = [];
  zoomLevel: number = 1;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.previousFile();
        break;
      case 'ArrowRight':
        this.nextFile();
        break;
      case 'Escape':
        this.closeDialog();
        break;
      case '+':
        this.zoomIn();
        break;
      case '-':
        this.zoomOut();
        break;
    }
  }

  @HostListener('document:dblclick', ['$event'])
  handleDoubleClick(event: MouseEvent) {
    // Check if the double click is on the image
    const target = event.target as HTMLElement;
    if (target.tagName === 'IMG') {
      this.zoomIn();
    }
  }

  constructor(
    public dialogRef: MatDialogRef<MediaPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      files: MediaFile[];
      currentIndex: number;
    },
    private sanitizer: DomSanitizer,
  ) {
    this.files = data.files;
    this.currentIndex = data.currentIndex;
    this.updateSafeUrl();
  }

  private updateSafeUrl() {
    const currentFile = this.files[this.currentIndex];
    if (currentFile.type === 'document') {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        currentFile.url,
      );
    }
  }

  get currentFile(): MediaFile {
    return this.files[this.currentIndex];
  }

  nextFile(): void {
    if (this.currentIndex < this.files.length - 1) {
      this.currentIndex++;
      this.updateSafeUrl();
    }
  }

  previousFile(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateSafeUrl();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadFile(): void {
    const file = this.currentFile;
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  zoomIn(): void {
    this.zoomLevel += 0.1;
  }

  zoomOut(): void {
    this.zoomLevel -= 0.1;
  }

  resetZoom(): void {
    this.zoomLevel = 1;
  }

  get hasPrevious(): boolean {
    return this.currentIndex > 0;
  }

  get hasNext(): boolean {
    return this.currentIndex < this.files.length - 1;
  }
}
