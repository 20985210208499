<div class="page_container">
  <div class="header">
    <h1>Notifications bucket</h1>
  </div>

  <mat-divider></mat-divider>

  <div class="create_container">
    <button class="pinploy_button" routerLink="/notification-buckets/new">
      Create new bucket
    </button>
  </div>

  <mat-divider></mat-divider>

  <paginated-list
    [data]="definitions"
    [filterKeys]="['id', 'title', 'isActive', 'type']"
    (displayDataChanged)="updateDisplayNotification($event)"
    (dataRequested)="fetchMoreNotifications()"
    [moreDataAvailable]="hasMore"
    >
    <table class="notification_table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Title</th>
          <th>Is active</th>
          <th>Type</th>
          <th>Function</th>
          <th>Created at</th>
          <th>Last update</th>
        </tr>
      </thead>
      <tbody>
        @for (definition of displayedDefinitions; track definition) {
          <tr
            (click)="navigate(definition)"
            >
            <td>{{ definition.id }}</td>
            <td>{{ definition.name }}</td>
            <td
              class="is_active_item"
              [ngClass]="{ active: definition.isActive }"
              >
              {{ definition.isActive }}
            </td>
            <td>{{ definition.type }}</td>
            <td>{{ definition.bucketFunction }}</td>
            <td>{{ definition.createdAt | date : "dd/MM/yy HH:mm" }}</td>
            <td>{{ definition.updatedAt | date : "dd/MM/yy HH:mm" }}</td>
          </tr>
        }
      </tbody>
    </table>
  </paginated-list>
</div>
