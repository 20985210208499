export class Tier {
  totalOffers: number;
  completedOffers: number;
  compRate: number;
  compRateTier: string;
  earnings: number;
  earningTier: string;
  tier: string;

  constructor(data: any = null) {
    Object.assign(this, data);
  }
}
