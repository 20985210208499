<h2 mat-dialog-title>New support ticket</h2>
<mat-dialog-content>
  <div style="display: flex; flex-direction: column; gap: 0.5rem">
    <mat-form-field>
      <mat-label
        >Select topic (determines what FAQ's will be shown to the
        user)</mat-label
        >
        <mat-select
          required
          (selectionChange)="onTopicChange($event)"
          >
          @for (cat of requestCategories; track $index) {
            <mat-option [value]="cat">{{ cat.issue }}</mat-option>
          }
        </mat-select>
        @if (supportTicketCreationData.topic === null) {
          <mat-error>
            Topic is required
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Ticket title"
          required
          [(ngModel)]="supportTicketCreationData.mainConcern"
          />
        @if (supportTicketCreationData.mainConcern === '') {
          <mat-error>
            Title is required
          </mat-error>
        }
      </mat-form-field>

      <!-- Email field (shown only when no user is specified) -->
      @if (!isUserSpecified) {
        <mat-form-field>
          <input
            matInput
            placeholder="Email address"
            required
            type="email"
            [(ngModel)]="supportTicketCreationData.email"
            />
          @if (supportTicketCreationData.email === '') {
            <mat-error>
              Email is required
            </mat-error>
          }
          @if (supportTicketCreationData.email === '') {
            <mat-error>
              Please enter a valid email address
            </mat-error>
          }
        </mat-form-field>
      }

      <!-- User info (shown when user is specified) -->
      @if (isUserSpecified && user) {
        <div class="user-info">
          <p>
            <strong>Creating ticket for:</strong> {{ user.firstName }} ({{
            user.email
            }})
          </p>
        </div>
      }

      <!-- Rich Text Editor -->
      <div class="rich-text-editor">
        <div
          #editorToolbar
          id="quill-toolbar"
          class="quill-toolbar"
          >
          <span class="ql-formats">
            <select class="ql-header"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
          <span class="ql-formats">
            <button
              class="ql-list"
              value="ordered"
            ></button>
            <button
              class="ql-list"
              value="bullet"
            ></button>
            <button
              class="ql-indent"
              value="-1"
            ></button>
            <button
              class="ql-indent"
              value="+1"
            ></button>
          </span>
          <span class="ql-formats">
            <button class="ql-link"></button>
          </span>
          <span class="ql-formats">
            <button
              (click)="imagesPicker.click()"
              id="imageButton"
              >
              <svg viewBox="0 0 18 18">
                <rect
                  class="ql-stroke"
                  height="10"
                  width="12"
                  x="3"
                  y="4"
                ></rect>
                <circle
                  class="ql-fill"
                  cx="6"
                  cy="7"
                  r="1"
                ></circle>
                <polyline
                  class="ql-even ql-fill"
                  points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
                ></polyline>
              </svg>
            </button>
            <button
              (click)="docsPicker.click()"
              id="docButton"
              >
              <svg
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                >
                <g
                  id="SVGRepo_bgCarrier"
                  stroke-width="0"
                ></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#000000"
                    d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm320 512V448h64v128h128v64H544v128h-64V640H352v-64h128z"
                  ></path>
                </g>
              </svg>
            </button>
          </span>
          <span class="ql-formats">
            <div
              class="custom-buttons"
              >
              <mat-icon>star</mat-icon>
              <button
                [class.disabled]="!supportTicketCreationData.faqTopic"
                [disabled]="!supportTicketCreationData.faqTopic"
                (click)="openSmartResponses()"
                id="smart-response"
                class="button"
                >
                Smart responses
              </button>
            </div>
          </span>
        </div>
        <div
          #editor
          class="html-editor"
        ></div>
        <input
          accept="image/*"
          multiple
          style="display: none"
          type="file"
          #imagesPicker
          (change)="onImagesPicked($event)"
          />
        <input
          accept=".doc,.docx,.pdf"
          multiple
          style="display: none"
          type="file"
          #docsPicker
          (change)="onDocumentsPicked($event)"
          />
      </div>

      <div
        #editor
        class="html-editor"
      ></div>

      <!-- File Upload Section -->
      @if (supportTicketCreationData.images?.length > 0) {
        <app-media-grid
          [images]="supportTicketCreationData.images"
          (removeImage)="removePreviewImage($event)"
        ></app-media-grid>
      }
      @if (supportTicketCreationData.docs?.length > 0) {
        <app-media-grid
          [docs]="supportTicketCreationData.docs"
          (removeDoc)="removePreviewDoc($event)"
        ></app-media-grid>
      }

      <!-- Upload Buttons -->
      <div class="file-buttons">
        <button
          mat-stroked-button
          (click)="imagesPicker.click()"
          type="button"
          >
          <mat-icon>image</mat-icon>
          Add Images
        </button>
        <button
          mat-stroked-button
          (click)="docsPicker.click()"
          type="button"
          >
          <mat-icon>file_present</mat-icon>
          Add Documents
        </button>
      </div>

      <input
        accept="image/*"
        multiple
        style="display: none"
        type="file"
        #imagesPicker
        (change)="onImagesPicked($event)"
        />
      <input
        accept=".doc,.docx,.pdf"
        multiple
        style="display: none"
        type="file"
        #docsPicker
        (change)="onDocumentsPicked($event)"
        />

      @if (user?.tasks && user.tasks.length > 0) {
        <mat-form-field>
          <mat-label>Associate task</mat-label>
          <mat-select
            placeholder="None chosen"
            [(ngModel)]="supportTicketCreationData.taskId"
            >
            <mat-option [value]="null">None</mat-option>
            @for (task of user.tasks; track $index) {
              <mat-option [value]="task.id">
                ID: {{ task.id }} | {{ task.title }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      color="warn"
      mat-raised-button
      (click)="dialogRef.close()"
      >
      Cancel
    </button>
    <button
      color="primary"
      mat-raised-button
      (click)="createSupportTicket()"
      >
      Create
    </button>
  </mat-dialog-actions>
