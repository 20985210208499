<mat-card [ngClass]="{ deleted: note.deletedAt != undefined || null }">
  @if (note) {
    <div
      class="card_header"
      >
      <h5 class="strong">Id: {{ note.id }}</h5>
      <h6>Note written by admin: {{ note.admin.firstName }}</h6>
    </div>
  }
  <div class="card_body">
    @if (note) {
      <div
        class="card_row"
        >
        <p class="strong">Note</p>
        <p [innerHTML]="note.comment | linkify"></p>
      </div>
    }
    @if (note.deletedAt) {
      <div
        class="card_row"
        >
        <p class="strong">Deleted at:</p>
        <p>{{ formatDate(note.deletedAt) }}</p>
      </div>
    }
  </div>
  @if (!note.deletedAt) {
    <div
      class="button-container"
      >
      <button
        (click)="deleteNote()"
        class="pinploy_button small_button red_button"
        >
        Delete
      </button>
    </div>
  }
</mat-card>
