import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CancelTask } from '../../../models/cancelTask.model';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from '../../../services/offer.service';
import { TaskService } from '../../../services/task.service';
import { CancelTaskAutomation } from 'src/app/models/cancelTaskAutomation';
import { Task } from 'src/app/models/task.model';
import { Offer } from 'src/app/models/offer.model';
import { StateService } from 'src/app/services/state.service';
import { MiscService } from 'src/app/services/misc.service';
import { TaskCardComponent } from '../../../components/cards/task-card/task-card.component';
import { UserCardComponent } from '../../../components/cards/user-card/user-card.component';
import { ImagePreviewComponent } from '../../../components/image-preview/image-preview.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-cancel-task',
    templateUrl: './cancelTask.component.html',
    styleUrls: ['./cancelTask.component.scss'],
    imports: [
        ...ALL_IMPORTS,
        ImagePreviewComponent,
        UserCardComponent,
        TaskCardComponent,
    ]
})
export class CancelTaskComponent implements OnInit {
  cancelTask: CancelTask;
  cancelTaskChanges: CancelTask;
  cancelTaskAuto: CancelTaskAutomation;
  task: Task;
  ppUserId;
  offerPriceInc;
  offerPriceIncChanges;
  clickedResolve: boolean = false;
  offer: Offer;

  hhReportImages: any[] = [];

  constructor(
    private miscService: MiscService,
    private stateService: StateService,
    private taskService: TaskService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private offerService: OfferService,
  ) {
    this.offerPriceIncChanges = {
      refundType: 'AppFee',
      customFee: 0,
    };
  }

  ngOnInit() {
    this.cancelTask = new CancelTask();
    this.cancelTaskChanges = { ...this.cancelTask };
    this.fetchCancelTaskDataSource();
  }

  fetchCancelTaskDataSource() {
    this.stateService.toggleWaitingPage(true);
    this.route.params.subscribe((params) => {
      this.taskService.getCancelTask(params.id).subscribe(
        (res) => {
          this.cancelTask = res;
          this.cancelTaskChanges = { ...this.cancelTask };
          this.fetchOfferAndTaskDetail(this.cancelTask.offerId);
          this.getTaskById(this.cancelTask.taskId);
          this.fetchOfferPriceInc(this.cancelTask.offerId);
          this.taskService.getCancelTaskAuto(params.id).subscribe((res) => {
            this.cancelTaskAuto = res;
            if (this.cancelTaskAuto.hhReport) {
              this.miscService
                .getMediaByResourceAndId('cancel_task', this.cancelTask.id)
                .subscribe((res) => {
                  this.hhReportImages = res;
                });
            }
          });
          this.stateService.toggleWaitingPage(false);
        },
        (err) => {
          this.taskService.handleError(
            err,
            'Error fetching Cancel single task',
          );
          this.stateService.toggleWaitingPage(false);
        },
      );
    });
  }

  fetchOfferAndTaskDetail(offerId: number) {
    this.offerService.getOfferById(offerId).subscribe((res) => {
      this.cancelTask.chargeId = res.stripeChargeId;
      this.cancelTask.priceBid = res.priceBid;
      this.ppUserId = res.userId;
      this.offer = res;
    });
  }

  getTaskById(taskId: number) {
    this.taskService.getTaskById(taskId).subscribe((res) => {
      this.cancelTask.taskTitle = res.title;
      this.task = res;
    });
  }

  fetchOfferPriceInc(offerId) {
    this.offerService.getPriceIncOffer(offerId).subscribe((res) => {
      if (res) {
        this.offerPriceInc = res;
      }
    });
  }

  isBtnDisabled(): boolean {
    for (const property in this.cancelTask) {
      if (this.cancelTask[property] !== this.cancelTaskChanges[property]) {
        return false;
      }
    }
    return true;
  }

  goToPinployee() {
    this.router.navigate([`/users/${this.ppUserId}`]);
  }

  removeUnchangesProperties() {
    for (const property in this.cancelTask) {
      if (this.cancelTask[property] === this.cancelTaskChanges[property]) {
        delete this.cancelTask[property];
      } else {
        this.cancelTaskChanges[property] = this.cancelTask[property];
      }
    }
  }

  saveChanges() {
    this.removeUnchangesProperties();
    this.cancelTaskChanges.customFee = parseInt(
      String(this.cancelTaskChanges.customFee),
      10,
    );
    const validated = this.validateDisplayInfo();

    if (!this.cancelTaskChanges.ppConsent) {
      this.toastr.warning('Must select if PP or To have consent');
    }
    if (validated) {
      this.taskService
        .editCancelTask(this.cancelTaskChanges.id, this.cancelTaskChanges)
        .subscribe(
          (res) => {
            if (res && res.status === 'canceled') {
              this.toastr.success(`Task refunded with ${res.refundType}`);
              // call stripe with amount here
            }
          },
          (err) => {
            this.taskService.handleError(err, 'Can´t save CancelTask');
          },
        );
    }
  }

  validateDisplayInfo() {
    if (this.cancelTaskChanges.status === 'pending') {
      this.toastr.info(
        'Nothing affected , status must set to other than pending',
      );
      return false;
    }
    if (this.cancelTaskChanges.refundType === 'CustomFee') {
      if (
        Number.isNaN(this.cancelTaskChanges.customFee) ||
        this.cancelTaskChanges.customFee < 1 ||
        this.cancelTaskChanges.customFee > 99
      ) {
        this.toastr.warning('Custom fee must be in range 1-99% and a number');
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  /*refundPriceIncreaseOffer() {
    const data = {
      refundType: '',
    };
    this.taskService.refundAndReversalTransferPriceInc(data).subscribe(res => {
      if (res) {
        this.toastr.success('Offer Price Inc refunded');
      }
    });
  }*/

  resolveConflict() {
    this.clickedResolve = true;
    this.taskService
      .resolveConflict({
        taskId: this.task.id,
        cancelTaskId: this.cancelTask.id,
        cancelTaskAutoId: this.cancelTaskAuto.id,
        feeOwner: this.cancelTaskAuto.feeOwner,
      })
      .subscribe((res) => {
        this.taskService
          .getCancelTaskAuto(this.cancelTask.id)
          .subscribe((res) => {
            if (res.status === 'closed') {
              this.toastr.success('Cancellation complete');
              this.router.navigateByUrl('/cancel-task');
            } else {
              this.toastr.error('Cancellation failed');
              this.clickedResolve = false;
            }
          });
      });
  }
}
