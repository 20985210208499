import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BanService } from '../../services/ban.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { isBefore, format } from 'date-fns';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'ban-list',
    templateUrl: './banList.component.html',
    styleUrls: ['./banList.component.scss'],
    imports: [...COMMON_IMPORTS, MatSlideToggleModule, PaginatedList]
})
export class BanListComponent {
  bans: any[] = [];
  filteredBans: any[] = [];
  displayBans: any[] = [];
  users: User[] = [];
  activeBansOnly: boolean = true;
  filterKeys: string[] = ['userId'];
  hasMore: boolean = true;

  constructor(
    private authService: AuthService,
    private banService: BanService,
    private userService: UserService,
  ) {}

  fetchBans(): void {
    if (this.hasMore) {
      this.banService.getAllBans().subscribe(
        (res) => {
          this.bans = res.map((user) => user.bans).flat();
          this.hasMore = false;
          this.filterActiveBans(this.activeBansOnly);
        },
        (err) => this.authService.handleError(err, 'Failed to get all bans!'),
      );
    }
  }

  updateDisplayBans(bans: any[]): void {
    this.displayBans = bans;
    const userIds: number[] = Array.from(
      new Set(bans.map((ban) => ban.userId)),
    );
    if (userIds.length > 0) {
      this.userService.getMultipleUsers(userIds).subscribe(
        (res) => (this.users = res),
        (err) => this.authService.handleError(err, 'Failed to get users!'),
      );
    }
  }

  isActive(ban: any) {
    return !ban.cancelled && isBefore(new Date(), new Date(ban.bannedUntil));
  }

  convertTimestamp(ts: string): string {
    return format(new Date(ts), 'dd-MM-yyyy');
  }

  filterActiveBans(x: boolean): void {
    this.activeBansOnly = x;
    if (x) {
      this.filteredBans = this.bans.filter((ban) => this.isActive(ban));
    } else {
      this.filteredBans = this.bans.slice();
    }
  }
}
