import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Offer } from 'src/app/models/offer.model';
import { Task } from 'src/app/models/task.model';
import { UserService } from 'src/app/services/user.service';
import { OfferService } from 'src/app/services/offer.service';
import { StateService } from 'src/app/services/state.service';
import {
  MatDatepickerInputEvent,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth.service';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TaskCardComponent } from '../../cards/task-card/task-card.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

interface options {
  value: string;
  viewValue: string;
}
@Component({
    selector: 'user-active-offers-dialog',
    templateUrl: 'userActiveOffers.component.html',
    styleUrls: ['../dialogs.scss', 'userActiveOffers.component.scss'],
    imports: [...ALL_IMPORTS, TaskCardComponent]
})
export class UserActiveOffersDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('picker') datePicker: MatDateRangePicker<Date>; // Add ViewChild for date picker
  dataSource = new MatTableDataSource<Offer>();

  constructor(
    public userService: UserService,
    public offerService: OfferService,
    public stateService: StateService,
    public matDialog: MatDialog,
    public authService: AuthService,
    public dialogRef: MatDialogRef<UserActiveOffersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.userId === undefined) {
      throw new Error('No user id passed to user active offers dialog');
    }
  }

  statusOptions: options[] = [
    { value: null, viewValue: 'All' },
    { value: 'accepted', viewValue: 'Accepted' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'canceled', viewValue: 'Cancelled' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'erased', viewValue: 'Erased' },
  ];

  userActiveOffers = [];
  paginatedOffers = [];
  searchHasMore = true;
  lastPage = 0;
  isBusy = false;

  pageSize = 5;
  pageIndex = 0;
  pageEvent: PageEvent;

  searchStartDate: any = null;
  searchEndDate: any = null;
  searchQuery = '';
  searchStatus = null;

  ngOnInit(): void {
    this.searchOffers();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  searchOffers(click?: boolean): void {
    if (click) {
      this.lastPage = 0;
      this.userActiveOffers = [];
      this.searchHasMore = true;
      this.pageIndex = 0;
      this.paginator.firstPage();
    }
    if (this.data.userId && !this.isBusy && this.searchHasMore) {
      this.isBusy = true;
      this.offerService
        .getAllOffersFiltered(
          this.data.userId,
          this.lastPage + 1,
          this.searchStatus === 'canceled'
            ? ['canceled', 'disputed']
            : this.searchStatus,
          { start: this.searchStartDate, end: this.searchEndDate },
          this.searchQuery,
        )
        .subscribe({
          next: (res: any) => {
            if (this.searchHasMore && this.userActiveOffers.length) {
              this.userActiveOffers = this.userActiveOffers.concat(res.data);
            } else if (
              this.searchHasMore &&
              this.userActiveOffers.length === 0
            ) {
              this.userActiveOffers = res.data;
            } else if (
              !this.searchHasMore &&
              this.userActiveOffers.length === 0
            ) {
              this.userActiveOffers = res.data;
            }
            this.lastPage += 1;
            this.searchHasMore = res.hasMore;
            this.dataSource.data = this.userActiveOffers;
            this.updatePaginatedOffers();
          },
          error: (err) => {
            this.authService.handleError(err, 'Failed to get user offers!');
          },
          complete: () => {
            this.isBusy = false;
          },
        });
    }
  }

  onPageChange(event: PageEvent): void {
    if (this.searchHasMore && this.paginator.hasNextPage() === false) {
      this.searchOffers();
    }

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.updatePaginatedOffers();
  }

  updatePaginatedOffers(): void {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedOffers = this.userActiveOffers.slice(startIndex, endIndex);
  }

  searchQueryChange(event): void {
    this.searchQuery = event;
  }
  searchStartDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.searchStartDate = event.value;
  }
  searchEndDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.searchEndDate = event.value;
  }
  selectedStatusChange(event): void {
    this.searchStatus = event.value;
  }

  resetFilter(): void {
    this.searchQuery = '';
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.searchStatus = null;
    this.datePicker.select(null);
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }

  closeDialogEvent() {
    this.close();
  }
}
