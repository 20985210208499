import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BucketDefinition } from 'src/app/models/BucketDefinition.model';
import { BucketNotificationService } from 'src/app/services/bucket-notification.service';
import { PaginatedList } from '../../components/paginatedList/paginatedList.component';
import { COMMON_IMPORTS } from 'src/app/shared/standalone-imports';
import { MatDividerModule } from '@angular/material/divider';
@Component({
    selector: 'app-notification-bucket',
    templateUrl: 'notification-bucket.component.html',
    styleUrls: ['notification-bucket.component.scss'],
    imports: [...COMMON_IMPORTS, PaginatedList, MatDividerModule]
})
export class NotificationBucketComponent implements OnInit {
  definitions: BucketDefinition[] = [];
  displayedDefinitions: BucketDefinition[] = [];

  hasMore: boolean = true;
  isBusy: boolean = false;
  lastPage: number = 0;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bucketNotificationervice: BucketNotificationService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // ngAfterViewInit() {
  //   @ViewChild("iframe") iframe: ElementRef;
  //   let doc =
  //     this.iframe.nativeElement.contentDocument ||
  //     this.iframe.nativeElement.contentWindow;
  //   doc.open();
  //   doc.write('');
  //   doc.close();
  // }

  updateDisplayNotification(notifications: BucketDefinition[]): void {
    this.displayedDefinitions = notifications;
  }

  fetchMoreNotifications(): void {
    if (!this.isBusy && this.hasMore) {
      this.isBusy = true;
      this.bucketNotificationervice
        .listBucketDefinitions(this.lastPage + 1)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            this.definitions = this.definitions.concat(res.bucketDefinitions);
            this.hasMore = res.hasMore;
            this.lastPage += 1;
          },
          error: (err) =>
            this.toastr.error(err.message, 'Failed to get notifications!'),
          complete: () => (this.isBusy = false),
        });
    }
  }

  navigate(definition: BucketDefinition): void {
    this.router.navigate(['/notification-buckets', definition.id]);
  }
}
