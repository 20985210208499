import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { first } from "rxjs/operators";
import { NotificationSegmentScheduledListComponent } from "./notificationSegmentScheduledList/notification-segment-scheduled-list.component";
import { NotificationSegmentListComponent } from "./notificationSegmentList/notification-segment-list.component";
import { MatTabGroup, MatTab, MatTabContent } from "@angular/material/tabs";

@Component({
    selector: "app-notification-segment",
    templateUrl: "notificationSegment.component.html",
    styleUrls: ["notificationSegment.component.scss"],
    imports: [
        RouterLink,
        MatTabGroup,
        MatTab,
        MatTabContent,
        NotificationSegmentListComponent,
        NotificationSegmentScheduledListComponent,
    ]
})
export class NotificationSegmentComponent implements OnInit {
  selectedIndex: number | null = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      if ("tabs" in params) {
        if (params.tabs === "1") {
          this.selectedIndex = 1;
        } else {
          this.selectedIndex = 0;
        }
      }
    });
  }

  changeSelectedIndex(index: number): void {
    this.selectedIndex = index;
    this.router.navigate([], {
      queryParams: { tabs: index },
    });
  }
}
