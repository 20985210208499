import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConflictCase } from 'src/app/models/conflictCase.model';
import { User } from 'src/app/models/user.model';
import { ConflictService } from 'src/app/services/conflict.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MiscService } from 'src/app/services/misc.service';
import { UploadConflictCaseMediaDialogComponent } from 'src/app/components/dialogs/uploadConflictCaseMediaDialog/uploadConflictCaseMediaDialog.component';
import { MatDialog } from '@angular/material/dialog';
import saveAs from 'file-saver';
import { ImagePreviewComponent } from '../../../components/image-preview/image-preview.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { UserCardComponent } from '../../../components/cards/user-card/user-card.component';
import { TaskCardComponent } from '../../../components/cards/task-card/task-card.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';
@Component({
    selector: 'app-conflict-case',
    templateUrl: './conflictCase.component.html',
    styleUrls: ['./conflictCase.component.scss'],
    imports: [
        ...ALL_IMPORTS,
        CdkTextareaAutosize,
        TaskCardComponent,
        UserCardComponent,
        ImagePreviewComponent,
    ]
})
export class ConflictCaseComponent implements OnInit, AfterViewInit {
  @ViewChild('taskOwnerDescriptionArea') taskOwnerDescriptionArea: ElementRef;
  @ViewChild('handyhanderDescriptionArea')
  handyhanderDescriptionArea: ElementRef;
  @ViewChild('taskOwnerImagesArea') taskOwnerImagesArea: ElementRef;
  @ViewChild('handyhanderImagesArea') handyhanderImagesArea: ElementRef;

  conflictCase$: Observable<ConflictCase>;
  conflictCase?: ConflictCase;
  taskOwner$: Observable<User>;
  taskOwner?: User;
  handyhander$: Observable<User>;
  handyhander?: User;
  includeAdminComment: boolean = false;

  toImageMedia: any[] = [];
  toData: any[] = [];
  hhImageMedia: any[] = [];
  hhData: any[] = [];

  private _taskOwnerFetched: boolean = false;
  private _handyhanderFetched: boolean = false;

  constructor(
    private miscService: MiscService,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private conflictService: ConflictService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.fetchData();
  }

  ngAfterViewInit(): void {
    this.syncHeight();
  }

  syncHeight(): void {
    setTimeout(() => {
      const taskOwnerImagesAreaHeight =
        this.taskOwnerImagesArea.nativeElement.clientHeight;
      const handyhanderImagesAreaHeight =
        this.handyhanderImagesArea.nativeElement.clientHeight;
      const imagesMaxHeight = Math.max(
        taskOwnerImagesAreaHeight,
        handyhanderImagesAreaHeight,
      );
      this.renderer.setStyle(
        this.taskOwnerImagesArea.nativeElement,
        'height',
        imagesMaxHeight,
      );
      this.renderer.setStyle(
        this.handyhanderImagesArea.nativeElement,
        'height',
        imagesMaxHeight,
      );

      const taskOwnerDescriptionArea =
        this.taskOwnerDescriptionArea.nativeElement.clientHeight;
      const handyhanderDescriptionArea =
        this.handyhanderDescriptionArea.nativeElement.clientHeight;
      const descriptionMaxHeight =
        Math.max(taskOwnerDescriptionArea, handyhanderDescriptionArea) + 'px';
      this.renderer.setStyle(
        this.taskOwnerDescriptionArea.nativeElement,
        'height',
        descriptionMaxHeight,
      );
      this.renderer.setStyle(
        this.handyhanderDescriptionArea.nativeElement,
        'height',
        descriptionMaxHeight,
      );
    }, 1000);
  }

  fetchData(): void {
    this.route.params.subscribe(
      (params) => {
        this.stateService.toggleWaitingPage(true);
        this.conflictCase$ = this.conflictService
          .getConflictCase(params.id)
          .pipe(
            tap((res) => {
              this.conflictCase = res;
              this.taskOwner$ = this.userService
                .getUserProfile(res.taskOwnerId)
                .pipe(
                  tap((res) => {
                    this.taskOwner = res;
                    this._taskOwnerFetched = true;
                    this.stateService.toggleWaitingPage(
                      !this._handyhanderFetched,
                    );
                  }),
                );
              this.handyhander$ = this.userService
                .getUserProfile(res.handyhanderId)
                .pipe(
                  tap((res) => {
                    this.handyhander = res;
                    this._handyhanderFetched = true;
                    this.stateService.toggleWaitingPage(
                      !this._taskOwnerFetched,
                    );
                    this.syncHeight();
                  }),
                );
            }),
            catchError((error) => {
              this.toastr.error(error.error, 'Error');
              this.router.navigate(['/conflict-cases']);
              return throwError(error);
            }),
          );
      },
      (error) => {
        this.toastr.error(error.error, 'Error');
        this.router.navigate(['/conflict-cases']);
      },
    );
  }

  saveThirdPartyComment(conflictCase: ConflictCase): void {
    if (
      !conflictCase.thirdPartyComment ||
      conflictCase.thirdPartyComment.trim() === ''
    ) {
      this.toastr.error('Comment cannot be empty', 'Error');
      return;
    }
    this.stateService.toggleWaitingPage(true);

    this.conflictService
      .setThirdPartyComment(
        conflictCase.id,
        conflictCase.thirdPartyComment.trim(),
      )
      .subscribe(
        (res) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success('Comment saved successfully', 'Success');
        },
        (error) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(error.error.message, 'Error');
        },
      );
  }

  saveAdminComment(conflictCase: ConflictCase): void {
    if (!conflictCase.adminComment || conflictCase.adminComment.trim() === '') {
      this.toastr.error('Comment cannot be empty', 'Error');
      return;
    }
    this.stateService.toggleWaitingPage(true);
    this.conflictService
      .setAdminComment(conflictCase.id, conflictCase.adminComment.trim())
      .subscribe(
        (res) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success('Comment saved successfully', 'Success');
        },
        (error) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(error.error.message, 'Error');
        },
      );
  }

  setHHpercentage(conflictCase: ConflictCase): void {
    if (
      conflictCase.toHHpercentage === null ||
      Number.isNaN(Number(conflictCase.toHHpercentage))
    ) {
      this.toastr.error('Percentage have to be a number', 'Error');
      return;
    }

    conflictCase.refundAmountHH = Math.round(
      (conflictCase.totalAmount * conflictCase.toHHpercentage) / 100,
    );
    conflictCase.refundAmountTO =
      conflictCase.totalAmount - conflictCase.refundAmountHH;

    this.stateService.toggleWaitingPage(true);
    this.conflictService
      .setToHHpercentage(conflictCase.id, conflictCase.toHHpercentage)
      .subscribe(
        (res) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success('Percentage saved successfully', 'Success');
        },
        (error) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(error.error.message, 'Error');
        },
      );
  }

  setTORefundAmount(conflictCase: ConflictCase): void {
    conflictCase.refundAmountHH =
      conflictCase.totalAmount - conflictCase.refundAmountTO;

    if (conflictCase.refundAmountTO < 0) {
      this.toastr.error('Amount cannot be negative', 'Error');
      return;
    }
    if (conflictCase.refundAmountTO > conflictCase.totalAmount) {
      this.toastr.error('Amount cannot be greater than total amount', 'Error');
      return;
    }
    if (
      conflictCase.refundAmountTO === null ||
      conflictCase.refundAmountTO === undefined
    ) {
      this.toastr.error('Amount cannot be empty', 'Error');
      return;
    }
    if (Number.isNaN(Number(conflictCase.refundAmountTO))) {
      this.toastr.error('Amount have to be a number', 'Error');
      return;
    }

    this.setRefundAmount(conflictCase);
  }

  setHHRefundAmount(conflictCase: ConflictCase): void {
    conflictCase.refundAmountTO =
      conflictCase.totalAmount - conflictCase.refundAmountHH;

    if (conflictCase.refundAmountHH < 0) {
      this.toastr.error('Amount cannot be negative', 'Error');
      return;
    }
    if (conflictCase.refundAmountHH > conflictCase.totalAmount) {
      this.toastr.error('Amount cannot be greater than total amount', 'Error');
      return;
    }
    if (
      conflictCase.refundAmountHH === null ||
      conflictCase.refundAmountHH === undefined
    ) {
      this.toastr.error('Amount cannot be empty', 'Error');
      return;
    }
    if (Number.isNaN(Number(conflictCase.refundAmountHH))) {
      this.toastr.error('Amount have to be a number', 'Error');
      return;
    }
    this.setRefundAmount(conflictCase);
  }

  setRefundAmount(conflictCase: ConflictCase): void {
    this.stateService.toggleWaitingPage(true);
    this.conflictService
      .setRefundAmount(
        conflictCase.id,
        conflictCase.refundAmountTO,
        conflictCase.refundAmountHH,
      )
      .subscribe(
        (res) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success('Amount saved successfully', 'Success');
        },
        (error) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(error.error.message, 'Error');
        },
      );
  }

  setDeadline(event, conflictCase: ConflictCase): void {
    conflictCase.deadline = event.target.value;
    this.stateService.toggleWaitingPage(true);
    this.conflictService
      .setDeadline(conflictCase.id, conflictCase.deadline)
      .subscribe(
        (res) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.success('Deadline saved successfully', 'Success');
        },
        (error) => {
          this.stateService.toggleWaitingPage(false);
          this.toastr.error(error.error.message, 'Error');
        },
      );
  }

  closeConflictCase(conflictCaseId: number): void {
    this.stateService.toggleWaitingPage(true);
    this.conflictService.closeConflictCase(conflictCaseId).subscribe(
      (res) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.success('Conflict case closed successfully', 'Success');
        this.fetchData();
      },
      (error) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error(error.error.message, 'Error');
      },
    );
  }

  cancelConflictCase(conflictCaseId: number): void {
    this.stateService.toggleWaitingPage(true);
    this.conflictService.cancelConflictCase(conflictCaseId).subscribe(
      (res) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.success('Conflict case cancelled successfully', 'Success');
        this.fetchData();
      },
      (error) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error(error.error.message, 'Error');
      },
    );
  }

  reopenConflictCase(conflictCaseId: number): void {
    this.stateService.toggleWaitingPage(true);
    this.conflictService.reopenConflictCase(conflictCaseId).subscribe(
      (res) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.success('Conflict case reopened successfully', 'Success');
        this.fetchData();
      },
      (error) => {
        this.stateService.toggleWaitingPage(false);
        this.toastr.error(error.error.message, 'Error');
      },
    );
  }

  handleDownloadEvidence(userRole: string): void {
    switch (userRole) {
      case 'to':
        this.downloadEvidenceTO();
        break;
      case 'hh':
        this.downloadEvidenceHH();
        break;
      default:
        break;
    }
  }

  downloadEvidenceTO(): void {
    const docs = this.conflictCase.evidenceTO?.docs;
    const images = this.conflictCase.evidenceTO?.images;
    const media = [...docs, ...images];
    let fileUrls = [];
    media.forEach((file) => {
      fileUrls.push(file.url);
    });

    this.miscService.downloadFiles(fileUrls).subscribe((blob: Blob) => {
      saveAs(blob, `Opgaver-ejer-konfliktsag-${this.conflictCase.id}.zip`);
    });
  }

  downloadEvidenceHH(): void {
    const docs = this.conflictCase.evidenceHH?.docs;
    const images = this.conflictCase.evidenceHH?.images;
    const media = [...docs, ...images];
    let fileUrls = [];
    media.forEach((file) => {
      fileUrls.push(file.url);
    });

    this.miscService.downloadFiles(fileUrls).subscribe((blob: Blob) => {
      saveAs(blob, `Handyhander-konfliktsag-${this.conflictCase.id}.zip`);
    });
  }

  uploadConflictCaseMedia(userType: string) {
    let userId;
    if (userType === 'to') {
      userId = this.taskOwner.id;
    } else if (userType === 'hh') {
      userId = this.handyhander.id;
    }
    const dialog = this.dialog.open(UploadConflictCaseMediaDialogComponent, {
      width: '80vw',
      height: '80vh',

      data: { userId, conflictCase: this.conflictCase },
    });
  }
}
