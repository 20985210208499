<div class="dialog_container" style="max-height: 80vh">
  <div>
    <h3 class="header">{{user?.firstName}}'s Stored Credit</h3>
    <p class="header"> All available credits by Task</p>
  </div>
  @for (storedCredit of storedCredits; track storedCredit) {
    <div class="card_container">
      <app-storedCredits-card [storedCreditEntry]="storedCredit"></app-storedCredits-card>
      <mat-checkbox
        [color]="'primary'"
        (change)="onCheckboxChange($event, storedCredit)"
      ></mat-checkbox>
    </div>
  }
  <div class="button_row">
    <button class="pinploy_button gray small_button" (click)="close()">Cancel</button>
    <button class="pinploy_button small_button" [ngClass]="{ 'disabled': isWithdrawBtnDisabled() }" [disabled]="isWithdrawBtnDisabled()" (click)="confirmWithdrawal()">Withdraw selected credits ({{creditsToBeWithdrawnTotalAmount / 100}} kr.)</button>
  </div>
</div>

