import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { OfferService } from 'src/app/services/offer.service';
import { UserService } from 'src/app/services/user.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserCardComponent } from '../../cards/user-card/user-card.component';
import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'offer-negotiation-dialog',
    templateUrl: 'offerNegotiationDialog.component.html',
    styleUrls: ['../dialogs.scss', 'offerNegotiationDialog.component.scss'],
    imports: [UserCardComponent, ...ALL_IMPORTS]
})
export class OfferNegotiationDialogComponent {
  offerId: number;
  handyhander: any;
  handyhanderId: number;
  taskOwner: any;
  taskOwnerId: number;

  rounds: any;

  isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<OfferNegotiationDialogComponent>,
    private offerService: OfferService,
    private userService: UserService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  async ngOnInit() {
    this.offerId = this.data.offerId;
    this.handyhanderId = this.data.handyhanderId;
    this.taskOwnerId = this.data.taskOwnerId;

    const userProfilePromises = [
      lastValueFrom(this.userService.getUserProfile(this.handyhanderId)),
      lastValueFrom(this.userService.getUserProfile(this.taskOwnerId)),
    ];

    const [handyhander, taskOwner] = await Promise.all(userProfilePromises);
    this.handyhander = handyhander;
    this.taskOwner = taskOwner;

    if (!this.handyhander || !this.taskOwner) {
      this.toastr.error('An error occurred while fetching user profiles');
      this.close();
      return;
    }

    this.offerService.getNegotiationHistory(this.offerId).subscribe((res) => {
      this.rounds = res;
      this.mapUsersToNegotiations();
      this.isLoading = false;
    });
  }

  mapUsersToNegotiations() {
    if (this.handyhander && this.taskOwner && this.rounds) {
      this.rounds.forEach((round) => {
        if (round.fromUserId === this.handyhanderId) {
          round.user = this.handyhander;
        } else if (round.fromUserId === this.taskOwnerId) {
          round.user = this.taskOwner;
        }
      });
    }
  }

  close(res: Task = null): void {
    this.dialogRef.close(res);
  }
}
