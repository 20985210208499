import { trigger, transition, style, animate, keyframes } from '@angular/animations';

export const fadeUpAnimation = trigger('fadeUpAnimation', [
  transition(':leave', [
    animate(
      '800ms ease-out',
      keyframes([
        style({ 
          opacity: 1, 
          transform: 'translateY(0%)', 
          height: '*', 
          minHeight: '*',
          margin: '*',
          padding: '*',
          offset: 0 
        }),
        style({ 
          opacity: 0.8, 
          transform: 'translateY(-2%)', 
          height: '*', 
          minHeight: '*',
          margin: '*',
          padding: '*',
          offset: 0.4 
        }),
        style({ 
          opacity: 0, 
          transform: 'translateY(-5%)', 
          height: 0, 
          minHeight: 0,
          margin: 0,
          padding: 0,
          offset: 1 
        }),
      ]),
    ),
  ]),
]); 