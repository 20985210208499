import { User } from './user.model';
import { Task } from './task.model';
import { Tier } from './tier.model';
export class Offer {
  id: number;
  status: string;
  message: string;
  stripeChargeId: string;
  priceBid: number;
  isPaid: boolean;

  taskId: number;
  task: Task;

  userId: number;
  user: User;

  createdAt: string;
  updatedAt: string;

  offerPriceIncreases?: any;
  acceptedAt?: string;
  completedAt?: any;
  cancelledAt?: any;
  expiresAt?: string;

  censored: Date;

  taskOwnerFee: number;

  tierData: Tier;

  constructor(data: any = null) {
    Object.assign(this, data);

    if (!this.user) {
      this.user = new User();
    }
    if (!this.task) {
      this.task = new Task();
    }
    if (!this.tierData) {
      this.tierData = new Tier();
    }
  }
}
