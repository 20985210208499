<div class="page_container">
  <form class="form-container">
    <mat-form-field class="input">
      <input matInput placeholder="Object's id ..." type="number" [(ngModel)]="id" name="objectId" />
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Type" [(ngModel)]="type" name="typeSelector">
        @for (type of types; track type) {
          <mat-option [value]="type.value">
            {{type.name}}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="getData()">Submit</button>
  </form>

</div>

