import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';

export interface QuestionDialogData {
  title: string;
  description: string;
  showCheckBox?: boolean;
  checkBoxQuestion?: string;
}

@Component({
    selector: 'app-question-dialog',
    templateUrl: 'questionDialog.component.html',
    styleUrls: ['../dialogs.scss'],
    imports: [MatCheckbox]
})
export class QuestionDialogComponent {
  title: any;
  description: any;

  showCheckBox = false;
  checkBoxQuestion = '';
  checkBoxStatus = false;

  constructor(
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionDialogData,
  ) {
    if (!data.title || !data.description) {
      throw new Error('Title and description are required to open this dialog');
    }
    this.title = data.title;
    this.description = data.description;
    this.showCheckBox = data.showCheckBox || false;
    this.checkBoxQuestion = data.checkBoxQuestion || '';
  }

  close(res: any = null): void {
    if (!this.showCheckBox && !this.checkBoxQuestion) {
      this.dialogRef.close(res);
    } else {
      const data = {
        res,
        checkBoxStatus: this.checkBoxStatus,
      };
      this.dialogRef.close(data);
    }
  }

  apply(): void {
    this.close('yes');
  }

  checkBox(event): void {
    this.checkBoxStatus = event;
  }
}
