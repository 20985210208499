<!-- Header -->
@if (showComponent()) {
  <div>
    <app-header [isInsurance]="isInsurance"></app-header>
  </div>
}

<!-- Pages -->
<div class="container">
  @if (showComponent()) {
    <div>
      @if (isInsurance) {
        <app-navbar-insurance></app-navbar-insurance>
      }
      @if (!isInsurance) {
        <app-navbar></app-navbar>
      }
    </div>
  }

  <div
    class="page_container"
    [ngClass]="{ 'full-screen': !showComponent() }"
    >
    <router-outlet></router-outlet>
  </div>
</div>

<app-waiting />