@if (user) {
  <mat-card>
    <div class="card_header">
      <h5>{{ title }}</h5>
      <button
        class="pinploy_button small_button"
        (click)="goToUserProfile(user.id, $event)"
        >
        Open user profile
      </button>
    </div>
    <div class="card_body">
      <!-- USER ID-->
      <div class="card_row">
        <p class="strong">Id</p>
        @if (user.id) {
          <p>{{ user.id }}</p>
        }
      </div>
      <!-- USER NAME-->
      <div class="card_row">
        <p class="strong">Name</p>
        <p>{{ user.firstName }} {{ user.lastName }}</p>
      </div>
      <!-- USER EMAIL-->
      <div class="card_row">
        <p class="strong">Email</p>
        @if (user.email) {
          <p>{{ user.email }}</p>
        }
        @if (!user.email) {
          <p>No email</p>
        }
      </div>
      <!-- USER PHONE-->
      <div class="card_row">
        <p class="strong">Phone</p>
        @if (user.phone) {
          <p>{{ user.phone }}</p>
        }
        @if (!user.phone) {
          <p>No phone number</p>
        }
      </div>
      <!-- USER STRIPE CUSTOMER ID-->
      @if (inclStripeCustomer && user.stripeCustomerId) {
        <div
          class="card_row"
          >
          <p class="strong">Stripe Customer</p>
          <div class="icon-row">
            <p>{{ user.stripeCustomerId }}</p>
            <a
              target="_blank"
          href="https://dashboard.stripe.com/customers/{{
            user.stripeCustomerId
          }}"
              class="open-icon"
              >
              <mat-icon class="open-icon">open_in_new</mat-icon>
            </a>
          </div>
        </div>
      }
      <!-- USER STRIPE CONNECT ID-->
      @if (inclStripeConnect && user.stripeAccountId) {
        <div
          class="card_row"
          >
          <p class="strong">Stripe Connect</p>
          <div class="icon-row">
            <p>{{ user.stripeAccountId }}</p>
            <a
              target="_blank"
          href="https://dashboard.stripe.com/connect/accounts/{{
            user.stripeAccountId
          }}"
              class="open-icon"
              >
              <mat-icon class="open-icon">open_in_new</mat-icon>
            </a>
          </div>
        </div>
      }
    </div>
  </mat-card>
}

@if (!user) {
  <mat-card>
    <div class="card_header">
      <h5>{{ title }}</h5>
    </div>

  <div class="spinner_body">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
}
