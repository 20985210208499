import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ALL_IMPORTS } from 'src/app/shared/standalone-imports';

@Component({
    selector: 'app-notification-web-section',
    templateUrl: 'notification-web-section.component.html',
    styleUrls: ['notification-web-section.component.scss'],
    imports: [...ALL_IMPORTS]
})
export class NotificationWebSectionComponent implements OnInit {
  @Input() contextForm: UntypedFormGroup;
  @Input() choosedLanguage: string;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(change: any) {
    if ('choosedLanguage' in change) {
      this.computeLanguage();
    }

    if ('disabled' in change) {
      this.computeDisabled(change.disabled.currentValue);
    }
  }

  /**
   * Disable or enable both language fields then call computeLanguage() to enable/disable the choosed language field
   */
  private computeDisabled(disabled: boolean) {
    this.contextForm.markAsUntouched();
    if (disabled) {
      this.contextForm.disable();
    } else {
      this.contextForm.enable();
      this.computeLanguage();
    }
  }

  private computeLanguage() {
    if (this.disabled) {
      this.contextForm.disable();
      return;
    }
    if (
      !this.contextForm ||
      !('en' in this.contextForm.controls) ||
      !('dk' in this.contextForm.controls)
    )
      return;

    if (this.choosedLanguage === 'en') {
      this.contextForm.get('en').enable();
      this.contextForm.get('dk').disable();
    } else if (this.choosedLanguage === 'dk') {
      this.contextForm.get('en').disable();
      this.contextForm.get('dk').enable();
    } else {
      this.contextForm.get('en').enable();
      this.contextForm.get('dk').enable();
    }
  }
}
