<div class="dialog_container">
  <h3>{{ title }}</h3>

  <div class="cancel-information">
    <mat-form-field class="reason-comment-input no-underline">
      <mat-label>Reason</mat-label>
      <mat-select [(ngModel)]="cancelReason">
        @for (reason of reasons; track reason) {
          <mat-option
            [value]="reason.reason"
            >{{ reason.readable }}</mat-option
            >
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="reason-comment-input">
        <mat-label>Cancel reason comment</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="3"
          placeholder="Comment"
          [(ngModel)]="cancelReasonComment"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="button">
      <button (click)="updateCancellation()" class="pinploy_button small_button">Update</button>
    </div>
  </div>
