import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "./auth.service";

import { environment } from "../../environments/environment";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private toastr: ToastrService, private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf(environment.apiUrl) !== -1 &&
      !request.url.endsWith("/login")
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: this.auth.getToken(),
          "X-Handyhand-Client": "admin",
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event) => {},
        (err) => {
          if (err && err.error === "Token does not exist") {
            this.auth.logout();
            this.toastr.info(
              `The platform has been recently updated. Please try to login again.`
            );
          }
        }
      )
    );
  }
}
