import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAuthenticated()) {
      this.toastr.warning('Please log in to access this page');
      this.router.navigate(['/login']);
      return false;
    }

    if (this.authService.hasRole('admin')) {
      return true;
    }

    if (this.authService.hasRole('insurance')) {
      // Redirect insurance users to insurance-applications
      this.toastr.warning(
        'Access denied. Insurance users can only access insurance-related pages',
      );
      this.router.navigate(['/insurance-applications']);
      return false;
    }

    // If neither admin nor insurance, redirect to login
    this.toastr.warning('Access denied. Invalid user role');
    this.router.navigate(['/login']);
    return false;
  }
}

@Injectable()
export class IsInsurance {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAuthenticated()) {
      this.toastr.warning('Please log in to access this page');
      this.router.navigate(['/login']);
      return false;
    }

    // Admin can access insurance pages
    if (this.authService.hasRole('admin')) {
      return true;
    }

    // Insurance role can access insurance pages
    if (this.authService.hasRole('insurance')) {
      return true;
    }

    // If neither admin nor insurance, redirect to login
    this.toastr.warning(
      'Access denied. You need insurance or admin role to access this page',
    );
    this.router.navigate(['/login']);
    return false;
  }
}

@Injectable()
export class PreventLoginGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  canActivate() {
    if (!this.authService.isAuthenticated()) {
      return true;
    }

    // If user is insurance, redirect to insurance-applications
    if (this.authService.hasRole('insurance')) {
      this.toastr.info('You are already logged in');
      this.router.navigate(['/insurance-applications']);
      return false;
    }

    // If user is admin, redirect to users page
    this.toastr.info('You are already logged in');
    this.router.navigate(['/users']);
    return false;
  }
}
