import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { StorageService } from './storage.service';
import { StateService } from './state.service';

import { environment } from '../../environments/environment';
import { logClass } from './logger.decorator';
import { SocketService } from './socket.service';

@logClass(['getToken', 'isAuthenticated', 'handleError'])
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public tokenUpdated = new Subject<void>();

  constructor(
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
    private storage: StorageService,
    private stateService: StateService,
    private toastr: ToastrService,
    private router: Router,
    private socketService: SocketService,
  ) {}

  login(data: any): Observable<any> {
    const url = `${environment.apiUrl}/api/users/login`;
    return this.http.post<any>(url, data);
  }

  getToken(): string {
    return this.storage.token;
  }

  logout() {
    this.storage.clear();
    this.socketService.disconnect();
    this.router.navigate(['/login']);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    const isExpired = this.jwtHelper.isTokenExpired(token);
    if (isExpired) {
      return false;
    }

    return true;
  }

  hasRole(role: string | string[]): boolean {
    const roles = Array.isArray(role) ? role : [role];
    const isAuthenticated = this.isAuthenticated();
    if (!isAuthenticated) {
      return false;
    }

    const token = this.getToken();
    const decodedToken = this.jwtHelper.decodeToken(token);
    return roles.includes(decodedToken.role);
  }

  handleError(err: any, msg: string) {
    if (err && err.error !== 'Token does not exist') {
      this.toastr.error(msg);
      this.stateService.toggleWaitingPage(false);
    }
  }
}
